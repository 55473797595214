import { FC, memo, ReactNode, useEffect } from 'react';
import { HeatmapDataType } from '@demind-inc/core';
import { Skeleton } from '@mui/material';
import { useRecoilState } from 'recoil';
import dayjs from 'dayjs';

import './CustomTimeGutter.scss';
import { Heatmap, ZoneLabelTimeline, EnergyTooltip } from '../../Circadian';
import { HOUR_BLOCK_HEIGHT } from '../constants';
import {
  CreateEventProps,
  PeakDipStartEndSet,
  heatmapPopupAtom,
  isEnergyPopupVisibleAtom,
} from '../../../../data-access';

interface CustomTimeGutterProps {
  heatmapData: HeatmapDataType[];
  peaksDipsBoundaries?: PeakDipStartEndSet;
  isDefaultHeatmap?: boolean;
  onCreateEvent?: (event: CreateEventProps) => void;
  children: ReactNode;
}

const calendarHeight = 24 * HOUR_BLOCK_HEIGHT;
const heatmapWidth = '18';
let hasInitializedPopup = false;

const CustomTimeGutter: FC<CustomTimeGutterProps> = memo(
  ({
    heatmapData,
    peaksDipsBoundaries,
    isDefaultHeatmap = false,
    onCreateEvent = () => void 0,
    children,
  }) => {
    const [selectedPoint, setSelectedPoint] = useRecoilState(heatmapPopupAtom);
    const [, setIsPopupVisible] = useRecoilState(isEnergyPopupVisibleAtom);

    // Initialize the selected point as the current time
    useEffect(() => {
      if (!heatmapData?.length || hasInitializedPopup) {
        return;
      }
      const currentData = heatmapData.find((d) => d.y === dayjs().format('YYYY-MM-DD HH:mm'));
      if (currentData) {
        setSelectedPoint(currentData);
        hasInitializedPopup = true;
        setTimeout(() => setIsPopupVisible(true), 50);
      }
    }, [heatmapData]);

    // Hide the popup when the calendar is scrolled
    useEffect(() => {
      const calendarElement = document.querySelector('.rbc-time-content');
      if (!calendarElement) return;

      const handleScroll = () => {
        setIsPopupVisible(false);
      };

      calendarElement.addEventListener('scroll', handleScroll);
      return () => {
        calendarElement.removeEventListener('scroll', handleScroll);
      };
    }, []);

    const handlePointClick = (point: HeatmapDataType) => {
      setSelectedPoint(point);
      setTimeout(() => setIsPopupVisible(true), 50);
    };

    const tooltipTop = selectedPoint
      ? `${(dayjs(selectedPoint.y).get('hour') + dayjs(selectedPoint.y).get('minute') / 60) * HOUR_BLOCK_HEIGHT}px`
      : '0';

    return (
      <div className="custom-time-gutter">
        {peaksDipsBoundaries && (
          <ZoneLabelTimeline
            peaksDipsBoundaries={peaksDipsBoundaries}
            className="custom-time-gutter__zone-labels"
          />
        )}
        {children}
        <div className="custom-time-gutter__heatmap-container">
          {!heatmapData?.length ? (
            <Skeleton
              variant="rectangular"
              width={`${heatmapWidth}px`}
              height={`${calendarHeight}px`}
            />
          ) : (
            <Heatmap
              data={heatmapData}
              width={heatmapWidth}
              height={`${calendarHeight}`}
              opacity={isDefaultHeatmap ? 0.1 : 1}
              onPointClick={handlePointClick}
            />
          )}
          <EnergyTooltip
            selectedPoint={selectedPoint}
            top={tooltipTop}
            onCreateEvent={onCreateEvent}
          />
        </div>
      </div>
    );
  }
);

export default CustomTimeGutter;
