import { debounce } from 'lodash';
import { startTransition, useCallback, useEffect, useState } from 'react';

interface UseDelayedVisibleParams {
  skippedDependencies?: boolean[];
  delayedMs?: number;
}

export const useDelayedVisible = ({
  skippedDependencies = [],
  delayedMs = 1000,
}: UseDelayedVisibleParams) => {
  const [isVisible, setIsVisible] = useState(false);

  const init = useCallback(
    debounce(async (skip: boolean) => {
      if (skip) {
        return;
      }

      setIsVisible(true);
    }, delayedMs),
    [delayedMs]
  );

  useEffect(() => {
    const delayShowingOverlay = setTimeout(() => {
      startTransition(() => {
        const shouldSkip = skippedDependencies.some((dep) => dep);
        init(shouldSkip);
      });
    }, delayedMs); // Adjust the delay to avoid screen freeze

    return () => clearTimeout(delayShowingOverlay);
  }, [delayedMs, skippedDependencies]);

  const handleClose = async () => {
    startTransition(() => {
      setTimeout(() => {
        // Delay the closing of the overlay to allow the animation to finish
        setIsVisible(false);
      }, 300);
    });
  };

  return { isVisible, handleClose };
};
