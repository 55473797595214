import { useMutation } from '@tanstack/react-query';
import { ScheduledAction, SchedulerPreference } from '@demind-inc/core';

import { schedulerApi } from '../api';
import { AISchedulerVersion } from '../types';

interface UseRunAITimeBoxPlannerParams {
  userId: string;
  metricId: string;
  targetTime: string;
  preferences: SchedulerPreference;
  version?: AISchedulerVersion;
}

export function useRunAITScheduler() {
  const runAISchedulerMutaion = useMutation({
    mutationFn: ({
      userId,
      metricId,
      targetTime,
      preferences,
      version = 'timebox',
    }: UseRunAITimeBoxPlannerParams) => {
      if (version === 'timebox') {
        return schedulerApi
          .runAITimeBoxPlannerV2FormattedRes(userId, {
            metricId,
            currentTime: targetTime,
            preferences,
          })
          .then(({ data }) => ({
            schedules: (data.schedules || []) as ScheduledAction[],
            scienceApiReq: data.scienceApiReq,
            scienceApiRes: data.scienceApiRes,
          }));
      } else {
        return schedulerApi
          .runAITaskScheduler(userId, {
            metricId,
            currentTime: targetTime,
            preferences,
          })
          .then(({ data }) => ({
            schedules: (data.schedules || []) as ScheduledAction[],
            scienceApiReq: data.scienceApiReq,
            scienceApiRes: data.scienceApiRes,
          }));
      }
    },
  });

  return {
    runAIScheduler: runAISchedulerMutaion.mutateAsync,
    ...runAISchedulerMutaion,
  };
}
