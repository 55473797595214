import { FC } from 'react';
import './AiSchedulerText.scss';
import { Typography } from '@mui/material';

const AiSchedulerText: FC = () => {
  return (
    <Typography variant="caption" className="ai-scheduler-text">
      AI Scheduler
    </Typography>
  );
};

export default AiSchedulerText;
