import { TaskItem, TodoAppFrom } from '@demind-inc/core';
import dayjs from 'dayjs';

export const dummyTasks: TaskItem[] = [
  {
    taskId: 'task-1',
    originalTaskId: 'task-1',
    updatedAt: '1672222340',
    appFrom: 'lifestack',
    name: 'Important task',
    dueDateTime: {
      date: dayjs().format('YYYY-MM-DD'),
      timezone: dayjs.tz.guess(),
      datetime: undefined,
    },
    startDateTime: {
      date: dayjs().format('YYYY-MM-DD'),
      timezone: dayjs.tz.guess(),
      datetime: undefined,
    },
  },
];
