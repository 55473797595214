import { useCallback, useMemo, useState } from 'react';
import { useAuthContext, useCircadianContext } from '../context';
import { CalcTimeFrame, schedulerApi } from '../api';
import { useRecoilValue } from 'recoil';
import { selectedDateAtom } from '../recoil';

export const useAIPlannerTimeFrame = () => {
  const { user } = useAuthContext();
  const selectedDate = useRecoilValue(selectedDateAtom);
  const { peakEnergyByDate, isFetchingCircadian } = useCircadianContext();

  const [isCalculatingTimeFrame, setIsCalculatingTimeFrame] = useState(false);

  const targetDatePeakEnergy = useMemo(
    () =>
      peakEnergyByDate.find((item) => item.date === selectedDate.format('YYYY-MM-DD'))?.peakEnergy,
    [peakEnergyByDate, selectedDate]
  );

  const calcAIPlannerTimeFrame = useCallback(
    async (originalDurations: CalcTimeFrame) => {
      if (!user.dataTrendId || !targetDatePeakEnergy) {
        return;
      }
      setIsCalculatingTimeFrame(true);

      const output = await schedulerApi.calcAIPlannerTimeFrame(user.dataTrendId, {
        targetPeakEnergy: targetDatePeakEnergy,
        originalDurations,
      });
      setIsCalculatingTimeFrame(false);

      return output.data;
    },
    [user, targetDatePeakEnergy]
  );

  return {
    isCalculatingTimeFrame: isFetchingCircadian || isCalculatingTimeFrame,
    calcAIPlannerTimeFrame,
  };
};
