import { Typography } from '@mui/material';
import './ChartLegend.scss';
import { LEGENDS } from './constants';

const ChartLegend = () => {
  
  return (
    <div className="chart-legends">
      {LEGENDS.map((legend) => (
        <div key={legend.name} className="chart-legends__item">
          <legend.icon className="chart-legend-item-icon" />
          <Typography variant={'subtitle2'} className="chart-legends__item__name">
            {legend.name}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default ChartLegend;
