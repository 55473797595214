import {
  CalendarEvent,
  DateTimeSet,
  TaskDataTrendWithData,
  TaskItem,
  TimeFormatType,
} from '@demind-inc/core';
import dayjs from 'dayjs';

import { FirestoreTaskItem } from '../data-access';
import { start } from 'repl';
import { generateRandomHexColor, getCssVariable } from '../utils';
import { TaskLabelChartSet } from '../components';

const colorGrayLight = getCssVariable('--color-gray-light');

export const getParamToUpdateTaskTimeFromCalendar = (
  event: Partial<CalendarEvent>
): Partial<FirestoreTaskItem> => {
  return {
    ...(event.start
      ? {
          startDateTime: {
            datetime: dayjs(event.start.date).toISOString(),
            date: '',
            timezone: dayjs.tz.guess(),
          },
        }
      : {}),
    ...(event.end
      ? {
          dueDateTime: {
            datetime: dayjs(event.end.date).toISOString(),
            date: '',
            timezone: dayjs.tz.guess(),
          },
        }
      : {}),
    ...(event.start && event.end
      ? {
          duration: {
            amount: dayjs(event.end.date).diff(dayjs(event.start.date), 'minute'),
            unit: 'minute',
          },
        }
      : {}),
    addedToCalendar: true,
  };
};

export const removeScheduledTaskTimeFromTask = (date: string): Partial<FirestoreTaskItem> => {
  return {
    startDateTime: {
      datetime: '',
      date,
      timezone: dayjs.tz.guess(),
    },
    dueDateTime: {
      datetime: '',
      date,
      timezone: dayjs.tz.guess(),
    },
    duration: null,
    addedToCalendar: false,
  };
};

/**
 * Formats the due date for updating a task.
 *
 * @param label - Specifies whether the date is a 'start' or 'due' date.
 * @param dateSet - An optional object containing date and time information.
 * @returns A partial `TaskItem` object with the formatted date and time.
 */
export const getFormattedDueDateForUpdateTask = (
  label: 'start' | 'due',
  dateSet?: DateTimeSet
): Partial<TaskItem> => {
  const labelKey = label === 'start' ? 'startDateTime' : 'dueDateTime';
  if (!dateSet) {
    return {
      [labelKey]: undefined,
    };
  }
  const hasDataTime = !!dateSet.datetime;

  return {
    [labelKey]: {
      ...(dateSet.datetime ? { datetime: dateSet.datetime } : {}),
      ...(dateSet.date && !hasDataTime ? { date: dateSet.date } : {}), // Send date only when datetime is not set
      timezone: dateSet.timezone || dayjs.tz.guess(),
    },
  };
};

/**
 * Formats the due date for updating a task.
 *
 * @param label - Specifies whether the date is a 'start' or 'due' date.
 * @param dateSet - An optional object containing date and time information.
 * @returns A partial `TaskItem` object with the formatted date and time.
 */
export const getFormattedDueDateForAddTask = (
  label: 'start' | 'due',
  dateSet?: DateTimeSet
): Partial<TaskItem> => {
  const hasValidDate = !!dateSet && (!!dateSet.datetime || !!dateSet.date);
  if (!hasValidDate) {
    return {};
  }

  const labelKey = label === 'start' ? 'startDateTime' : 'dueDateTime';
  const hasDataTime = !!dateSet.datetime;

  return {
    [labelKey]: {
      ...(dateSet.datetime ? { datetime: dateSet.datetime } : {}),
      ...(dateSet.date && !hasDataTime ? { date: dateSet.date } : {}), // Send date only when datetime is not set
      timezone: dateSet.timezone || dayjs.tz.guess(),
    },
  };
};

/**
 * Calculates the duration between two DateTimeSet objects and returns it in minutes.
 *
 * @param startDateTime - The starting DateTimeSet object.
 * @param dueDateTime - The ending DateTimeSet object.
 * @returns A Partial<TaskItem> object containing the duration in minutes, or an empty object if either DateTimeSet is invalid.
 */
export const getFormattedDuration = (
  startDateTime?: DateTimeSet,
  dueDateTime?: DateTimeSet
): Partial<TaskItem> => {
  if (!startDateTime || !dueDateTime || !dueDateTime.datetime || !startDateTime.datetime) {
    return {};
  }

  const durationMin = Math.abs(
    dayjs(dueDateTime.datetime).diff(dayjs(startDateTime.datetime), 'minute')
  );

  return {
    duration: {
      amount: durationMin,
      unit: 'minute',
    },
  };
};

export const getFormattedOverdueTime = (task: TaskItem, timeFormat: TimeFormatType) => {
  let outputTime = '';
  const timeformat = timeFormat === '12h' ? 'h:mm A' : 'HH:mm';

  if (task.dueDateTime?.date) {
    outputTime = dayjs(task.dueDateTime.date).format('MMM D');
  } else if (task.dueDateTime?.datetime) {
    if (task.startDateTime?.datetime) {
      outputTime =
        dayjs(task.startDateTime.datetime).format(`MMM D ${timeformat}`) +
        ' - ' +
        dayjs(task.dueDateTime.datetime).format(timeformat);
    } else {
      outputTime = dayjs(task.dueDateTime.datetime).format(`MMM D ${timeformat}`);
    }
  }

  return outputTime;
};

export const transformTaskLabelToChartSetForTrend = (
  item: TaskDataTrendWithData
): TaskLabelChartSet => {
  let obj: TaskLabelChartSet = {};
  const labels = item.labels ?? [];

  labels.forEach((label) => {
    obj[label.labelName] = {
      color: label.labelColor || generateRandomHexColor(), //Assign color randomly
      plannedTaskNum: label.plannedTasks.length ?? 0,
      completedTaskNum: label.completedTasks.length ?? 0,
    };
  });

  const unlabeledPlannedTask = item.plannedTasks?.filter((t) => !t.labels?.length).length ?? 0;
  const unlabeledCompletedTask = item.completedTasks?.filter((t) => !t.labels?.length).length ?? 0;

  // Add unlabeled data
  obj.unlabeld = {
    color: colorGrayLight,
    plannedTaskNum: unlabeledPlannedTask,
    completedTaskNum: unlabeledCompletedTask,
  };

  return obj;
};

export const getDueDateYYYYMMDD = (dueDateTime?: DateTimeSet) => {
  if (!dueDateTime) {
    return;
  }
  if (dueDateTime.date) {
    return dueDateTime.date;
  }
  return dayjs(dueDateTime.datetime).format('YYYY-MM-DD');
};
