import clsx from 'clsx';
import { FC, useMemo } from 'react';
import {
  EventDataTrendWeeklyRes,
  selectedDateAtom,
  useWearablesContext,
} from '../../../data-access';
import { useRecoilValue } from 'recoil';
import { useEventTrend } from '../../../hooks/useEventTrend';
import { EventDataTrendEventItemWithData } from '@demind-inc/core';
import { CircularProgress, Typography } from '@mui/material';
import './DataEventDashboard.scss';
import {
  colorGreenBase,
  colorOrangeBase,
  eventTypeInfo,
} from '../../../components/DataView/constants';
import { EventWithCategory } from '../../../components';

interface DataEventDashboardProps {
  className?: string;
}

const DataEventDashboard: FC<DataEventDashboardProps> = ({ className }) => {
  const selectedDate = useRecoilValue(selectedDateAtom);
  const { dataTrendPeriod, eventDailyTrend, eventWeeklyTrend, isLoading } = useEventTrend({
    date: selectedDate,
  });
  const { stressDevices } = useWearablesContext();

  const hasStressWearableConnected = useMemo(() => stressDevices.length, [stressDevices]);
  return (
    <div className={clsx('data-event-dashboard', className)}>
      {eventTypeInfo?.map(({ label, eventKey, iconType }) => {
        const targetData =
          dataTrendPeriod === 'daily'
            ? eventDailyTrend?.[eventKey]
            : eventWeeklyTrend?.[eventKey as keyof EventDataTrendWeeklyRes];
        if (eventKey === 'stressfulEvent' && !hasStressWearableConnected) {
          return (
            <EventBox title={label} isLoading={isLoading}>
              <div className="data-event-dashboard__inner_box">
                <Typography className="data-event-dashboard__inner_box__title" variant="body2">
                  No available devices are connected
                </Typography>
              </div>
            </EventBox>
          );
        }
        if (!targetData) {
          return (
            <EventBox title={label} isLoading={isLoading}>
              <div className="data-event-dashboard__inner_box">
                <Typography className="data-event-dashboard__inner_box__title" variant="body2">
                  No data
                </Typography>
                {['energyBoostingEvent', 'energyDrainingEvent'].includes(eventKey) && (
                  <div>
                    <Typography
                      variant="subtitle1"
                      className="data-event-dashboard__inner_box__description"
                    >
                      To get data, report your subjective feelings about events
                    </Typography>
                  </div>
                )}
              </div>
            </EventBox>
          );
        }
        const item = targetData as EventDataTrendEventItemWithData;
        const color = item.score >= 0 ? colorGreenBase : colorOrangeBase;
        return (
          <EventBox title={label} isLoading={isLoading}>
            <EventWithCategory
              className="data-event-dashboard__inner_box"
              event={item.event}
              color={color}
              score={item.score}
            />
          </EventBox>
        );
      })}
    </div>
  );
};

export default DataEventDashboard;

const EventBox: FC<{ title: string; children: React.ReactNode; isLoading: boolean }> = ({
  title,
  children,
  isLoading,
}) => (
  <div className="data-event-dashboard__box">
    <Typography variant="body1" className="data-event-dashboard__title">
      {title}
    </Typography>
    {isLoading ? (
      <div className="data-event-dashboard__inner_box data-event-dashboard__inner_box--loading">
        <CircularProgress />
      </div>
    ) : (
      children
    )}
  </div>
);
