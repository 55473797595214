import { useQuery } from '@tanstack/react-query';

import { tasksApi } from '../api';

interface UseTaskTrendParams {
  dataTrendId: string;
  startDate: string;
  endDate: string;
  enabled?: boolean;
}

interface UseTaskWeeklyTrendQueryParams {
  queryKey: ['lifestack.trend.task.weekly', UseTaskTrendParams];
}

export const useTaskWeeklyTrend = ({
  dataTrendId,
  startDate,
  endDate,
  enabled = true,
}: UseTaskTrendParams) => {
  const taskTrendQuery = useQuery({
    queryKey: ['lifestack.trend.task.weekly', { dataTrendId, startDate, endDate }],
    queryFn: ({ queryKey }: UseTaskWeeklyTrendQueryParams) => {
      const [, { dataTrendId, startDate, endDate }] = queryKey;
      return tasksApi.getWeeklyTasksTrend(dataTrendId, startDate, endDate).then(({ data }) => data);
    },
    refetchOnWindowFocus: false,
    staleTime: 15000, // 15s
    enabled: !!dataTrendId && enabled,
  });

  return {
    taskTrend: taskTrendQuery.data,
    ...taskTrendQuery,
  };
};
