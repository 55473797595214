import React, { useEffect, useRef, useState } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import {
  EventCategoryEnergyPreference,
  EventCategoryEnergyPreferenceLevel,
} from '@demind-inc/core';

import './EnergyPrefSelectMenu.scss';
import { energyPrefLevelText } from '../../../../Preference';

interface EnergyPrefSelectMenuProps {
  defaultEventPref: EventCategoryEnergyPreference;
  onChange: (newPref: EventCategoryEnergyPreference) => void;
}

const EnergyPrefSelectMenu: React.FC<EnergyPrefSelectMenuProps> = ({
  defaultEventPref,
  onChange,
}) => {
  const [selectedPrefLevel, setSelectedPrefLevel] =
    useState<EventCategoryEnergyPreferenceLevel>('about_same');
  const hasSetDefault = useRef(false);

  useEffect(() => {
    if (hasSetDefault.current) {
      return;
    }

    setSelectedPrefLevel(defaultEventPref.level);
    hasSetDefault.current = true;
  }, [defaultEventPref]);

  const handleChange = (newLevel: EventCategoryEnergyPreferenceLevel) => {
    setSelectedPrefLevel(newLevel);
    onChange({
      ...defaultEventPref,
      level: newLevel,
    });
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="energy-pref-settings-label" className="energy-pref-setting-select__label">
        {defaultEventPref.categoryName}
      </InputLabel>
      <Select
        style={{ height: '40px' }}
        labelId="energy-pref-settings-label"
        id="energy-pref-settings-select"
        value={selectedPrefLevel}
        MenuProps={{
          PaperProps: {
            className: 'energy-pref-setting-select__menu',
          },
        }}
        className="energy-pref-setting-select__select"
        label={defaultEventPref.categoryName}
        onChange={(e) => handleChange(e.target.value as EventCategoryEnergyPreferenceLevel)}
      >
        {Object.entries(energyPrefLevelText).map(([key, text]) => (
          <MenuItem value={key} className="energy-pref-setting-select__menu__item">
            {text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default EnergyPrefSelectMenu;
