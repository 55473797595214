import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TodoIntegrationType } from '@demind-inc/core';
import { useRecoilState } from 'recoil';

import { integrationsApi } from '../api';
import { taskErrorSnackBarAtom } from '../recoil';

interface UseInitTodoIntegrationParams {
  userId: string;
  todoType: TodoIntegrationType;
  token: string;
  shouldSyncTasks?: boolean;
}

export function useInitTodoIntegration() {
  const queryClient = useQueryClient();
  const [_, setTaskErrorSnackbar] = useRecoilState(taskErrorSnackBarAtom);

  const initTodoIntegrationMutation = useMutation({
    mutationFn: ({ userId, todoType, token, shouldSyncTasks }: UseInitTodoIntegrationParams) => {
      return integrationsApi
        .initTodoIntegrations(todoType, token, userId, shouldSyncTasks)
        .then(({ data }) => data.newTodoIntegrationId as string);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.projects'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.todoIntegrations'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.user'] });
    },
    onError: (error, { todoType }) => {
      if (!!error) {
        // If todo integration failed, need to reconnect so returns 401 error.
        setTaskErrorSnackbar({
          status: 401,
          message: 'Failed to connect.',
          provider: todoType,
        });
      }
    },
  });

  return {
    initTodoIntegration: initTodoIntegrationMutation.mutateAsync,
    ...initTodoIntegrationMutation,
  };
}
