import { FC } from 'react';

import './LifestackTitle.scss';

const LifestackTitle: FC = () => {
  return (
    <div className="lifestack-title">
      <span className="lifestack-title__text">
        The First Calendar With <span className="lifestack-title__text--highlight">Energy</span> In
        Mind
      </span>
    </div>
  );
};

export default LifestackTitle;
