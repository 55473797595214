import { FC, memo, useMemo } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import './WeekTaskBarChart.scss';

import { WeekBarChartItem } from '../../types';
import { CHART_HEIGHT, grayBase, grayPurple } from '../../constants';
import { Typography } from '@mui/material';

interface WeekTaskBarChartProps {
  data: WeekBarChartItem[];
  keys: string[];
  colors: string[];
  labels?: string[];
}

interface BarLabelProp {
  x: number;
  y: number;
  width: number;
  height: number;
  value: string;
  index: number;
}

const WeekTaskBarChart: FC<WeekTaskBarChartProps> = ({ data, keys, colors, labels }) => {
  const chartData = useMemo(() => {
    return data.map((item, index) => {
      const dayName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][index];
      const percentage = labels ? labels[index] : '';
      return {
        dayName,
        percentage,
        ...item,
      };
    });
  }, [data, labels]);

  const activeKeys = useMemo(() => {
    const filteredKeys = keys.filter((key) => data.some((day) => day[key] > 0));
    return filteredKeys;
  }, [keys, data]);

  const keyColorMap = useMemo(() => {
    const map = {};
    keys.forEach((key, index) => {
      map[key] = colors[index];
    });
    return map;
  }, [keys, colors]);

  const renderCustomizedLabel = (props: BarLabelProp) => {
    const { x, y, width, height, value, index } = props;
    const percentage = labels ? labels[index].replace(' %', '') : '0';

    if (value === '0 %') return null;

    return (
      <text
        x={x + width / 2}
        y={y - 10}
        fill="black"
        textAnchor="middle"
        fontSize={12}
        fontWeight={500}
      >
        {percentage}%
      </text>
    );
  };
  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <div className="week-task-chart__legends">
        {payload.map((entry: { color: string; value: string }, index: number) => (
          <div
            className="week-task-chart__legend"
            key={`item-${index}`}
            style={{ border: `1px solid ${entry.color}` }}
          >
            <div
              className="week-task-chart__legend-color"
              style={{ backgroundColor: entry.color }}
            />
            <Typography variant="caption">{entry.value}</Typography>
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className="week-task-chart">
      <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
        <BarChart
          data={chartData}
          margin={{ top: 20, right: 20, left: 10 }}
          style={{
            background: grayPurple,
            borderRadius: 16,
          }}
          barSize={20}
        >
          <CartesianGrid stroke={grayBase} strokeOpacity={0.1} />
          <XAxis
            dataKey="dayName"
            scale="point"
            padding={{ left: 30, right: 30 }}
            tickLine={false}
            style={{ fontSize: '12px', color: grayBase }}
            axisLine={false}
          />
          <Legend content={renderLegend} />
          {activeKeys.map((key, index) => (
            <Bar
              key={key}
              dataKey={key}
              name={key}
              stackId="a"
              fill={keyColorMap[key]}
              isAnimationActive={false}
            >
              {index === activeKeys.length - 1 && (
                <LabelList dataKey="percentage" content={renderCustomizedLabel} />
              )}
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default memo(WeekTaskBarChart);
