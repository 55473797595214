import { useEffect, useState } from 'react';
import { FeatureFlags } from '@demind-inc/core';
import { fetchAndActivate, getAll } from 'firebase/remote-config';

import { remoteConfig, useAuthContext } from '../data-access';
import { isDevelopment } from './environment';

//@link: https://www.notion.so/demind-inc/Feature-Flags-2327fe3c821a444d9076b771c606c0be

export const defaultRemoteConfig: FeatureFlags = {
  force_update_version: '1.0.0',
  FF_calendly: false,
  FF_30_day_trial: false,
  FF_ai_task_scheduler_v2: true,
};

export const useFeatureFlag = () => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(defaultRemoteConfig);
  const { user } = useAuthContext();

  useEffect(() => {
    const init = async () => {
      if (isDevelopment) {
        // Use customized local feature flags for development
        return;
      }

      remoteConfig.settings = {
        minimumFetchIntervalMillis: 3600000, // 1 hour
        fetchTimeoutMillis: 60000, // 1 minute
      };

      await fetchAndActivate(remoteConfig);
      remoteConfig.defaultConfig = defaultRemoteConfig;

      const ff = getAll(remoteConfig);

      setFeatureFlags({
        FF_calendly: ff.FF_calendly.asBoolean(),
        FF_30_day_trial: ff.FF_30_day_trial.asBoolean(),
        force_update_version: ff.force_update_version.asString(),
        FF_ai_task_scheduler_v2:
          user.access === 'internal' ? true : ff.FF_ai_task_scheduler_v2.asBoolean(),
      });
    };

    init();
  }, [user.access]);

  return { featureFlags };
};
