import { CustomTooltipContentProps } from '../../../common';

export const emaExplanationInfo: CustomTooltipContentProps = {
  title: '⚡️ Subjective energy adjustment',
  description:
    'While wearable devices can provide more “objective” data regarding your energy and health, data doesn’t always reflect how we feel. And we believe that how we feel in terms of energy is a valid and important data point to consider. We will adjust your energy for a shorter period of time if you answer how energized you are. Depending on the event duration, category, and your response, we will adjust your energy accordingly.',
  learnMoreLink:
    'https://demind-inc.notion.site/How-Lifestack-Works-7fd29b5e0bc649cc859a51487ea38805',
  referenceLinkList: [
    {
      title:
        'Quantifying Energy and Fatigue: Classification and Assessment of Energy and Fatigue Using Subjective, Objective, and Mixed Methods towards Health and Quality of Life',
      url: 'https://link.springer.com/chapter/10.1007/978-3-030-94212-0_4',
    },
    {
      title:
        'micro-Stress EMA: A Passive Sensing Framework for Detecting in-the-wild Stress in Pregnant Mothers',
      url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7236910/',
    },
  ],
};
