import React from 'react';

import { ReactComponent as AISchedulerIcon } from '../../../../assets/scheduler/ai-scheduler-icon.svg';
import AISchedulerSaveBtn from '../../../../assets/scheduler/ai-scheduler-save-btn.png';

import './AISchedulerSaveAllModal.scss';
import { Collapse } from '@mui/material';
import { clsx } from 'clsx';

interface AISchedulerSaveAllModalProps {
  isOpen: boolean;
  width: number;
  className?: string;
  onClose: () => void;
  onSave: () => void;
}

const AISchedulerSaveAllModal: React.FC<AISchedulerSaveAllModalProps> = ({
  isOpen,
  width,
  className,
  onClose,
  onSave,
}) => {
  return (
    <Collapse
      orientation="vertical"
      in={isOpen}
      className={clsx('ai-scheduler-save-all-modal', className)}
    >
      <div className="ai-scheduler-save-all-modal__container" style={{ width }}>
        <div className="ai-scheduler-save-all-modal__left">
          <div className="ai-scheduler-save-all-modal__title">
            Save these schedules as calendar events
          </div>
          <div className="ai-scheduler-save-all-modal__description">
            <AISchedulerIcon style={{ width: 10, height: 10 }} />
            Recommended by AI Scheduler
          </div>
        </div>
        <img
          src={AISchedulerSaveBtn}
          alt="Save"
          className="ai-scheduler-save-all-modal__right"
          onClick={onSave}
        />
      </div>
    </Collapse>
  );
};

export default AISchedulerSaveAllModal;
