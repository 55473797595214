import React, { useEffect, useMemo, useState } from 'react';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { ScheduledAction } from '@demind-inc/core';
import { Dayjs } from 'dayjs';

import { CalendarAccountDropdown } from '../CalendarAccountDropdown';
import { useCalendarContext } from '../../../../data-access';
import './ActivitySaveMenu.scss';
import { DateTimePicker, TimePicker } from '@mui/x-date-pickers';

interface ActivitySaveMenuProps {
  title?: string;
  visible: boolean;
  actionOptions?: ScheduledAction[];
  defaultStartTime?: Dayjs;
  defaultEndTime?: Dayjs;
  hideTimeSelector?: boolean;
  subChildren?: React.ReactNode;
  onClose: () => void;
  onDiscard: () => void;
  onAccept: (
    action: ScheduledAction,
    calendarId: string,
    startTime?: Dayjs,
    endTime?: Dayjs
  ) => void;
}

const ActivitySaveMenu: React.FC<ActivitySaveMenuProps> = ({
  title = 'Choose Activity',
  visible,
  actionOptions = [],
  defaultStartTime,
  defaultEndTime,
  hideTimeSelector,
  subChildren,
  onDiscard,
  onAccept,
  onClose,
}) => {
  const [selectedAction, setSelectedAction] = useState<ScheduledAction | null>(null);
  const { calendarAccountsToCreateEvents, mainCalendar } = useCalendarContext();
  const [selectedCalendarId, setSelectedCalendarId] = useState<string>(mainCalendar?.calendarId);
  const [startTime, setStartTime] = useState<Dayjs | null>(defaultStartTime || null);
  const [endTime, setEndTime] = useState<Dayjs | null>(defaultEndTime || null);

  const isDisabledToAccept = useMemo(
    () =>
      (!!actionOptions.length && !selectedAction) ||
      !selectedCalendarId ||
      (startTime && endTime && startTime.isAfter(endTime)),
    [selectedAction, actionOptions, selectedCalendarId, startTime, endTime]
  );

  useEffect(() => {
    setSelectedCalendarId(mainCalendar?.calendarId);
  }, [mainCalendar?.calendarId]);

  useEffect(() => {
    if (defaultStartTime) {
      setStartTime(defaultStartTime);
    }
    if (defaultEndTime) {
      setEndTime(defaultEndTime);
    }
  }, [defaultStartTime, defaultEndTime]);

  const handleAccept = () => {
    onAccept(selectedAction, selectedCalendarId, startTime, endTime);
    setSelectedAction(null);
    onClose();
  };

  const handleDiscard = () => {
    onDiscard();
    setSelectedAction(null);
    onClose();
  };

  return (
    <Dialog
      open={visible}
      className="activity-save-menu"
      hideBackdrop
      onClose={onClose}
      key={calendarAccountsToCreateEvents[0]?.calendarId}
    >
      <DialogTitle className="activity-save-menu__header">
        <p>{title}</p>
        <CloseOutlined onClick={onClose} className="activity-save-menu__header__icon" />
      </DialogTitle>
      <DialogContent className="activity-save-menu__content">
        <div className="activity-save-menu__title-container">
          <CalendarAccountDropdown
            calendars={calendarAccountsToCreateEvents}
            onSelectCalendarId={(id) => setSelectedCalendarId(id)}
            selectedCalendarId={selectedCalendarId}
          />
        </div>
        {!!actionOptions.length && (
          <div className="activity-save-menu__chip-container">
            {actionOptions.map((option) => (
              <Chip
                label={option.name}
                key={option.taskId}
                variant={selectedAction?.taskId === option.taskId ? 'filled' : 'outlined'}
                color="primary"
                onClick={() => setSelectedAction(option)}
              />
            ))}
          </div>
        )}
        {!hideTimeSelector && startTime && endTime && (
          <div className="activity-save-menu__time-selector">
            <TimePicker
              label="Start time"
              value={startTime}
              className="activity-save-menu__time-selector__item"
              format={'h:mm A'}
              views={['hours', 'minutes']}
              onChange={(newValue) => {
                if (newValue) {
                  setStartTime(newValue);
                }
              }}
            />
            <TimePicker
              label="End time"
              value={endTime}
              className="activity-save-menu__time-selector__item"
              format={'h:mm A'}
              views={['hours', 'minutes']}
              onChange={(newValue) => {
                if (newValue) {
                  setEndTime(newValue);
                }
              }}
            />
          </div>
        )}
        {subChildren}
      </DialogContent>
      <DialogActions className="activity-save-menu__actions">
        <div className="activity-save-menu__button-group">
          <Button
            onClick={handleDiscard}
            variant="text"
            className="activity-save-menu__button-group__button delete-button"
          >
            Discard
          </Button>

          <Button
            variant="contained"
            className="activity-save-menu__button-group__button"
            onClick={handleAccept}
            disabled={isDisabledToAccept}
          >
            Save as calendar event
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ActivitySaveMenu;
