import { FC } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import clsx from 'clsx';

import './ToggleButtons.scss';

interface ToggleButtonOption<T> {
  value: T;
  label: string;
}

interface ToggleButtonsProps<T> {
  value: T;
  options: ToggleButtonOption<T>[];
  onChange: (value: T) => void;
  className?: string;
}

const ToggleButtons = <T extends string>({
  value,
  options,
  onChange,
  className,
}: ToggleButtonsProps<T>) => {
  const handleChange = (_: React.MouseEvent<HTMLElement>, newValue: T) => {
    if (newValue !== null) {
      onChange(newValue);
    }
  };

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={handleChange}
      className={clsx('toggle-buttons', className)}
    >
      {options.map((option) => (
        <ToggleButton key={option.value} value={option.value}>
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ToggleButtons;
