import React, { CSSProperties, useState } from 'react';
import { MenuItem, Menu } from '@mui/material';
import { ModeEdit } from '@mui/icons-material';

import './DurationField.scss';
import { clsx } from 'clsx';

interface DurationFieldProps {
  value: {
    hours: number;
    minutes: number;
    maxDurationMin?: number;
  };
  className?: string;
  textStyle?: CSSProperties;
  onChange: (value: { hours: number; minutes: number }) => void;
}

const DurationField: React.FC<DurationFieldProps> = ({ value, className, textStyle, onChange }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const handleChange = () => {
    setMenuAnchorEl(null);
  };

  const isValidDurationForHour = (hours: number) => {
    return hours <= value.maxDurationMin / 60;
  };

  const isValidDuration = (hours: number, minutes: number) => {
    const maxDurationHours = Math.floor(value.maxDurationMin / 60);
    const maxDurationMinutes = value.maxDurationMin - hours * 60;
    if (hours > maxDurationHours) {
      return false;
    }
    if (hours === maxDurationHours && minutes > maxDurationMinutes) {
      return false;
    }
    return true;
  };

  return (
    <>
      <div
        className={clsx('duration-field', className)}
        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
      >
        <div className="duration-field__text" style={textStyle}>
          {value.hours} h {value.minutes} m
        </div>
        <ModeEdit className="duration-field__input__edit" />
      </div>
      <Menu
        id="duration-field"
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        onClose={handleChange}
        className="duration-field__menu"
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <div className="duration-field__menu__list">
          <div className="duration-field__menu__list__items--wrapper">
            <div className="duration-field__menu__list__items">
              {Array.from({ length: 13 }, (_, i) => (
                <MenuItem
                  key={`hour-${i}`}
                  onClick={() =>
                    isValidDurationForHour(i) && onChange({ hours: i, minutes: value.minutes })
                  }
                  className={clsx(
                    'duration-field__menu__list__items__item',
                    value.hours === i && 'duration-field__menu__list__items__item--selected',
                    !isValidDurationForHour(i) &&
                      'duration-field__menu__list__items__item--disabled'
                  )}
                >
                  {i}
                </MenuItem>
              ))}
            </div>
            <span className="duration-field__menu__list__items__format">h</span>
          </div>
          <div className="duration-field__menu__list__items--wrapper">
            <div className="duration-field__menu__list__items">
              {[0, 15, 30, 45].map((val) => (
                <MenuItem
                  key={`minute-${val}`}
                  onClick={() =>
                    isValidDuration(value.hours, val) &&
                    onChange({ hours: value.hours, minutes: val })
                  }
                  className={clsx(
                    'duration-field__menu__list__items__item',
                    value.minutes === val && 'duration-field__menu__list__items__item--selected',
                    !isValidDuration(value.hours, val) &&
                      'duration-field__menu__list__items__item--disabled'
                  )}
                >
                  {val}
                </MenuItem>
              ))}
            </div>
            <span className="duration-field__menu__list__items__format">m</span>
          </div>
        </div>
      </Menu>
    </>
  );
};

export default DurationField;
