import React from 'react';
import clsx from 'clsx';

import './TaskCheckBox.scss';

interface TaskCheckBoxProps {
  isCompleted: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
  size?: number;
  color?: string;
}

export const TaskCheckBox: React.FC<TaskCheckBoxProps> = ({
  isCompleted,
  onChange,
  className = '',
  size = 20,
  color = 'var(--color-primary)',
}) => {
  const style = {
    '--checkbox-width': `${size}px`,
    '--checkbox-height': `${size}px`,
    '--checkbox-color': color,
  } as React.CSSProperties;

  return (
    <div
      className={clsx('task-checkbox', isCompleted && 'task-checkbox--completed', className)}
      onClick={(e) => {
        e.stopPropagation();
        onChange(!isCompleted);
      }}
      role="checkbox"
      aria-checked={isCompleted}
      style={style}
    >
      <div className="task-checkbox__inner">
        {isCompleted && (
          <svg
            width={size * 0.5}
            height={size * 0.5}
            viewBox="0 0 10 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.5 8.0251L0.5 5.0251L1.55 3.9751L3.5 5.9251L8.45 0.975098L9.5 2.0251L3.5 8.0251Z"
              fill="white"
            />
          </svg>
        )}
      </div>
    </div>
  );
};
