import { useRecoilValue } from 'recoil';
import dayjs, { Dayjs } from 'dayjs';

import { selectedDateAtom } from '../recoil';
import { useUpdateSleepDetails } from '../mutations';
import { useAuthContext } from '../context';
import { trackEventMixpanel } from '../../utils';
import { SleepWakeTimeISOStringSet } from '../types';

interface UseEditSleepParams {
  date: Dayjs;
}

export const useEditSleep = ({ date }: UseEditSleepParams) => {
  const { updateSleepDetail, isPending: isEditingData } = useUpdateSleepDetails();
  const { user } = useAuthContext();

  const handleEditSleepData = async (newData: SleepWakeTimeISOStringSet) => {
    await updateSleepDetail({
      userId: user.userId,
      metricId: user.metricId!,
      detailsByDate: [
        {
          date: date.format('YYYY-MM-DD'),
          details: {
            sleepTime: newData.sleepTime,
            awakeTime: newData.wakeTime,
            durationMin: dayjs(newData.wakeTime).diff(dayjs(newData.sleepTime), 'minutes'),
            source: 'Manual(Lifestack)',
            stages: {
              deep: 0,
              light: 0,
              rem: 0,
              awake: 0,
            },
          },
        },
      ],
    });
    trackEventMixpanel('edit_sleep_data', { ...newData });
  };

  return { handleEditSleepData, isEditingData };
};
