import { FC } from 'react';
import clsx from 'clsx';

import './SubscriptionTimeline.scss';
import { DEFAULT_FREE_TRIAL_DAYS } from '../../../data-access';
import dayjs from 'dayjs';

interface SubscriptionTimelineProps {
  startTrialDate?: string;
  startChargeDate: string;
  freeTrialDays?: number;
  price?: string;
  className?: string;
}

const REMINDER_DAYS = 3;

const SubscriptionTimeline: FC<SubscriptionTimelineProps> = ({
  startChargeDate,
  startTrialDate,
  freeTrialDays = DEFAULT_FREE_TRIAL_DAYS,
  price = '',
  className,
}) => {
  const reminderDate = startTrialDate
    ? dayjs(startChargeDate).subtract(REMINDER_DAYS, 'day').format('YYYY-MM-DD')
    : undefined;

  return (
    <div className={clsx('subscription-timeline', className)}>
      <div className="subscription-timeline__left-group">
        <div className={clsx('subscription-timeline__vertical-line', 'vertical-line')}>
          <div className="vertical-line__circle" />
          <div className="vertical-line__line" /> <div className="vertical-line__circle" />
        </div>
        <div className="subscription-timeline__title-group">
          {!!startTrialDate && (
            <div className="subscription-timeline__title-group__action">{startTrialDate}</div>
          )}
          {!!reminderDate && (
            <div className="subscription-timeline__title-group__action">
              {reminderDate} (3 days before)
            </div>
          )}
          <div className="subscription-timeline__title-group__action">{startChargeDate}</div>
        </div>
      </div>
      <div className="subscription-timeline__right-group">
        {!!startTrialDate && (
          <div className="subscription-timeline__price">
            <span className="subscription-timeline__price--red"> $0</span>
          </div>
        )}
        {!!reminderDate && (
          <div className="subscription-timeline__price">Trial ending reminder</div>
        )}
        <div className="subscription-timeline__price">{price}</div>
      </div>
    </div>
  );
};

export default SubscriptionTimeline;
