import React from 'react';
import { EnergyFeedbackType } from '@demind-inc/core';

import './EventEnergyFeedback.scss';
import { ReactComponent as EnergyIcon } from '../../../../assets/circadian/energy.svg';
import { EnergyFeedback } from '../../../Energy';
import { HelpOutline } from '@mui/icons-material';
import { CustomTooltip, CustomTooltipContent } from '../../../common';
import { emaExplanationInfo } from './constants';

interface EventEnergyFeedbackProps {
  selectedFeedback?: EnergyFeedbackType;
  onSelectFeedback: (feedback: EnergyFeedbackType) => void;
  isDisabled?: boolean;
}

const EventEnergyFeedback: React.FC<EventEnergyFeedbackProps> = ({
  selectedFeedback,
  onSelectFeedback,
  isDisabled,
}) => {
  return (
    <div className="event-energy-feedback">
      <EnergyIcon className="event-energy-feedback__icon" />
      <div className="event-energy-feedback__content">
        <p className="event-energy-feedback__content__title">
          How energized did you feel after this event?
          <CustomTooltip title={<CustomTooltipContent {...emaExplanationInfo} />}>
            <HelpOutline fontSize="inherit" width={6} />
          </CustomTooltip>
        </p>
        <EnergyFeedback
          selectedFeedback={selectedFeedback}
          onFeedbackSelect={onSelectFeedback}
          disabled={isDisabled}
          width={250}
        />
      </div>
    </div>
  );
};

export default EventEnergyFeedback;
