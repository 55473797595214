import { atom } from 'recoil';
import { ActivityCalendarEvent } from '../types';

export const activityEventsAtom = atom<ActivityCalendarEvent[]>({
  key: 'activityEvents',
  default: [],
});

export const selectedActivityEventAtom = atom<ActivityCalendarEvent | null>({
  key: 'selectedActivityEvent',
  default: null,
});
