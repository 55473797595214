import { useRecoilState, useRecoilValue } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { CalendarEvent, SchedulerPreference } from '@demind-inc/core';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';

import {
  aiFeedbackModalVisibleAtom,
  aiScheduledActionsAtom,
  aiSchedulerErrorAtom,
  aiSchedulerFeedbackApiInfoAtom,
  aiSchedulerVersionAtom,
  generalSnackBarAtom,
  getDayjsOfEarliestXHour,
  isAISchedulerSetupOverlayVisibleAtom,
  isRunningAISchedulerAtom,
  selectedCoreActionModalAtom,
  selectedDateAtom,
  useAuthContext,
  useCalendarContext,
  useCreateCalendarEvent,
  useRunAITScheduler,
  useSchedulerPreference,
} from '../data-access';
import { translateAIScheduledActionToTemporalCalendarEvent } from '../helpers';
import { getCssVariable, trackEventMixpanel, useFeatureFlag } from '../utils';

interface UseAISchedulerParams {
  isDemo?: boolean;
}

const DEMO_START_HOUR = 9;
const DEMO_BOUNDARY_HOUR_FOR_NEXT_DAY = 15;

const primaryColor = getCssVariable('--primary-color');

export const useAIScheduler = ({ isDemo = false }: UseAISchedulerParams = {}) => {
  const [isAISchedulerSetupOverlayVisible, setIsAISchedulerSetupOverlayVisible] = useRecoilState(
    isAISchedulerSetupOverlayVisibleAtom
  );
  const [_, setSelectedModal] = useRecoilState(selectedCoreActionModalAtom);
  const [isRunningAIScheduler, setIsRunningAIScheduler] = useRecoilState(isRunningAISchedulerAtom);
  const [aiScheduledActions, setAiScheduledActions] = useRecoilState(aiScheduledActionsAtom);
  const [aiSchedulerError, setAiSchedulerError] = useRecoilState(aiSchedulerErrorAtom);
  const [selectedDate, setSelectedDate] = useRecoilState(selectedDateAtom);
  const [__, setGeneralSnackbar] = useRecoilState(generalSnackBarAtom);
  const [aiFeedbackDialogVisible, setAiFeedbackDialogVisible] = useRecoilState(
    aiFeedbackModalVisibleAtom
  );
  const [___, setAiSchedulerFeedbackApiInfo] = useRecoilState(aiSchedulerFeedbackApiInfoAtom);
  const [aiSchedulerTargetVersion, setAiSchedulerTargetVersion] =
    useRecoilState(aiSchedulerVersionAtom);

  const {
    featureFlags: { FF_ai_task_scheduler_v2 = false },
  } = useFeatureFlag();
  const { user } = useAuthContext();
  const { schedulerPreferences } = useSchedulerPreference({ preferenceId: user.preferenceId! });
  const { runAIScheduler } = useRunAITScheduler();
  const { mainCalendar, calendarPreferences } = useCalendarContext();
  const { createCalendarEvent } = useCreateCalendarEvent();

  // For demo, we change the date depending on the time of day
  useEffect(() => {
    if (!isDemo) {
      return;
    }
    setSelectedDate(getDayjsOfEarliestXHour(DEMO_START_HOUR, DEMO_BOUNDARY_HOUR_FOR_NEXT_DAY));
  }, [isDemo]);

  const targetDate = useMemo(
    () => (selectedDate.isSame(dayjs(), 'day') ? dayjs() : selectedDate),
    [selectedDate]
  );

  const hasSetDefaultPrefs = useMemo(() => !isEmpty(schedulerPreferences), [schedulerPreferences]);

  const canResetAISchedule = useMemo(() => !!aiScheduledActions.length, [aiScheduledActions]);

  const aiScheduledActionsEvents = useMemo(() => {
    const newEventCalendarId = mainCalendar?.calendarId!;
    const color = mainCalendar?.color || primaryColor;

    return aiScheduledActions.map(
      (action) =>
        ({
          ...translateAIScheduledActionToTemporalCalendarEvent(action),
          calendarId: newEventCalendarId,
          color,
        }) as CalendarEvent
    );
  }, [aiScheduledActions, mainCalendar]);

  const isSaveModalVisible = useMemo(
    () => !!aiScheduledActionsEvents.length,
    [aiScheduledActionsEvents]
  );

  const handleAISchedulreSetupOverlayVisible = (visible: boolean) => {
    setIsAISchedulerSetupOverlayVisible(visible);
  };

  const handleRunAIScheduler = async (preferences: SchedulerPreference) => {
    if (!user.userId) {
      return;
    }

    setIsRunningAIScheduler(true);

    try {
      const {
        schedules: predictedActions,
        scienceApiReq,
        scienceApiRes,
      } = await runAIScheduler({
        userId: user.userId,
        metricId: user.metricId!,
        targetTime: targetDate.toISOString(),
        preferences,
        version: FF_ai_task_scheduler_v2 ? aiSchedulerTargetVersion : 'timebox',
      });
      setAiSchedulerFeedbackApiInfo({
        appApiRes: JSON.stringify(predictedActions),
        scienceApiReq,
        scienceApiRes,
        currentTime: targetDate.toISOString(),
        preference: JSON.stringify(preferences),
      });
      setAiScheduledActions(predictedActions);

      if (!predictedActions.length) {
        setAiSchedulerError('No events to schedule.');
        setGeneralSnackbar("Couldn't schedule any events");
      }
    } catch {
      setAiSchedulerError('Failed to schedule events.');
      setGeneralSnackbar('Failed to schedule events');
    } finally {
      setIsRunningAIScheduler(false);
      setSelectedModal(null);
      if (isDemo) {
        trackEventMixpanel('run_ai_scheduler_practice');
      } else {
        trackEventMixpanel('run_ai_scheduler');
      }
    }
  };

  const resetAiScheduler = () => {
    setAiScheduledActions([]);
    setSelectedModal(null);
    setAiSchedulerError(null);
  };

  const handleAcceptAIScheduleEvent = useCallback(
    (event: Partial<CalendarEvent>, calendarId?: string) => {
      createCalendarEvent({
        userId: user.userId,
        calendarId: calendarId || mainCalendar?.calendarId!,
        newEventOption: {
          ...event,
          eventId: (uuidv4() as string).replace(/-/g, ''),
          color: mainCalendar?.color || primaryColor,
          scheduleFrom: 'aiScheduler',
          showAs: calendarPreferences.transparency?.aiScheduler || 'available',
          unSyncToCalendar: calendarPreferences.unSyncToCalendar?.aiScheduler,
        },
      });
    },
    [
      user.userId,
      mainCalendar,
      calendarPreferences.transparency,
      calendarPreferences.unSyncToCalendar,
    ]
  );

  const handleAcceptAllEvents = useCallback(() => {
    aiScheduledActionsEvents.forEach((event) => handleAcceptAIScheduleEvent(event));
    trackEventMixpanel('accept_all_ai_scheduled_events');
    resetAiScheduler();
  }, [aiScheduledActionsEvents, handleAcceptAIScheduleEvent]);

  const handleDiscard = useCallback(() => {
    setAiFeedbackDialogVisible(true);
  }, []);

  const handleDiscardEvent = useCallback((actionId: string) => {
    setAiScheduledActions((prevs) => prevs.filter((prev) => prev.id !== actionId));
  }, []);

  const handleFeedbackDialogClose = useCallback(() => {
    resetAiScheduler();
    setAiFeedbackDialogVisible(false);
  }, []);

  return {
    targetDate,
    aiSchedulerError,
    isRunningAIScheduler,
    isAISchedulerSetupOverlayVisible,
    canResetAISchedule,
    aiScheduledActions,
    aiScheduledActionsEvents,
    hasSetDefaultPrefs,
    schedulerPreferences,
    aiFeedbackDialogVisible,
    aiSchedulerTargetVersion,
    isSaveModalVisible,
    handleTargetVersionForScheduler: setAiSchedulerTargetVersion,
    handleDiscardEvent,
    handleFeedbackDialogClose,
    handleDiscard,
    handleAcceptAIScheduleEvent,
    handleAcceptAllEvents,
    resetAiScheduler,
    handleRunAIScheduler,
    handleAISchedulreSetupOverlayVisible,
  };
};
