import { useMutation, useQueryClient } from '@tanstack/react-query';

import { tasksApi } from '../api';
import { TasksByDate } from '../types';
import { TaskItem } from '@demind-inc/core';

interface UseUpdateTodoTaskParams {
  taskId: string;
  completed: boolean;
  boardId: string;
  userId: string;
  isOverdue?: boolean;
}
export const useUpdateTaskStatus = () => {
  const queryClient = useQueryClient();

  const updateTaskMutation = useMutation({
    mutationFn: async ({ taskId, completed, boardId, userId }: UseUpdateTodoTaskParams) => {
      return tasksApi
        .updateCompleteStatusOfTask(taskId, boardId, userId, completed)
        .then(({ data }) => data);
    },
    onMutate: async ({ taskId, completed, isOverdue = false }) => {
      if (isOverdue) {
        await queryClient.cancelQueries({ queryKey: ['lifestack.todo.overdueTasks'] });
        queryClient.setQueriesData<TaskItem[]>(
          { queryKey: ['lifestack.todo.overdueTasks'] },
          (prevTasks) =>
            prevTasks?.map((task) => (task.taskId === taskId ? { ...task, completed } : task))
        );
      } else {
        await queryClient.cancelQueries({ queryKey: ['lifestack.todo.tasks'] });
        queryClient.setQueriesData<TasksByDate>(
          { queryKey: ['lifestack.todo.tasks'] },
          (prevTasks) => ({
            date: prevTasks?.date,
            tasks: prevTasks?.tasks?.map((task) =>
              task.taskId === taskId ? { ...task, completed } : task
            ),
          })
        );
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.tasks'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.overdueTasks'] });
    },
  });

  return {
    updateTodoTaskStatus: updateTaskMutation.mutateAsync,
    isTaskStatusUpdating: updateTaskMutation.isPending,
    ...updateTaskMutation,
  };
};
