import { useMutation, useQueryClient } from '@tanstack/react-query';
import { tasksApi } from '../api';
import { TaskLabel } from '@demind-inc/core';

interface UseUpdateTaskLabelParams {
  taskLabelGroupId: string;
  labelId: string;
  newLabel: Partial<TaskLabel>;
}

export const useUpdateTaskLabel = () => {
  const queryClient = useQueryClient();

  const updateTaskLabelMutation = useMutation({
    mutationFn: ({ taskLabelGroupId, labelId, newLabel }: UseUpdateTaskLabelParams) =>
      tasksApi.updateTaskLabel(taskLabelGroupId, labelId, newLabel).then(({ data }) => data),
    onMutate: async ({ newLabel }) => {
      await queryClient.cancelQueries({ queryKey: ['lifestack.todo.tasks.labels'] });

      queryClient.setQueriesData<TaskLabel[]>(
        { queryKey: ['lifestack.todo.labels'] },
        (prevLabels) =>
          prevLabels?.map((label) => (label.id === newLabel.id ? { ...label, ...newLabel } : label))
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.tasks.labels'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.tasks'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.overdueTasks'] });
    },
  });

  return {
    ...updateTaskLabelMutation,
    updateTaskLabel: updateTaskLabelMutation.mutateAsync,
  };
};
