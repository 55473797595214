import React from 'react';
import clsx from 'clsx';
import { LoadingButton } from '@mui/lab';

import './AISchedulerModalView.scss';
import AISchedulerExplaGif from '../../../../../../assets/scheduler/ai-scheduler-expla.gif';

interface AISchedulerSetupModalViewProps {
  onNext: () => void;
}

const AISchedulerSetupModalView: React.FC<AISchedulerSetupModalViewProps> = ({ onNext }) => {
  return (
    <div className="ai-scheduler-modal">
      <div className={clsx('ai-scheduler-modal__left-section')}>
        <img
          src={AISchedulerExplaGif}
          alt="scheduler preview"
          className="ai-scheduler-modal__gif"
          draggable="false"
        />
      </div>
      <div className={clsx('ai-scheduler-modal__description-container')}>
        <p className="ai-scheduler-modal__description">
          Before we get started, let’s confirm a few details to help you schedule tasks instantly
          with personalized preferences.
        </p>
        <LoadingButton onClick={onNext} className="core-action-modal__button">
          Set up
        </LoadingButton>
      </div>
    </div>
  );
};

export default AISchedulerSetupModalView;
