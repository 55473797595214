import React, { cloneElement, FC } from 'react';
import clsx from 'clsx';

import './SettingsActionList.scss';
import { SettingsActionItem } from './types';
import { settingsMenuStructure } from './constants';

interface SettingsActionListProps {
  selectedAction: SettingsActionItem;
  onSelectAction: (action: SettingsActionItem) => void;
}

const SettingsActionList: FC<SettingsActionListProps> = ({ selectedAction, onSelectAction }) => {
  return (
    <div className="settings-action-list">
      {Object.entries(settingsMenuStructure).map(([section, items]) => (
        <div key={section} className="settings-action-list__section">
          <div className="settings-action-list__section-title">{section}</div>
          {Object.entries(items).map(([action, { text, icon: Icon }]) => (
            <div
              key={action}
              className={clsx(
                'settings-action-list__item',
                action === selectedAction && 'settings-action-list__item--selected'
              )}
              onClick={() => onSelectAction(action as SettingsActionItem)}
            >
              {cloneElement(<Icon />, { style: { width: 16, height: 16 } })}
              <div className="settings-action-list__item__title">{text}</div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default SettingsActionList;
