import React from 'react';
import './TimeFormatToggle.scss';
import { useGeneralSettings } from '../../../../hooks/useGeneralSettings';
import { TimeFormatType } from '@demind-inc/core';
import { CircularProgress } from '@mui/material';
import { ToggleButtons } from '../../../common';

const timeFormatOptions = [
  { value: '12h' as const, label: '12h (Am/Pm)' },
  { value: '24h' as const, label: '24h' },
];

const TimeFormatToggle = () => {
  const {
    generalSettings: { timeFormat },
    handleGeneralSettingsChange,
    isPending,
  } = useGeneralSettings();

  const handleFormatChange = (format: TimeFormatType) => {
    handleGeneralSettingsChange({ timeFormat: format });
  };

  return (
    <div className="time-format-toggle">
      <div className="time-format-toggle__title">Time Format</div>
      <div className="time-format-toggle__container">
        {isPending ? (
          <div className="time-format-toggle__loader">
            <CircularProgress size={16} />
          </div>
        ) : (
          <ToggleButtons
            value={timeFormat}
            options={timeFormatOptions}
            onChange={handleFormatChange}
            className="time-format-toggle__buttons"
          />
        )}
      </div>
    </div>
  );
};

export default TimeFormatToggle;
