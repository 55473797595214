import { useQuery } from '@tanstack/react-query';
import { calendarApi } from '../api';
import { CalendarEvent } from '@demind-inc/core';

interface UseAllDayEventsParams {
  userId: string;
  startDate: string;
  endDate: string;
  enabled?: boolean;
}

interface UseCalendarEventsQueryParams {
  queryKey: [
    'lifestack.calendar.allDayEvents',
    { userId: string; startDate: string; endDate: string }
  ];
}

export const useAllDayEvents = ({
  userId,
  startDate,
  endDate,
  enabled = true,
}: UseAllDayEventsParams) => {
  const calendarAllDayEventsQuery = useQuery({
    queryKey: ['lifestack.calendar.allDayEvents', { userId, startDate, endDate }],
    queryFn: ({ queryKey }: UseCalendarEventsQueryParams) => {
      const [, { userId, startDate, endDate }] = queryKey;

      return calendarApi
        .getAllDayCalendarEventsV2(userId, startDate, endDate)
        .then(({ data }) => data as CalendarEvent[]);
    },
    refetchOnWindowFocus: false,
    staleTime: 15000, // 15s
    enabled: !!userId && enabled,
  });

  return {
    ...calendarAllDayEventsQuery,
    allDayEvents: calendarAllDayEventsQuery.data ?? [],
  };
};
