export interface SchedulerPrefTimeConstraintOption {
  start?: string;
  end?: string;
  durationMin?: number;
}

export type SchedulerPrefTimeConstraintOptionKey = keyof SchedulerPrefTimeConstraintOption;

export interface SchedulerPrefTimeRange {
  time: string;
  minimumTime: string;
  maximumTime: string;
  defaultTime?: string;
}

export interface SchedulerPrefDurationRange {
  durationMin: number;
  minimumDurationMin: number;
  maximumDurationMin: number;
  defaultMin?: number;
}

export enum AutoTimeboxAdjustmentLevel {
  ABOVE_AVERAGE = 'above_average',
  AVERAGE = 'average',
  BELOW_AVERAGE = 'below_average',
}
