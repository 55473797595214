import React from 'react';
import { DefaultPrefEditItem } from '../DefaultPrefEditItem';

import './DefaultPrefGrid.scss';
import { PrefItemState } from '../../prefItems.state';
import { schedulerPrefSwitchableOptions, schedulerPrefTitleSet } from '../../constants';
import { SchedulerPrefDurationRange, SchedulerPrefTimeRange } from '../../types';
import { SchedulerPrefType } from '../../../../../data-access';
import { clsx } from 'clsx';

interface DefaultPrefGridProps {
  prefItems: PrefItemState;
  onChangePrefItem: ({
    type,
    payload,
  }: {
    type: keyof PrefItemState;
    payload: Partial<PrefItemState[SchedulerPrefType] | PrefItemState>;
  }) => void;
  className?: string;
  itemClassName?: string;
}

const DefaultPrefGrid: React.FC<DefaultPrefGridProps> = ({
  prefItems,
  onChangePrefItem,
  className,
  itemClassName,
}) => {
  return (
    <div className={clsx('default-pref-grid', className)}>
      {Object.entries(prefItems).map(([prefKeyRaw, prefInfo]) => {
        const prefKey = prefKeyRaw as keyof typeof prefItems;

        return (
          <DefaultPrefEditItem
            className={clsx('default-pref-grid__item', itemClassName)}
            key={prefKey}
            title={schedulerPrefTitleSet[prefKey]}
            isRange={['workout', 'lunch', 'dinner'].includes(prefKey)}
            start={'start' in prefInfo ? prefInfo.start : undefined}
            end={'end' in prefInfo ? prefInfo.end : undefined}
            duration={'duration' in prefInfo ? prefInfo.duration : undefined}
            enabled={'enabled' in prefInfo ? prefInfo.enabled : true}
            hasSwitch={schedulerPrefSwitchableOptions.includes(prefKey)}
            onStartTimeChange={(startTime) =>
              onChangePrefItem({
                type: prefKey,
                payload: {
                  start: {
                    ...(prefInfo as { start: SchedulerPrefTimeRange }).start,
                    time: startTime,
                  } as SchedulerPrefTimeRange,
                },
              })
            }
            onEndTimeChange={(endTime) =>
              onChangePrefItem({
                type: prefKey,
                payload: {
                  end: {
                    ...(prefInfo as { end: SchedulerPrefTimeRange }).end,
                    time: endTime,
                  } as SchedulerPrefTimeRange,
                },
              })
            }
            onDurationChange={(durationMin) =>
              onChangePrefItem({
                type: prefKey,
                payload: {
                  duration: {
                    ...(prefInfo as { duration: SchedulerPrefDurationRange }).duration,
                    durationMin: durationMin,
                  } as SchedulerPrefDurationRange,
                },
              })
            }
            onSwitchChange={(enabled) =>
              onChangePrefItem({
                type: prefKey,
                payload: {
                  enabled,
                },
              })
            }
          />
        );
      })}
    </div>
  );
};

export default DefaultPrefGrid;
