import { Chronotype, DefaultEventCategoryId, EnergyFluctuationItem } from '@demind-inc/core';

import { EnergyFluctuateItemSet } from './types';

import { upperFirst } from 'lodash';
import { getDefaultCategoryEventIconSet } from '../../../../common';
import { formatToAmPm, formatToHHmm } from '../../../../../data-access';
import { getCssVariable } from '../../../../../utils';
import { ReactComponent as MoonIcon } from '../../../../../assets/actions/moon.svg';
import { ReactComponent as EmaIcon } from '../../../../../assets/calendar/category/ema.svg';

const whiteColor = getCssVariable('--color-white-lightest');

export const transformSleepFluctuationItem = (
  item: EnergyFluctuationItem,
  chronotype?: Chronotype,
  format24h = false
): EnergyFluctuateItemSet => {
  return {
    icon: <MoonIcon color={whiteColor} />,
    title: `Sleep${chronotype ? ` (${chronotype} chronotype)` : ''}`,
    eventTime: `${
      format24h ? formatToHHmm(item.eventTime.startTime) : formatToAmPm(item.eventTime.startTime)
    } - ${
      format24h ? formatToHHmm(item.eventTime.endTime!) : formatToAmPm(item.eventTime.endTime!)
    }`,
    reason: 'sleep',
    fluctuateValue: item.fluctuateScore * 100,
    fluctuateTime: 'Peak Energy',
  } as EnergyFluctuateItemSet;
};

export const transformPhaseEmaFluctuationItem = (
  item: EnergyFluctuationItem,
  format24h = false
): EnergyFluctuateItemSet => {
  return {
    icon: <EmaIcon height={18} />,
    title: `Subjective Energy (${upperFirst(item.eventTitle)})`,
    reason: 'phaseEma',
    eventTime: `${
      format24h ? formatToHHmm(item.eventTime.startTime) : formatToAmPm(item.eventTime.startTime)
    }`,
    fluctuateValue: item.fluctuateScore,
    fluctuateTime:
      !!item.fluctuateTime?.startTime && !!item.fluctuateTime?.endTime
        ? `${
            format24h
              ? formatToHHmm(item.fluctuateTime?.startTime!)
              : formatToAmPm(item.fluctuateTime?.startTime!)
          } - ${
            format24h
              ? formatToHHmm(item.fluctuateTime?.endTime!)
              : formatToAmPm(item.fluctuateTime?.endTime!)
          }`
        : '',
  };
};

export const transformEventEmaFluctuationItem = (
  item: EnergyFluctuationItem,
  format24h = false
): EnergyFluctuateItemSet => {
  return {
    icon: getDefaultCategoryEventIconSet(
      (item.eventCategory?.id as DefaultEventCategoryId) ?? 'default_other'
    ),
    title: item.eventTitle,
    eventTime: `${
      format24h ? formatToHHmm(item.eventTime.startTime) : formatToAmPm(item.eventTime.startTime)
    } - ${
      format24h ? formatToHHmm(item.eventTime.endTime!) : formatToAmPm(item.eventTime.endTime!)
    }`,
    reason: 'event',
    fluctuateValue: item.fluctuateScore,
    fluctuateTime: `${
      format24h
        ? formatToHHmm(item.fluctuateTime?.startTime!)
        : formatToAmPm(item.fluctuateTime?.startTime!)
    } - ${
      format24h
        ? formatToHHmm(item.fluctuateTime?.endTime!)
        : formatToAmPm(item.fluctuateTime?.endTime!)
    }`,
    eventCategory: item.eventCategory,
  };
};
