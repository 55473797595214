import { CustomTooltipContentProps } from '../../common';

export const sleepExplanationInfo: CustomTooltipContentProps = {
  title: '💤 Sleep Quality',
  description:
    'Your sleep quality refers to the overall effectiveness of your sleep. It’s not justabout the total number of hours you spend in bed, but also how restorative your sleep is. There are many factors that can affect the quality of your sleep including how long you sleep, as well as how consistent your sleep is. Lifestack takes your sleep metrics nto consideration when calculating your energy score to help you optimize your schedule.',
  referenceLinkList: [
    {
      title: 'Measuring Sleep Efficiency: What Should the Denominator Be?',
      url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4751425/',
    },
    {
      title: "National Sleep Foundation's sleep quality recommendations: first report",
      url: 'https://www.sciencedirect.com/science/article/abs/pii/S2352721816301309',
    },
    {
      title:
        'Irregular sleep/wake patterns are associated with poorer academic performance and delayed circadian and sleep/wake timing',
      url: 'https://www.nature.com/articles/s41598-017-03171-4',
    },
    {
      title:
        'Sleep quality, duration, and consistency are associated with better academic performance in college students',
      url: 'https://www.nature.com/articles/s41539-019-0055-z',
    },
    {
      title:
        'Sleep regularity is a stronger predictor of mortality risk than sleep duration: A prospective cohort study',
      url: 'https://academic.oup.com/sleep/article/47/1/zsad253/7280269?login=false#supplementary-data',
    },
    {
      title:
        'Recommended Amount of Sleep for a Healthy Adult: A Joint Consensus Statement of the American Academy of Sleep Medicine and Sleep Research Society',
      url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4434546/',
    },
  ],
};
