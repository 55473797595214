import { useState } from 'react';
import { Box, MenuItem, FormControl, Select, SelectChangeEvent, Typography } from '@mui/material';
import clsx from 'clsx';
import {
  DefaultEventCategoryId,
  defaultEventCategorySet,
  EventCategorySet,
} from '@demind-inc/core';

import './EventCategories.scss';
import { getCssVariable } from '../../../../utils';
import { getDefaultCategoryEventIconSet } from '../../../common';
import { CategoryOutlined } from '@mui/icons-material';

interface EventCategoriesProps {
  className?: string;
  selectedCategory: EventCategorySet;
  setSelectedCategory: (selectedCategories: EventCategorySet) => void;
}

const colorGrayBase = getCssVariable('--color-gray-base');
const colorBlack = getCssVariable('--color-black');
const colorGrayMedium = getCssVariable('--color-gray-medium');

export default function EventCategories({
  className,
  selectedCategory,
  setSelectedCategory,
}: EventCategoriesProps) {
  const [open, setOpen] = useState(false);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const category = defaultEventCategorySet.find((item) => item.id === event.target.value);
    if (category) {
      setSelectedCategory(category);
    }
  };

  return (
    <div className={clsx('event-categories', className)}>
      <CategoryOutlined className="event-categories__icon" />
      <FormControl className="event-categories__form-control">
        <Select
          value={selectedCategory?.id ?? 'default'}
          disableUnderline
          onChange={handleChange}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          MenuProps={{
            PaperProps: {
              className: 'event-categories__menu-paper',
            },
          }}
          renderValue={(selected) => {
            if (selected === 'default') {
              return <Typography>Select Category</Typography>;
            }

            const category = defaultEventCategorySet.find((c) => c.id === selected);
            return (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {getDefaultCategoryEventIconSet(
                  category?.id as DefaultEventCategoryId,
                  24,
                  colorBlack
                )}
                <Typography sx={{ ml: 1 }}>{category?.name}</Typography>
              </Box>
            );
          }}
          className={clsx('event-categories__select', { 'event-categories__select--open': open })}
        >
          <MenuItem value="default" disabled>
            <Typography>Select Category</Typography>
          </MenuItem>
          {defaultEventCategorySet.map((category) => (
            <MenuItem key={category.id} value={category.id} className="event-categories__menu-item">
              {getDefaultCategoryEventIconSet(
                category.id as DefaultEventCategoryId,
                24,
                category.id === selectedCategory?.id ? colorGrayMedium : colorGrayBase
              )}
              &nbsp;
              <Typography>{category.name}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
