import { atom } from 'recoil';
import dayjs, { Dayjs } from 'dayjs';
import { HeatmapDataType, ScheduledActionOptions, TaskItem } from '@demind-inc/core';
import {
  RecommendationType,
  DataTrendPeriod,
  SettingsModalInfo,
  TaskErrorInfo,
  WearableErrorInfo,
  ViewSwitcherStateType,
  DataMenuItem,
  HomeViewSwitcherType,
  CalendarViewType,
  CoreActionOption,
} from '../types';
import { CannyPostOptionnName } from '../hooks';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();
export const selectedDateAtom = atom<Dayjs>({
  key: 'selectedDate',
  default: dayjs(),
});

export const visibleTaskDetailAtom = atom<TaskItem | null>({
  key: 'visibleTaskDetail',
  default: null,
});

export const eventsSnackBarAtom = atom<string>({
  key: 'eventsSnackBar',
  default: '',
});

export const taskErrorSnackBarAtom = atom<TaskErrorInfo | null>({
  key: 'taskErrorSnackBar',
  default: null,
});

export const generalSnackBarAtom = atom<string | null>({
  key: 'generalSnackBar',
  default: null,
});

export const visibleFeedbackDialogPostNameAtom = atom<CannyPostOptionnName | null>({
  key: 'visibleFeedbackDialogPostName',
  default: null,
});

export const wearableErrorSnackBarAtom = atom<WearableErrorInfo | null>({
  key: 'wearableErrorSnackBar',
  default: null,
});

export const settingsModalAtom = atom<SettingsModalInfo>({
  key: 'settingsModal',
  default: {
    visible: false,
    defaultPosition: undefined,
  },
});
export const isFetchingCircadianAtom = atom<boolean>({
  key: 'isFetchingCircadian',
  default: false,
});

export const isFetchingAvgCircadianAtom = atom<boolean>({
  key: 'isFetchingAvgCircadian',
  default: false,
});

export const dataTrendPeriodAtom = atom<DataTrendPeriod>({
  key: 'dataTrendPeriod',
  default: 'daily',
});

export const energyboostOptionsTimelineAtom = atom<ScheduledActionOptions[]>({
  key: 'energyBoost.energyboostOptionsTimeline',
  default: [],
});

export const selectedRecommendationTypeAtom = atom<RecommendationType>({
  key: 'selectedRecommendationType',
  default: 'default',
});

export const selectedEnergyBoostOptionsAtom = atom<ScheduledActionOptions | undefined>({
  key: 'energyBoost.selectedEnergyBoostOptions',
  default: undefined,
});

export const selectedCoreActionModalAtom = atom<CoreActionOption | undefined>({
  key: 'coreActions.selectedCoreActionModal',
  default: undefined,
});
export const viewSwitcherAtom = atom<ViewSwitcherStateType>({
  key: 'viewSwitcher',
  default: 'Home',
});

export const selectedDataMenuItem = atom<DataMenuItem>({
  key: 'selectedDataMenuItem',
  default: 'dataCircadian',
});

export const homeViewSwitcherAtom = atom<HomeViewSwitcherType>({
  key: 'homeViewSwitcher',
  default: 'Todo',
  effects_UNSTABLE: [persistAtom],
});

export const calendarViewSwitcherAtom = atom<CalendarViewType>({
  key: 'calendarViewSwitcher',
  default: 'week',
  effects_UNSTABLE: [persistAtom],
});

export const isEnergyPopupVisibleAtom = atom<boolean>({
  key: 'isEnergyPopupVisible',
  default: false,
});

export const heatmapPopupAtom = atom<HeatmapDataType | null>({
  key: 'heatmapPopup',
  default: null,
});
