import { FC } from 'react';
import clsx from 'clsx';
import { Switch } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { ModeEdit } from '@mui/icons-material';

import { SchedulerPrefDurationRange, SchedulerPrefTimeRange } from '../../types';
import './DefaultPrefEditItem.scss';
import { getTimeRangePickedDateValues } from '../../helpers';
import { DurationField } from '../../../../common';
import { useGeneralSettings } from '../../../../../hooks';

interface DefaultPrefEditItemProps {
  title: string;
  start?: SchedulerPrefTimeRange;
  end?: SchedulerPrefTimeRange;
  duration?: SchedulerPrefDurationRange;
  hasSwitch?: boolean;
  enabled?: boolean;
  isRange?: boolean;
  className?: string;
  onSwitchChange?: (value: boolean) => void;
  onStartTimeChange?: (start: string) => void;
  onEndTimeChange?: (end: string) => void;
  onDurationChange?: (durationMin: number) => void;
}

const DefaultPrefEditItem: FC<DefaultPrefEditItemProps> = ({
  title,
  start,
  end,
  duration,
  hasSwitch,
  enabled = true,
  isRange = false,
  className,
  onSwitchChange = () => void 0,
  onStartTimeChange = () => void 0,
  onEndTimeChange = () => void 0,
  onDurationChange = () => void 0,
}) => {
  const {
    generalSettings: { timeFormat },
  } = useGeneralSettings();

  return (
    <div className={clsx('default-pref-edit-item', className)}>
      <div className="default-pref-edit-item__top-group">
        <div className="default-pref-edit-item__title">{title}</div>
        {hasSwitch && (
          <Switch
            checked={enabled}
            size="small"
            color="success"
            onChange={(e) => onSwitchChange(e.target.checked)}
            className="default-pref-edit-item__switch"
          />
        )}
      </div>
      {(!!start || !!end) && (
        <div className="default-pref-edit-item__bottom-group">
          {!!start && (
            <div className="default-pref-edit-item__time-item">
              <div className="default-pref-edit-item__time-item__title">
                {isRange ? 'Earliest Start' : 'Start'}
              </div>

              <TimePicker
                className="default-pref-edit-item__time-item__value"
                slotProps={{
                  popper: {
                    className: 'default-pref-edit-item__time-item__value__popper',
                  },
                }}
                ampm={timeFormat === '12h'}
                label=""
                format={timeFormat === '12h' ? 'h:mmA' : 'HH:mm'}
                minTime={getTimeRangePickedDateValues(start).minimumPickedDate}
                maxTime={getTimeRangePickedDateValues(start).maximumPickedDate}
                value={getTimeRangePickedDateValues(start).defaultPickedDate}
                onChange={(val) => onStartTimeChange(val.format('HH:mm'))}
                slots={{
                  openPickerIcon: ModeEdit,
                }}
              />
            </div>
          )}
          {!!end && (
            <div className="default-pref-edit-item__time-item">
              <div className="default-pref-edit-item__time-item__title">
                {isRange ? 'Latest End' : 'End'}
              </div>
              <TimePicker
                className="default-pref-edit-item__time-item__value"
                label=""
                slotProps={{
                  popper: {
                    className: 'default-pref-edit-item__time-item__value__popper',
                  },
                }}
                ampm={timeFormat === '12h'}
                format={timeFormat === '12h' ? 'h:mmA' : 'HH:mm'}
                minTime={getTimeRangePickedDateValues(end).minimumPickedDate}
                maxTime={getTimeRangePickedDateValues(end).maximumPickedDate}
                value={getTimeRangePickedDateValues(end).defaultPickedDate}
                onChange={(val) => onEndTimeChange(val.format('HH:mm'))}
                slots={{
                  openPickerIcon: ModeEdit,
                }}
              />
            </div>
          )}
        </div>
      )}
      {!!duration && (
        <div className="default-pref-edit-item__time-item">
          <div className="default-pref-edit-item__time-item__title">Duration</div>

          <DurationField
            value={{
              hours: Math.floor(duration.durationMin / 60),
              minutes: duration.durationMin % 60,
              maxDurationMin: duration.maximumDurationMin,
            }}
            onChange={(val) => onDurationChange(val.hours * 60 + val.minutes)}
          />
        </div>
      )}
    </div>
  );
};

export default DefaultPrefEditItem;
