import { FC, useState } from 'react';
import { Chip, Autocomplete, TextField } from '@mui/material';
import clsx from 'clsx';
import { TaskLabel } from '@demind-inc/core';

import './TaskLabelsSelect.scss';
import { getCssVariable } from '../../../utils';
import { TaskLabelSelectOption } from './TaskLabelSelectOption';
import { useAuthContext, useUpdateTaskLabel } from '../../../data-access';
import { CloseOutlined } from '@mui/icons-material';

interface TaskLabelsSelectProps {
  className?: string;
  allLabels: TaskLabel[];
  selectedLabels: TaskLabel[];
  setSelectedLabels: (newLabels: TaskLabel[]) => void;
}

const grayLightColor = getCssVariable('--color-gray-light');

const TaskLabelsSelect: FC<TaskLabelsSelectProps> = ({
  className,
  allLabels = [],
  selectedLabels = [],
  setSelectedLabels,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { user } = useAuthContext();
  const { updateTaskLabel } = useUpdateTaskLabel();

  const handleUpdateLabel = (label: TaskLabel) => {
    updateTaskLabel({
      taskLabelGroupId: user.taskLabelsGroupId!,
      labelId: label.id,
      newLabel: label,
    });
  };

  return (
    <Autocomplete
      multiple
      className={clsx('task-labels-select', className)}
      id="tags-filled"
      key="labels-select"
      options={allLabels}
      value={selectedLabels}
      getOptionLabel={(option: TaskLabel) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disableCloseOnSelect
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={(event, reason) => {
        const nativeEvent = event.nativeEvent as unknown as FocusEvent;
        const relatedTarget = nativeEvent.relatedTarget as HTMLElement;

        // Keep open if focus moves to color picker content or its parent popover
        if (
          relatedTarget?.querySelector('.color-picker-dropdown__content') || // Check if target contains color picker
          relatedTarget?.closest('.color-picker-dropdown__content')
        ) {
          event.preventDefault();
          event.stopPropagation();
          return;
        }

        setIsOpen(false);
      }}
      slotProps={{
        paper: {
          className: 'task-labels-select__menu',
        },
        listbox: {
          className: 'task-labels-select__listbox',
        },
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          event.stopPropagation(); // Avoid a modal from closing by key down
        }
      }}
      onChange={(event, val) => {
        setSelectedLabels(val);
      }}
      renderOption={(props, option) => (
        <li
          {...props}
          className={clsx(
            'task-labels-select__listbox__option',
            selectedLabels.some((label) => label.id === option.id) &&
              'task-labels-select__listbox__option--selected'
          )}
        >
          <TaskLabelSelectOption
            label={option}
            allLabels={allLabels}
            onUpdateLabel={handleUpdateLabel}
          />
        </li>
      )}
      renderTags={(values, getTagProps) =>
        values.map((option: TaskLabel, index: number) => {
          const { key, ...tagProps } = getTagProps({ index });
          const color =
            allLabels.find((o) => o.id === option.id)?.color || option.color || grayLightColor;

          return (
            <Chip
              {...tagProps}
              deleteIcon={<CloseOutlined className="task-labels-select__tag__delete-icon" />}
              variant="outlined"
              className={clsx('task-labels-select__tag')}
              label={
                <div className="task-labels-select__tag__label">
                  <div
                    className="task-labels-select__tag__label__color"
                    style={{ backgroundColor: color }}
                  />
                  <div className="task-labels-select__tag__label__name">
                    {option.name || '(No name)'}
                  </div>
                </div>
              }
              key={key}
            />
          );
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label="Add labels"
          placeholder="Type a label"
          InputLabelProps={{ style: { fontSize: 14 } }}
        />
      )}
    />
  );
};

export default TaskLabelsSelect;
