import { useQuery } from '@tanstack/react-query';

import { calendarApi } from '../api';

interface UseEventWeeklyTrendParams {
  dataTrendId: string;
  startDate: string;
  endDate: string;
  enabled?: boolean;
}

interface UseEventWeeklyTrendQueryParams {
  queryKey: ['lifestack.trend.event.weekly', UseEventWeeklyTrendParams];
}

export const useEventWeeklyTrend = ({
  dataTrendId,
  startDate,
  endDate,
  enabled = true,
}: UseEventWeeklyTrendParams) => {
  const eventTrendQuery = useQuery({
    queryKey: ['lifestack.trend.event.weekly', { dataTrendId, startDate, endDate }],
    queryFn: ({ queryKey }: UseEventWeeklyTrendQueryParams) => {
      const [, { dataTrendId, startDate, endDate }] = queryKey;
      return calendarApi
        .getWeeklyEventTrend(dataTrendId, startDate, endDate)
        .then(({ data }) => data);
    },
    refetchOnWindowFocus: false,
    staleTime: 15000, // 15s
    enabled: !!dataTrendId && enabled,
  });

  return {
    eventTrend: eventTrendQuery.data,
    ...eventTrendQuery,
  };
};
