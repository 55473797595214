import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { RecommendedTask } from '@demind-inc/core';

import aiSchedulerBtn from '../../../../assets/scheduler/ai-scheduler-run-btn.png';
import { ReactComponent as EnergyBoostIcon } from '../../../../assets/scheduler/energy-boost-icon.svg';

import './EnergyTaskSuggestions.scss';
import clsx from 'clsx';

const DISPLAYING_TASKS_LIMIT = 2;

interface EnergyTaskSuggestionsProps {
  tasks: RecommendedTask[];
  hasAiScheduler?: boolean;
  hasEnergyBoosts?: boolean;
  onClickTask: (taskId: string) => void;
  onClickAIScheduler?: () => void;
  onClickEnergyBoosts?: () => void;
  onClickAddPreset?: () => void;
}

const EnergyTaskSuggestions: React.FC<EnergyTaskSuggestionsProps> = ({
  tasks,
  hasAiScheduler = false,
  hasEnergyBoosts = false,
  onClickTask,
  onClickAIScheduler = () => void 0,
  onClickEnergyBoosts = () => void 0,
  onClickAddPreset = () => void 0,
}) => {
  const { displayingTasks, shouldShowEllipsis } = useMemo(() => {
    const displayingTasks = tasks.slice(0, DISPLAYING_TASKS_LIMIT);
    const shouldShowEllipsis = false; //tasks.length > DISPLAYING_TASKS_LIMIT; //TODO: #789 enable to customize a task

    return { displayingTasks, shouldShowEllipsis };
  }, [tasks]);

  return (
    <Box className="energy-tasks__suggestions">
      {hasAiScheduler && (
        <button
          className={clsx('energy-tasks__task-button', 'energy-tasks__task-button--ai-scheduler')}
          onClick={onClickAIScheduler}
        >
          <img
            src={aiSchedulerBtn}
            alt="AI Scheduler"
            className="energy-tasks__task-icon--ai-scheduler"
          />
        </button>
      )}

      {hasEnergyBoosts && (
        <button
          className={clsx('energy-tasks__task-button', 'energy-tasks__task-button--primary')}
          onClick={onClickEnergyBoosts}
        >
          <EnergyBoostIcon className="energy-tasks__task-icon" />
          <span>Energy Boosts</span>
        </button>
      )}

      {displayingTasks.map((task) => (
        <button
          key={task.recommendedTaskId}
          className={clsx('energy-tasks__task-button', 'energy-tasks__task-button--primary')}
          onClick={() => onClickTask(task.recommendedTaskId)}
        >
          {task.name}
        </button>
      ))}

      {shouldShowEllipsis && (
        <button
          className={clsx('energy-tasks__task-button', 'energy-tasks__task-button--primary')}
          onClick={onClickAddPreset}
        >
          <MoreHorizIcon className="energy-tasks__task-button__icon" />
        </button>
      )}
    </Box>
  );
};

export default EnergyTaskSuggestions;
