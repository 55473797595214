import React from 'react';
import { Box, IconButton } from '@mui/material';
import { EnergyFeedbackType } from '@demind-inc/core';

import { energyFeedbackLabelSet } from '../constants';
import { EnergyBarLabel } from '../EnergyBarLabel';
import { getCssVariable } from '../../../utils';

interface EnergyFeedbackProps {
  selectedFeedback?: EnergyFeedbackType;
  onFeedbackSelect?: (feedback: EnergyFeedbackType) => void;
  disabled?: boolean;
  width?: number | string;
}

const primaryColor = getCssVariable('--color-primary');
const purpleLightColor = getCssVariable('--color-purple-light');
const grayLightColor = getCssVariable('--color-gray-light');
const grayBaseColor = getCssVariable('--color-gray-base');

const EnergyFeedback: React.FC<EnergyFeedbackProps> = ({
  selectedFeedback,
  onFeedbackSelect = () => void 0,
  disabled = false,
  width = 240,
}) => {
  return (
    <Box sx={{ width, display: 'flex', flexDirection: 'column', gap: 0 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-around', gap: 0 }}>
        {energyFeedbackLabelSet.map((label) => (
          <IconButton
            key={label}
            sx={{
              width: 30,
              height: 30,
              borderRadius: '50%',
              backgroundColor: purpleLightColor,
              ...(selectedFeedback === label &&
                !disabled && {
                  backgroundColor: primaryColor,
                }),
              ...(selectedFeedback === undefined && {
                borderWidth: 1,
                borderColor: primaryColor,
                backgroundColor: grayLightColor,
              }),
              ...(disabled && {
                backgroundColor: grayBaseColor,
                borderWidth: 0,
              }),
            }}
            onClick={() => !disabled && onFeedbackSelect(label)}
          />
        ))}
      </Box>
      <EnergyBarLabel width={width} selectedFeedback={selectedFeedback} />
    </Box>
  );
};

export default EnergyFeedback;
