import { FC } from 'react';

import './CustomTooltipContent.scss';
import { ReferenceLinkInfo, ReferenceLinkList } from '../ReferenceLink';
import { Button, Divider } from '@mui/material';
import { getCssVariable } from '../../../utils';

export interface CustomTooltipContentProps {
  title: string;
  description: string;
  referenceLinkList?: ReferenceLinkInfo[];
  learnMoreLink?: string;
}

const lightestGray = getCssVariable('--color-gray-lightest');

export const CustomTooltipContent: FC<CustomTooltipContentProps> = ({
  title,
  description,
  referenceLinkList = [],
  learnMoreLink,
}) => {
  return (
    <div className="custom-tooltip-content">
      <p className="custom-tooltip-content__title">{title}</p>
      <Divider color={lightestGray} />
      <p className="custom-tooltip-content__description">{description}</p>
      {referenceLinkList.length > 0 && <ReferenceLinkList referenceLinks={referenceLinkList} />}
      {!!learnMoreLink && (
        <Button
          onClick={() => window.open(learnMoreLink, '_blank')}
          className="custom-tooltip-content__learn-more"
        >
          Learn more
        </Button>
      )}
    </div>
  );
};
