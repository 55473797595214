import { useQuery } from '@tanstack/react-query';
import { tasksApi } from '../api';
import { TaskItem } from '@demind-inc/core';
import { TaskFilterOptions } from '../types';
import { TASK_FILTER_ALL_LABEL_KEY } from '../constants';

interface FetchOverdueTasksParams {
  visibleProjectIds: string[];
  dueDateTill: string;
  filter?: Pick<TaskFilterOptions, 'labelNames' | 'hideNoDueDate'>;
}

interface UseOverdueTasksQueryParams {
  queryKey: ['lifestack.todo.overdueTasks', FetchOverdueTasksParams];
}

export const useOverdueTasks = ({
  visibleProjectIds,
  dueDateTill,
  filter,
}: FetchOverdueTasksParams) => {
  return useQuery({
    queryKey: ['lifestack.todo.overdueTasks', { visibleProjectIds, dueDateTill, filter }],
    queryFn: ({ queryKey }: UseOverdueTasksQueryParams) => {
      const [, { visibleProjectIds, dueDateTill, filter }] = queryKey;

      const transformedLabelNames =
        filter?.labelNames === undefined
          ? undefined
          : filter?.labelNames === TASK_FILTER_ALL_LABEL_KEY
            ? undefined
            : filter?.labelNames.join(',');

      return tasksApi
        .getOverdueTodoTasks(
          visibleProjectIds,
          dueDateTill,
          undefined,
          transformedLabelNames,
          false,
          filter?.hideNoDueDate
        )
        .then(({ data }) => data as TaskItem[]);
    },
    enabled: visibleProjectIds?.length > 0 && !!dueDateTill,
    staleTime: 15000, // 15s
  });
};
