import { FC, useState } from 'react';
import { upperFirst } from 'lodash';
import { Subscription } from '@demind-inc/core';
import { LoadingButton } from '@mui/lab';
import clsx from 'clsx';

import './SubscriptionSettings.scss';
import {
  useAuthContext,
  useCancelStripeSubscription,
  useDeleteUser,
  useSubscriptionContext,
} from '../../../../data-access';
import { UnsubscribeDialog } from './UnsubscribeDialog';
import {
  checkFreeTrial,
  getFormattedSubscriptionDates,
  getFormattedPrice,
  getPriceAfterTrial,
  getPriceWithRecurring,
} from './helpers';
import {
  DeleteAccountDialog,
  ExpiredSubscriptionTimeline,
  SubscriptionTimeline,
} from '../../../Subscriptions';
import { getFreeTrialPeriodDaysFromLSSubscription } from '../../../../helpers';

const SubscriptionSettings: FC = () => {
  const [unsubscribeDialogVisible, setUnsubscribeDialogVisible] = useState(false);
  const { currentSubscription } = useSubscriptionContext();
  const {
    cancelStripeSubscription,
    error: cancelError,
    isPending: isCancelling,
  } = useCancelStripeSubscription();
  const { user, signOut } = useAuthContext();
  const [deleteUserDialogVisible, setDeleteUserDialogVisible] = useState(false);
  const { deleteUser: deleteUserApi, isPending: isDeleting } = useDeleteUser();

  const subscription = currentSubscription as Subscription;

  const isInFreeTrial = subscription ? checkFreeTrial(subscription) : false;
  const formattedDates = subscription ? getFormattedSubscriptionDates(subscription) : undefined;
  const price = subscription ? getFormattedPrice(subscription) : '';
  const priceAfterTrial = subscription ? getPriceAfterTrial(subscription, price) : '';
  const priceWithRecurring = subscription ? getPriceWithRecurring(subscription, price) : '';

  const handleSignOut = () => {
    signOut();
    setTimeout(() => window.location.reload(), 1000);
  };

  const handleUnsubscribe = () => {
    cancelStripeSubscription({ userId: user.userId });
  };

  const handleDeleteUser = async () => {
    // If user is unsubscribed, do not show the feedback dialog
    if (subscription?.status === 'unsubscribed') {
      if (isDeleting) {
        return;
      }
      await deleteUserApi({ userId: user.userId });
      handleSignOut();
      return;
    }

    setDeleteUserDialogVisible(true);
  };

  const renderTimeline = () => {
    if (isInFreeTrial) {
      return (
        <SubscriptionTimeline
          startTrialDate={formattedDates?.startFreeTrialDate}
          startChargeDate={formattedDates?.startChargeDate}
          freeTrialDays={getFreeTrialPeriodDaysFromLSSubscription(subscription)}
          price={priceAfterTrial}
          className="subscription-settings__timeline"
        />
      );
    }
    if (
      subscription?.status === 'unsubscribed' &&
      formattedDates.unsubscribedDate &&
      formattedDates.expirationDate
    ) {
      return (
        <ExpiredSubscriptionTimeline
          unsubscribedDate={formattedDates.unsubscribedDate}
          expiredDate={formattedDates.expirationDate}
          className="subscription-settings__timeline"
        />
      );
    }

    return <div className="subscription-settings__free-trial-container">{priceWithRecurring}</div>;
  };

  return (
    <div className="subscription-settings">
      <div className="subscription-settings__plan">
        <div className="subscription-settings__plan__title">
          {upperFirst(subscription?.recurring.toLowerCase())} plan{' '}
          {subscription?.status === 'unsubscribed' && (
            <p className="subscription-settings__plan__title--small">(Unsubscribed)</p>
          )}
          {renderTimeline()}
        </div>
      </div>

      {!!cancelError && <div className="subscription-settings__error">*{cancelError?.message}</div>}

      {subscription?.deviceFrom === 'web' &&
        ['freeTrial', 'active'].includes(subscription?.status!) && (
          <LoadingButton
            variant="outlined"
            className="subscription-settings__unsubscribe"
            onClick={() => setUnsubscribeDialogVisible(true)}
            loading={isCancelling}
            disabled={isCancelling}
          >
            Cancel subscription
          </LoadingButton>
        )}

      <UnsubscribeDialog
        open={unsubscribeDialogVisible}
        onClose={() => setUnsubscribeDialogVisible(false)}
        onUnsubscribe={handleUnsubscribe}
      />

      <div className="subscription-settings__account">
        <div onClick={handleSignOut}>Sign Out</div>
        <div
          onClick={handleDeleteUser}
          className={clsx(
            'subscription-settings__account__delete',
            isDeleting && 'subscription-settings__account__delete--deleting'
          )}
        >
          {isDeleting ? 'Deleting...' : 'Delete Account'}
        </div>
        <DeleteAccountDialog
          visible={deleteUserDialogVisible}
          where="afterPaywall"
          onClose={() => setDeleteUserDialogVisible(false)}
        />
      </div>
    </div>
  );
};

export default SubscriptionSettings;
