import { useMutation, useQueryClient } from '@tanstack/react-query';
import { calendarApi } from '../api';
import { CalendarEventMetrics } from '@demind-inc/core';

interface UseUpdateCalendarEventEnergyParams {
  calendarId: string;
  eventId: string;
  eventMetrics: CalendarEventMetrics;
}

export function useUpdateCalendarEventEnergy() {
  const queryClient = useQueryClient();

  const updateCalendarEventEnergyMutation = useMutation({
    mutationFn: ({ calendarId, eventId, eventMetrics }: UseUpdateCalendarEventEnergyParams) => {
      return calendarApi.updateCalendarEventEnergy(calendarId, eventId, eventMetrics);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.calendar.events'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.circadian'] });
    },
  });

  return {
    updateCalendarEventEnergy: updateCalendarEventEnergyMutation.mutateAsync,
    ...updateCalendarEventEnergyMutation,
  };
}
