import { FC, useState, useEffect, useCallback } from 'react';
import { clsx } from 'clsx';
import {
  Menu,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import { FilterList, ArrowDropDown } from '@mui/icons-material';
import { useRecoilState } from 'recoil';
import { TaskLabel } from '@demind-inc/core';
import { debounce } from 'lodash';

import './TaskFilter.scss';
import { useDropdownState } from '../../../hooks';
import { TaskFilterLabelNames, TaskFilterOptions, taskFilterAtom } from '../../../data-access';

interface TaskFilterProps {
  allLabels: TaskLabel[];
  className?: string;
}

const TaskFilter: FC<TaskFilterProps> = ({ allLabels = [], className }) => {
  const { anchorEl, handleOpenDropdown, handleCloseDropdown } = useDropdownState();
  const [taskFilter, setTaskFilter] = useRecoilState(taskFilterAtom);
  const [selectedLabels, setSelectedLabels] = useState<TaskLabel[]>([]);

  // Initialize selected labels from taskFilter
  useEffect(() => {
    if (taskFilter.labelNames === 'lifestack_all') {
      setSelectedLabels(allLabels);
    } else if (Array.isArray(taskFilter.labelNames)) {
      const labels = allLabels.filter((label) => taskFilter.labelNames?.includes(label.name));
      setSelectedLabels(labels);
    }
  }, [taskFilter.labelNames, allLabels]);

  const handleFilterChange = useCallback(
    debounce((newVal: Partial<TaskFilterOptions>) => {
      setTaskFilter((prev) => ({ ...prev, ...newVal }));
    }, 1000),
    []
  );

  const handleLabelToggle = (label: TaskLabel) => {
    const isSelected = selectedLabels.some((l) => l.name === label.name);
    let newSelectedLabels: TaskLabel[];

    if (isSelected) {
      newSelectedLabels = selectedLabels.filter((l) => l.name !== label.name);
    } else {
      newSelectedLabels = [...selectedLabels, label];
    }

    setSelectedLabels(newSelectedLabels);

    let newLabelNames: TaskFilterLabelNames = [];
    if (newSelectedLabels.length === allLabels.length) {
      newLabelNames = 'lifestack_all';
    } else if (newSelectedLabels.length > 0) {
      newLabelNames = newSelectedLabels.map((l) => l.name);
    }

    handleFilterChange({
      labelNames: newLabelNames,
    });
  };

  const handleSelectAllLabel = (isSelected: boolean) => {
    const newSelectedLabels = isSelected ? allLabels : [];
    setSelectedLabels(newSelectedLabels);
    handleFilterChange({
      labelNames: isSelected ? 'lifestack_all' : [],
    });
  };

  return (
    <>
      <IconButton
        className={clsx('task-filter', className, anchorEl && 'task-filter--open')}
        onClick={handleOpenDropdown}
        size="small"
      >
        <FilterList />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseDropdown}
        classes={{
          paper: 'task-filter__menu',
        }}
      >
        <div className="task-filter__content">
          <Accordion defaultExpanded className="task-filter__accordion">
            <AccordionSummary
              expandIcon={<ArrowDropDown />}
              className="task-filter__accordion__summary"
            >
              <Typography className="task-filter__accordion__summary__text">
                Filter by labels
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup className="task-filter__accordion__content">
                <FormControlLabel
                  labelPlacement="start"
                  className="task-filter__accordion__content__label--wrapper"
                  control={
                    <Checkbox
                      size="small"
                      checked={selectedLabels.length === allLabels.length}
                      onChange={(e) => handleSelectAllLabel(e.target.checked)}
                    />
                  }
                  label={
                    <div className="task-filter__accordion__content__label">
                      <span className="task-filter__accordion__content__label__text">
                        Select All
                      </span>
                    </div>
                  }
                />
                {allLabels.map((label) => (
                  <FormControlLabel
                    labelPlacement="start"
                    key={label.name}
                    className="task-filter__accordion__content__label--wrapper"
                    control={
                      <Checkbox
                        size="small"
                        checked={selectedLabels.some((l) => l.name === label.name)}
                        onChange={() => handleLabelToggle(label)}
                      />
                    }
                    label={
                      <div className="task-filter__accordion__content__label">
                        <div
                          className="task-filter__accordion__content__label__color"
                          style={{ backgroundColor: label.color }}
                        />
                        <span className="task-filter__accordion__content__label__text">
                          {label.name}
                        </span>
                      </div>
                    }
                  />
                ))}
              </FormGroup>
            </AccordionDetails>
          </Accordion>
        </div>
      </Menu>
    </>
  );
};

export default TaskFilter;
