import { TodoIntegrationType, SupportedTerraSDKType } from '@demind-inc/core';
import { TaskFilterOptions } from './types';

export const AVAILABLE_TODO_INTEGRATIONS_TYPE: TodoIntegrationType[] = [
  'todoist',
  'trello',
  'ticktick',
];

export const DEFAULT_FREE_TRIAL_DAYS = 7;
export const MONTH_FREE_TRIAL_DAYS = 30;

export const basicOfferingId = 'basic (with free trial)';

export const terraSdkNames: SupportedTerraSDKType[] = [
  'APPLE',
  'FREESTYLE_LIBRE',
  'GOOGLE_FIT',
  'SAMSUNG_HEALTH',
];

export const TASK_FILTER_ALL_LABEL_KEY = 'lifestack_all';

export const defaultTaskFilterOptions: TaskFilterOptions = {
  labelNames: TASK_FILTER_ALL_LABEL_KEY,
  hideNoDueDate: false,
  onlyMyTask: false,
};
