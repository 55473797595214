import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { TodoTasksBoard } from '@demind-inc/core';
import { Checkbox, FormControlLabel } from '@mui/material';

import './TodoListProject.scss';
import { debounce } from 'lodash';

interface TodoListProjectProps {
  project: TodoTasksBoard;
  defaultSelected: boolean;
  onSelect: (checked: boolean) => void;
  className?: string;
}

const TodoListProject: React.FC<TodoListProjectProps> = ({
  project,
  defaultSelected,
  onSelect,
  className,
}) => {
  const [selected, setSelected] = useState(defaultSelected);

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  const debouncedSelectApi = useCallback(
    debounce((newSelected: boolean) => {
      onSelect(newSelected);
    }, 1000),
    []
  );

  const handleSelect = (newSelected: boolean) => {
    setSelected(newSelected);
    debouncedSelectApi(newSelected);
  };

  return (
    <FormControlLabel
      className={clsx('calendar-list-item', className)}
      control={
        <Checkbox
          size="small"
          checked={selected}
          onChange={(e) => handleSelect(e.target.checked)}
        />
      }
      label={`# ${project.name}`}
    />
  );
};

export default TodoListProject;
