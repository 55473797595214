import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';

import './GoogleCallback.scss';
import {
  getWeekEdgesDateOf,
  googleCalAuth,
  useAuthContext,
  useSyncCalendarEvents,
  useSyncGCalendarsList,
  generalSnackBarAtom,
} from '../../../data-access';
import { Path } from '../../../routing';
import { useRecoilState } from 'recoil';

const GoogleCallback: FC = () => {
  const location = useLocation();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { syncGCalendarsList } = useSyncGCalendarsList();
  const { syncCalEvents } = useSyncCalendarEvents();
  const [_, setGeneralSnackbar] = useRecoilState(generalSnackBarAtom);

  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code') ?? '';

  useEffect(() => {
    if (!user.userId || !code) {
      return;
    }

    const update = async () => {
      try {
        const cred = await googleCalAuth.signinCallback(window.location.href);

        if (cred) {
          const scopes = cred.scopes;
          const newCalendarIds = await syncGCalendarsList({
            userId: user.userId,
            rootEmail: cred.profile.email,
            accessToken: cred.access_token,
            refreshToken: cred.refresh_token,
            expiryDate: dayjs.unix(cred.expires_at).toISOString(),
            scopes,
          });
          const { startDate, endDate } = getWeekEdgesDateOf(dayjs());

          syncCalEvents({
            userId: user.userId,
            calendarIds: newCalendarIds,
            startTime: startDate.toISOString(),
            endTime: endDate.toISOString(),
          });
        }
      } catch (error) {
        if (error instanceof Error && error.message.includes('No matching state found')) {
          setGeneralSnackbar(
            'Something went wrong. Make sure you have cookies enabled and try again.'
          );
        } else {
          setGeneralSnackbar('Something went wrong. Please try again.');
        }
      } finally {
        navigate(Path.DASHBOARD);
      }
    };

    update();
  }, [user?.userId, code]);

  return (
    <div className="google-callback-page">
      <CircularProgress />
    </div>
  );
};

export default GoogleCallback;
