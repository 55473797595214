import { FC, lazy, Suspense, useMemo } from 'react';

import './DashboardPage.scss';

import { SideBar } from './SideBar';
import DataView from '../DataView/DataView';
import {
  CoreActions,
  SettingsModal,
  WearableErrorSnackBar,
  TaskErrorSnackBar,
  CalendarEventSnackBar,
  ViewSwitcher,
} from '../../components';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  calendarViewSwitcherAtom,
  homeViewSwitcherAtom,
  settingsModalAtom,
  useSyncOnFirstRender,
  viewSwitcherAtom,
} from '../../data-access';
import { FirstOpenDialogGroup } from './FirstOpenDialogGroup';
import { ContentViewSwitcher } from '../../components/common/ContentViewSwitcher';
import { CircularProgress } from '@mui/material';
const CalendarWeekMonthView = lazy(() =>
  import('./CalendarWeekMonthView').then((module) => ({ default: module.CalendarWeekMonthView }))
);
const KanbanView = lazy(() =>
  import('./KanbanView').then((module) => ({ default: module.KanbanView }))
);
const OneDayCalendarView = lazy(() =>
  import('./CalendarView').then((module) => ({ default: module.OneDayCalendarView }))
);

const DashboardPage: FC = () => {
  const [settingsModalVisible, setSettingsModalVisible] = useRecoilState(settingsModalAtom);
  const currentView = useRecoilValue(viewSwitcherAtom);
  const currentHomeView = useRecoilValue(homeViewSwitcherAtom);
  useSyncOnFirstRender();
  const currentCalendarView = useRecoilValue(calendarViewSwitcherAtom);

  // Show core actions only in one-day calendar view
  const showCoreActions = useMemo(() => {
    if (currentView === 'Data') {
      return false;
    }
    if (currentHomeView === 'Todo') {
      return true;
    }
    return currentCalendarView === 'day';
  }, [currentCalendarView, currentHomeView, currentView]);

  return (
    <>
      <div className="dashboard-page">
        <div className="dashboard-page__sidebar-container">
          <SideBar className="dashboard-page__side-bar" />
        </div>
        <div className="dashboard-page__content">
          <div className="dashboard-page__core-actions">
            <ViewSwitcher />
            {showCoreActions && <CoreActions />}
          </div>
          {currentView === 'Home' ? (
            <div className="dashboard-page__kanban-calendar-container">
              {currentHomeView === 'Todo' ? (
                <Suspense
                  fallback={
                    <div className="dashboard-page__loader">
                      <CircularProgress />
                    </div>
                  }
                >
                  <KanbanView className="dashboard-page__kanban-calendar-container__kanban-view" />
                  <OneDayCalendarView className="dashboard-page__kanban-calendar-container__calendar-view" />
                </Suspense>
              ) : (
                <Suspense
                  fallback={
                    <div className="dashboard-page__loader">
                      <CircularProgress />
                    </div>
                  }
                >
                  <CalendarWeekMonthView className="dashboard-page__calendar-week-month-view" />
                </Suspense>
              )}
              <ContentViewSwitcher className="dashboard-page__content-view-toggle" />
            </div>
          ) : (
            <div className="dashboard-page__data-view-container">
              <DataView />
            </div>
          )}
        </div>
      </div>
      <SettingsModal
        visible={settingsModalVisible.visible}
        onClose={() => setSettingsModalVisible({ visible: false, defaultPosition: undefined })}
        defaultPosition={settingsModalVisible.defaultPosition}
      />
      <WearableErrorSnackBar />
      <TaskErrorSnackBar />
      <CalendarEventSnackBar />
      <FirstOpenDialogGroup />
    </>
  );
};

export default DashboardPage;
