import { Gauge, gaugeClasses } from '@mui/x-charts';
import { Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { getColorByEnergyScore } from '../../../KanbanView/CoreActions/CoreActionModal/ActionModalViews/DailyAnalysisModalView/helpers';
import clsx from 'clsx';
import './EnergyGaugeCard.scss';

interface EnergyGaugeCardProps {
  peakEnergy: number;
  dataTrendPeriod: 'daily' | 'weekly';
  className?: string;
}

const EnergyGaugeCard: FC<EnergyGaugeCardProps> = ({ peakEnergy, dataTrendPeriod, className }) => {
  const targetColor = useMemo(() => getColorByEnergyScore(peakEnergy), [peakEnergy]);

  return (
    <div className={clsx('energy-gauge-card', className)}>
      <div className="energy-gauge-card__value-container">
        <Typography variant="h2" className="energy-gauge-card__energy-value">
          {Math.round(peakEnergy)}
        </Typography>
        <Typography variant="subtitle2" className="energy-gauge-card__energy-unit">
          {dataTrendPeriod === 'daily' ? 'Peak Energy' : 'Avg Peak Energy'}
        </Typography>
      </div>
      <Gauge
        width={299}
        height={148}
        value={Math.round(peakEnergy)}
        startAngle={-90}
        endAngle={90}
        innerRadius={80}
        className="energy-gauge-card__gauge-chart"
        sx={(theme) => ({
          [`& .${gaugeClasses.valueText}`]: {
            fontSize: 48,
            fontWeight: 700,
            transform: 'translateY(-40px)',
          },
          [`& .${gaugeClasses.valueArc}`]: {
            fill: targetColor,
          },
          [`& .${gaugeClasses.referenceArc}`]: {
            fill: theme.palette.text.disabled,
          },
          [`& .${gaugeClasses.valueText} text`]: {
            fill: 'transparent',
          },
        })}
      />
    </div>
  );
};

export default EnergyGaugeCard;
