import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import clsx from 'clsx';

import './DiscountCodeDialog.scss';

interface DiscountCodeDialogProps {
  open: boolean;
  isValidating: boolean;
  error?: string;
  onClose: () => void;
  onValidate: (code: string) => Promise<void>;
}

const DiscountCodeDialog: React.FC<DiscountCodeDialogProps> = ({
  open,
  error,
  isValidating,
  onClose,
  onValidate,
}) => {
  const [discountCode, setDiscountCode] = useState('');
  const [validationError, setValidationError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (error) {
      setValidationError('Invalid Discount Code');
      setIsSuccess(false);
    } else {
      setValidationError('');
    }
  }, [error]);

  const handleSubmit = async () => {
    if (!discountCode) {
      setIsSuccess(false);
      return;
    }

    try {
      await onValidate(discountCode);
      setIsSuccess(true);
      // Close the dialog after 2 seconds
      setTimeout(() => {
        onClose();
      }, 1000);
    } catch (e) {}
  };

  const handleClose = () => {
    setDiscountCode('');
    setIsSuccess(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} className="discount-code-dialog">
      <DialogTitle>Claim your benefit</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Enter your referral code"
          type="text"
          fullWidth
          variant="standard"
          value={discountCode}
          onChange={(e) => {
            setDiscountCode(e.target.value);
            setValidationError('');
          }}
        />
        <div
          className={clsx(
            'discount-code-dialog__error',
            isSuccess && 'discount-code-dialog__success'
          )}
        >
          {isSuccess ? 'Code verified!' : validationError}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className="discount-code-dialog__cancel">
          Cancel
        </Button>
        <LoadingButton
          onClick={handleSubmit}
          color="primary"
          loading={isValidating}
          disabled={!discountCode}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DiscountCodeDialog;
