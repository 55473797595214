import React, { FC, memo, useMemo, useState, useEffect, useTransition } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  ReferenceLine,
  ReferenceDot,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import dayjs from 'dayjs';
import { HeatmapDataType } from '@demind-inc/core';
import { FluctuationSetByReasons, selectedDateAtom } from '../../../../data-access';
import clsx from 'clsx';
import './CircadianChart.scss';
import { useRecoilValue } from 'recoil';
import { useGeneralSettings } from '../../../../hooks/useGeneralSettings';
import { CustomMarker } from './CustomMarker';
import { ChartLegend } from './ChartLegend';
import { EnergyFluctuations } from './EnergyFluctuations';
import { CHART_HEIGHT, grayBase, grayPurple, primaryColor, purpleTaro } from '../../constants';
import { CircadianChartToolTip } from './CircadianChartToolTip';
import { CircularProgress } from '@mui/material';
import { CircadianChartData, CircadianChartDecorator } from './types';

const XAXIS_TICK_NUM = 6;
const XAXIS_TICK_START_TIME = 5;
const CHUNK_SIZE = 500; // Process 500 items per frame

interface CircadianChartProps {
  selectedDateCircadianData: HeatmapDataType[];
  selectedDateEnergyFluctuations: FluctuationSetByReasons;
  avgCircadianData: HeatmapDataType[];
  hideLegend?: boolean;
  className?: string;
}

const CircadianChart: FC<CircadianChartProps> = memo(
  ({ selectedDateCircadianData, selectedDateEnergyFluctuations, avgCircadianData, className }) => {
    const {
      generalSettings: { timeFormat },
    } = useGeneralSettings();
    const selectedDate = useRecoilValue(selectedDateAtom);
    const [isPending, startTransition] = useTransition();
    const [formattedData, setFormattedData] = useState<CircadianChartData[] | null>(null);

    const targetDate = useMemo(() => selectedDate.format('YYYY-MM-DD'), [selectedDate]);

    const { shouldShowPastData, xAxisTicks } = useMemo(() => {
      const shouldShowPastData = !selectedDate.isAfter(dayjs(), 'day');
      const xAxisTicks = Array.from({ length: XAXIS_TICK_NUM }, (_, i) => {
        const targetTime = i * 4 + XAXIS_TICK_START_TIME;
        return targetTime < 24
          ? dayjs(`${targetDate} ${String(targetTime).padStart(2, '0')}:00`).valueOf()
          : dayjs(
              `${selectedDate.add(1, 'day').format('YYYY-MM-DD')} ${String(targetTime - 24).padStart(2, '0')}:00`
            ).valueOf();
      });
      return { shouldShowPastData, xAxisTicks };
    }, [selectedDate, targetDate]);

    useEffect(() => {
      startTransition(() => {
        // Precompute avgCircadianData map
        const avgDataMap = new Map<string, number>();
        avgCircadianData.forEach((avg) => {
          avgDataMap.set(dayjs(avg.y).format('HH:mm'), avg.value);
        });

        // Chunk the computation
        const chunks = [];
        for (let i = 0; i < selectedDateCircadianData.length; i += CHUNK_SIZE) {
          chunks.push(selectedDateCircadianData.slice(i, i + CHUNK_SIZE));
        }

        let accumulatedData: typeof formattedData = [];
        const processChunk = (chunkIndex: number) => {
          if (chunkIndex >= chunks.length) {
            setFormattedData(accumulatedData);
            return;
          }

          const chunkData = chunks[chunkIndex].map((item) => {
            const timeStr = dayjs(item.y).format('HH:mm');
            return {
              time: dayjs(item.y).valueOf(),
              currentValue: shouldShowPastData ? item.value : null,
              avgValue: avgDataMap.get(timeStr),
            };
          });

          accumulatedData = [...accumulatedData, ...chunkData];
          requestAnimationFrame(() => processChunk(chunkIndex + 1));
        };

        processChunk(0);
      });
    }, [selectedDateCircadianData, avgCircadianData, shouldShowPastData]);

    const circadianDataMap = useMemo(
      () =>
        selectedDateCircadianData.reduce(
          (acc, d) => {
            acc[dayjs(d.y).format('YYYY-MM-DD HH:mm')] = d.value;
            return acc;
          },
          {} as Record<string, number>
        ),
      [selectedDateCircadianData]
    );

    const decorators = useMemo(() => {
      const decorations: CircadianChartDecorator[] = [];

      selectedDateEnergyFluctuations?.phaseEma?.forEach((emaData) => {
        const endTime = dayjs(emaData.eventTime.endTime);
        decorations.push({
          time: endTime.valueOf(),
          type: 'phaseEma',
          score: emaData.fluctuateScore,
          y: circadianDataMap[endTime.format('YYYY-MM-DD HH:mm')],
        });
      });

      selectedDateEnergyFluctuations?.event?.forEach((event) => {
        if (event.eventTime.endTime) {
          const endTime = dayjs(event.eventTime.endTime);
          decorations.push({
            time: endTime.valueOf(),
            type: 'event',
            score: event.fluctuateScore,
            category: event.eventCategory?.id,
            y: circadianDataMap[endTime.format('YYYY-MM-DD HH:mm')],
          });
        }
      });

      selectedDateEnergyFluctuations?.sleep?.forEach((sleep) => {
        const endTime = dayjs(sleep.eventTime.endTime);
        decorations.push({
          time: endTime.valueOf(),
          type: 'sleep',
          score: sleep.fluctuateScore,
          y: circadianDataMap[endTime.format('YYYY-MM-DD HH:mm')],
        });
      });

      return decorations;
    }, [selectedDateEnergyFluctuations, circadianDataMap]);

    const formatXAxis = (timestamp: number) =>
      dayjs(timestamp).format(timeFormat === '24h' ? 'HH:mm' : 'h:mm a');

    if (!formattedData) {
      return (
        <div className={clsx('circadian-chart-container', className)}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <div className={clsx('circadian-chart-container', className)}>
        {isPending && (
          <div className="circadian-chart__loading-overlay">
            <CircularProgress />
          </div>
        )}
        <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
          <LineChart
            style={{ background: grayPurple, borderRadius: 16 }}
            data={formattedData}
            margin={{ top: 5, right: 20, bottom: 15, left: 10 }}
          >
            <CartesianGrid stroke={grayBase} strokeOpacity={0.1} />
            <XAxis
              dataKey="time"
              tickFormatter={formatXAxis}
              ticks={xAxisTicks}
              type="number"
              domain={[xAxisTicks[0], xAxisTicks[xAxisTicks.length - 1]]}
              style={{ fontSize: '12px', color: grayBase }}
              tickLine={false}
              axisLine={false}
              padding={{ left: 30, right: 30 }}
            />
            <Tooltip
              content={({ active, payload }) => (
                <CircadianChartToolTip active={active} payload={payload} />
              )}
              wrapperStyle={{ outline: 'none' }}
              cursor={{ strokeDasharray: '3 3' }}
            />
            <Line
              type="monotone"
              dataKey="currentValue"
              stroke={primaryColor}
              strokeWidth={3}
              dot={false}
              connectNulls
            />
            <Line
              type="monotone"
              dataKey="avgValue"
              stroke={primaryColor}
              strokeWidth={3}
              strokeDasharray="4 1"
              dot={false}
              connectNulls
            />
            {decorators.map((decorator, index) => (
              <ReferenceDot
                key={index}
                x={decorator.time}
                y={decorator.y}
                shape={
                  <CustomMarker
                    eventType={decorator.type}
                    score={decorator.score}
                    category={decorator.category}
                  />
                }
              />
            ))}
            {shouldShowPastData && (
              <ReferenceLine x={dayjs().valueOf()} stroke={purpleTaro} strokeWidth={1} />
            )}
          </LineChart>
        </ResponsiveContainer>
        <ChartLegend />
        <EnergyFluctuations selectedDateEnergyFluctuations={selectedDateEnergyFluctuations} />
      </div>
    );
  }
);

export default CircadianChart;
