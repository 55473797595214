import { SVGProps } from 'react';
import { ReactComponent as ActualRhythm } from '../../../../../assets/icons/actual-rythm-legend.svg';
import { ReactComponent as AverageRhythm } from '../../../../../assets/icons/average-rythm-legend.svg';
import { ReactComponent as CurrentTime } from '../../../../../assets/icons/current-time-legend.svg';

export const LEGENDS: { icon: React.FC<SVGProps<SVGSVGElement>>; name: string }[] = [
  {
    icon: ActualRhythm,
    name: 'Actual Rhythm',
  },
  {
    icon: AverageRhythm,
    name: 'Average Rhythm',
  },
  {
    icon: CurrentTime,
    name: 'Current Time',
  },
];
