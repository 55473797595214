import React from 'react';
import clsx from 'clsx';
import { Icon, IconButton, Popover } from '@mui/material';
import { AppDownloadSwitcher } from '../../AppDownloadSwitcher';
import { SIDEBAR_ICONS } from '../constants';
import './GeneralCTA.scss';
import { useRecoilState } from 'recoil';
import { settingsModalAtom } from '../../../data-access';
import { SIDEBAR_MENU } from '../../../pages/DashboardPage/SideBar';

interface GeneralCTAProps {
  className?: string;
}

const GeneralCTA: React.FC<GeneralCTAProps> = ({ className }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [_, setSettingsModalVisible] = useRecoilState(settingsModalAtom);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={clsx('general-cta', className)}>
      <button aria-describedby={id} className="general-cta__button" onClick={handleClick}>
        <img
          src={SIDEBAR_ICONS['mobile']}
          alt="Lifestack mobile app"
          className="general-cta__item__icon"
        />
        <span className="general-cta__item__text">Install Mobile App</span>
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        className="general-cta__popover"
      >
        <div className="general-cta__modal">
          <div className="general-cta__modal__header">
            <p>Install Mobile App</p>
            <IconButton onClick={handleClose} className="general-cta__modal__close-button">
              <img
                src={SIDEBAR_ICONS['close']}
                alt="Close"
                className="general-cta__modal__close-button__icon"
              />
            </IconButton>
          </div>
          <div className="general-cta__modal__content">
            <AppDownloadSwitcher />
          </div>
        </div>
      </Popover>
      <a
        href="https://chromewebstore.google.com/detail/lifestack-extension/mbpcaihbaecjnndomdpkigmmhdnfccjb"
        target="_blank"
        className="general-cta__item"
        rel="noopener noreferrer"
      >
        <img
          src={SIDEBAR_ICONS['chrome']}
          alt="Lifestack Chrome extension"
          className="general-cta__item__icon"
        />
        <span className="general-cta__item__text">Install Chrome Extension</span>
      </a>
      <button
        className="general-cta__button"
        onClick={() => setSettingsModalVisible({ visible: true })}
      >
        <div className="general-cta__item__icon">
          <SIDEBAR_MENU.setting />
        </div>
        <span className="general-cta__item__text">Settings</span>
      </button>
    </div>
  );
};

export default GeneralCTA;
