import { useQuery } from '@tanstack/react-query';
import { EventDataTrendWithData } from '@demind-inc/core';

import { calendarApi } from '../api';

interface UseEventDailyTrendParams {
  dataTrendId: string;
  date: string;
  enabled?: boolean;
}

interface UseEventDailyTrendQueryParams {
  queryKey: ['lifestack.trend.event.daily', UseEventDailyTrendParams];
}

export const useEventDailyTrend = ({
  dataTrendId,
  date,
  enabled = true,
}: UseEventDailyTrendParams) => {
  const eventTrendQuery = useQuery({
    queryKey: ['lifestack.trend.event.daily', { dataTrendId, date }],
    queryFn: ({ queryKey }: UseEventDailyTrendQueryParams) => {
      const [, { dataTrendId, date }] = queryKey;
      return calendarApi
        .getDailyEventTrend(dataTrendId, date)
        .then(({ data }) => data as EventDataTrendWithData);
    },
    refetchOnWindowFocus: false,
    staleTime: 15000, // 15s
    enabled: !!dataTrendId && enabled,
  });

  return {
    eventTrend: eventTrendQuery.data,
    ...eventTrendQuery,
  };
};
