import { FC, memo, useEffect, useMemo, useState } from 'react';
import { Modal, TextField, Tooltip } from '@mui/material';
import { TaskItem, TaskItemDuration } from '@demind-inc/core';
import { CloseOutlined, LocalOfferOutlined } from '@mui/icons-material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';

import './TaskDetailModal.scss';
import {
  circadianPhaseInfo,
  eventsSnackBarAtom,
  useAddTodoTask,
  useAuthContext,
  useCircadianContext,
  useDeleteTask,
  useTodoTasksContext,
  useUpdateTaskStatus,
  useUpdateTodoTask,
} from '../../data-access';
import { useRecoilState } from 'recoil';
import { TaskDueDatePicker } from './TaskDueDatePicker';
import {
  formatTaskTimeRange,
  getFormattedDueDateForAddTask,
  getFormattedDueDateForUpdateTask,
  getFormattedDuration,
} from '../../helpers';
import { isEmpty, isEqual, omit } from 'lodash';
import TaskDetailTimeProject from './TaskDetailTimeProject/TaskDetailTimeProject';
import { useAddProject } from '../../hooks';
import { AddProjectDialog } from '../Project';
import { trackEventMixpanel } from '../../utils';
import { useGeneralSettings } from '../../hooks/useGeneralSettings';
import { TaskLabelsSelect } from './TaskLabelsSelect';

interface TaskDetailModalProps {
  visible: boolean;
  task?: TaskItem;
  onClose: () => void;
}

export type TaskDetail = Pick<
  TaskItem,
  'name' | 'desc' | 'dueDateTime' | 'startDateTime' | 'boardId' | 'labels'
>;

const TaskDetailModal: FC<TaskDetailModalProps> = memo(
  ({ visible, task: defaultTask, onClose }) => {
    const isEditMode = !isEmpty(omit(defaultTask, ['dueDateTime']));
    const { user } = useAuthContext();
    const { findProject, visibleProjectIds, todoProjects, taskLabels } = useTodoTasksContext();
    const { findPhaseForTaskTime } = useCircadianContext();

    const [_, setEventsSnackbar] = useRecoilState(eventsSnackBarAtom);
    const { updateTodoTask, isUpdating } = useUpdateTodoTask();
    const { addTodoTask, isPending: isAddingTask } = useAddTodoTask();
    const { projectDialogVisible, isAddingProject, handleAddProject, handleProjectDialogVisible } =
      useAddProject();
    const { isTaskStatusUpdating, updateTodoTaskStatus } = useUpdateTaskStatus();
    const [taskInfo, setTaskInfo] = useState<TaskDetail>({
      name: defaultTask?.name || '',
      desc: defaultTask?.desc || '',
      boardId: defaultTask?.boardId || '',
      dueDateTime: defaultTask?.dueDateTime || { timezone: dayjs.tz.guess() },
      startDateTime: defaultTask?.startDateTime || { timezone: dayjs.tz.guess() },
      labels: defaultTask?.labels ?? [],
    });
    const {
      generalSettings: { timeFormat },
    } = useGeneralSettings();

    const { deleteTask, isDeleting } = useDeleteTask();

    const selectedProject = useMemo(
      () => todoProjects?.find((p) => p.todoTaskId === taskInfo?.boardId),
      [taskInfo?.boardId]
    );
    const canSave = useMemo(() => {
      if (!isEditMode) {
        const hasDateSet = !!Object.values({
          date: taskInfo.dueDateTime?.date,
          datetime: taskInfo.dueDateTime?.datetime,
        }).filter((i) => !!i).length;
        return !!taskInfo.name.trim() && hasDateSet && !!taskInfo.boardId;
      }

      const { name, desc, dueDateTime, startDateTime, boardId, labels } = taskInfo;
      const defaultTaskInfo = defaultTask as TaskItem;

      return (
        name?.trim() !== defaultTaskInfo.name?.trim() ||
        desc?.trim() !== defaultTaskInfo.desc?.trim() ||
        dueDateTime.date !== defaultTaskInfo.dueDateTime?.date ||
        dueDateTime.datetime !== defaultTaskInfo.dueDateTime?.datetime ||
        startDateTime?.date !== defaultTaskInfo.startDateTime?.date ||
        startDateTime?.datetime !== defaultTaskInfo.startDateTime?.datetime ||
        boardId !== (defaultTaskInfo.boardId ?? '') ||
        !isEqual(labels, defaultTaskInfo.labels ?? [])
      );
    }, [taskInfo, defaultTask, isEditMode]);

    useEffect(() => {
      if (defaultTask) {
        setTaskInfo({
          name: defaultTask.name || '',
          desc: defaultTask.desc || '',
          boardId: defaultTask.boardId || visibleProjectIds?.[0] || '', // In case of a new task, set the default project to the visible project.
          dueDateTime: defaultTask.dueDateTime || { timezone: dayjs.tz.guess() },
          startDateTime: defaultTask.startDateTime || { timezone: dayjs.tz.guess() },
          labels: defaultTask.labels ?? [],
        });
      }
    }, [defaultTask, visibleProjectIds]);

    const isCompletedTickTickTask = useMemo(
      () => defaultTask?.appFrom === 'ticktick' && defaultTask?.completed,
      [defaultTask]
    );

    const handleTask = async () => {
      if (!isEditMode) {
        await addTodoTask({
          boardId: taskInfo.boardId,
          userId: user?.userId,
          newTaskInfo: {
            name: taskInfo.name,
            desc: taskInfo.desc,
            ...getFormattedDueDateForAddTask('due', taskInfo.dueDateTime),
            ...getFormattedDueDateForAddTask('start', taskInfo.startDateTime),
            appFrom: selectedProject?.appFrom,
            labels: taskInfo.labels ?? [],
            assigneesIds: [],
            ...getFormattedDuration(taskInfo.startDateTime, taskInfo.dueDateTime),
            addedToCalendar: false,
            completed: false,
            rawJson: {},
            updatedAt: new Date().toISOString(),
          },
        });
        trackEventMixpanel('create_task', {
          name: taskInfo.name,
          appFrom: selectedProject?.appFrom,
        });
        handleClose();
        return;
      }

      try {
        updateTodoTask({
          userId: user?.userId,
          boardId: taskInfo.boardId,
          taskId: defaultTask.taskId,
          newTaskInfo: {
            name: taskInfo.name,
            desc: taskInfo.desc,
            ...getFormattedDueDateForUpdateTask('due', taskInfo.dueDateTime),
            ...getFormattedDueDateForUpdateTask('start', taskInfo.startDateTime),
            ...getFormattedDuration(taskInfo.startDateTime, taskInfo.dueDateTime),
            labels: taskInfo?.labels ?? [],
            appFrom: defaultTask.appFrom,
          },
        });
        handleClose();
        trackEventMixpanel('update_todo_task', {
          name: taskInfo.name,
          appFrom: selectedProject?.appFrom,
        });
        if (taskInfo?.labels?.length) {
          trackEventMixpanel('add_labels');
        }
      } catch (error) {
        console.error('Failed to update task:', error);
        setEventsSnackbar('Failed to update task. Please try again.');
      }
    };

    const handleKeyDown = (key: string) => {
      if (!canSave) {
        return;
      }

      if (key === 'Enter') {
        handleTask();
      }
    };

    const handleUpdateTaskStatus = async () => {
      try {
        await updateTodoTaskStatus({
          taskId: defaultTask.taskId,
          completed: !defaultTask.completed,
          boardId: taskInfo.boardId,
          userId: user?.userId,
        });
        trackEventMixpanel('update_todo_task_status');
        onClose();
      } catch (error) {
        console.error('Failed to update task status:', error);
        setEventsSnackbar('Failed to update task status. Please try again. ');
      }
    };

    const taskTime = isEditMode
      ? formatTaskTimeRange({
          startDatetime: taskInfo.startDateTime?.datetime
            ? dayjs(taskInfo.startDateTime.datetime)
            : undefined,
          endDatetime: taskInfo.dueDateTime.datetime
            ? dayjs(taskInfo.dueDateTime?.datetime)
            : undefined,
          timeFormat,
        })
      : '';

    const phase = useMemo(() => {
      if (!taskInfo.startDateTime?.datetime || !taskInfo.dueDateTime?.datetime) return null;

      const targetPhase = findPhaseForTaskTime(
        taskInfo.startDateTime.datetime,
        taskInfo.dueDateTime.datetime
      );
      return targetPhase ? circadianPhaseInfo[targetPhase] : null;
    }, [taskInfo, findPhaseForTaskTime]);

    const handleDeleteTask = async () => {
      try {
        await deleteTask({
          taskId: defaultTask.taskId,
          boardId: taskInfo.boardId,
          userId: user?.userId,
        });
        handleClose();
      } catch (error) {
        console.error('Failed to delete task:', error);
        setEventsSnackbar('Failed to delete task. Please try again.');
      }
    };

    const handleClose = () => {
      onClose();
      setTaskInfo({
        name: '',
        desc: '',
        boardId: '',
        dueDateTime: { timezone: dayjs.tz.guess() },
        startDateTime: { timezone: dayjs.tz.guess() },
      });
    };

    return (
      <>
        <Modal
          open={visible}
          onClose={handleClose}
          className="task-detail-modal"
          onKeyDown={({ key }) => handleKeyDown(key)}
          disableRestoreFocus
        >
          <div className="task-detail-modal__container">
            <div className="task-detail-modal__header">
              <div className="task-detail-modal__header__title">Task</div>
              <CloseOutlined
                fontSize="small"
                className="task-detail-modal__header__close"
                onClick={handleClose}
              />
            </div>

            <div className="task-detail-modal__content">
              <div className="task-detail-modal__title-group">
                <TextField
                  placeholder="Title"
                  value={taskInfo.name}
                  onChange={(e) => setTaskInfo((prev) => ({ ...prev, name: e.target.value }))}
                  variant="standard"
                  className="task-detail-modal__title-input"
                  autoFocus
                />
                <TextField
                  placeholder="Description"
                  multiline
                  rows={2}
                  value={taskInfo.desc}
                  onChange={(e) => setTaskInfo((prev) => ({ ...prev, desc: e.target.value }))}
                  variant="standard"
                  className="task-detail-modal__desc-textarea"
                />
              </div>

              <TaskDetailTimeProject
                taskTime={taskTime}
                phase={phase}
                taskInfo={taskInfo}
                findProject={findProject}
                todoProjects={todoProjects}
                onProjectSelect={(boardId) => setTaskInfo((prev) => ({ ...prev, boardId }))}
                onProjectAdd={() => handleProjectDialogVisible(true)}
                isDisabled={isEditMode}
              />

              <div className="task-detail-modal__details-section">
                <div className="task-detail-modal__due-date-picker">
                  <AccessTimeIcon
                    style={{ marginTop: '5px' }}
                    className="task-detail-modal__icon"
                  />
                  <div className="task-detail-modal__due-date-picker__content">
                    {taskInfo?.dueDateTime?.datetime && (
                      <TaskDueDatePicker
                        label="Due Date (Start)"
                        maxDateTime={
                          taskInfo.dueDateTime.datetime
                            ? dayjs(taskInfo.dueDateTime.datetime)
                            : undefined
                        }
                        dateSet={{
                          date: taskInfo.startDateTime?.date,
                          datetime: taskInfo.startDateTime?.datetime,
                        }}
                        onDateChange={(dateSet) => {
                          setTaskInfo((prev) => ({
                            ...prev,
                            startDateTime: {
                              timezone: dayjs.tz.guess(),
                              datetime: dateSet.datetime,
                              date: dateSet.date,
                            },
                          }));
                        }}
                        onClearDate={
                          selectedProject?.appFrom === 'ticktick' //TODO: #749 support ticktick
                            ? undefined
                            : () =>
                                setTaskInfo((prev) => ({
                                  ...prev,
                                  startDateTime: {
                                    timezone: dayjs.tz.guess(),
                                    datetime: '',
                                    date: '',
                                  },
                                }))
                        }
                      />
                    )}
                    <TaskDueDatePicker
                      label={taskInfo.dueDateTime.datetime ? 'Due Date (End)' : 'Due Date'}
                      minDateTime={
                        taskInfo.startDateTime.datetime
                          ? dayjs(taskInfo.startDateTime.datetime)
                          : undefined
                      }
                      dateSet={{
                        date: taskInfo?.dueDateTime?.date,
                        datetime: taskInfo?.dueDateTime?.datetime,
                      }}
                      onDateChange={(dateSet) =>
                        setTaskInfo((prev) => ({
                          ...prev,
                          dueDateTime: {
                            timezone: dayjs.tz.guess(),
                            datetime: dateSet.datetime,
                            date: dateSet.date,
                          },
                        }))
                      }
                      onClearDate={
                        selectedProject?.appFrom === 'ticktick'
                          ? undefined
                          : () =>
                              setTaskInfo((prev) => ({
                                ...prev,
                                dueDateTime: {
                                  timezone: dayjs.tz.guess(),
                                  date: '',
                                },
                              }))
                      }
                      onClearTime={
                        selectedProject?.appFrom === 'ticktick'
                          ? undefined
                          : () =>
                              setTaskInfo((prev) => ({
                                ...prev,
                                dueDateTime: {
                                  timezone: dayjs.tz.guess(),
                                  date: prev.dueDateTime.datetime
                                    ? dayjs(prev.dueDateTime.datetime).format('YYYY-MM-DD')
                                    : prev.dueDateTime?.date!,
                                  datetime: '',
                                },
                              }))
                      }
                    />
                  </div>
                </div>
                <div className="task-detail-modal__content__item">
                  <LocalOfferOutlined className="task-detail-modal__icon" />
                  <TaskLabelsSelect
                    allLabels={taskLabels}
                    selectedLabels={taskInfo.labels}
                    setSelectedLabels={(newLabels) =>
                      setTaskInfo((prev) => ({ ...prev, labels: newLabels }))
                    }
                  />
                </div>
              </div>
            </div>

            <div className="task-detail-modal__button-group">
              <Tooltip title={isCompletedTickTickTask ? 'Incomplete on TickTick app' : ''}>
                <div>
                  <LoadingButton
                    variant="text"
                    className="task-detail-modal__button-group__button task-status"
                    onClick={handleUpdateTaskStatus}
                    loading={isTaskStatusUpdating}
                    disabled={isTaskStatusUpdating || !isEditMode || isCompletedTickTickTask}
                  >
                    {isTaskStatusUpdating
                      ? ''
                      : defaultTask?.completed
                        ? 'Mark as incomplete'
                        : 'Mark as completed'}
                  </LoadingButton>
                </div>
              </Tooltip>

              {isEditMode && (
                <LoadingButton
                  variant="text"
                  className="task-detail-modal__button-group__button delete"
                  onClick={handleDeleteTask}
                  loading={isDeleting}
                  disabled={isDeleting}
                >
                  {isDeleting ? '' : 'Delete'}
                </LoadingButton>
              )}

              <LoadingButton
                loading={isUpdating || isAddingTask}
                disabled={!canSave || isUpdating || isAddingTask}
                onClick={handleTask}
                variant="contained"
                className="task-detail-modal__button-group__button"
              >
                {isEditMode ? 'Save' : 'Create'}
              </LoadingButton>
            </div>
          </div>
        </Modal>
        <AddProjectDialog
          isAdding={isAddingProject}
          visible={projectDialogVisible}
          onAdd={handleAddProject}
          onClose={() => handleProjectDialogVisible(false)}
        />
      </>
    );
  }
);

export default TaskDetailModal;
