import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ExerciseDetailsByDate } from '@demind-inc/core';

import { metricsApi } from '../api';
import { useCallback } from 'react';

interface UseExerciseDetailsParams {
  metricId: string;
  dates: string[];
}

interface UseExerciseDetailsQueryParams {
  queryKey: ['lifestack.exercise.details', UseExerciseDetailsParams];
}

export const useExerciseDetails = ({ metricId, dates }: UseExerciseDetailsParams) => {
  const queryClient = useQueryClient();

  const exerciseQuery = useQuery({
    queryKey: ['lifestack.exercise.details', { metricId, dates }],
    queryFn: ({ queryKey }: UseExerciseDetailsQueryParams) => {
      const [, { metricId, dates }] = queryKey;
      return metricsApi
        .getExerciseDetails(metricId, dates)
        .then(({ data }) => data as ExerciseDetailsByDate[]);
    },
    refetchOnWindowFocus: false,
    staleTime: 15000, // 15s
    enabled: !!metricId && !!dates.length,
  });

  const refetchExerciseDetails = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: ['lifestack.exercise.details'],
    });
  }, [queryClient]);

  return {
    exerciseDetails: exerciseQuery.data,
    refetchExerciseDetails,
    ...exerciseQuery,
  };
};
