import { CSSProperties, FC } from 'react';
import clsx from 'clsx';
import { CircadianPhase } from '@demind-inc/core';

import './ZoneLabelTimeline.scss';
import { circadianPhaseInfo, PeakDipStartEndSet } from '../../../../data-access';
import { HOUR_BLOCK_HEIGHT } from '../../CalendarTimeline/constants';
import dayjs from 'dayjs';
import { ZoneLabelExplaTooltipContent } from './ZoneLabelExplaTooltipContent';
import { CustomTooltip } from '../../../common';
import { useGeneralSettings } from '../../../../hooks/useGeneralSettings';

interface ZoneLabelTimelineProps {
  peaksDipsBoundaries: PeakDipStartEndSet;
  className?: string;
}

const buildZoneStyle = (startTime: string, endTime: string): CSSProperties => {
  const startTimeHour = dayjs(startTime).hour() + dayjs(startTime).minute() / 60;
  const duration = dayjs(endTime).diff(dayjs(startTime), 'minutes') / 60;

  const top = Math.round(startTimeHour * HOUR_BLOCK_HEIGHT);
  const height = Math.round(duration * HOUR_BLOCK_HEIGHT);

  return {
    top: `${top}px`,
    height: `${height - 1.8}px`, // -1.8px to make the height equal to the zone
    width: '100%',
  };
};

const ZoneLabelTimeline: FC<ZoneLabelTimelineProps> = ({ peaksDipsBoundaries, className }) => {
  const {
    generalSettings: { timeFormat },
  } = useGeneralSettings();
  const isTimeFormat24h = timeFormat === '24h';
  const timeFormatForTooltip = isTimeFormat24h ? 'HH:mm' : 'h:mm A';
  return (
    <div className={clsx('zone-label-timeline', className)}>
      {Object.entries(peaksDipsBoundaries).map(([phase, { start, end }]) => {
        const phaseInfo = circadianPhaseInfo[phase as CircadianPhase];

        const hasNoZone = dayjs(end).diff(start, 'minutes') <= 0;
        if (hasNoZone) {
          return null;
        }

        const style = {
          ...buildZoneStyle(start, end),
          backgroundColor: `${phaseInfo?.color}0A`,
          border: `1px solid ${phaseInfo?.color}66`,
        };

        const zoneTime = `${dayjs(start).format(timeFormatForTooltip)} - ${dayjs(end).format(timeFormatForTooltip)}`;

        return (
          <div
            className={clsx('zone-label-timeline__item', {
              'zone-label-timeline__item--24h': isTimeFormat24h,
            })}
            style={style}
            key={phase}
          >
            <CustomTooltip
              title={
                <ZoneLabelExplaTooltipContent
                  title={phaseInfo.title}
                  time={zoneTime}
                  color={phaseInfo.color}
                  explanation={phaseInfo.explanation}
                />
              }
            >
              <div className="zone-label-timeline__item__label" style={{ color: phaseInfo.color }}>
                {phaseInfo?.label}
                {phaseInfo.labelIcon}{' '}
              </div>
            </CustomTooltip>
          </div>
        );
      })}
    </div>
  );
};

export default ZoneLabelTimeline;
