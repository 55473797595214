import { ReactNode, createContext, useCallback, useContext, useEffect, useRef } from 'react';
import {
  ExerciseDetailsByDate,
  MealDetailsByDate,
  SleepDetailsByDate,
  SleepStage,
} from '@demind-inc/core';
import { useRecoilValue } from 'recoil';
import dayjs, { Dayjs } from 'dayjs';

import { useAuthContext } from './AuthProvider';
import { useExerciseDetails, useMealDetails, useSleepDetails } from '../queries';
import { selectedDateAtom } from '../recoil';
import { useSyncExerciseData, useSyncMealData, useSyncSleepData } from '../../hooks';
import { TotalSleepHourAndMinute, translateSecondsToHourAndMinutes } from '../../helpers';
import { isEmpty } from 'lodash';
import { useWearablesContext } from './WearablesProvider';
import { getTargetTerraUserIds, getWeekDatesOf } from '../helpers';

interface IMetricsContext {
  sleepDetails: SleepDetailsByDate[];
  exerciseDetails: ExerciseDetailsByDate[];
  getSleepOnDate: (date: Dayjs, fromCache?: boolean) => GetSleepOnDateRes;
  isFetchingSleep: boolean;
  isFetchingExercise: boolean;
  isFetchingMeal: boolean;
  mealDetails: MealDetailsByDate[];
}
interface GetSleepOnDateRes {
  totalSleepHours: TotalSleepHourAndMinute;
  hasNoSleepData: boolean;
  sleepStage?: SleepStage;
  awakeTime?: string;
  sleepTime?: string;
  sleepSource?: string;
  sleepDurationMin?: number;
  sleepEfficiency?: number;
}

export const MetricsContext = createContext({} as IMetricsContext);
export const useMetricsContext = () => useContext(MetricsContext);

export const MetricsProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuthContext();
  const selectedDate = useRecoilValue(selectedDateAtom);
  const syncedDates = useRef<string[]>([]);
  const { apiDevices } = useWearablesContext();
  const weekDates = getWeekDatesOf(selectedDate);
  const { sleepDetails, isLoading: isFetchingSleep } = useSleepDetails({
    userId: user.userId,
    metricId: user.metricId!,
    dates: weekDates.map((date) => date.format('YYYY-MM-DD')),
  });
  const { exerciseDetails = [], isLoading: isFetchingExercise } = useExerciseDetails({
    metricId: user.metricId!,
    dates: [selectedDate.format('YYYY-MM-DD')],
  });
  const { mealDetails = [], isLoading: isFetchingMeal } = useMealDetails({
    metricId: user.metricId!,
    dates: [selectedDate.format('YYYY-MM-DD')],
  });
  const { syncSleepData } = useSyncSleepData();
  const { syncExerciseData } = useSyncExerciseData();
  const { syncMealData } = useSyncMealData();

  useEffect(() => {
    if (
      !apiDevices.length ||
      syncedDates.current.includes(selectedDate.format('YYYY-MM-DD')) ||
      selectedDate.isAfter(dayjs(), 'day')
    ) {
      return;
    }
    const startDate = selectedDate.subtract(1, 'day').format('YYYY-MM-DD');
    const endDate = selectedDate.add(1, 'day').format('YYYY-MM-DD');

    syncSleepData({
      terraUserIds: getTargetTerraUserIds(apiDevices, 'sleep'),
      startDate,
      endDate,
    });
    syncExerciseData({
      terraUserIds: getTargetTerraUserIds(apiDevices, 'exercise'),
      startDate,
      endDate,
    });
    syncMealData({
      terraUserIds: getTargetTerraUserIds(apiDevices, 'meal'),
      startDate,
      endDate,
    });
    syncedDates.current.push(selectedDate.format('YYYY-MM-DD'));
  }, [selectedDate.toISOString(), apiDevices.length]);

  const getSleepOnDate = useCallback(
    (date: Dayjs) => {
      const sourceData = sleepDetails.find(
        (item) => item.date === date.format('YYYY-MM-DD')
      )?.details;
      const totalSleepHours = translateSecondsToHourAndMinutes((sourceData?.durationMin ?? 0) * 60);
      const hasNoSleepData = isFetchingSleep ? false : !sourceData || isEmpty(sourceData);
      const sleepStage = sourceData?.stages;
      const awakeTime = sourceData?.awakeTime;
      const sleepTime = sourceData?.sleepTime;
      const sleepSource = sourceData?.source;
      const sleepDurationMin = sourceData?.durationMin;
      const sleepEfficiency = sourceData?.efficiency;

      return {
        sleepStage,
        awakeTime,
        sleepTime,
        sleepSource,
        sleepDurationMin,
        totalSleepHours,
        sleepEfficiency,
        hasNoSleepData,
      };
    },
    [sleepDetails, isFetchingSleep]
  );
  return (
    <MetricsContext.Provider
      value={{
        exerciseDetails,
        mealDetails,
        sleepDetails,
        isFetchingSleep,
        isFetchingExercise,
        isFetchingMeal,
        getSleepOnDate,
      }}
    >
      {children}
    </MetricsContext.Provider>
  );
};
