import { FC } from 'react';
import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import './MobileInstallDialog.scss';
import MobileApp from '../../../assets/common/mobile_app.png';
import AppStore from '../../../assets/settings/appstore.png';
import PlayStore from '../../../assets/settings/playstore.png';

interface MobileInstallDialogProps {
  visible: boolean;
  onClose: () => void;
}

const MobileInstallDialog: FC<MobileInstallDialogProps> = ({ visible, onClose }) => {
  return (
    <Dialog
      open={visible}
      onClose={onClose}
      maxWidth={'sm'}
      fullWidth
      className="mobile-install-dialog"
    >
      <DialogTitle>Try Lifestack mobile app!</DialogTitle>
      <DialogContent className="mobile-install-dialog__content">
        Check your energy on the go and access your calendar through the Lifestack mobile app.
        <div className="mobile-install-dialog__content__image">
          <img src={MobileApp} alt="Mobile App" style={{ width: '60%' }} />
        </div>
        <div className="mobile-install-dialog__content__store-links">
          <a
            href="https://apps.apple.com/us/app/lifestack-calendar-and-health/id6473464429"
            target="_blank"
          >
            <img
              src={AppStore}
              alt="App Store"
              draggable="false"
              className="mobile-install-dialog__content__store-links__image"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.demind.lifestack_calendar&hl=en_US&gl=US"
            target="_blank"
          >
            <img
              src={PlayStore}
              alt="Play Store"
              draggable="false"
              className="mobile-install-dialog__content__store-links__image"
            />
          </a>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MobileInstallDialog;
