import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CalendarPreference } from '@demind-inc/core';

import { calendarApi } from '../api';

interface UseUpdateCalendarPrefParams {
  preferenceId: string;
  preferences: Partial<CalendarPreference>;
}

export const useUpdateCalendarPref = () => {
  const queryClient = useQueryClient();

  const updateCalendarPrefMutation = useMutation({
    mutationFn: ({ preferenceId, preferences }: UseUpdateCalendarPrefParams) => {
      return calendarApi.updatCalendarPreference(preferenceId, preferences).then((data) => data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.preference.calendar'] });
    },
  });

  return {
    updateCalendarPref: updateCalendarPrefMutation.mutateAsync,
    ...updateCalendarPrefMutation,
  };
};
