import { FC } from 'react';

import './AboutSettings.scss';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
const AboutSettings: FC = () => {
  return (
    <div className="about-settings">
      <div
        className="about-settings__item"
        onClick={() =>
          window.open(
            'https://demind-inc.notion.site/Customer-Support-2ccca26442da4c40995b3836f0718b22?pvs=4',
            '_blank'
          )
        }
      >
        <p> Learn more</p>
      </div>
      <div
        className="about-settings__item"
        onClick={() => window.open('https://lifestack.canny.io', '_blank')}
      >
        Share feedback
      </div>
      <div
        className="about-settings__item"
        onClick={() => window.open('mailto:contact@demind-inc.com', '_blank')}
      >
        <p> Contact Support</p>
      </div>
      <div
        className="about-settings__item"
        onClick={() => window.open('https://www.reddit.com/r/Lifestack/', '_blank')}
      >
        <p>Community</p>
      </div>
      <div
        className="about-settings__item"
        onClick={() => window.open('https://calendly.com/yukawanao/lifestack', '_blank')}
      >
        Talk to founders
      </div>
      <div
        className="about-settings__item"
        onClick={() =>
          window.open(
            'https://demind-inc.notion.site/Privacy-Policy-Lifestack-867d4c361ca744e0a01be8645e951998?pvs=4',
            '_blank'
          )
        }
      >
        Privacy policy
      </div>
      <div
        className="about-settings__item"
        onClick={() => window.open('https://lifestack.ai', '_blank')}
      >
        About us
      </div>
    </div>
  );
};

export default AboutSettings;
