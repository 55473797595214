import { PeakDipPhase } from '../../../data-access';
import { getCssVariable } from '../../../utils';

export const HOUR_BLOCK_HEIGHT = 72;

const COLOR_RED = getCssVariable('--color-red-base') || '#f34720';
const COLOR_GREEN = getCssVariable('--color-green-base') || '#09c25a';

export const zoneColorForPeaksDipsPhase: Record<PeakDipPhase, string> = {
  wakeup_low: COLOR_RED,
  morning_high: COLOR_GREEN,
  morning_low: COLOR_RED,
  evening_high: COLOR_GREEN,
  evening_low: COLOR_RED,
};

export const CALENDAR_STEP_MIN = 15;
export const DEFAULT_START_END_DATE_DURATION_MIN = 30;
