import React, { FC, useMemo } from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as FlameIcon } from '../../../../../../assets/icons/flame-icon.svg';
import { round } from 'lodash';
import './EnergyFluctuateItemWrapper.scss';
import clsx from 'clsx';
import { getCssVariable } from '../../../../../../utils';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { CustomTooltip, CustomTooltipContent } from '../../../../../common';
import { HelpOutline } from '@mui/icons-material';
import { getEnergyFluctationTooltipContent } from './constants';
import { EventCategorySet } from '@demind-inc/core';

const secondaryColor = getCssVariable('--color-secondary');
const redBaseColor = getCssVariable('--color-red-base');
const blackColor = getCssVariable('--color-black');

interface EnergyFluctuateItemWrapperProps {
  item: {
    title: string;
    fluctuateValue: number;
    eventCategory?: EventCategorySet;
    eventTime: string;
    fluctuateTime?: string;
    reason?: string;
    icon: React.ReactNode;
  };
  onClickExplanation?: () => void;
}

const EnergyFluctuateItemWrapper: FC<EnergyFluctuateItemWrapperProps> = ({ item }) => {
  const fluctuateInfo = useMemo(() => {
    if (item.fluctuateValue >= 0) {
      return { color: secondaryColor, sign: '+', score: Math.abs(round(item.fluctuateValue, 1)) };
    } else {
      return { color: redBaseColor, sign: '-', score: Math.abs(round(item.fluctuateValue, 1)) };
    }
  }, [item.fluctuateValue]);

  return (
    <div className="energy-item">
      <div className="energy-item__label-group">
        <div
          className={clsx(
            'energy-item__icon-wrapper',
            { 'energy-item__icon-wrapper--blue': item.reason === 'sleep' },
            item.fluctuateValue >= 0
              ? 'energy-item__icon-wrapper--green'
              : 'energy-item__icon-wrapper--red'
          )}
        >
          {item.icon}
        </div>
        <div className="energy-item__title-group">
          <div className="energy-item__title-wrapper">
            <Typography variant="body2">{item.title}</Typography>
            {getEnergyFluctationTooltipContent(item.reason, item.eventCategory) && (
              <CustomTooltip
                title={
                  <CustomTooltipContent
                    {...getEnergyFluctationTooltipContent(item.reason, item.eventCategory)}
                  />
                }
              >
                <HelpOutline
                  fontSize="inherit"
                  width={4}
                  className="energy-item__title-group__icon"
                />
              </CustomTooltip>
            )}
          </div>
          <Typography variant="subtitle2">
            {item.eventCategory?.name ? `[${item.eventCategory.name}] ` : ''}
            {item.eventTime}
          </Typography>
        </div>
      </div>
      <div className="energy-item__fluctuation-wrapper">
        <SubdirectoryArrowRightIcon fontSize="small" />
        <div className="energy-item__fluctuation-item-group">
          <FlameIcon className="energy-item__flame-icon" fill={blackColor} stroke={blackColor} />
          <Typography variant="subtitle1" style={{ color: fluctuateInfo.color }}>
            {fluctuateInfo.sign}
            {fluctuateInfo.score}
          </Typography>
          {item.reason !== 'sleep' && item.fluctuateTime && (
            <Typography variant="subtitle1">
              <span className="energy-item__gray-text">in </span>
              {item.fluctuateTime}
            </Typography>
          )}
          {item.reason === 'sleep' && <Typography variant="subtitle1">peak energy</Typography>}
        </div>
      </div>
    </div>
  );
};
export default EnergyFluctuateItemWrapper;
