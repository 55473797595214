import { useMutation, useQueryClient } from '@tanstack/react-query';

import { metricsApi } from '../api';

interface UseDiagnoseChronotypeParams {
  userId: string;
  metricId: string;
}

export const useDiagnoseChronotype = () => {
  const queryClient = useQueryClient();

  const diagnoseChronotypeMutation = useMutation({
    mutationFn: ({ userId, metricId }: UseDiagnoseChronotypeParams) => {
      return metricsApi.diagnoseChronotype(userId, metricId).then(({ data }) => data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['recommendedTasks'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.user'] });
    },
  });

  return {
    diagnoseChronotype: diagnoseChronotypeMutation.mutateAsync,
    ...diagnoseChronotypeMutation,
  };
};
