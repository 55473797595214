import { FC } from 'react';
import { PulseLoader } from 'react-spinners';

import './LoadingOverlay.scss';
import Logo from '../../assets/logo/logo_transparent.png';
import { getCssVariable } from '../../utils';

const primaryColor = getCssVariable('--color-primary');

const LoadingOverlay: FC = () => (
  <div className="loading-overlay">
    <img src={Logo} alt="Lifestack" className="loading-overlay__logo" />
    <PulseLoader color={primaryColor} loading={true} size={12} aria-label="Loading Spinner" />
  </div>
);

export default LoadingOverlay;
