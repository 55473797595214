import { SleepDetailsByDate, SleepStage } from '@demind-inc/core';
import clsx from 'clsx';
import { sum } from 'lodash';
import { FC, useMemo } from 'react';
import { BarChart, Bar, XAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { TotalSleepHourAndMinute } from '../../../../helpers';
import { CHART_HEIGHT, grayBase, grayPurple, sleepSummaryColor } from '../../constants';
import { WeekBarChartItem } from '../../types';

interface SleepBarChartProps {
  dataByDate: SleepDetailsByDate[];
  targetSleepStage: SleepStage;
  targetSleepHours: TotalSleepHourAndMinute;
  hasNoSleepStage: boolean;
  className?: string;
}

const SleepBarChart: FC<SleepBarChartProps> = ({
  dataByDate,
  targetSleepStage,
  targetSleepHours,
  hasNoSleepStage,
  className,
}) => {
  const chartData = useMemo(() => {
    return dataByDate.map(({ details }, index) => {
      const hasSleepStage = sum(Object.values(details.stages ?? {})) > 0;
      const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

      let stageData: WeekBarChartItem;
      if (hasSleepStage) {
        stageData = Object.entries(details.stages ?? {}).reduce((acc, [key, value]) => {
          acc[key] = Math.round((value ?? 0) / 60);
          return acc;
        }, {} as WeekBarChartItem);
      } else {
        stageData = {
          deep: 0,
          light: details.durationMin ?? 0,
          rem: 0,
          awake: 0,
        };
      }

      return {
        name: dayNames[index],
        ...stageData,
      };
    });
  }, [dataByDate, targetSleepStage, targetSleepHours, hasNoSleepStage]);

  return (
    <div className={clsx(className)}>
      <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
        <BarChart
          data={chartData}
          margin={{ top: 5, right: 20, left: 10 }}
          barSize={20}
          style={{
            background: grayPurple,
            borderRadius: 16,
          }}
        >
          <CartesianGrid stroke={grayBase} strokeOpacity={0.1} />
          <XAxis
            dataKey="name"
            scale="point"
            padding={{ left: 30, right: 30 }}
            tickLine={false}
            style={{ fontSize: '12px', color: grayBase }}
            axisLine={false}
          />
          {Object.keys(sleepSummaryColor)?.map((stage) => (
            <Bar key={stage} dataKey={stage} stackId="a" fill={sleepSummaryColor[stage]} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SleepBarChart;
