import { useMutation, useQueryClient } from '@tanstack/react-query';

import { metricsApi } from '../api';

interface UseSyncMealDetailsParams {
  metricId: string;
  terraUserIds: string[];
  startDate: string;
  endDate: string;
}

export function useSyncMealDetails() {
  const queryClient = useQueryClient();

  const syncMealDetailMutation = useMutation({
    mutationFn: ({ metricId, terraUserIds, startDate, endDate }: UseSyncMealDetailsParams) => {
      return metricsApi.syncMeal(metricId, { terraUserIds, startDate, endDate });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.meal.details'] });
    },
  });

  return {
    syncMealDetail: syncMealDetailMutation.mutateAsync,
    ...syncMealDetailMutation,
  };
}
