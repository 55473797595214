import React from 'react';
import { upperFirst } from 'lodash';
import { EnergyFeedbackType } from '@demind-inc/core';
import { energyFeedbackLabelSet } from '../constants';

import './EnergyBarLabel.scss';
import { getCssVariable } from '../../../utils';
import clsx from 'clsx';

interface EnergyBarLabelProps {
  selectedFeedback?: EnergyFeedbackType;
  width?: number | string;
}

const RED_COLOR = getCssVariable('--color-red-base');
const GREEN_COLOR = getCssVariable('--color-green-base');
const YELLOW_COLOR = getCssVariable('--color-yellow-base');

const EnergyBarLabel: React.FC<EnergyBarLabelProps> = ({ selectedFeedback, width = '100%' }) => {
  return (
    <div className="energy-bar-label">
      <div className="energy-bar-label__bar-container">
        <svg width={width} height={11}>
          <defs>
            <linearGradient id="energyFeedback" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={RED_COLOR} />
              <stop offset="0.5" stopColor={YELLOW_COLOR} />
              <stop offset="1" stopColor={GREEN_COLOR} />
            </linearGradient>
          </defs>
          <rect width="100%" height="100%" fill="url(#energyFeedback)" rx={5} ry={5} />
        </svg>
      </div>
      <div className="energy-bar-label__label-group">
        {energyFeedbackLabelSet.map((label) => (
          <span
            key={label}
            className={clsx(
              'energy-bar-label__label-group__item',
              label === selectedFeedback && 'energy-bar-label__label-group__item--selected'
            )}
          >
            {upperFirst(label)}
          </span>
        ))}
      </div>
    </div>
  );
};

export default EnergyBarLabel;
