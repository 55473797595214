import { FC, useState } from 'react';
import { Button, Typography } from '@mui/material';

import './AISchedulerPracticePage.scss';
import { AISchedulerPracticeModal, OnboardingContainer } from '../../../components';
//@ts-ignore
import AISchedulerExplaDemo from '../../../assets/scheduler/ai-scheduler-demo.mp4';
import { useNavigate } from 'react-router-dom';
import {
  useAuthContext,
  useSubscriptionContext,
  useUpdateOnboardingStatus,
} from '../../../data-access';
import { Path } from '../../../routing';
import { useBreakpoints } from '../../../utils';

const AISchedulerPracticePage: FC = () => {
  const [practiceModalVisible, setPracticeModalVisible] = useState(false);

  const navigate = useNavigate();
  const { hasAccessToApp } = useSubscriptionContext();
  const { updateOnboardingStatus } = useUpdateOnboardingStatus();
  const { user } = useAuthContext();
  const { isDesktop } = useBreakpoints();

  const handleFinishPractice = async () => {
    await updateOnboardingStatus({
      userId: user.userId,
      newStatus: { aiSchedulerPractice: 'answered' },
    });
    setPracticeModalVisible(false);
    navigate(hasAccessToApp ? Path.DASHBOARD : Path.PAYWALL); // Redirect to dashboard if user has access to app
  };

  const handleClickNext = async () => {
    if (!isDesktop) {
      // Skip if it's mobile view
      await updateOnboardingStatus({
        userId: user.userId,
        newStatus: { aiSchedulerPractice: 'answered' },
      });
      navigate(hasAccessToApp ? Path.DASHBOARD : Path.PAYWALL);
      return;
    }
    setPracticeModalVisible(true);
  };

  return (
    <div className="ai-scheduler-page">
      <OnboardingContainer
        leftChildren={
          <video
            src={AISchedulerExplaDemo}
            autoPlay
            loop
            controls
            muted
            className="ai-scheduler-page__asset"
          />
        }
        rightChildren={
          <div className="ai-scheduler-page__right-section">
            <div className="ai-scheduler-page__content">
              <Typography variant="h1" className="ai-scheduler-page__content__title">
                AI Scheduler (beta)
              </Typography>
              <Typography variant="h3" className="ai-scheduler-page__content__description">
                Timebox your day in one click with the AI Scheduler—Lifestack will suggest the best
                sequence of events and tasks based on your preferences and energy levels.
              </Typography>
            </div>
            <div className="ai-scheduler-page__btn-group">
              <Button
                variant="contained"
                className="ai-scheduler-page__btn"
                onClick={handleClickNext}
              >
                Next
              </Button>
            </div>
          </div>
        }
      />
      <AISchedulerPracticeModal
        visible={practiceModalVisible}
        onFinish={handleFinishPractice}
        onClose={() => setPracticeModalVisible(false)}
      />
    </div>
  );
};

export default AISchedulerPracticePage;
