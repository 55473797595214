import { useQuery } from '@tanstack/react-query';
import { SchedulerPreference } from '@demind-inc/core';

import { schedulerApi } from '../api';

interface UseSchedulerPreferenceParams {
  preferenceId: string;
}

interface UseSchedulerPreferenceQueryParams {
  queryKey: ['lifestack.preference.scheduler', UseSchedulerPreferenceParams];
}

export const useSchedulerPreference = ({ preferenceId }: UseSchedulerPreferenceParams) => {
  const schedulerPrefQuery = useQuery({
    queryKey: ['lifestack.preference.scheduler', { preferenceId }],
    queryFn: ({ queryKey }: UseSchedulerPreferenceQueryParams) => {
      const [, { preferenceId }] = queryKey;
      return schedulerApi
        .getSchedulerPreference(preferenceId)
        .then(({ data }) => data as SchedulerPreference);
    },
    refetchOnWindowFocus: false,
    staleTime: 15000, // 15s
    enabled: !!preferenceId,
  });

  return {
    schedulerPreferences: schedulerPrefQuery.data ?? {},
    ...schedulerPrefQuery,
  };
};
