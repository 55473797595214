import { FC } from 'react';
import clsx from 'clsx';
import { Skeleton, Switch } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { ModeEdit } from '@mui/icons-material';

import { SchedulerPrefDurationRange, SchedulerPrefTimeRange } from '../../types';
import './DailyPrefEditItem.scss';
import { getTimeRangePickedDateValues } from '../../helpers';
import { DurationField } from '../../../../common';
import { translateMinToHHhMMmString } from '../../../../../data-access';
import { getCssVariable } from '../../../../../utils';
import { useGeneralSettings } from '../../../../../hooks';
import { formatTimeFromHHMM } from '../../../../../helpers';

interface DailyPrefEditItemProps {
  title: string;
  start?: SchedulerPrefTimeRange;
  end?: SchedulerPrefTimeRange;
  duration?: SchedulerPrefDurationRange;
  hasSwitch?: boolean;
  enabled?: boolean;
  isRange?: boolean;
  className?: string;
  isLoading?: boolean;
  color?: string;
  onSwitchChange?: (value: boolean) => void;
  onStartTimeChange?: (start: string) => void;
  onEndTimeChange?: (end: string) => void;
  onDurationChange?: (durationMin: number) => void;
}

const BLACK_COLOR = getCssVariable('--color-black');

const DailyPrefEditItem: FC<DailyPrefEditItemProps> = ({
  title,
  start,
  end,
  duration,
  hasSwitch,
  enabled = true,
  isRange = false,
  className,
  isLoading = false,
  color = BLACK_COLOR,
  onSwitchChange = () => void 0,
  onStartTimeChange = () => void 0,
  onEndTimeChange = () => void 0,
  onDurationChange = () => void 0,
}) => {
  const {
    generalSettings: { timeFormat },
  } = useGeneralSettings();

  return (
    <div className={clsx('daily-pref-edit-item', className)}>
      <div className="daily-pref-edit-item__top-group">
        <div className="daily-pref-edit-item__title">{title}</div>
        {hasSwitch && (
          <Switch
            checked={enabled}
            size="small"
            color="success"
            onChange={(e) => onSwitchChange(e.target.checked)}
            className="daily-pref-edit-item__switch"
          />
        )}
      </div>
      {(!!start || !!end) && (
        <div className="daily-pref-edit-item__bottom-group">
          {!!start && (
            <div className="daily-pref-edit-item__time-item">
              <div className="daily-pref-edit-item__time-item__title">
                {isRange ? 'Earliest Start' : 'Start'}
              </div>
              {isLoading ? (
                <Skeleton animation="wave" variant="text" width={50} height={24} />
              ) : (
                <TimePicker
                  className="daily-pref-edit-item__time-item__value"
                  slotProps={{
                    popper: {
                      className: 'daily-pref-edit-item__time-item__value__popper',
                    },
                  }}
                  ampm={timeFormat === '12h'}
                  label=""
                  format={timeFormat === '12h' ? 'h:mmA' : 'HH:mm'}
                  minTime={getTimeRangePickedDateValues(start).minimumPickedDate}
                  maxTime={getTimeRangePickedDateValues(start).maximumPickedDate}
                  value={getTimeRangePickedDateValues(start).defaultPickedDate}
                  onChange={(val) => onStartTimeChange(val.format('HH:mm'))}
                  slots={{
                    openPickerIcon: ModeEdit,
                  }}
                />
              )}
              <div className="daily-pref-edit-item__time-item__default">
                Default:{' '}
                {isLoading ? (
                  '--'
                ) : (
                  <p className="daily-pref-edit-item__time-item__default--bold">
                    {formatTimeFromHHMM(start.defaultTime, timeFormat)}
                  </p>
                )}
              </div>
            </div>
          )}
          {!!end && (
            <div className="daily-pref-edit-item__time-item">
              <div className="daily-pref-edit-item__time-item__title">
                {isRange ? 'Latest End' : 'End'}
              </div>
              {isLoading ? (
                <Skeleton animation="wave" variant="text" width={50} height={24} />
              ) : (
                <TimePicker
                  className="daily-pref-edit-item__time-item__value"
                  slotProps={{
                    popper: {
                      className: 'daily-pref-edit-item__time-item__value__popper',
                    },
                  }}
                  ampm={timeFormat === '12h'}
                  label=""
                  format={timeFormat === '12h' ? 'h:mmA' : 'HH:mm'}
                  minTime={getTimeRangePickedDateValues(end).minimumPickedDate}
                  maxTime={getTimeRangePickedDateValues(end).maximumPickedDate}
                  value={getTimeRangePickedDateValues(end).defaultPickedDate}
                  onChange={(val) => onEndTimeChange(val.format('HH:mm'))}
                  slots={{
                    openPickerIcon: ModeEdit,
                  }}
                />
              )}
              <div className="daily-pref-edit-item__time-item__default">
                Default:{' '}
                {isLoading ? (
                  '--'
                ) : (
                  <p className="daily-pref-edit-item__time-item__default--bold">
                    {formatTimeFromHHMM(end.defaultTime, timeFormat)}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {!!duration && (
        <div className="daily-pref-edit-item__time-item">
          <div className="daily-pref-edit-item__time-item__title">Duration</div>
          {isLoading ? (
            <Skeleton animation="wave" variant="text" width={50} height={24} />
          ) : (
            <DurationField
              value={{
                hours: Math.floor(duration.durationMin / 60),
                minutes: duration.durationMin % 60,
                maxDurationMin: duration.maximumDurationMin,
              }}
              onChange={(val) => onDurationChange(val.hours * 60 + val.minutes)}
              textStyle={{ color }}
            />
          )}
          <div className="daily-pref-edit-item__time-item__default">
            Default:{' '}
            {isLoading ? (
              '--'
            ) : (
              <p className="daily-pref-edit-item__time-item__default--bold">
                {translateMinToHHhMMmString(duration.defaultMin || 0)}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DailyPrefEditItem;
