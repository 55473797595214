import { CustomTooltipContentProps } from '../../common';

export const circadianTooltipInfo: CustomTooltipContentProps = {
  title: '⚡️ Energy',
  description:
    'Energy scores represent your predicted energy levels from 0 to 100. The score is calculated using circadian rhythms and your sleep data. The higher the wave, the higher your score — indicating the best times for you to perform challenging tasks. Your self-reported data, along with the category of each calendar event, further fine - tune your energy wave.',
  learnMoreLink:
    'https://demind-inc.notion.site/How-We-Calculate-Energy-5764b5cef9824a8e8523868216d49228?pvs=4',
  referenceLinkList: [
    {
      title: 'Overview of Circadian Rhythms',
      url: 'https://www.uptodate.com/contents/overview-of-circadian-rhythm-sleep-wake-disorders',
    },
    {
      title: 'Physiology, Circadian Rhythm',
      url: 'https://www.ncbi.nlm.nih.gov/books/NBK519507/',
    },
  ],
};
