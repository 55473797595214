import { emaExplanationInfo } from '../../../../../CalendarView';
import { CustomTooltipContentProps } from '../../../../../common';
import { DefaultEventCategoryId, EventCategorySet } from '@demind-inc/core';

export const getEnergyFluctationTooltipContent = (
  reason: string,
  category?: EventCategorySet
): CustomTooltipContentProps | undefined => {
  if (reason === 'sleep') {
    return {
      title: '⚡️ Sleep-based energy adjustment',
      description:
        'We start with your sleep. Our algorithm uses your sleep data, including the time you wake up and your sleep quality as the basis to calculate an energy wave — picture a gentle rollercoaster of alertness throughout the day. Also, Lifestack determines whether you are a morning, hybrid, or night chronotype using your past sleep data, and adjusts your energy peaks accordingly to personalize yourrecommended tasks. For example, if you’re a morning chronotype, your morningenergy peak will be significantly higher than your evening peak. This allows you to focus on high-energy tasks earlier in the day and reserve lower-energy activities for the evening when you’re not as energized.',
      referenceLinkList: [
        {
          title:
            'Boredom effects on sleepiness/alertness in the early afternoon vs. early evening and interactions with warm ambient temperature',
          url: 'https://pubmed.ncbi.nlm.nih.gov/7921744/',
        },
      ],
    };
  } else if (reason === 'phaseEma') {
    return emaExplanationInfo;
  } else {
    if (category) {
      const { title, description } =
        defaultEventCategoryExplaSet[category.id as DefaultEventCategoryId];
      return {
        title: `⚡️ ${title || 'Event'}-based energy adjustment`,
        description:
          description +
          '\n' +
          '[How we adjust your energy with event categories]  Different categories of events will affect your energy in different degrees. Categorize each event so we can provide a more accurate prediction of your energy levels. An example is deep work might reduce your energy more than light tasks. We’ll also take into account how you felt (by answering “How energized did you feel after this event?”) — click on each event to see the 5 point scale!',
        referenceLinkList: [
          {
            title: 'Mood Prediction Based on Calendar Events Using Multitask Learning',
            url: 'https://ieeexplore.ieee.org/document/9839466',
          },
        ],
      };
    }
    return;
  }
};

export const defaultEventCategoryExplaSet: Record<
  DefaultEventCategoryId,
  { title: string; description: string }
> = {
  default_exercise: {
    title: 'Exercise',
    description: 'Exercise takes energy but will also give you energy in the long run!',
  },
  default_break: {
    title: 'Break',
    description:
      'Breaks are essential to balance your energy — whether it be physical or mental. Schedule breaks to have small boosts in your energy.',
  },
  default_meal: {
    title: 'Meal',
    description:
      'Rejuvenate with a meal or a snack! While you might get hit with an immediate food coma, you will be more energized for the remainder of the day.',
  },
  default_deepWork: {
    title: 'Deep-Work',
    description:
      'Deep work is exhausting but can also fuel you to do more throughout the day! Answer how energized you felt after your deep work to get a better prediction of your energy!',
  },
  default_lightTask: {
    title: 'Light-Task',
    description:
      'Light tasks such as administrative tasks will not have a huge effect on your energy.',
  },
  default_mtg: {
    title: 'Meeting',
    description:
      'Meetings can be exhausting or exhilarating… Your energy will change more based on how you felt than anything we can predict from just the category “meeting”',
  },
  default_other: {
    title: '',
    description:
      'We will adjust your energy for a shorter period of time if you answer how energized you are. Categorize each event so we can provide a more accurate prediction of your energy levels.',
  },
  default_social: {
    title: 'Social',
    description:
      'Social events can be energizing for some and draining for others. Tell us how you felt to get a better prediction of your energy.',
  },
};
