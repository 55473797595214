import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { Dayjs } from 'dayjs';
import { dataTrendPeriodAtom, getWeekDatesOf, useAuthContext, useEventDailyTrend, useEventWeeklyTrend } from '../data-access';
 
 

interface UseEventTrendParams {
  date: Dayjs;
}

export const useEventTrend = ({ date }: UseEventTrendParams) => {
  const dataTrendPeriod = useRecoilValue(dataTrendPeriodAtom);
  const { user } = useAuthContext();

  const { eventTrend: eventDailyTrend, isLoading: isDailyLoading } = useEventDailyTrend({
    dataTrendId: user.dataTrendId!,
    date: date.format('YYYY-MM-DD'),
    enabled: dataTrendPeriod === 'daily',
  });

  const weekEdge = useMemo(() => {
    const week = getWeekDatesOf(date);
    return {
      startDate: week[0],
      endDate: week[week.length - 1],
    };
  }, [date]);

  const { eventTrend: eventWeeklyTrend, isLoading: isWeeklyLoading } = useEventWeeklyTrend({
    dataTrendId: user.dataTrendId!,
    startDate: weekEdge.startDate.format('YYYY-MM-DD'),
    endDate: weekEdge.endDate.format('YYYY-MM-DD'),
    enabled: dataTrendPeriod === 'weekly',
  });

  const isLoading = dataTrendPeriod === 'daily' ? isDailyLoading : isWeeklyLoading;

  return {
    dataTrendPeriod,
    isLoading,
    eventDailyTrend,
    eventWeeklyTrend,
  };
};
