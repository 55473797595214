import { FC } from 'react';
import clsx from 'clsx';
import { Typography } from '@mui/material';
import { RBCEvent } from '../types';
import { getCssVariable } from '../../../../utils';
import './EnergyEventBlock.scss';

interface EnergyEventBlockProps {
  event: Partial<RBCEvent>;
  className?: string;
}

const primaryColor = getCssVariable('--color-primary');

const EnergyEventBlock: FC<EnergyEventBlockProps> = ({ event, className }) => {
  const contentTitleColor = event.color || primaryColor;

  return (
    <div className={clsx('energy-event-block', className)}>
      <div className="energy-event-block__content">
        <Typography
          variant="subtitle2"
          noWrap
          className="energy-event-block__content__title"
          style={{
            color: contentTitleColor,
          }}
        >
          {event.title}
        </Typography>
      </div>
    </div>
  );
};

export default EnergyEventBlock;
