import { useMutation, useQueryClient } from '@tanstack/react-query';

import { metricsApi } from '../api';

interface UseHideExerciseParams {
  metricId: string;
  date: string;
  activityId: string;
}

export function useHideExercise() {
  const queryClient = useQueryClient();

  const hideExerciseMutation = useMutation({
    mutationFn: ({ metricId, date, activityId }: UseHideExerciseParams) => {
      return metricsApi.hideExercise(metricId, date, activityId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.exercise.details'] });
    },
  });

  return {
    hideExericse: hideExerciseMutation.mutateAsync,
    ...hideExerciseMutation,
  };
}
