import { FC } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import './CommonTaskTable.scss';
import { TaskTableRow } from '../types';

interface CommonTaskTableProps {
  rows: TaskTableRow[];
}

const CommonTaskTable: FC<CommonTaskTableProps> = ({ rows }) => {
  return (
    <div className="task-table">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <Typography variant="subtitle2" className="task-table__header">
                  Completion
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" className="task-table__header">
                  Completed
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" className="task-table__header">
                  Planned
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.label}</TableCell>
                <TableCell>
                  <Typography variant="body1" className="task-table__row-item">
                    {row.completion}
                    <Typography component={'span'} variant="subtitle2">
                      %
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" className="task-table__row-item">
                    {row.completedTaskNum}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" className="task-table__row-item">
                    {row.plannedTaskNum}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CommonTaskTable;
