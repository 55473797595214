import { CalendarType, SupportedTerraProvidersType, SupportedTerraSDKType } from '@demind-inc/core';
import { FC, SVGProps } from 'react';

import { ReactComponent as HealthIcon } from '../../assets/settings/wearable/health-icon.svg';
import { ReactComponent as GarminIcon } from '../../assets/settings/wearable/garmin-icon.svg';
import { ReactComponent as WhoopIcon } from '../../assets/settings/wearable/whoop-icon.svg';
import { ReactComponent as FitbitIcon } from '../../assets/settings/wearable/fitbit-icon.svg';
import { ReactComponent as UltrahumanIcon } from '../../assets/settings/wearable/ultrahuman.svg';
import { ReactComponent as GoogleFitIcon } from '../../assets/settings/wearable/google-fit.svg';
import { ReactComponent as SamsungHealthIcon } from '../../assets/settings/wearable/samsung-icon.svg';
import { ReactComponent as OuraIcon } from '../../assets/settings/wearable/oura-icon.svg';
import { ReactComponent as EightSleepIcon } from '../../assets/settings/wearable/eight-sleep.svg';
import { ReactComponent as MyFitnessPalIcon } from '../../assets/settings/wearable/myfitnesspal.svg';

import {
  AccountSettings,
  CalendarsSettings,
  WearablesSettings,
  TodoIntgSettings,
  SubscriptionSettings,
  NotificationSettings,
  MobileApp,
  InstallExtension,
  AboutSettings,
  TimeFormatToggle,
  EnergyPrefSettings,
  SVG_ICONS,
  AISchedulerSettings,
} from './SettingsModal';

export interface SettingsSectionItemComponent {
  Component: React.ComponentType;
  key: string;
}

export interface SettingsSectionItem {
  title: string;
  components: SettingsSectionItemComponent[];
}

export interface WearbaleItem {
  id: SupportedTerraProvidersType | SupportedTerraSDKType;
  name: string;
  Icon: FC<SVGProps<SVGSVGElement>>;
}

export interface CalendarOptionsType {
  calendar: string;
  id: CalendarType;
  icon: React.ComponentType;
}

export const SIDEBAR_ICONS = {
  mobile: require('../../assets/icons/mobile_icon.png'),
  chrome: require('../../assets/icons/chrome_icon.png'),
  close: require('../../assets/icons/close_icon.png'),
  todoist: require('../../assets/todoIntegrations/todoist_icon.png'),
  trello: require('../../assets/todoIntegrations/trello_icon.png'),
  ticktick: require('../../assets/todoIntegrations/ticktick_icon.jpg'),
};

export const WEARABLES: WearbaleItem[] = [
  { id: 'APPLE', name: 'Health', Icon: HealthIcon },
  { id: 'GOOGLE_FIT', name: 'Google Fit', Icon: GoogleFitIcon },
  { id: 'SAMSUNG_HEALTH', name: 'Samsung Health', Icon: SamsungHealthIcon },
  { id: 'GARMIN', name: 'Garmin', Icon: GarminIcon },
  { id: 'WHOOP', name: 'Whoop', Icon: WhoopIcon },
  { id: 'FITBIT', name: 'Fitbit', Icon: FitbitIcon },
  { id: 'OURA', name: 'Oura', Icon: OuraIcon },
  { id: 'EIGHTSLEEP', name: 'Eight Sleep', Icon: EightSleepIcon },
  { id: 'ULTRAHUMAN', name: 'Ultrahuman', Icon: UltrahumanIcon },
  { id: 'MYFITNESSPAL', name: 'MyFitnessPal', Icon: MyFitnessPalIcon },
];

export const MobileSdkOnlyWearbales: SupportedTerraSDKType[] = [
  'APPLE',
  'GOOGLE_FIT',
  'SAMSUNG_HEALTH',
];

export const settingsSections: SettingsSectionItem[] = [
  {
    title: 'Account',
    components: [
      { Component: AccountSettings, key: 'profile' },
      { Component: CalendarsSettings, key: 'calendars' },
      { Component: WearablesSettings, key: 'wearables' },
      { Component: TodoIntgSettings, key: 'todos' },
      { Component: EnergyPrefSettings, key: 'energyPreferences' },
      { Component: SubscriptionSettings, key: 'subscription' },
    ],
  },
  {
    title: 'General',
    components: [
      { Component: TimeFormatToggle, key: 'timeFormat' },
      { Component: NotificationSettings, key: 'notifications' },
    ],
  },
  { title: 'Feature', components: [{ Component: AISchedulerSettings, key: 'aiScheduler' }] },
  {
    title: 'Product Hub',
    components: [
      { Component: MobileApp, key: 'mobileApp' },
      { Component: InstallExtension, key: 'chromeExtension' },
    ],
  },
  {
    title: `Lifestack ${process.env.REACT_APP_VERSION}`,
    components: [{ Component: AboutSettings, key: 'about' }],
  },
];

export const calendars: CalendarOptionsType[] = [
  { calendar: 'Google', id: 'google', icon: SVG_ICONS.google },
  { calendar: 'Outlook', id: 'outlook', icon: SVG_ICONS.outlook },
];
