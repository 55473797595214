import { FC, useState } from 'react';
import { Divider, Popover } from '@mui/material';
import { labelColors, TaskLabel } from '@demind-inc/core';
import { getCssVariable } from '../../../../../utils';

import './ColorPickerDropdown.scss';
import { CheckOutlined } from '@mui/icons-material';
import { upperFirst } from 'lodash';

interface ColorPickerDropdownProps {
  label: TaskLabel;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onColorChange: (color: string) => void;
}

const ColorPickerDropdown: FC<ColorPickerDropdownProps> = ({
  label,
  anchorEl,
  onClose,
  onColorChange,
}) => {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      key="color-picker-dropdown"
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      slotProps={{
        paper: {
          className: 'color-picker-dropdown__menu',
        },
      }}
      className="color-picker-dropdown"
    >
      <div className="color-picker-dropdown__content">
        <div className="color-picker-dropdown__label">{label.name}</div>
        <Divider className="color-picker-dropdown__divider" />
        <div className="color-picker-dropdown__colors">
          <div className="color-picker-dropdown__colors__label">Colors</div>
          <div className="color-picker-dropdown__colors__list">
            {Object.entries(labelColors).map(([name, color]) => (
              <div
                key={name}
                className="color-picker-dropdown__colors__list__item"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onColorChange(color);
                  onClose();
                }}
              >
                <div className="color-picker-dropdown__colors__list__item__left">
                  <div
                    key={color}
                    className="color-picker-dropdown__colors__list__item__color"
                    style={{ backgroundColor: color }}
                  />
                  <div className="color-picker-dropdown__colors__list__item__name">
                    {upperFirst(name)}
                  </div>
                </div>
                {color === label.color && (
                  <CheckOutlined className="color-picker-dropdown__colors__list__item__check" />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default ColorPickerDropdown;
