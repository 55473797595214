import React, { useMemo } from 'react';

import { SchedulerPrefType, translateMinToHHhMMmString } from '../../../../data-access';
import { schedulerPrefTitleSet, PrefItemState } from '../../../Scheduler';
import './AISchedulerPracticePrefItem.scss';

interface AISchedulerPracticePrefItemProps {
  items: PrefItemState;
}

interface TransformedPrefItem {
  key: SchedulerPrefType;
  title: string;
  durationString: string;
  timeString?: string;
}

const AISchedulerPracticePrefItem: React.FC<AISchedulerPracticePrefItemProps> = ({ items }) => {
  const transformedItems: Array<TransformedPrefItem> = useMemo(
    () =>
      Object.entries(items)
        .filter(([key]) => key !== 'workTime')
        .map(([key, value]) => {
          let durationString = '';
          let timeString = '';

          if ('duration' in value) {
            durationString = translateMinToHHhMMmString(value.duration.durationMin);
          }
          if ('start' in value && 'end' in value) {
            timeString = `(Between ${value.start.time} - ${value.end.time})`;
          }
          return {
            key,
            title: schedulerPrefTitleSet[key as SchedulerPrefType],
            durationString,
            timeString,
          } as TransformedPrefItem;
        }),
    [items]
  );

  return (
    <div className="ai-scheduler-practice-pref">
      <div className="ai-scheduler-practice-pref__header">
        Default preferences{' '}
        <span className="ai-scheduler-practice-pref__description">(You can change it later)</span>
      </div>
      {transformedItems.map((item) => (
        <div className="ai-scheduler-practice-pref__item" key={item.key}>
          <div className="ai-scheduler-practice-pref__item-title">{item.title}</div>
          <div className="ai-scheduler-practice-pref__item-duration">
            {item.durationString}{' '}
            <span className="ai-scheduler-practice-pref__item-time">{item.timeString}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AISchedulerPracticePrefItem;
