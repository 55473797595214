import { ACTIONS } from './constants';
import './CoreActions.scss';
import { CoreActionModal } from './CoreActionModal';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  selectedCoreActionModalAtom,
  selectedDateAtom,
  CoreActonModalItem,
  CoreActionOption,
} from '../../../data-access';
import { Close } from '@mui/icons-material';
import { useAIScheduler, useEnergyBoost } from '../../../hooks';
import { useOvernightSummary } from '../../../hooks';
import { FC, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { AISchedulerFeedbackDialog } from '../../Scheduler';
import clsx from 'clsx';

const betaCoreActions: CoreActionOption[] = ['ai_scheduler'];

const CoreActions: FC = () => {
  const [_, setSelectedModal] = useRecoilState(selectedCoreActionModalAtom);
  const { handleClickEnergyBoostBtn, scheduledEnergyboostEvents } = useEnergyBoost();
  const {
    aiScheduledActionsEvents,
    aiFeedbackDialogVisible,
    handleDiscard,
    handleFeedbackDialogClose,
  } = useAIScheduler();
  const selectedDate = useRecoilValue(selectedDateAtom);
  const { hasNoSleepData = false, isLoading } = useOvernightSummary({ date: selectedDate });

  const availableActions = useMemo(() => {
    let actions = ACTIONS;
    if (hasNoSleepData || isLoading) {
      actions = actions.filter((action) => !['daily_analysis', 'edit_sleep'].includes(action.id));
    } else {
      actions = actions.filter((action) => action.id !== 'add_sleep');
    }
    if (selectedDate.isAfter(dayjs())) {
      actions = actions.filter((action) => !['edit_sleep', 'add_sleep'].includes(action.id));
    }
    return actions;
  }, [hasNoSleepData, selectedDate, isLoading]);

  const renderDiscardBtn = useCallback(
    (action: CoreActonModalItem) => {
      if (scheduledEnergyboostEvents?.length && action.id === 'energy_boost') {
        return (
          <div className="core-actions__energy-boost-discard" onClick={handleClickEnergyBoostBtn}>
            <Close fontSize="small" /> Discard
          </div>
        );
      }
      if (aiScheduledActionsEvents?.length && action.id === 'ai_scheduler') {
        return (
          <>
            <div className="core-actions__energy-boost-discard" onClick={handleDiscard}>
              <Close fontSize="small" /> Discard
            </div>
            <AISchedulerFeedbackDialog
              visible={aiFeedbackDialogVisible}
              onClose={handleFeedbackDialogClose}
            />
          </>
        );
      }
      return null;
    },
    [
      scheduledEnergyboostEvents?.length,
      aiFeedbackDialogVisible,
      handleDiscard,
      aiScheduledActionsEvents?.length,
    ]
  );

  return (
    <div className="core-actions">
      <CoreActionModal />
      {availableActions.map((action) => (
        <div key={action.title} className="core-actions__action-container">
          {renderDiscardBtn(action)}
          <div className="core-actions__action" onClick={() => setSelectedModal(action.id)}>
            <div className="core-actions__action__top-group">
              {betaCoreActions.includes(action.id) && (
                <div className="core-actions__action__beta">Beta</div>
              )}
              <p
                className={clsx(
                  'core-actions__action__title',
                  action.id === 'ai_scheduler' && 'core-actions__action--text-colorful'
                )}
              >
                {action.title}
              </p>
            </div>
            <p
              className={clsx(
                'core-actions__action__description',
                action.id === 'ai_scheduler' && 'core-actions__action--text-colorful'
              )}
            >
              {action.description}
            </p>
          </div>
        </div>
      ))}
      {/* {aiSchedulerFeedbackDialog} */}
    </div>
  );
};

export default CoreActions;
