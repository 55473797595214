import { FC } from 'react';
import { clsx } from 'clsx';
import { Menu, MenuItem, Typography } from '@mui/material';

import './ProjectFilter.scss';
import { ProjectFilterItem, useDropdownState } from '../../../hooks';
import { AddProjectBtn } from '../AddProjectBtn';
import { TODO_INTEGRATION_ICONS } from '../../TodoIntegration';

interface ProjectFilterProps {
  filterOptions: ProjectFilterItem[];
  selectedFilters: ProjectFilterItem[];
  onSelectFilter: (projectId: string) => void;
  onProjectAdd: () => void;
  className?: string;
}

const ProjectFilter: FC<ProjectFilterProps> = ({
  filterOptions,
  selectedFilters,
  onSelectFilter,
  onProjectAdd,
  className,
}) => {
  const { anchorEl, handleOpenDropdown, handleCloseDropdown } = useDropdownState();
  const selectedProjectIds = selectedFilters.map((p) => p.todoTaskId);
  const isProjectSelected = (projectId: string) => selectedProjectIds.includes(projectId);

  return (
    <>
      <div
        className={clsx('project-filter', className, anchorEl && 'project-filter__open')}
        onClick={handleOpenDropdown}
      >
        {selectedFilters.length > 0 && (
          <>
            {selectedFilters[0].appFrom === 'all' ? (
              <span>#</span>
            ) : (
              <img
                src={TODO_INTEGRATION_ICONS[selectedFilters[0].appFrom]}
                alt={selectedFilters[0].appFrom}
                className="project-filter__dropdown__item__icon"
              />
            )}
            <div className="project-filter__selected-title">
              {selectedFilters[0].name}
              <Typography variant="subtitle2" component={'span'}>
                {selectedFilters.length > 1 && ` +${selectedFilters.length - 1}`}
              </Typography>
            </div>
          </>
        )}
      </div>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseDropdown}
        className="project-filter__dropdown"
      >
        {filterOptions.map((project) => (
          <MenuItem
            key={project.todoTaskId}
            onClick={() => onSelectFilter(project.todoTaskId)}
            className={clsx('project-filter__dropdown__item', {
              'project-filter__dropdown__item--selected': isProjectSelected(project.todoTaskId),
            })}
          >
            {project.appFrom === 'all' ? (
              <span
                className={clsx('project-filter__dropdown__item--all-label', {
                  'project-filter__dropdown__item--all-label--selected': isProjectSelected('all'),
                })}
              >
                #
              </span>
            ) : (
              <img
                src={TODO_INTEGRATION_ICONS[project.appFrom]}
                alt={project.appFrom}
                className="project-filter__dropdown__item__icon"
              />
            )}
            <Typography
              variant="body1"
              className={clsx('project-filter__dropdown__item__text', {
                'project-filter__dropdown__item__text--selected': isProjectSelected(
                  project.todoTaskId
                ),
              })}
            >
              {project.name}
            </Typography>
          </MenuItem>
        ))}
        <AddProjectBtn onClick={onProjectAdd} className="project-filter__dropdown__add-project" />
      </Menu>
    </>
  );
};

export default ProjectFilter;
