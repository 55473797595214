import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TodoIntegrationType } from '@demind-inc/core';

import './ConnectTodoPage.scss';
import { ConnectTodosList, OnboardingContainer } from '../../../components';
import { Path } from '../../../routing/path';
import { useStartTodoAuth } from '../../../hooks';
import {
  useAuthContext,
  useSubscriptionContext,
  useUpdateOnboardingStatus,
} from '../../../data-access';
import ConnectTodoAsset from '../../../assets/onboarding/connectTodo.gif';
import { DragAndDropPractice } from '../../../components/Onboarding/DragAndDropPractice';
import { useBreakpoints } from '../../../utils';

const ConnectTodoPage: React.FC = () => {
  const [showPractice, setShowPractice] = useState(false);
  const navigate = useNavigate();
  const { connectedTodos, generatingTodoAuth, handleOpenTodoAuth } = useStartTodoAuth({
    redirectTo: Path.ONBOARDING.CONNECT_TODO,
  });
  const { user } = useAuthContext();
  const { updateOnboardingStatus } = useUpdateOnboardingStatus();
  const { isDesktop } = useBreakpoints();
  const { hasAccessToApp } = useSubscriptionContext();

  const handleFinishPractice = async () => {
    await updateOnboardingStatus({ userId: user.userId, newStatus: { connectTodo: 'answered' } });
    setShowPractice(false);
    navigate(hasAccessToApp ? Path.DASHBOARD : Path.ONBOARDING.AI_SCHEDULER_PRACTICE);
  };

  const handleConnectTodo = (todoType: TodoIntegrationType) => {
    if (generatingTodoAuth) {
      return;
    }

    handleOpenTodoAuth(todoType);
  };

  const handleClickContinue = async () => {
    if (isDesktop) {
      setShowPractice(true);
      return;
    }
    await handleFinishPractice();
  };

  return (
    <div className="connect-todo-page">
      {showPractice && isDesktop && <DragAndDropPractice onFinishPractice={handleFinishPractice} />}
      <OnboardingContainer
        leftChildren={
          <img
            src={ConnectTodoAsset}
            alt="lifestack connect todo"
            className="connect-todo-page__asset"
          />
        }
        rightChildren={
          <div className="connect-todo-page__right-section">
            <div className="connect-todo-page__content">
              <Typography variant="h1" className="connect-todo-page__content__title">
                Connect Todos
              </Typography>
              <ConnectTodosList connectedTodos={connectedTodos} onConnectTodo={handleConnectTodo} />
            </div>
            <Button
              variant="contained"
              className={'connect-todo-page__btn'}
              onClick={handleClickContinue}
            >
              Continue
            </Button>
          </div>
        }
        additionalChildren={
          <p className="connect-todo-page__additional-children">
            *You can delete your data anytime later
          </p>
        }
      />
    </div>
  );
};

export default ConnectTodoPage;
