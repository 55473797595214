import { useRecoilValue } from 'recoil';
import { useCallback, useMemo } from 'react';
import { TaskDataTrendWithData } from '@demind-inc/core';
import { Dayjs } from 'dayjs';
import {
  dataTrendPeriodAtom,
  getWeekDatesOf,
  useAuthContext,
  useTaskDailyTrend,
  useTaskWeeklyTrend,
} from '../data-access';
import { TaskCompletionItem, TaskCompletionTableItem } from './types';
import { transformTaskLabelToChartSetForTrend } from '../helpers';

interface UseTaskTrendParams {
  date: Dayjs;
}

export const useTaskTrend = ({ date }: UseTaskTrendParams) => {
  const dataTrendPeriod = useRecoilValue(dataTrendPeriodAtom);
  const { user } = useAuthContext();

  const { taskTrend: taskDailyTrend, isLoading: isDailyLoading } = useTaskDailyTrend({
    dataTrendId: user.dataTrendId!,
    date: date.format('YYYY-MM-DD'),
    enabled: dataTrendPeriod === 'daily',
  });

  const weekEdge = useMemo(() => {
    const week = getWeekDatesOf(date);
    return {
      startDate: week[0],
      endDate: week[week.length - 1],
    };
  }, [date]);

  const { taskTrend: taskWeeklyTrend, isLoading: isWeeklyLoading } = useTaskWeeklyTrend({
    dataTrendId: user.dataTrendId!,
    startDate: weekEdge.startDate.format('YYYY-MM-DD'),
    endDate: weekEdge.endDate.format('YYYY-MM-DD'),
    enabled: dataTrendPeriod === 'weekly',
  });

  const isLoading = dataTrendPeriod === 'daily' ? isDailyLoading : isWeeklyLoading;

  const taskCompletionLabelItemSets = useMemo(() => {
    if (dataTrendPeriod === 'weekly') {
      return [];
    }
    return (taskDailyTrend?.labels ?? []).map(
      (labelItem) =>
        ({
          title: labelItem.labelName,
          completedTaskNum: labelItem.completedTasks?.length ?? 0,
          plannedTaskNum: labelItem.plannedTasks?.length ?? 0,
          completion:
            labelItem.taskCompletion && labelItem.taskCompletion >= 0
              ? Math.round(labelItem.taskCompletion)
              : 0,
          plannedTask: labelItem.plannedTasks ?? [],
        }) as TaskCompletionTableItem
    );
  }, [taskDailyTrend, dataTrendPeriod]);

  const taskWeeklyLabelSet = useMemo(() => {
    if (dataTrendPeriod === 'daily') {
      return [];
    }
    return ((taskWeeklyTrend?.data ?? []) as TaskDataTrendWithData[]).map((item) => {
      return {
        date: item.date,
        items: transformTaskLabelToChartSetForTrend(item),
      };
    });
  }, [taskWeeklyTrend, dataTrendPeriod]);

  const findLabelColorByName = useCallback(
    (name: string) => {
      return taskWeeklyLabelSet
        .map(({ items }) => items)
        .flat()
        .find((obj) => !!obj[name])?.[name]?.color;
    },
    [taskWeeklyLabelSet]
  );

  const taskCompletionItemSets = useMemo(() => {
    if (dataTrendPeriod === 'daily') {
      return [
        {
          date: date.format('YYYY-MM-DD'),
          completedTaskNum: taskDailyTrend?.completedTasks?.length ?? 0,
          plannedTaskNum: taskDailyTrend?.plannedTasks?.length ?? 0,
          completion:
            taskDailyTrend?.taskCompletion && taskDailyTrend?.taskCompletion >= 0
              ? Math.round(taskDailyTrend.taskCompletion)
              : 0,
        },
      ] as TaskCompletionItem[];
    } else {
      return ((taskWeeklyTrend?.data ?? []) as TaskDataTrendWithData[]).map(
        ({ completedTasks, plannedTasks, taskCompletion, date }) => ({
          date,
          completedTaskNum: completedTasks?.length ?? 0,
          plannedTaskNum: plannedTasks?.length ?? 0,
          completion: !!taskCompletion && taskCompletion >= 0 ? Math.round(taskCompletion) : 0,
        })
      );
    }
  }, [taskDailyTrend, taskWeeklyTrend, dataTrendPeriod]);

  return {
    dataTrendPeriod,
    taskDailyTrend,
    taskWeeklyTrend,
    isLoading,
    taskCompletionItemSets,
    taskCompletionLabelItemSets,
    taskWeeklyLabelSet,
    findLabelColorByName,
  };
};
