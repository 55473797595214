import { useAuthContext, useCalendarContext, useUpdateCalendarPref } from '../../../../data-access';
import { debounce, first, unionBy } from 'lodash';
import { Button, FormControlLabel, Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import './CalendarsSettings.scss';
import { getCssVariable, useFeatureFlag } from '../../../../utils';
import { Path } from '../../../../routing';
import { CalendarAccountDropdown } from '../../../CalendarView';
import { useCallback, useEffect, useState } from 'react';
import { CalendarEventShowAs, CalendarPreference } from '@demind-inc/core';
import { ToggleButtons } from '../../../common';
import clsx from 'clsx';

type UnSyncToCalendarType = CalendarPreference['unSyncToCalendar'];

const primaryColor = getCssVariable('--color-primary');

const transparencyOptions = [
  { value: 'available' as const, label: 'Available' },
  { value: 'busy' as const, label: 'Busy' },
];

const CalendarsSettings = () => {
  const [defaultCalendarId, setDefaultCalendarId] = useState('');
  const [aiEventTransparency, setAiEventTransparency] = useState<CalendarEventShowAs>('available');

  const [unSyncToCalendar, setUnSyncToCalendar] = useState<UnSyncToCalendarType>({
    aiScheduler: false,
    dragAndDrop: false,
  });

  const { calendarsMeta, mainCalendar, calendarAccountsToCreateEvents, calendarPreferences } =
    useCalendarContext();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const {
    featureFlags: { FF_calendly = false },
  } = useFeatureFlag();
  const { updateCalendarPref } = useUpdateCalendarPref();

  useEffect(() => {
    if (!mainCalendar) {
      return;
    }
    setDefaultCalendarId(mainCalendar.calendarId || '');
    setAiEventTransparency(calendarPreferences.transparency?.aiScheduler || 'available');
    setUnSyncToCalendar(
      calendarPreferences.unSyncToCalendar || { aiScheduler: false, dragAndDrop: false }
    );
  }, [mainCalendar, calendarPreferences]);

  const debouncedUpdateCalendarSettings = useCallback(
    debounce(
      (newData: Partial<CalendarPreference>) =>
        updateCalendarPref({
          preferenceId: user.preferenceId!,
          preferences: { ...newData },
        }),
      1000
    ),
    [user.preferenceId]
  );

  const handleDefaultCalendarChange = (calendarId: string) => {
    setDefaultCalendarId(calendarId);
    debouncedUpdateCalendarSettings({
      defaultCalendarId: calendarId,
    });
  };

  const handleAiEventTransparencyChange = (newValue: CalendarEventShowAs) => {
    setAiEventTransparency(newValue);
    debouncedUpdateCalendarSettings({
      transparency: {
        aiScheduler: newValue,
      },
    });
  };

  const handleUnSyncToCalendarChange = (newValue: UnSyncToCalendarType) => {
    setUnSyncToCalendar(newValue);
    debouncedUpdateCalendarSettings({
      unSyncToCalendar: newValue,
    });
  };

  const rootEmailCalendars = unionBy(calendarsMeta, 'rootEmail');

  return (
    <div className="calendars-settings">
      <p className="calendars-settings__title">Calendars</p>
      <div className="calendars-settings__content">
        <div className="calendars-settings__grid">
          {rootEmailCalendars?.map((calendar) => (
            <div key={calendar.calendarId} className="calendars-settings__calendar-item">
              <div
                className="calendars-settings__calendar-icon"
                style={{ background: calendar.color || primaryColor }}
              >
                {first(calendar.rootEmail)}
              </div>
              {calendar.rootEmail}
            </div>
          ))}
        </div>

        <div className="calendars-settings__section">
          <div className="calendars-settings__item__title">Default Calendar</div>
          <div className="calendars-settings__item__description">
            New events and todos will be scheduled in this calendar.
          </div>
          <CalendarAccountDropdown
            calendars={calendarAccountsToCreateEvents}
            onSelectCalendarId={handleDefaultCalendarChange}
            selectedCalendarId={defaultCalendarId}
            className="calendars-settings__default-calendar__dropdown"
          />
        </div>

        <div className="calendars-settings__section">
          <div className="calendars-settings__item__title">Event Availability</div>
          <div className="calendars-settings__item__description">
            Set the availability of events created in Lifestack.
          </div>
          <div className="calendars-settings__item-content--group">
            <div className="calendars-settings__item-content">
              <div className="calendars-settings__item-content__title">AI Scheduler</div>
              <ToggleButtons
                value={aiEventTransparency}
                options={transparencyOptions}
                onChange={handleAiEventTransparencyChange}
                aria-label="ai event availability"
                className="calendars-settings__transparency-group__availability-toggle"
              />
            </div>
          </div>
        </div>
        <div className="calendars-settings__section">
          <div className="calendars-settings__item__title">Calendar Sync</div>
          <div className="calendars-settings__item__description">
            Sync events to your calendar (Google, Outlook, etc.)
          </div>
          <div className="calendars-settings__item-content--group">
            <div
              className={clsx(
                'calendars-settings__item-content',
                'calendars-settings__sync-calendar'
              )}
            >
              <div className="calendars-settings__item-content__title">AI Scheduler</div>
              <FormControlLabel
                control={
                  <Switch
                    checked={!unSyncToCalendar.aiScheduler}
                    size="small"
                    color="primary"
                    onChange={(e) =>
                      handleUnSyncToCalendarChange({
                        ...unSyncToCalendar,
                        aiScheduler: !e.target.checked,
                      })
                    }
                  />
                }
                label="Sync"
                className="calendars-settings__sync-calendar__label"
              />
            </div>
            <div
              className={clsx(
                'calendars-settings__item-content',
                'calendars-settings__sync-calendar'
              )}
            >
              <div className="calendars-settings__item-content__title">Drag and Drop</div>
              <FormControlLabel
                className="calendars-settings__sync-calendar__label"
                control={
                  <Switch
                    checked={!unSyncToCalendar.dragAndDrop}
                    size="small"
                    color="primary"
                    onChange={(e) =>
                      handleUnSyncToCalendarChange({
                        ...unSyncToCalendar,
                        dragAndDrop: !e.target.checked,
                      })
                    }
                  />
                }
                label="Sync"
              />
            </div>
          </div>
        </div>

        {(FF_calendly || user.access === 'internal') && (
          <Button
            className="calendars-settings__calendly"
            variant="outlined"
            onClick={() => navigate(Path.SETTINGS.CALENDLY)}
          >
            Calendly Integration
          </Button>
        )}
      </div>
    </div>
  );
};

export default CalendarsSettings;
