import { useQuery } from '@tanstack/react-query';
import { EnergyDataTrend } from '@demind-inc/core';

import { energyApi } from '../api';

interface UseEnergyDailyTrendParams {
  dataTrendId: string;
  date: string;
  enabled?: boolean;
}

interface UseEnergyDailyTrendQueryParams {
  queryKey: ['lifestack.trend.energy.daily', UseEnergyDailyTrendParams];
}

export const useEnergyDailyTrend = ({
  dataTrendId,
  date,
  enabled = true,
}: UseEnergyDailyTrendParams) => {
  const energyTrendQuery = useQuery({
    queryKey: ['lifestack.trend.energy.daily', { dataTrendId, date }],
    queryFn: ({ queryKey }: UseEnergyDailyTrendQueryParams) => {
      const [, { dataTrendId, date }] = queryKey;
      return energyApi
        .getDailyEnergyTrend(dataTrendId, date)
        .then(({ data }) => data as EnergyDataTrend);
    },
    refetchOnWindowFocus: false,
    staleTime: 15000, // 15s
    enabled: !!dataTrendId && enabled,
  });

  return {
    energyTrend: energyTrendQuery.data,
    ...energyTrendQuery,
  };
};
