import React, { useState } from 'react';
import { MENU_ITEMS, SIDEBAR_MENU } from '../constants';
import './SideBarMinimized.scss';
import { Avatar, IconButton, Popover, CircularProgress } from '@mui/material';
import { useAuthContext } from '../../../../data-access';
import {
  AppDownloadSwitcher,
  SettingsModal,
  ManualSyncButton,
  SIDEBAR_ICONS,
} from '../../../../components';

interface SideBarMinimizedProps {
  setOpen: (open: boolean) => void;
  isRefreshing: boolean;
  onRefreshData: () => Promise<void>;
}

const SideBarMinimized: React.FC<SideBarMinimizedProps> = ({
  setOpen,
  isRefreshing,
  onRefreshData,
}) => {
  const [settingsModalVisible, setSettingsModalVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { user } = useAuthContext();

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'mobile-app-popover' : undefined;

  const handleItemClick = (event: React.MouseEvent<HTMLButtonElement>, key: string) => {
    switch (key) {
      case 'mobile':
        setAnchorEl(event.currentTarget);
        break;
      case 'settings':
        setSettingsModalVisible(true);
        break;
      default:
        break;
    }
  };

  return (
    <div className="sidebar-minimized">
      <Popover
        id={id}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        className="sidebar-minimized__popover"
      >
        <div className="sidebar-minimized__modal">
          <div className="sidebar-minimized__modal__header">
            <p>Install Mobile App</p>
            <IconButton
              onClick={handlePopoverClose}
              className="sidebar-minimized__modal__close-button"
            >
              <img
                src={SIDEBAR_ICONS['close']}
                alt="Close"
                className="sidebar-minimized__modal__close-button__icon"
              />
            </IconButton>
          </div>
          <div className="sidebar-minimized__modal__content">
            <AppDownloadSwitcher />
          </div>
        </div>
      </Popover>
      <SettingsModal
        visible={settingsModalVisible}
        onClose={() => setSettingsModalVisible(false)}
      />
      <button onClick={() => setOpen(true)} className="sidebar-minimized__header__menu-button">
        <SIDEBAR_MENU.open title="Open Menu" />
      </button>
      <div className="sidebar-minimized__footer">
        <ManualSyncButton
          isMinimized
          className="sidebar-minimized__item"
          onSync={onRefreshData}
          isSyncing={isRefreshing}
        />
        {MENU_ITEMS.map((item) => (
          <IconButton
            key={item.key}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
              item.onClick ? item.onClick() : handleItemClick(event, item.key)
            }
            className="sidebar-minimized__item"
          >
            <item.icon className="sidebar-minimized__item__icon" />
          </IconButton>
        ))}
        <Avatar
          variant="square"
          alt="user-image"
          src={user.photoUrl}
          className="sidebar-minimized__avatar"
        />
      </div>
    </div>
  );
};

export default SideBarMinimized;
