import { FC, lazy, Suspense, useMemo } from 'react';
import { useEnergyTrend } from '../../../hooks';
import './DataCircadianDashboard.scss';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import { useCircadianAnalysis } from '../../../data-access';
import { EnergyDataTrend } from '@demind-inc/core';
import {
  EnergyGaugeCard,
  EnergyValuesCard,
  PeakDipsGraph,
  PeakDipsValuesCard,
} from '../../../components';

const LazyCircadianChart = lazy(
  () => import('../../../components/DataView/DataCircadianDashboard/CircadianChart/CircadianChart')
);

interface DataCircadianDashboardProps {
  className?: string;
}

const DataCircadianDashboard: FC<DataCircadianDashboardProps> = ({ className }) => {
  const { energyWeeklyTrend, dataTrendPeriod, energyDailyTrend, isLoading } = useEnergyTrend();
  const {
    selectedDatePeaksDipsBoundaries,
    avgPeaksDipsBounrdaies,
    selectedDateEnergyFluctuations,
    selectedDateCircadianChartData,
    avgCircadianChartData,
    isFetchingSelectedCircadian,
    isFetchingAvgCircadian,
  } = useCircadianAnalysis();

  const isWeekly = dataTrendPeriod === 'weekly';
  const chartData = useMemo(() => {
    return ((energyWeeklyTrend?.data ?? []) as EnergyDataTrend[]).map(
      (item) => item.peakEnergy ?? 0
    );
  }, [energyWeeklyTrend]);
  return (
    <div className={clsx('circadian-dashboard', className)}>
      {isLoading || isFetchingSelectedCircadian || isFetchingAvgCircadian ? (
        <div className="circadian-dashboard__loading">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="circadian-dashboard__energy">
            <EnergyGaugeCard
              peakEnergy={
                dataTrendPeriod === 'daily'
                  ? (energyDailyTrend?.peakEnergy ?? 0)
                  : (energyWeeklyTrend.avgPeakEnergy ?? 0)
              }
              dataTrendPeriod={dataTrendPeriod}
              className="circadian-dashboard__box"
            />
            <EnergyValuesCard
              className="circadian-dashboard__box"
              energyDailyTrend={energyDailyTrend}
            />
          </div>
          <div
            className={clsx(
              'circadian-dashboard__peak-dips',
              isWeekly && 'circadian-dashboard__peak-dips--weekly'
            )}
          >
            {isWeekly ? (
              <PeakDipsGraph
                className="circadian-dashboard__box"
                chartData={chartData.filter((item) => item)}
              />
            ) : (
              <PeakDipsValuesCard
                className={clsx(
                  'circadian-dashboard__box',
                  isWeekly && 'circadian-dashboard__box--weekly'
                )}
                targetPeakDipStartEndSet={selectedDatePeaksDipsBoundaries}
                basePeakDipStartEndSet={avgPeaksDipsBounrdaies}
                isWeekly={isWeekly}
              />
            )}
          </div>
          {!isWeekly && (
            <Suspense
              fallback={
                <div className="circadian-dashboard__box loader">
                  <CircularProgress />
                </div>
              }
            >
              <LazyCircadianChart
                className="circadian-dashboard__box"
                selectedDateCircadianData={selectedDateCircadianChartData}
                selectedDateEnergyFluctuations={selectedDateEnergyFluctuations}
                avgCircadianData={avgCircadianChartData}
              />
            </Suspense>
          )}
        </>
      )}
    </div>
  );
};

export default DataCircadianDashboard;
