import { createContext, useContext, ReactNode, useCallback, useMemo } from 'react';
import {
  Device,
  STRESS_SUPPORTED_TERRA_PROVIDERS,
  SupportedTerraProvidersType,
  SupportedTerraSDKType,
} from '@demind-inc/core';

import { useDevices, useTerraStatus } from '../queries';
import { useAuthContext } from '../context';
import { terraSdkNames } from '../constants';

interface WearablesContextProps {
  allDevices: Device[];
  terraUserIds: string[];
  apiDevices: Device[];
  invalidTerraUserIds: string[];
  stressDevices: Device[];
}

const WearablesContext = createContext({} as WearablesContextProps);
export const useWearablesContext = () => useContext(WearablesContext);

export const WearablesProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuthContext();
  const { devices, terraUserIds, stressPriorities } = useDevices({
    deviceGroupId: user.deviceGroupId!,
  });
  const { terraStatus } = useTerraStatus({ terraUserIds: terraUserIds });

  const apiDevices = useMemo(
    () =>
      devices.filter(
        ({ terraManufacturer }) =>
          !terraSdkNames.includes(terraManufacturer as SupportedTerraSDKType)
      ),
    [devices]
  );
  const stressDevices = useMemo(() => {
    const filteredDevices = devices.filter((device) =>
      STRESS_SUPPORTED_TERRA_PROVIDERS.includes(
        device.terraManufacturer as SupportedTerraProvidersType
      )
    );

    if (!stressPriorities) {
      return filteredDevices;
    }
    return [...filteredDevices].sort(
      (a, b) => stressPriorities[a.terraManufacturer] - stressPriorities[b.terraManufacturer]
    );
  }, [devices, stressPriorities]);

  const invalidTerraUserIds = useMemo(
    () => terraStatus.filter(({ valid }) => !valid).map(({ terraUserId }) => terraUserId),
    [terraStatus]
  );

  return (
    <WearablesContext.Provider
      value={{
        allDevices: devices,
        apiDevices,
        terraUserIds,
        invalidTerraUserIds,
        stressDevices,
      }}
    >
      {children}
    </WearablesContext.Provider>
  );
};
