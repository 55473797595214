import { FC } from 'react';
import { SleepBar } from '../../../KanbanView/CoreActions/CoreActionModal/ActionModalViews/DailyAnalysisModalView/SleepAnalysis/SleepBar';
import { selectedCoreActionModalAtom } from '../../../../data-access';
import { Typography } from '@mui/material';
import { SleepStage } from '@demind-inc/core';
import clsx from 'clsx';
import './SleepBarAndInfo.scss';
import { ReactComponent as EditIcon } from '../../../../assets/actions/edit.svg';
import { useRecoilState } from 'recoil';
import { sleepSummaryColor, sleepSummaryText } from '../../constants';
import { translateSecondsToHourAndMinutes } from '../../../../helpers';

interface SleepBarAndInfoProps {
  targetSleepStage: SleepStage;
  targetAwakeTime: string;
  targetSleepTime: string;
  hasNoSleepStage?: boolean;
  className?: string;
}

const SleepBarAndInfo: FC<SleepBarAndInfoProps> = ({
  targetSleepStage,
  targetAwakeTime,
  targetSleepTime,
  hasNoSleepStage = false,
  className,
}) => {
  const [_, setSelectedModal] = useRecoilState(selectedCoreActionModalAtom);

  return (
    <div className={clsx('sleep-bar-info-container', className)}>
      <EditIcon
        className="sleep-bar-info-container__edit-icon"
        onClick={() => setSelectedModal('edit_sleep')}
      />
      <SleepBar
        sleepStage={targetSleepStage}
        awakeTime={targetAwakeTime}
        sleepTime={targetSleepTime}
      />
      {!hasNoSleepStage && (
        <div className="sleep-bar-info-container__stage-info">
          {Object.keys(targetSleepStage ?? {}).map((key, value) => {
            const { hours, minutes } = translateSecondsToHourAndMinutes(targetSleepStage[key]);
            return (
              <div key={value}>
                <div className="sleep-bar-info-container__stage-info__time">
                  <Typography>
                    {hours}
                    <Typography variant="subtitle2" component="span">
                      h
                    </Typography>
                  </Typography>
                  <Typography>
                    {minutes}
                    <Typography variant="subtitle2" component="span">
                      m
                    </Typography>
                  </Typography>
                </div>
                <div className="sleep-bar-info-container__sleep-stage-bullet-container">
                  <div
                    style={{
                      background: sleepSummaryColor[key],
                    }}
                    className="sleep-bar-info-container__sleep-stage-bullet-container__bullet"
                  />
                  <Typography variant="subtitle2">{sleepSummaryText[key]}</Typography>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SleepBarAndInfo;
