import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SchedulerPreference } from '@demind-inc/core';

import { schedulerApi } from '../api';
import { isEmpty } from 'lodash';

interface UseUpdateSchedulerPrefParams {
  preferenceId: string;
  preferences: Partial<SchedulerPreference>;
}

export const useUpdateSchedulerPref = () => {
  const queryClient = useQueryClient();

  const updateSchedulerPrefMutation = useMutation({
    mutationFn: ({ preferenceId, preferences }: UseUpdateSchedulerPrefParams) => {
      if (isEmpty(preferences)) {
        return;
      }
      return schedulerApi.updateSchedulerPreference(preferenceId, preferences).then((data) => data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.preference.scheduler'] });
    },
  });

  return {
    updateSchedulerPref: updateSchedulerPrefMutation.mutateAsync,
    ...updateSchedulerPrefMutation,
  };
};
