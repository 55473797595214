import { FC, useEffect, useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { LoadingButton } from '@mui/lab';
import Stripe from 'stripe';
import { useNavigate } from 'react-router-dom';

import './CardPayment.scss';
import {
  DEFAULT_FREE_TRIAL_DAYS,
  useAuthContext,
  useRequestStripeSubscription,
  useSubscriptionContext,
} from '../../../../data-access';
import { Path } from '../../../../routing';
import { trackEventMixpanel } from '../../../../utils';
import { Discount } from '@demind-inc/core';

interface CardPaymentProps {
  selectedPlan: Stripe.Price;
  offeringId: string;
  freeTrialDays?: number;
  discount?: Discount;
}

const CardPayment: FC<CardPaymentProps> = ({
  selectedPlan,
  offeringId,
  freeTrialDays = DEFAULT_FREE_TRIAL_DAYS,
  discount,
}) => {
  const [isCardVerified, setIsCardVerified] = useState(false);
  const [error, setError] = useState('');
  const [isSubscribing, setIsSubscribg] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const { requestStripeSubscription, error: requestError } = useRequestStripeSubscription();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const {
    rewardfulCustomerId,
    rewardfulCaompaignId,
    hasExpiredSubscription,
    refetchSubscriptions,
  } = useSubscriptionContext();

  useEffect(() => {
    if (!elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    cardElement.on('change', (e) => {
      setIsCardVerified(e.complete);
    });
  }, [elements, CardElement]);

  useEffect(() => {
    if (!!requestError) {
      setError(`Something wrong with the payment. ${requestError.message}`);
      setIsSubscribg(false);
    }
  }, [requestError]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements || !selectedPlan || isSubscribing) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }
    setIsSubscribg(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error('[error]', error);
      setError('The card has been declined');
      setIsSubscribg(false);

      return;
    }

    await requestStripeSubscription({
      userId: user.userId,
      priceId: selectedPlan.id,
      email: user.email!,
      customerToken: paymentMethod.id,
      offeringId,
      freeTrialDays: hasExpiredSubscription ? 0 : freeTrialDays,
      ...(!!rewardfulCustomerId && !!rewardfulCaompaignId
        ? {
            referral: {
              campaignId: rewardfulCaompaignId,
              rewardfulClientId: rewardfulCustomerId,
            },
          }
        : {}),
      discount,
      isRestored: hasExpiredSubscription,
    });
    await refetchSubscriptions();
    trackEventMixpanel('subscribe', {
      from: 'web',
      offeringId,
      ...(discount
        ? {
            discount,
          }
        : {}),
    });
    setIsSubscribg(false);
    navigate(Path.DASHBOARD);
  };

  return (
    <form onSubmit={handleSubmit} className="card-payment">
      <CardElement
        options={{
          style: {
            base: {
              fontSize: '16px',
            },
          },
        }}
      />
      {!!error && <div className="card-payment__error">*{error}</div>}
      <LoadingButton
        type="submit"
        variant="contained"
        className="card-payment__btn"
        disabled={!isCardVerified || isSubscribing}
        loading={isSubscribing}
      >
        {hasExpiredSubscription ? 'Resume subscription' : `Start ${freeTrialDays}-day free trial`}
      </LoadingButton>
    </form>
  );
};

export default CardPayment;
