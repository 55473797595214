import clsx from 'clsx';
import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { getDayOfWeek, selectedDateAtom } from '../../../data-access';
import { useTaskTrend } from '../../../hooks';
import './DataTaskDashboard.scss';
import {
  CircularProgress,
  LinearProgress,
  linearProgressClasses,
  styled,
  Typography,
} from '@mui/material';
import { getCssVariable } from '../../../utils';
import {
  CommonTaskTable,
  TaskTableRow,
  WeekBarChartItem,
  WeekTaskBarChart,
} from '../../../components';
import { union } from 'lodash';

const colorBlack = getCssVariable('--color-black');
const colorGrayPurple = getCssVariable('--color-gray-purple');
interface DataTaskDashboardProps {
  className?: string;
}
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: colorGrayPurple,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: colorBlack,
  },
}));

const DataTaskDashboard: FC<DataTaskDashboardProps> = ({ className }) => {
  const selectedDate = useRecoilValue(selectedDateAtom);
  const {
    dataTrendPeriod,
    taskDailyTrend,
    taskCompletionItemSets,
    isLoading,
    taskCompletionLabelItemSets,
    taskWeeklyLabelSet,
    findLabelColorByName,
  } = useTaskTrend({
    date: selectedDate,
  });

  const { percent, progress } = useMemo(() => {
    if (taskDailyTrend?.taskCompletion === undefined || taskDailyTrend?.taskCompletion < 0) {
      return {
        percent: '--',
        progress: 0,
      };
    }
    return {
      percent: Math.round(taskDailyTrend.taskCompletion),
      progress: taskDailyTrend.taskCompletion,
    };
  }, [taskDailyTrend?.taskCompletion]);

  const taskCompletionRows: TaskTableRow[] = useMemo(
    () =>
      taskCompletionItemSets.map((item) => ({
        id: item.date,
        label: getDayOfWeek(item.date),
        completion: item.completion,
        completedTaskNum: item.completedTaskNum,
        plannedTaskNum: item.plannedTaskNum,
      })),
    [selectedDate, dataTrendPeriod, taskCompletionItemSets]
  );

  const taskLabelCompletionRows: TaskTableRow[] = useMemo(
    () =>
      taskCompletionLabelItemSets.map((item) => ({
        id: item.title,
        label: item.title,
        completion: item.completion,
        completedTaskNum: item.completedTaskNum,
        plannedTaskNum: item.plannedTaskNum,
      })),
    [selectedDate, taskCompletionLabelItemSets]
  );

  const weeklyLabelChartData = useMemo(() => {
    if (dataTrendPeriod === 'daily') {
      return { plannedTaskData: [], completedTaskData: [], colors: [], dataKeys: [] };
    }
    const allLabelNames = union(taskWeeklyLabelSet.map((item) => Object.keys(item.items)).flat());

    const allLabelColors = allLabelNames.map((name) => findLabelColorByName(name) ?? []).flat();

    const initalData: WeekBarChartItem = allLabelNames.reduce(
      (acc, name) => ({ ...acc, [name]: 0 }),
      {}
    );

    const plannedTaskData = taskWeeklyLabelSet.map((item) => {
      return Object.entries(item.items).reduce((acc, [key, value]) => {
        return { ...acc, [key]: value.plannedTaskNum };
      }, initalData as WeekBarChartItem);
    });

    const completedTaskData = taskWeeklyLabelSet.map((item) => {
      return Object.entries(item.items).reduce((acc, [key, value]) => {
        return { ...acc, [key]: value.completedTaskNum };
      }, initalData as WeekBarChartItem);
    });

    return {
      plannedTaskData,
      completedTaskData,
      colors: allLabelColors,
      dataKeys: allLabelNames,
    };
  }, [taskWeeklyLabelSet, dataTrendPeriod]);

  if (isLoading) {
    return (
      <div className="task-dashboard-container--loader">
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className={clsx('task-dashboard-container', className)}>
      {dataTrendPeriod === 'daily' && (
        <div className="task-dashboard-container__box">
          <div className="task-dashboard-container__value">
            <Typography variant="h2" className="task-dashboard-container__percent">
              {percent}%
            </Typography>
            <Typography variant="subtitle2" className="task-dashboard-container__title">
              Task Completion
            </Typography>
          </div>
          <BorderLinearProgress
            variant="determinate"
            value={progress}
            className="task-dashboard-container__progress"
          />
        </div>
      )}
      <div className="task-dashboard-container__box">
        <CommonTaskTable rows={taskCompletionRows} />
      </div>
      {dataTrendPeriod === 'daily' && (
        <div className="task-dashboard-container__box task-dashboard-container__box--with-title">
          <Typography className="task-dashboard-container__box__title">
            Task completion by labels
          </Typography>
          <CommonTaskTable rows={taskLabelCompletionRows} />
        </div>
      )}
      {dataTrendPeriod === 'weekly' && (
        <div className="task-dashboard-container__box task-dashboard-container__box--with-title">
          <Typography className="task-dashboard-container__box__title">
            Completed Tasks by Labels
          </Typography>
          <WeekTaskBarChart
            colors={weeklyLabelChartData.colors}
            keys={weeklyLabelChartData.dataKeys}
            data={weeklyLabelChartData.completedTaskData}
            labels={taskCompletionItemSets.map((item) => `${item.completion} %`)}
          />
        </div>
      )}
    </div>
  );
};

export default DataTaskDashboard;
