import { createTheme } from '@mui/material';

const breakpoints = {
  values: {
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
};

const theme = createTheme({
  typography: {
    allVariants: {
      fontWeight: 400,
      lineHeight: 1.5,
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    },
    button: {
      textTransform: 'none',
      fontSize: '20px',
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: '18px',
      },
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: '16px',
      },
    },
    h1: {
      fontSize: '40px',
      fontWeight: 500,
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: '32px',
      },
    },
    h2: {
      fontSize: '32px',
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: '24px',
      },
    },
    h3: {
      fontSize: '24px',
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: '18px',
      },
    },
    h4: {
      fontSize: '20px',
    },
    h5: {
      fontSize: '18px',
    },
    body1: {
      fontSize: '16px',
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: '14px',
      },
    },
    body2: {
      fontSize: '14px',
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: '12px',
      },
    },
    subtitle1: {
      fontSize: '13px',
    },
    subtitle2: {
      fontSize: '12px',
    },
    h6: {
      fontSize: '1.125rem',
    },
    caption: {
      fontSize: '10px',
    },
  },
  breakpoints,
  palette: {
    primary: {
      main: '#680ddb',
    },
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '22px',
          [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
            fontSize: '16px',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '14px',
          gap: '8px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: '#c9b8ff33',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '& .MuiButton-root': {
            fontSize: '14px',
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          '& .MuiToggleButton-root': {
            fontSize: '14px',
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          '& .MuiSnackbarContent-root': {
            borderRadius: '8px',
          },
          '& .MuiSnackbarContent-action': {
            gap: '4px',
          },
          '& .MuiSnackbarContent-action .MuiButton-root': {
            borderRadius: '8px',
            border: '1px solid #ffffff',
            color: '#ffffff',
          },
        },
      },
    },
  },
});

export default theme;
