import { Calendar, CalendarPreference } from '@demind-inc/core';
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useRecoilState } from 'recoil';

import {
  useAuthContext,
  useCalendarPreference,
  useCalendarsMeta,
  useUpdateCalendarInfo,
  visibleCalendarIdsAtom,
} from '../../data-access';

interface ICalendarContext {
  calendarsMeta: Calendar[];
  visibleCalendarIds: string[];
  mainCalendar?: Calendar;
  calendarAccountsToCreateEvents: Calendar[];
  calendarPreferences: CalendarPreference;
  isFetchingCalendars: boolean;
  findCalendarItem: (targetCalendarId: string) => Calendar | undefined;
  checkCalendarHassAccessToModifyEvent: (targetCalendarId: string) => boolean;
  handleSelectCalendar: (targetCalendar: Calendar, checked: boolean) => void;
}

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const CalendarContext = createContext({} as ICalendarContext);
export const useCalendarContext = () => useContext(CalendarContext);

export const CalendarProvider = ({ children }: { children: ReactNode }) => {
  const [visibleCalendarIds, setVisibleCalendarIds] = useRecoilState(visibleCalendarIdsAtom);

  const { user } = useAuthContext();
  const { calendarsMeta, isLoading: isFetchingCalendars } = useCalendarsMeta({
    userId: user.userId,
  });

  const { updateCalendarInfo } = useUpdateCalendarInfo();
  const { calendarPreferences } = useCalendarPreference({ preferenceId: user.preferenceId! });

  const mainCalendar = useMemo(
    () =>
      calendarsMeta.find((info) => {
        if (calendarPreferences.defaultCalendarId !== undefined) {
          // Set the default calendar from pref
          return info.calendarId === calendarPreferences.defaultCalendarId;
        }
        return info.default === undefined ? info?.internalGCalendarId === user.email : info.default; // Initially, the default calendar is the one who initialize calendars
      }),
    [calendarsMeta, user.email, calendarPreferences]
  );

  const calendarAccountsToCreateEvents = useMemo(
    () => calendarsMeta.filter(({ scope }) => !['freeBusyReader', 'reader'].includes(scope!)),
    [calendarsMeta]
  );

  // Update the visible calendar ids when the calendar meta is fetched
  useEffect(() => {
    if (!calendarsMeta.length) {
      return;
    }

    setVisibleCalendarIds(
      calendarsMeta
        .filter((info) => !!info && info.visible)
        .map((info) => info?.calendarId ?? [])
        .flat()
    );
  }, [calendarsMeta.length]);

  const findCalendarItem = useCallback(
    (targetCalendarId: string) => {
      return calendarsMeta.find(({ calendarId }) => calendarId === targetCalendarId);
    },
    [calendarsMeta]
  );

  const checkCalendarHassAccessToModifyEvent = useCallback(
    (targetCalendarId: string) => {
      const targetCalendar = findCalendarItem(targetCalendarId);

      return !['freeBusyReader', 'reader'].includes(targetCalendar?.scope!);
    },
    [findCalendarItem]
  );

  const handleSelectCalendar = useCallback((targetCalendar: Calendar, checked: boolean) => {
    if (checked) {
      setVisibleCalendarIds((prev) => [...prev, targetCalendar.calendarId]);
    } else {
      setVisibleCalendarIds((prev) => prev.filter((id) => id !== targetCalendar.calendarId));
    }
    updateCalendarInfo({
      calendarId: targetCalendar.calendarId,
      newCalendarInfo: { visible: checked },
    });
  }, []);

  return (
    <CalendarContext.Provider
      value={{
        mainCalendar,
        visibleCalendarIds,
        calendarsMeta,
        calendarPreferences,
        calendarAccountsToCreateEvents,
        isFetchingCalendars,
        findCalendarItem,
        handleSelectCalendar,
        checkCalendarHassAccessToModifyEvent,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};
