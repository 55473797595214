import React, { useReducer, useState } from 'react';
import clsx from 'clsx';
import { LoadingButton } from '@mui/lab';

import {
  initialDefaultPrefItemState,
  prefItemReducer,
  SchedulerPrefTimeRange,
  validatePreferenceItems,
  DefaultPrefEditItem,
  transformDailyPrefForRunEndpoint,
} from '../../../Scheduler';

import './AISchedulerPracticeSetup.scss';
import { AISchedulerPracticePrefItem } from '../AISchedulerPracticePrefItem';
import { useAIScheduler } from '../../../../hooks';
import { AISchedulerRunningModalView } from '../../../KanbanView';

interface AISchedulerPracticeSetupProps {
  onComplete: () => void;
}

const AISchedulerPracticeSetup: React.FC<AISchedulerPracticeSetupProps> = ({ onComplete }) => {
  const [prefItems, dispatch] = useReducer(prefItemReducer, initialDefaultPrefItemState);
  const validationError = validatePreferenceItems(prefItems);
  const { handleRunAIScheduler, resetAiScheduler } = useAIScheduler({
    isDemo: true,
  });
  const [isRunningAIScheduler, setIsRunningAIScheduler] = useState(false);

  const runAIScheduler = async () => {
    setIsRunningAIScheduler(true);
    resetAiScheduler();
    const transformedPref = transformDailyPrefForRunEndpoint(prefItems);
    try {
      await handleRunAIScheduler(transformedPref);
    } finally {
      setIsRunningAIScheduler(false);
      onComplete();
    }
  };

  if (isRunningAIScheduler) {
    return <AISchedulerRunningModalView />;
  }

  return (
    <div className="ai-scheduler-practice-setup">
      <div className="ai-scheduler-practice-setup__content">
        <div className="ai-scheduler-practice-setup__header">
          <div className="ai-scheduler-practice-setup__header__title">Tell us about your day</div>
        </div>
        <DefaultPrefEditItem
          key={'workTime-demo'}
          title="Work hours"
          start={prefItems.workTime.start}
          end={prefItems.workTime.end}
          isRange={false}
          hasSwitch={false}
          onStartTimeChange={(startTime) =>
            dispatch({
              type: 'workTime',
              payload: {
                start: {
                  ...prefItems.workTime.start,
                  time: startTime,
                } as SchedulerPrefTimeRange,
              },
            })
          }
          onEndTimeChange={(endTime) =>
            dispatch({
              type: 'workTime',
              payload: {
                end: {
                  ...prefItems.workTime.end,
                  time: endTime,
                } as SchedulerPrefTimeRange,
              },
            })
          }
        />
        <AISchedulerPracticePrefItem items={prefItems} />
      </div>
      <LoadingButton
        variant="contained"
        onClick={runAIScheduler}
        disabled={!!validationError || isRunningAIScheduler}
        loading={isRunningAIScheduler}
        className={clsx(
          'ai-scheduler-practice-setup__button',
          !!validationError && 'ai-scheduler-practice-setup__button--disabled'
        )}
      >
        Run AI Scheduler
      </LoadingButton>
    </div>
  );
};

export default AISchedulerPracticeSetup;
