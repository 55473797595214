import {
  ACTIVITY_SUPPORTED_TERRA_PROVIDERS,
  Device,
  MEAL_SUPPORTED_TERRA_PROVIDERS,
  SUPPORTED_TERRA_PROVIDERS,
  SupportedTerraProvidersType,
} from '@demind-inc/core';

export const getTargetTerraUserIds = (
  apiDevices: Device[],
  type: 'sleep' | 'exercise' | 'meal'
) => {
  const targetAllProviders = {
    sleep: SUPPORTED_TERRA_PROVIDERS,
    exercise: ACTIVITY_SUPPORTED_TERRA_PROVIDERS,
    meal: MEAL_SUPPORTED_TERRA_PROVIDERS,
  }[type];

  const targetDevices = apiDevices.filter((device) =>
    targetAllProviders.includes(device.terraManufacturer as SupportedTerraProvidersType)
  );

  return targetDevices.map(({ terraUserId }) => terraUserId);
};
