import { useEffect, useMemo, useTransition } from 'react';
import { useRecoilValue } from 'recoil';

import { useSyncCalEventsApi } from './useSyncCalEventsApi';
import { syncedDatesState } from '../recoil';
import { useSyncTodosApi } from './useSyncTodosApi';

// Sync processes on the first render of the app
export const useSyncOnFirstRender = () => {
  const [isPending, startTransition] = useTransition();
  const { syncAllCalendarEvents } = useSyncCalEventsApi();
  const { syncAllTodoTasks } = useSyncTodosApi();

  const syncedDates = useRecoilValue(syncedDatesState);

  const { startTime, endTime } = useMemo(() => {
    const startTime = syncedDates[0].startOf('day').toISOString();
    const endTime = syncedDates[syncedDates.length - 1].endOf('day').toISOString();

    return { startTime, endTime };
  }, [syncedDates]);

  useEffect(() => {
    startTransition(() => {
      syncAllCalendarEvents(startTime, endTime);
    });
  }, [syncAllCalendarEvents, startTime, endTime]);

  useEffect(() => {
    startTransition(() => {
      syncAllTodoTasks();
    });
  }, [syncAllTodoTasks]);

  return { isPending };
};
