import React from 'react';

import RunningText from '../../../../../../assets/scheduler/ai-scheduler-running-text.png';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';

const AISchedulerRunningModalView: React.FC = () => {
  return (
    <div className={clsx('ai-scheduler-modal', 'ai-scheduler-modal--with-loader')}>
      <div className="ai-scheduler-modal__running--container">
        <img src={RunningText} alt="Running" className="ai-scheduler-modal__running__image" />
        <CircularProgress size={45} thickness={4} className="ai-scheduler-modal__running__loader" />
      </div>
    </div>
  );
};

export default AISchedulerRunningModalView;
