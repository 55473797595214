import { FC } from 'react';
import { OpenInNew } from '@mui/icons-material';

import { ReferenceLinkInfo } from './types';
import './ReferenceLink.scss';

interface ReferenceLinkListProps {
  referenceLinks: ReferenceLinkInfo[];
}

export const ReferenceLinkList: FC<ReferenceLinkListProps> = ({ referenceLinks }) => {
  return (
    <div className="reference-link__list">
      {referenceLinks.map((link) => (
        <a className="reference-link" href={link.url} target="_blank" rel="noopener noreferrer">
          <OpenInNew width={4} height={4} fontSize="inherit" className="reference-link__icon" />
          <span className="reference-link__text">{link.title}</span>
        </a>
      ))}
    </div>
  );
};
