import { useCallback, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useRecoilState } from 'recoil';
import dayjs, { Dayjs } from 'dayjs';

import {
  activityEventsAtom,
  selectedActivityEventAtom,
  useAuthContext,
  useCalendarContext,
  useCreateCalendarEvent,
  useHideExercise,
  useHideMeal,
  useMetricsContext,
} from '../data-access';
import { translateActivityToTemporalCalendarEvent } from '../helpers';
import { getCssVariable } from '../utils';
import { uniqBy } from 'lodash';

const PRIMARY_COLOR = getCssVariable('--color-primary');

export const useMetricsActivitiesInCalendar = () => {
  const { user } = useAuthContext();
  const { exerciseDetails, mealDetails } = useMetricsContext();
  const { createCalendarEvent } = useCreateCalendarEvent();
  const { mainCalendar } = useCalendarContext();
  const { hideExericse } = useHideExercise();
  const { hideMeal } = useHideMeal();

  const [activityEvents, setActivityEvents] = useRecoilState(activityEventsAtom);
  const [selectedActivityEvent, setSelectedActivityEvent] =
    useRecoilState(selectedActivityEventAtom);

  useEffect(() => {
    const exercises = exerciseDetails
      .map(({ details }) => details)
      .flat()
      .filter((e) => !e.hide);

    // Merge meals with the same start time
    const meals = uniqBy(
      mealDetails
        .map(({ details }) => details)
        .flat()
        .filter((e) => !e.hide),
      'startTime'
    );

    const exerciseEvents = exercises.map((activity) =>
      translateActivityToTemporalCalendarEvent(activity, 'exercise')
    );
    const mealEvents = meals.map((activity) =>
      translateActivityToTemporalCalendarEvent(activity, 'meal')
    );

    setActivityEvents([...exerciseEvents, ...mealEvents]);
  }, [exerciseDetails, mealDetails]);

  const clearSelectedActivityEvent = () => {
    setSelectedActivityEvent(null);
  };

  const handleSaveActivityEvent = useCallback(
    (calendarId?: string, startTime?: Dayjs, endTime?: Dayjs) => {
      if (!selectedActivityEvent) {
        clearSelectedActivityEvent();
        return;
      }
      createCalendarEvent({
        userId: user.userId,
        calendarId: calendarId || mainCalendar?.calendarId!,
        newEventOption: {
          ...selectedActivityEvent,
          eventId: (uuidv4() as string).replace(/-/g, ''),
          color: mainCalendar?.color || PRIMARY_COLOR,
          scheduleFrom: 'activity',
          start: {
            ...selectedActivityEvent.start,
            date: startTime ? startTime.toISOString() : selectedActivityEvent.start.date,
          },
          end: {
            ...selectedActivityEvent.end,
            date: endTime ? endTime.toISOString() : selectedActivityEvent.end.date,
          },
        },
        metrics: { energy: 'high' },
      });
      setActivityEvents((prev) => prev.filter((e) => e.eventId !== selectedActivityEvent.eventId));
      clearSelectedActivityEvent();
    },
    [user.userId, mainCalendar, activityEvents, selectedActivityEvent]
  );

  const handleDiscardActivityEvent = useCallback(() => {
    if (!selectedActivityEvent || !selectedActivityEvent.activityFrom?.activityId) {
      clearSelectedActivityEvent();

      return;
    }
    if (selectedActivityEvent.activityType === 'exercise') {
      hideExericse({
        metricId: user.metricId!,
        date: dayjs(selectedActivityEvent.start.date).format('YYYY-MM-DD'),
        activityId: selectedActivityEvent.activityFrom?.activityId,
      });
    } else if (selectedActivityEvent.activityType === 'meal') {
      hideMeal({
        metricId: user.metricId!,
        date: dayjs(selectedActivityEvent.start.date).format('YYYY-MM-DD'),
        mealId: selectedActivityEvent.activityFrom?.activityId,
      });
    }

    setActivityEvents((prev) => prev.filter((e) => e.eventId !== selectedActivityEvent.eventId));
    clearSelectedActivityEvent();
  }, [user.metricId, selectedActivityEvent]);

  const handleSelectActivityEvent = useCallback(
    (eventId: string) => {
      const targetEvent = activityEvents.find((e) => e.eventId === eventId);
      setSelectedActivityEvent(targetEvent);
    },
    [activityEvents]
  );

  return {
    activityEvents,
    selectedActivityEvent,
    clearSelectedActivityEvent,
    handleSaveActivityEvent,
    handleDiscardActivityEvent,
    handleSelectActivityEvent,
  };
};
