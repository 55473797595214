import { FC } from 'react';
import { IconButton } from '@mui/material';
import {
  Crop54 as DayIcon,
  CalendarViewWeek as WeekIcon,
  CalendarViewMonth as MonthIcon,
} from '@mui/icons-material';
import clsx from 'clsx';

import './CalendarViewSwitcher.scss';
import { CalendarViewType } from '../../../data-access';

interface CalendarViewSwitcherProps {
  className?: string;
  onViewChange: (view: CalendarViewType) => void;
  view: CalendarViewType;
}

const viewConfig = {
  day: { icon: DayIcon, unit: 'day' },
  week: { icon: WeekIcon, unit: 'week' },
  month: { icon: MonthIcon, unit: 'month' },
};

const CalendarViewSwitcher: FC<CalendarViewSwitcherProps> = ({ className, onViewChange, view }) => {
  return (
    <div className={clsx('calendar-view-switcher', className)}>
      {Object.entries(viewConfig).map(([targetView, config]) => {
        const ViewIcon = config.icon;
        return (
          <IconButton
            key={targetView}
            onClick={() => onViewChange(targetView as CalendarViewType)}
            className={clsx(
              'calendar-view-switcher__button',
              view === targetView && 'calendar-view-switcher__button--active'
            )}
            aria-label={`Switch to ${targetView} view`}
          >
            <ViewIcon />
          </IconButton>
        );
      })}
    </div>
  );
};

export default CalendarViewSwitcher;
