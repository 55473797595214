import dayjs from 'dayjs';

import {
  getStartEndTimeOfMultDays,
  useAuthContext,
  useSyncMealDetails,
  wearableErrorSnackBarAtom,
} from '../data-access';
import { useEffect } from 'react';
import { AxiosError } from 'axios';
import { useRecoilState } from 'recoil';

interface SyncMealDataParams {
  terraUserIds: string[];
  startDate: string;
  endDate: string;
}

export const useSyncMealData = () => {
  const { syncMealDetail, error: syncMealDetailError } = useSyncMealDetails();
  const { user } = useAuthContext();
  const [_, setWearableErrorSnackbar] = useRecoilState(wearableErrorSnackBarAtom);

  useEffect(() => {
    if (!syncMealDetailError) {
      return;
    }
    const error = (syncMealDetailError as AxiosError).response;
    if (error?.status === 401) {
      setWearableErrorSnackbar({ message: 'Device connection issue', status: 401 });
    } else {
      setWearableErrorSnackbar({ message: 'Failed to sync meal data', status: error?.status });
    }
  }, [syncMealDetailError]);

  const syncMealData = async ({ terraUserIds, startDate, endDate }: SyncMealDataParams) => {
    if (!user.metricId || !user.userId || !terraUserIds.length) {
      return;
    }

    const { startTime, endTime } = getStartEndTimeOfMultDays(dayjs(startDate), dayjs(endDate));

    if (terraUserIds.length) {
      try {
        await syncMealDetail({
          metricId: user.metricId,
          terraUserIds,
          startDate: startTime.toISOString(),
          endDate: endTime.toISOString(),
        });
      } catch (error) {
        console.error('Error syncing meal data', error);
      }
    }
  };

  return { syncMealData };
};
