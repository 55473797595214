import dayjs, { Dayjs } from 'dayjs';
import { translateSecondsToHourAndMinutes } from '../../helpers';

/**
 * Calculates the start and end time of multiple days.
 * @param startDate - The start date.
 * @param endDate - The end date.
 * @returns An object containing the start and end time.
 */
export const getStartEndTimeOfMultDays = (startDate: Dayjs, endDate: Dayjs) => {
  const startTime = startDate
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)
    .set('milliseconds', 0);
  const endTime = endDate
    .add(1, 'day')
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)
    .set('milliseconds', 0);

  return { startTime, endTime };
};

/**
 * Returns an array of dates, starting from today and going back in time for the specified duration.
 * @param duration The number of days to go back in time.
 * @returns An array of dates in descending order.
 */
export const getArrayOfXDaysBackFromToday = (duration: number) => {
  const arrayOfDates = Array.from({ length: duration })
    .map((_, i) => dayjs().subtract(i, 'day'))
    .sort((a, b) => (a.isAfter(b) ? 1 : -1));

  return arrayOfDates;
};

/**
 * Returns an array of dates, starting from today and going forward in time for the specified duration.
 * @param duration The number of days to go back in time.
 * @returns An array of dates in descending order.
 */
export const getArrayOfXDaysForwardFromToday = (duration: number) => {
  const arrayOfDates = Array.from({ length: duration })
    .map((_, i) => dayjs().add(i, 'day'))
    .sort((a, b) => (a.isAfter(b) ? 1 : -1));

  return arrayOfDates;
};

/**
 * Returns an array of dates representing the week containing the given date.
 * The week starts on Sunday and ends on Saturday.
 *
 * @param date - The date for which to get the week dates.
 * @returns An array of dates representing the week.
 */
export const getWeekDatesOf = (date: Dayjs) => {
  const startDate = date
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)
    .set('milliseconds', 0)
    .weekday(0);

  return Array.from({ length: 7 }).map((_, i) => {
    return startDate.add(i, 'day');
  });
};

export const getMonthDatesOf = (date: Dayjs) => {
  const startDate = date.startOf('month');
  const endDate = date.endOf('month');

  return Array.from({ length: endDate.diff(startDate, 'day') + 1 }).map((_, i) => {
    return startDate.add(i, 'day');
  });
};

export const formatToHHmm = (datetime: string) => {
  if (!datetime) {
    return;
  }
  return dayjs(datetime).format('HH:mm');
};

export const formatToAmPm = (datetime: string) => {
  if (!datetime) {
    return;
  }
  return dayjs(datetime).format('h:mmA');
};

/**
 *Return the day of the week for the given date.
 * The week starts on Sunday and ends on Saturday.
 *
 * @param dateString - The date for which to get the day of the week.
 * @returns The day of the week.
 */
export const getDayOfWeek = (dateString: string) => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const date = new Date(dateString);
  return days[date.getDay()];
};

/**
 * Returns a Dayjs object representing the earliest occurrence of the specified hour.
 * If the current time is before the target hour, it returns the current day's target hour.
 * Otherwise, it returns the target hour of the next day.
 *
 * @param {number} targetHour - The hour of the day (0-23) to find the earliest occurrence of.
 * @returns {Dayjs} A Dayjs object set to the earliest occurrence of the specified hour.
 */
export const getDayjsOfEarliestXHour = (earliestHour: number, boundaryHourForNextDay: number) => {
  const isNowBeforeTargetHour = dayjs().get('hour') < earliestHour;

  if (isNowBeforeTargetHour) {
    return dayjs().set('hour', earliestHour).set('minute', 0);
  }

  if (dayjs().get('hour') >= boundaryHourForNextDay) {
    return dayjs().add(1, 'day').set('hour', earliestHour).set('minute', 0);
  }

  return dayjs();
};

/**
 * Calculates the difference in minutes between two times given in "HH:mm" format.
 *
 * @param start - The start time in "HH:mm" format.
 * @param end - The end time in "HH:mm" format.
 * @returns The difference in minutes between the start and end times.
 */
export const getDiffMinBetweenHHmm = (start: string, end: string) => {
  const [startHour, startMinute] = start.split(':').map((v) => parseInt(v, 10));
  const [endHour, endMinute] = end.split(':').map((v) => parseInt(v, 10));

  const startInMinutes = startHour * 60 + startMinute;
  const endInMinutes = endHour * 60 + endMinute;

  return endInMinutes - startInMinutes;
};

/**
 * Formats a given time string into a Date object with the current date.
 *
 * @param time - The time string in the format "HH:mm".
 * @returns A Date object with the current date and the specified time.
 */
export const transformHHmmToDayjs = (time: string) => {
  const [hour, minute] = time.split(':').map((v) => parseInt(v, 10));
  const newDate = dayjs().set('hour', hour).set('minute', minute);

  return newDate;
};

export const transformMinToDayjs = (min: number) => {
  const newDate = dayjs().set('hour', 0).set('minute', min);

  return newDate;
};

/**
 * Converts a given number of minutes into a string formatted as "HHh MMm".
 *
 * @param min - The number of minutes to convert.
 * @returns A string representing the time in hours and minutes, formatted as "HHh MMm".
 */
export const translateMinToHHhMMmString = (min: number) => {
  const { hours, minutes } = translateSecondsToHourAndMinutes(min * 60);
  if (hours === 0) {
    return `${minutes}m`;
  }
  return `${hours}h ${minutes}m`;
};

/**
 * Accepts minutes as number and return it in format as hour and minutes.
 * @param minutes - The minutes to be translated.
 * @returns An object containing the hours and remaining minutes.
 */
export const formatMinutesToHoursAndMinutes = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return { hours, remainingMinutes };
};
