import { Paper, Typography, Box } from '@mui/material';
import './CircadianChartToolTip.scss';

const CircadianChartToolTip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const currentValue = payload.find((p) => p.dataKey === 'currentValue')?.value;
    const avgValue = payload.find((p) => p.dataKey === 'avgValue')?.value;

    if (!currentValue && !avgValue) return null;

    return (
      <Paper className="chart-tooltip" elevation={3}>
        {currentValue !== null && (
          <Box className="chart-tooltip__item">
            <Typography variant="body2" className="chart-tooltip__label">
              Actual:
            </Typography>
            <Typography variant="body1" className="chart-tooltip__value">
              {(currentValue * 100).toFixed(0)}
            </Typography>
          </Box>
        )}
        {avgValue !== null && (
          <Box className="chart-tooltip__item">
            <Typography variant="body2" className="chart-tooltip__label">
              Average:
            </Typography>
            <Typography variant="body1" className="chart-tooltip__value">
              {isNaN(avgValue) ? '' : (avgValue * 100).toFixed(0)}
            </Typography>
          </Box>
        )}
      </Paper>
    );
  }
  return null;
};

export default CircadianChartToolTip;
