import { FC, memo, useMemo } from 'react';
import { HeatmapDataType } from '@demind-inc/core';
import { Tooltip } from '@mui/material';
import { useRecoilState } from 'recoil';

import {
  CreateEventProps,
  heatmapPopupAtom,
  isEnergyPopupVisibleAtom,
} from '../../../../data-access';
import { EnergyPopup } from '../EnergyPopup';
import './EnergyTooltip.scss';

interface EnergyTooltipProps {
  selectedPoint: HeatmapDataType | null;
  top: string;
  onCreateEvent?: (event: CreateEventProps) => void;
}

export const EnergyTooltip: FC<EnergyTooltipProps> = memo(
  ({ selectedPoint, top, onCreateEvent = () => void 0 }) => {
    const [isPopupVisible, setIsPopupVisible] = useRecoilState(isEnergyPopupVisibleAtom);

    const tooltipContent = useMemo(() => {
      if (!selectedPoint || !isPopupVisible) return null;
      return (
        <EnergyPopup
          data={selectedPoint}
          onClose={() => setIsPopupVisible(false)}
          onCreateEvent={onCreateEvent}
        />
      );
    }, [selectedPoint, isPopupVisible, onCreateEvent]);

    const indicatorStyle = useMemo(
      () => ({
        left: 2,
        top,
      }),
      [top]
    );

    return (
      <Tooltip
        open={!!selectedPoint && isPopupVisible}
        placement="right"
        arrow
        title={tooltipContent}
        PopperProps={{
          sx: {
            '&': {
              zIndex: 3,
              opacity: isPopupVisible ? 1 : 0,
              transition: 'opacity 0.2s ease-in-out',
            },
            '& .MuiTooltip-tooltip': {
              backgroundColor: 'transparent',
              padding: 0,
            },
            '& .MuiTooltip-arrow': {
              color: 'white',
            },
          },
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                boundary: document.querySelector('.calendar-timeline'),
                mainAxis: false,
                altAxis: true,
              },
            },
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ],
        }}
      >
        <div className="energy-tooltip__indicator" style={indicatorStyle} />
      </Tooltip>
    );
  }
);
