import { SchedulerPrefType } from '../../../data-access';
import { getCssVariable } from '../../../utils';
import { AutoTimeboxAdjustmentLevel } from './types';

const GREEN_COLOR = getCssVariable('--color-green-base');
const RED_COLOR = getCssVariable('--color-red-base');
const TEXT_COLOR = getCssVariable('--color-black');

export const schedulerPrefTitleSet: Record<SchedulerPrefType, string> = {
  workTime: 'Work hours',
  deepWork: 'Deep work',
  lightWork: 'Light work',
  lunch: 'Lunch',
  dinner: 'Dinner',
  workout: 'Work out',
};

export const schedulerPrefSwitchableOptions: SchedulerPrefType[] = ['workout', 'lunch', 'dinner'];

export const autoTimeboxAdjustmentText: Record<AutoTimeboxAdjustmentLevel, string> = {
  [AutoTimeboxAdjustmentLevel.ABOVE_AVERAGE]:
    "Your energy today is higher than usual — let's focus on more deep work!",
  [AutoTimeboxAdjustmentLevel.AVERAGE]: 'Your energy today is average -- keep up the good work!',
  [AutoTimeboxAdjustmentLevel.BELOW_AVERAGE]:
    "Your energy today is lower than usual — let's reduce deep work and focus on lighter tasks",
};

export const autoTimeboxAdjustmentColor: Record<AutoTimeboxAdjustmentLevel, string> = {
  [AutoTimeboxAdjustmentLevel.ABOVE_AVERAGE]: GREEN_COLOR,
  [AutoTimeboxAdjustmentLevel.AVERAGE]: TEXT_COLOR,
  [AutoTimeboxAdjustmentLevel.BELOW_AVERAGE]: RED_COLOR,
};
