import { useQuery } from '@tanstack/react-query';
import { EnergyPreference } from '@demind-inc/core';

import { energyApi } from '../api';

interface UseEnergyPreferenceParams {
  preferenceId: string;
}

interface UseEnergyPreferenceQueryParams {
  queryKey: ['lifestack.preference.energy', UseEnergyPreferenceParams];
}

export const useEnergyPreference = ({ preferenceId }: UseEnergyPreferenceParams) => {
  const energyPrefQuery = useQuery({
    queryKey: ['lifestack.preference.energy', { preferenceId }],
    queryFn: ({ queryKey }: UseEnergyPreferenceQueryParams) => {
      const [, { preferenceId }] = queryKey;
      return energyApi
        .getEnergyPreference(preferenceId)
        .then(({ data }) => data as EnergyPreference);
    },
    refetchOnWindowFocus: false,
    staleTime: 15000, // 15s
    enabled: !!preferenceId,
  });

  return {
    energyPreferences: energyPrefQuery.data ?? {},
    ...energyPrefQuery,
  };
};
