import { useMutation, useQueryClient } from '@tanstack/react-query';
import { debounce, throttle } from 'lodash';
import { AxiosError } from 'axios';

import { calendarApi, CalendarError401Res } from '../api';
import { useRecoilState } from 'recoil';
import { syncErrorRootEmailAtom } from '../recoil';

interface UseSyncCalendarEventsParams {
  calendarIds: string[];
  userId: string;
  startTime: string;
  endTime: string;
}

export function useSyncCalendarEvents() {
  const queryClient = useQueryClient();
  const [_, setSyncErrorRootEmail] = useRecoilState(syncErrorRootEmailAtom);

  const syncCalMutation = useMutation({
    mutationFn: ({ calendarIds, userId, startTime, endTime }: UseSyncCalendarEventsParams) => {
      return calendarApi.syncCalendarEvents(userId, startTime, endTime, calendarIds);
    },
    onSuccess: debounce((data, { startTime, endTime }) => {
      queryClient.invalidateQueries({
        queryKey: ['calendar.events', { startDate: startTime, endDate: endTime }],
      });
    }, 2000),
    onError: throttle((error) => {
      const errorData = (error as AxiosError)?.response?.data;
      const errorRootEmail = (errorData as CalendarError401Res)?.rootEmail as string;
      if (errorRootEmail) {
        setSyncErrorRootEmail(errorRootEmail);
      }
    }, 1000),
  });

  return {
    syncCalEvents: syncCalMutation.mutateAsync,
    ...syncCalMutation,
  };
}
