import React from 'react';
import { Add, Check } from '@mui/icons-material';
import { Button } from '@mui/material';
import { TodoIntegrationType } from '@demind-inc/core';
import clsx from 'clsx';

import './ConnectTodosListItem.scss';
import { AVAILABLE_TODO_INTEGRATIONS_TYPE } from '../../../data-access';
import { TODO_INTEGRATION_ICONS } from '../constants';

interface ConnectTodosListItemProps {
  todoType: TodoIntegrationType;
  connected: boolean;
  onConnect: () => void;
}

const ConnectTodosListItem: React.FC<ConnectTodosListItemProps> = ({
  todoType,
  connected,
  onConnect,
}) => {
  const isDisabled = !AVAILABLE_TODO_INTEGRATIONS_TYPE.includes(todoType);

  const icon = TODO_INTEGRATION_ICONS[todoType];

  const handleConnect = () => {
    if (connected || isDisabled) {
      return;
    }

    onConnect();
  };

  if (isDisabled) {
    return (
      <Button
        className={clsx('connect-todos-list-item')}
        variant={'outlined'}
        endIcon={<img src={icon} alt={todoType} className="connect-todos-list-item__icon" />}
      >
        <div className="connect-todos-list-item__content__text">Coming soon</div>
      </Button>
    );
  }

  return (
    <Button
      className={clsx(
        'connect-todos-list-item',
        !connected && 'connect-todos-list-item--not-connected'
      )}
      data-todo-type={todoType}
      variant={connected ? 'contained' : 'outlined'}
      endIcon={<img src={icon} alt={todoType} className="connect-todos-list-item__icon" />}
      onClick={handleConnect}
    >
      <div className={'connect-todos-list-item__content'}>
        {connected ? <Check /> : <Add />}
        <div className="connect-todos-list-item__content__text">
          {connected ? 'Connected' : 'Connect'}
        </div>
      </div>
    </Button>
  );
};

export default ConnectTodosListItem;
