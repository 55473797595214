import { useCallback, useEffect, useRef } from 'react';
import { chunk, union } from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useAuthContext } from '../context';
import { isSyncingCalEventsAtom, visibleCalendarIdsAtom } from '../recoil';
import { useSyncCalendarEvents } from '../mutations';

const SYNC_CALENDAR_CHUNK_SIZE = 7;

export const useSyncCalEventsApi = () => {
  const { syncCalEvents, isPending: isSyncingEvents } = useSyncCalendarEvents();
  const alreadySyncedWeeks = useRef<string[]>([]);
  const { user } = useAuthContext();
  const [_, setIsSyncingCalEvents] = useRecoilState(isSyncingCalEventsAtom);
  const visibleCalendarIds = useRecoilValue(visibleCalendarIdsAtom);

  useEffect(() => {
    setIsSyncingCalEvents(isSyncingEvents);
  }, [isSyncingEvents]);

  const syncAllCalendarEvents = useCallback(
    (startTime: string, endTime: string, targetCalendarIds: string[] = visibleCalendarIds) => {
      if (
        alreadySyncedWeeks.current.includes(startTime) ||
        !targetCalendarIds?.length ||
        !user.userId
      ) {
        return;
      }

      // Chunk the calendar ids to avoid long requests
      chunk(targetCalendarIds, SYNC_CALENDAR_CHUNK_SIZE).forEach((calendarIds) => {
        syncCalEvents({
          userId: user.userId,
          calendarIds,
          startTime,
          endTime,
        });
      });

      alreadySyncedWeeks.current = union(alreadySyncedWeeks.current, startTime);
    },
    [user.userId, visibleCalendarIds]
  );

  const reSyncCalEvents = useCallback(
    async (calendarIds: string[], startTime: string, endTime: string) => {
      await syncCalEvents({
        userId: user.userId,
        calendarIds,
        startTime,
        endTime,
      });
      alreadySyncedWeeks.current = union(alreadySyncedWeeks.current, startTime);
    },

    [user]
  );

  return { syncAllCalendarEvents, reSyncCalEvents };
};
