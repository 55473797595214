import { FC } from 'react';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import './EnergyPrefSettings.scss';
import {
  useAuthContext,
  useEnergyPreference,
  useUpdateEventCategoryEnergyPref,
} from '../../../../data-access';
import { eventCategoriesForEnergyPref } from '../../../Preference';
import { EventCategoryEnergyPreference } from '@demind-inc/core';
import { EnergyPrefSelectMenu } from './EnergyPrefSelectMenu';
import { defaultEnergyPref } from '../../../../pages';

const EnergyPrefSettings: FC = () => {
  const { user } = useAuthContext();
  const { energyPreferences } = useEnergyPreference({ preferenceId: user.preferenceId! });
  const { updateEventCategoryEnergyPref } = useUpdateEventCategoryEnergyPref();

  const handleChange = async (val: EventCategoryEnergyPreference) => {
    await updateEventCategoryEnergyPref({
      preferenceId: user.preferenceId!,
      preferences: [val],
    });
  };

  return (
    <div className="energy-pref-settings">
      <div className="energy-pref-settings__title">Energy Preferences</div>
      <div className="energy-pref-settings__grid">
        {eventCategoriesForEnergyPref.map((category) => {
          const targetEventPref = energyPreferences.eventCategories?.find(
            ({ categoryId }) => categoryId === category.id
          );
          const defaultEventPref: EventCategoryEnergyPreference = {
            categoryId: category.id,
            categoryName: category.name,
            level: targetEventPref?.level || defaultEnergyPref[category.id] || 'more_energized',
          };

          return (
            <EnergyPrefSelectMenu defaultEventPref={defaultEventPref} onChange={handleChange} />
          );
        })}
      </div>
    </div>
  );
};

export default EnergyPrefSettings;
