import { useMutation, useQueryClient } from '@tanstack/react-query';

import { calendarApi } from '../api';
import { useRecoilState } from 'recoil';
import { eventsSnackBarAtom } from '../recoil';

interface UseSyncGCalendarsListParams {
  userId: string;
  rootEmail: string;
  accessToken?: string;
  refreshToken?: string;
  expiryDate?: string;
  scopes?: string[];
}

export function useSyncGCalendarsList() {
  const queryClient = useQueryClient();
  const [_, setEventsSnackbar] = useRecoilState(eventsSnackBarAtom);

  const syncGCalendarsListMutation = useMutation({
    mutationFn: ({
      userId,
      rootEmail,
      accessToken,
      refreshToken,
      expiryDate,
      scopes,
    }: UseSyncGCalendarsListParams) => {
      return calendarApi
        .syncGCalendarsList(userId, rootEmail, {
          accessToken,
          refreshToken,
          expiryDate,
          scopes,
        })
        .then(({ data }) => data as string[]);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.calendar.meta'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.calendar.events'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.user'] });
    },
    onError: (error) => {
      if (error) {
        setEventsSnackbar('Failed to connect calendars. Please try again later.');
      }
    },
  });

  return {
    syncGCalendarsList: syncGCalendarsListMutation.mutateAsync,
    ...syncGCalendarsListMutation,
  };
}
