import { FC, useMemo } from 'react';
import dayjs from 'dayjs';
import { CalendarEvent, DefaultEventCategoryId } from '@demind-inc/core';
import { Typography } from '@mui/material';
import { colorGreenBase, colorOrangeBase } from '../../constants';
import { useGeneralSettings } from '../../../../hooks';
import { getDefaultCategoryEventIconSet } from '../../../common';
import { round } from 'lodash';
import { ReactComponent as FlameIcon } from '../../../../assets/circadian/energy.svg';
import './EventWithCategory.scss';
import clsx from 'clsx';

interface EventWithCategoryProps {
  event: CalendarEvent;
  color?: string;
  showDate?: boolean;
  score: number;
  className?: string;
}

const EventWithCategory: FC<EventWithCategoryProps> = ({
  event,
  color = colorGreenBase,
  showDate = false,
  score,
  className,
}) => {
  const {
    generalSettings: { timeFormat },
  } = useGeneralSettings();
  const eventTime = useMemo(() => {
    const startTime = dayjs(event.start.date).format(timeFormat === '24h' ? 'HH:mm' : 'h:mm A');
    const endTime = dayjs(event.end.date).format(timeFormat === '24h' ? 'HH:mm' : 'h:mm A');

    const time = `${startTime} - ${endTime}`;
    return showDate ? `${dayjs(event.start.date).format('MMM DD')}, ${time}` : time;
  }, [event, timeFormat]);

  const icon = useMemo(() => {
    return getDefaultCategoryEventIconSet(
      (event.categories?.[0]?.id as DefaultEventCategoryId) ?? 'default_other'
    );
  }, [event]);

  return (
    <div className={clsx('event-with-category', className)}>
      <div className="event-with-category__title-container">
        <div style={{ backgroundColor: color }} className="event-with-category__icon">
          {icon}
        </div>
        <div className="event-with-category__title-group">
          <Typography variant="body2" className="event-with-category__title">{event.summary}</Typography>
          <Typography variant="subtitle2" className="event-with-category__gray-text">
            {eventTime}
          </Typography>
        </div>
      </div>
      <div className="event-with-category__score-container">
        <FlameIcon className="event-with-category__score-container__icon" />
        <Typography variant="body2" color={score >= 0 ? colorGreenBase : colorOrangeBase}>
          {score >= 0 ? '+' : '-'}
          {Math.abs(round(score, 1))}
        </Typography>
      </div>
    </div>
  );
};

export default EventWithCategory;
