import React from 'react';
import clsx from 'clsx';
import './StepProgress.scss';
import CheckIcon from '../../../../assets/onboarding/check.png';

const STEPS = [
  { number: 1, text: 'Drag & drop your task to calendar' },
  { number: 2, text: null },
];

const StepProgress = ({ step }: { step: number }) => {
  return (
    <div className="stepper">
      {STEPS.map((item, index) => (
        <div key={item.number} className="stepper__container">
          <div className="stepper__steps">
            <div
              className={clsx('stepper__circle', {
                'stepper__circle--active': step > index,
                'stepper__circle--inactive': step <= index,
              })}
            >
              {item.text ? (
                item.number
              ) : (
                <img src={CheckIcon} alt="Checkmark" draggable="false" className="stepper__check" />
              )}
            </div>
            {index < STEPS.length - 1 && (
              <div
                className={clsx('stepper__line', {
                  'stepper__line--active': step > index + 1,
                })}
              />
            )}
          </div>
          {item.text && (
            <p
              className={clsx('stepper__text', {
                'stepper__text--active': step > index,
                'stepper__text--inactive': step <= index,
              })}
            >
              {item.text}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

export default StepProgress;
