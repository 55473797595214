import { EnergyFeedbackType } from '@demind-inc/core';
import { EnergyLevelType } from '../../data-access';
import { getCssVariable } from '../../utils';

const COLOR_RED = getCssVariable('--color-red-base') || '#f34720';
const COLOR_GREEN = getCssVariable('--color-green-base') || '#09c25a';
const COLOR_YELLOW = getCssVariable('--color-yellow-base') || '#fdc913';
const COLOR_BLUE = getCssVariable('--color-blue-base') || '#3a92fa';

export const energyFeedbackLabelSet: EnergyFeedbackType[] = [
  'low',
  'mild',
  'medium',
  'strong',
  'high',
];

// @link: https://www.notion.so/demind-inc/Feature-Title-options-on-the-Popup-on-home-view-c3f12774416a46298da069c87fb039ee
export const energyBarSet: Record<
  EnergyLevelType,
  { color: string; text: string; action: string }
> = {
  low: {
    color: COLOR_RED,
    text: 'Low Energy',
    action: 'Time for energy boosts',
  },
  normal: {
    color: COLOR_YELLOW,
    text: 'Medium Energy',
    action: 'Time for easy tasks',
  },
  high: {
    color: COLOR_GREEN,
    text: 'High Energy',
    action: 'Time for demanding tasks',
  },
  sleep: {
    color: COLOR_BLUE,
    text: 'Sleep',
    action: 'Good night :)',
  },
  wind_down: {
    color: COLOR_RED,
    text: 'Wind Down',
    action: 'Time for relaxing activities',
  },
};
