import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';

  import { dataTrendPeriodAtom, getWeekDatesOf, selectedDateAtom, useAuthContext, useEnergyDailyTrend, useEnergyWeeklyTrend } from '../data-access';

export const useEnergyTrend = () => {
  const dataTrendPeriod = useRecoilValue(dataTrendPeriodAtom);
  const selectedDate = useRecoilValue(selectedDateAtom);
  const { user } = useAuthContext();

  const { energyTrend: energyDailyTrend, isLoading: isDailyLoading } = useEnergyDailyTrend({
    dataTrendId: user.dataTrendId!,
    date: selectedDate.format('YYYY-MM-DD'),
    enabled: dataTrendPeriod === 'daily',
  });

  const weekEdge = useMemo(() => {
    const week = getWeekDatesOf(selectedDate);
    return {
      startDate: week[0],
      endDate: week[week.length - 1],
    };
  }, [selectedDate]);

  const { energyTrend: energyWeeklyTrend, isLoading: isWeeklyLoading } = useEnergyWeeklyTrend({
    dataTrendId: user.dataTrendId!,
    startDate: weekEdge.startDate.format('YYYY-MM-DD'),
    endDate: weekEdge.endDate.format('YYYY-MM-DD'),
    enabled: dataTrendPeriod === 'weekly',
  });

  const isLoading = dataTrendPeriod === 'daily' ? isDailyLoading : isWeeklyLoading;

  return {
    dataTrendPeriod,
    isLoading,
    energyDailyTrend,
    energyWeeklyTrend,
  };
};
