import dayjs from 'dayjs';
import { orderBy } from 'lodash';
import React, { FC, useMemo } from 'react';
import { FluctuationSetByReasons, useAuthContext } from '../../../../../data-access';
import {
  transformEventEmaFluctuationItem,
  transformPhaseEmaFluctuationItem,
  transformSleepFluctuationItem,
} from './helpers';
import { useGeneralSettings } from '../../../../../hooks/useGeneralSettings';
import { EnergyFluctuateItemWrapper } from './EnergyFluctuateItemWrapper';
import './EnergyFluctuations.scss';
interface EnergyFluctuationsProps {
  selectedDateEnergyFluctuations: FluctuationSetByReasons;
}

const EnergyFluctuations: FC<EnergyFluctuationsProps> = ({ selectedDateEnergyFluctuations }) => {
  const {
    generalSettings: { timeFormat },
  } = useGeneralSettings();
  const { user } = useAuthContext();

  const transformedFluctuationItems = useMemo(() => {
    const orderedFluctuationItems = orderBy(
      Object.values(selectedDateEnergyFluctuations).flat(),
      (item) => dayjs(item.eventTime.endTime ?? item.eventTime.startTime),
      'asc'
    );

    return orderedFluctuationItems
      .filter((item) => !!item.fluctuateScore)
      .map((item) => {
        switch (item.reason) {
          case 'sleep': {
            return transformSleepFluctuationItem(item, user.chronotype, timeFormat === '24h');
          }
          case 'phaseEma': {
            return transformPhaseEmaFluctuationItem(item, timeFormat === '24h');
          }
          case 'event': {
            return transformEventEmaFluctuationItem(item, timeFormat === '24h');
          }
          default:
            return;
        }
      })
      .filter((i) => !!i);
  }, [selectedDateEnergyFluctuations]);
  return (
    <div className="energy-fluctuations-container">
      {transformedFluctuationItems.map((item) => (
        <EnergyFluctuateItemWrapper key={`${item.title}-${Math.random()}`} item={item} />
      ))}
    </div>
  );
};

export default EnergyFluctuations;
