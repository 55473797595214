import { useCallback, useEffect, FC, useRef, useState } from 'react';
import { uniqueId } from 'lodash';
import { DragFromOutsideItemArgs } from 'react-big-calendar/lib/addons/dragAndDrop';
import dayjs from 'dayjs';
import { TaskItem } from '@demind-inc/core';
import { useDrop } from 'react-dnd';

import './PracticeCalendar.scss';
import { MemoizedCalendar, RBCEvent } from '../../CalendarView';
import { convertTaskToEvent } from '../../../helpers';
import { Snackbar } from '@mui/material';
import clsx from 'clsx';

interface PracticeCalendarProps {
  originalEvents?: RBCEvent[];
  onFinishDrop?: () => void;
  className?: string;
}

const PracticeCalendar: FC<PracticeCalendarProps> = ({
  originalEvents = [],
  onFinishDrop = () => void 0,
  className,
}) => {
  const [draggedEvent, setDraggedEvent] = useState<Partial<RBCEvent> | null>(null);
  const [events, setEvents] = useState<RBCEvent[]>([]);
  const [draggedTask, setDraggedTask] = useState<TaskItem>();
  const scrollToTime = useRef<Date>(dayjs().toDate());
  const dragFromOutsideItem = useCallback(() => draggedEvent, [draggedEvent]);
  const hasDraggedEvent = useRef(false);
  const [snackBar, setSnackBar] = useState('');

  useEffect(() => {
    if (hasDraggedEvent.current || !draggedTask) {
      return;
    }
    setDraggedEvent({
      title: draggedTask.name,
      eventId: 'dragging',
    });
  }, [draggedTask]);

  useEffect(() => {
    setEvents(originalEvents);
  }, [originalEvents]);

  const handleDropEventFromOutside = (data: DragFromOutsideItemArgs) => {
    setSnackBar('');
    const eventFromTask = draggedTask ? convertTaskToEvent(draggedTask) : {};

    const newEventRBC = {
      ...eventFromTask,
      start: new Date(dayjs(data.start).toISOString()),
      end: new Date(dayjs(data.end).toISOString()),
      title: draggedTask?.name,
      eventId: uniqueId('event-'),
      color: '#680ddb',
      calendarId: uniqueId(),
    } as RBCEvent;

    scrollToTime.current = dayjs(data.start).subtract(10, 'minutes').toDate();

    setEvents((prev) => [...prev, newEventRBC]);
    onFinishDrop();
  };

  const [_, dropTaskOnCalendar] = useDrop(() => ({
    accept: 'TASK',
    hover: (item: TaskItem) => setDraggedTask(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
    drop: () => setDraggedTask(undefined),
  }));

  return (
    <div ref={dropTaskOnCalendar} className={clsx('dummy-calendar', className)}>
      <MemoizedCalendar
        ref={dropTaskOnCalendar}
        events={events}
        hasNoSleepData={true}
        onEventDrop={() => {}}
        onEventResize={() => {}}
        onSelectEvent={() => {}}
        onDropEventFromOutside={handleDropEventFromOutside}
        onSelectSlot={() => {}}
        dragFromOutsideItem={dragFromOutsideItem}
        scrollToTime={scrollToTime.current}
      />
      <Snackbar
        open={!!snackBar}
        autoHideDuration={6000}
        onClose={() => setSnackBar('')}
        message={snackBar}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
      />
    </div>
  );
};

export default PracticeCalendar;
