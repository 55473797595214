import clsx from 'clsx';
import React, { useState } from 'react';
import { TodoIntegration, TodoIntegrationType } from '@demind-inc/core';
import { Add, ArrowDropDown } from '@mui/icons-material';

import { SIDEBAR_ICONS } from '../constants';
import './TodoList.scss';
import { ConnectTodosDropdown } from '../../TodoIntegration';
import { useDropdownState, useStartTodoAuth } from '../../../hooks';
import { TodoListTitle } from './TodoListTitle';
import {
  useAuthContext,
  useRemoveTodoIntegration,
  useSetTodoProjectVisibility,
  useSyncTodoProjects,
  useSyncTodosApi,
  useTodoTasksContext,
} from '../../../data-access';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { TodoListProject } from './TodoListProject';

interface TodoListProps {
  className?: string;
}

const TodoList: React.FC<TodoListProps> = ({ className }) => {
  const [disconnectingTodoType, setDisconnectingTodoType] = useState<TodoIntegrationType | null>(
    null
  );
  const [syncingTodoType, setSyncingTargetType] = useState<TodoIntegrationType | null>(null);

  const { anchorEl, handleCloseDropdown, handleOpenDropdown } = useDropdownState();
  const { user } = useAuthContext();
  const { connectedTodos, handleOpenTodoAuth, generatingTodoAuth } = useStartTodoAuth({});
  const { removeTodoIntegration } = useRemoveTodoIntegration();
  const { syncTodoProjects } = useSyncTodoProjects();
  const { todoProjects } = useTodoTasksContext();
  const { reSyncTasks } = useSyncTodosApi();
  const { setTodoProjectVisibility } = useSetTodoProjectVisibility();

  const hanelDeleteIntegration = async (targetTodo: TodoIntegration) => {
    setDisconnectingTodoType(targetTodo.source);
    await removeTodoIntegration({
      userId: user.userId,
      todoIntegrationId: targetTodo.todoIntegrationId,
    });
    setDisconnectingTodoType(null);
  };

  const hanelSyncIntegration = async (targetTodo: TodoIntegration) => {
    setSyncingTargetType(targetTodo.source);
    const newProjectIds = await syncTodoProjects({
      userId: user.userId,
      todoType: targetTodo.source,
    });
    await reSyncTasks(newProjectIds, true);
    setSyncingTargetType(null);
  };

  return (
    <>
      <div className={clsx('todos', className)}>
        {connectedTodos.map((targetTodo) => (
          <Accordion key={targetTodo.todoIntegrationId} className="todos__accordion" elevation={0}>
            <AccordionSummary expandIcon={<ArrowDropDown className="todos__more__icon" />}>
              <TodoListTitle
                key={targetTodo.source}
                iconSource={SIDEBAR_ICONS[targetTodo.source]}
                todoType={targetTodo.source}
                isDisconnecting={disconnectingTodoType === targetTodo.source}
                isSyncing={syncingTodoType === targetTodo.source}
                onSyncIntegration={() => hanelSyncIntegration(targetTodo)}
                onDeleteIntegration={() => hanelDeleteIntegration(targetTodo)}
              />
            </AccordionSummary>
            <AccordionDetails className="todos__accordion__projects">
              {todoProjects
                .filter((p) => p.appFrom === targetTodo.source)
                .map((project) => (
                  <TodoListProject
                    key={project.todoTaskId}
                    project={project}
                    onSelect={(selected) =>
                      setTodoProjectVisibility({ boardId: project.todoTaskId, closed: !selected })
                    }
                    defaultSelected={!project.closed}
                  />
                ))}
            </AccordionDetails>
          </Accordion>
        ))}
        <button aria-label="Add todos" onClick={handleOpenDropdown} className="todos__button">
          <div className="todos__button__icon">
            <Add />
          </div>
          <div className="todos__button__text">TODOs</div>
        </button>
      </div>
      <ConnectTodosDropdown
        anchorEl={anchorEl}
        onClose={handleCloseDropdown}
        onClickItem={handleOpenTodoAuth}
        generatingTodoAuth={generatingTodoAuth}
        connectedTodos={connectedTodos}
      />
    </>
  );
};

export default TodoList;
