import { useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import { useRecoilState } from 'recoil';

import { useAuthContext } from '../context';
import { useSyncTodoTasksByProjects } from '../mutations';
import { isSyncingTodosAtom } from '../recoil';
import { chunk } from 'lodash';

const SYNC_TODO_CHUNK_SIZE = 7;

export const useSyncTodosApi = () => {
  const { user } = useAuthContext();
  const { syncTodoTasks, isPending: isSyncingTodos } = useSyncTodoTasksByProjects();
  const [_, setIsSyncingTodos] = useRecoilState(isSyncingTodosAtom);

  useEffect(() => {
    setIsSyncingTodos(isSyncingTodos);
  }, [isSyncingTodos]);

  const syncAllTodoTasks = useCallback(() => {
    if (!user.userId || !user.todoTasksBoardIds?.length) {
      return;
    }

    chunk(user.todoTasksBoardIds, SYNC_TODO_CHUNK_SIZE).forEach((projectIds) => {
      syncTodoTasks({
        userId: user.userId,
        timezone: dayjs.tz.guess(),
        projectIds,
      });
    });
  }, [user.userId, user.todoTasksBoardIds]);

  const reSyncTasks = useCallback(
    async (todoTasksBoardIds: string[], fullSync?: boolean) => {
      await syncTodoTasks({
        userId: user.userId,
        projectIds: todoTasksBoardIds,
        timezone: dayjs.tz.guess(),
        fullSync,
      });
    },
    [user]
  );

  return { syncAllTodoTasks, reSyncTasks };
};
