import { FC, useCallback } from 'react';
import { getWeekDatesOf, selectedDateAtom, useMetricsContext } from '../../../data-access';
import dayjs, { Dayjs } from 'dayjs';
import './WeekDatesSwitcher.scss';
import { useRecoilState } from 'recoil';
import clsx from 'clsx';
import { Typography } from '@mui/material';

const WeekDatesSwitcher: FC = () => {
  const [selectedDate, setSelectedDate] = useRecoilState(selectedDateAtom);
  const weekDates = getWeekDatesOf(selectedDate);
  const { getSleepOnDate } = useMetricsContext();
  const getHasData = useCallback(
    (date: Dayjs) => !getSleepOnDate(date).hasNoSleepData,
    [getSleepOnDate]
  );

  return (
    <div className="week-dates-switcher">
      {weekDates.map((date) => (
        <div
          className={clsx(
            'week-dates-switcher__date',
            date.isSame(selectedDate, 'day') && 'week-dates-switcher__date--active'
          )}
          onClick={() => setSelectedDate(date)}
          key={date.toISOString()}
        >
          <Typography className="week-dates-switcher__date__day" variant="body2">
            {date.format('dd').slice(0, 1)}
          </Typography>
          <Typography className="week-dates-switcher__date__day" variant="body2">
            {date.format('DD')}
          </Typography>
          {getHasData(date) && <div className="week-dates-switcher__date__indicator" />}
        </div>
      ))}
    </div>
  );
};

export default WeekDatesSwitcher;
