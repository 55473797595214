import { useQuery } from '@tanstack/react-query';
import { CalendarPreference } from '@demind-inc/core';

import { calendarApi } from '../api';

interface UseCalendarPreferenceParams {
  preferenceId: string;
}

interface UseCalendarPreferenceQueryParams {
  queryKey: ['lifestack.preference.calendar', UseCalendarPreferenceParams];
}

export const useCalendarPreference = ({ preferenceId }: UseCalendarPreferenceParams) => {
  const calendarPrefQuery = useQuery({
    queryKey: ['lifestack.preference.calendar', { preferenceId }],
    queryFn: ({ queryKey }: UseCalendarPreferenceQueryParams) => {
      const [, { preferenceId }] = queryKey;
      return calendarApi
        .getCalendarPreference(preferenceId)
        .then(({ data }) => data as CalendarPreference);
    },
    refetchOnWindowFocus: false,
    staleTime: 15000, // 15s
    enabled: !!preferenceId,
  });

  return {
    calendarPreferences: calendarPrefQuery.data ?? {},
    ...calendarPrefQuery,
  };
};
