import { useEffect, useMemo, useState } from 'react';

import { allTypeProjectFilter, projectFilterAtom, useTodoTasksContext } from '../data-access';
import { ProjectFilterItem } from './types';
import { useRecoilState } from 'recoil';
import { trackEventMixpanel } from '../utils';

export const useProjectFilter = () => {
  const { todoProjects } = useTodoTasksContext();
  const [selectedProjectFilters, setSelectedProjectFilters] = useRecoilState(projectFilterAtom);

  const filterProjectOptions = useMemo(() => {
    const optionsFromDB = todoProjects
      .filter((p) => !p.closed)
      .map(
        (project) =>
          ({
            todoTaskId: project.todoTaskId,
            name: project.name,
            appFrom: project.appFrom,
          }) as ProjectFilterItem
      );
    return [allTypeProjectFilter, ...optionsFromDB];
  }, [todoProjects]);

  const handleSelectFilterProject = (projectId: string) => {
    trackEventMixpanel('select_project', { projectId });

    if (projectId === 'all') {
      setSelectedProjectFilters([allTypeProjectFilter]);
      return;
    }

    const project = filterProjectOptions.find(
      (p) => p.todoTaskId === projectId
    ) as typeof allTypeProjectFilter;

    const currentFilters = selectedProjectFilters.filter((p) => p.todoTaskId !== 'all');
    const isProjectSelected = currentFilters.some((p) => p.todoTaskId === projectId);

    let newSelectedFilters = isProjectSelected
      ? currentFilters.filter((p) => p.todoTaskId !== projectId)
      : [...currentFilters, project];

    if (newSelectedFilters.length === 0) {
      newSelectedFilters = [allTypeProjectFilter];
    }

    setSelectedProjectFilters(newSelectedFilters);
  };

  return {
    selectedFilters: selectedProjectFilters,
    filterProjectOptions,
    handleSelectFilterProject,
  };
};
