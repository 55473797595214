import clsx from 'clsx';
import { FC, useState } from 'react';
import { useAuthContext, useDiagnoseChronotype } from '../../../data-access';

import { useSleepTrend } from '../../../hooks/useSleepTrend';
import { PieChart } from '@mui/x-charts';
import { getCssVariable, trackEventMixpanel } from '../../../utils';
import './DataSleepDashboard.scss';
import { CircularProgress, Modal, Typography } from '@mui/material';
import { ReactComponent as ChronoIcon } from '../../../assets/actions/chronotype.svg';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import {
  chronotypeTitleSet,
  CustomTooltip,
  CustomTooltipContent,
  SleepBarAndInfo,
  SleepBarChart,
} from '../../../components';
import { LoadingButton } from '@mui/lab';
import { chronotypeTooltipInfo } from './constants';

interface DataSleepDashboardProps {
  className?: string;
}

const colorPurpleTaroLight = getCssVariable('--color-purple-taro-light');
const colorBlack = getCssVariable('--color-black');
const ONE_DAY_MINUTES = 24 * 60;

const DataSleepDashboard: FC<DataSleepDashboardProps> = ({ className }) => {
  const { user } = useAuthContext();
  const hasChronotype = !!user.chronotype;
  const {
    diagnoseChronotype: diagnoseChronotypeMutation,
    error,
    isPending: isDiagnosing,
  } = useDiagnoseChronotype();
  const {
    targetSleepStage,
    dataTrendPeriod,
    targetSleepHours,
    targetAwakeTime,
    isLoading,
    targetSleepTime,
    hasNoSleepStage,
    dataByDate,
  } = useSleepTrend();
  const isWeekly = dataTrendPeriod === 'weekly';

  const diagnoseChronotype = async () => {
    if (!user.metricId || !user.userId) {
      return;
    }

    await diagnoseChronotypeMutation({ userId: user.userId, metricId: user.metricId });
    trackEventMixpanel('diagnose_chronotype');
  };

  if (isLoading) {
    return (
      <div className="data-sleep-dashboard__box">
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className={clsx('data-sleep-dashboard', className)}>
      <div className="data-sleep-dashboard__box">
        <div className="data-sleep-dashboard__title-container">
          <Typography variant="h2" className="data-sleep-dashboard__title">
            {targetSleepHours.hours}h {targetSleepHours.minutes}m
          </Typography>
          <Typography variant="subtitle2" className="data-sleep-dashboard__subtitle">
            {isWeekly && 'Average'} Sleep Duration
          </Typography>
        </div>
        <PieChart
          series={[
            {
              data: [
                {
                  id: 0,
                  value: targetSleepHours.hours * 60 + targetSleepHours.minutes,
                  color: colorBlack,
                },
                {
                  id: 1,
                  value: ONE_DAY_MINUTES - (targetSleepHours.hours * 60 + targetSleepHours.minutes),
                  color: colorPurpleTaroLight,
                },
              ],
            },
          ]}
          width={420}
          height={96}
          tooltip={{ trigger: 'none' }}
          className="data-sleep-dashboard__pie-chart"
        />
      </div>
      {isWeekly && (
        <SleepBarChart
          className="data-sleep-dashboard__box"
          dataByDate={dataByDate}
          targetSleepStage={targetSleepStage}
          targetSleepHours={targetSleepHours}
          hasNoSleepStage={hasNoSleepStage}
        />
      )}
      <div
        className={clsx(
          'data-sleep-dashboard__box',
          !hasChronotype && 'data-sleep-dashboard__no-chrono'
        )}
      >
        <div className="data-sleep-dashboard__chrono-container">
          <div>
            <div className="data-sleep-dashboard__chrono-container__title">
              <ChronoIcon />
              <Typography variant="body1">
                {hasChronotype ? chronotypeTitleSet[user.chronotype!] : 'Chronotype'}
              </Typography>

              <CustomTooltip
                title={<CustomTooltipContent {...chronotypeTooltipInfo} />}
                className="data-sleep-dashboard__chronotype__tooltip"
              >
                <HelpOutlineOutlinedIcon className="data-sleep-dashboard__chronotype__tooltip__icon" />
              </CustomTooltip>
            </div>
            {!hasChronotype && (
              <Typography
                variant="body2"
                className="data-sleep-dashboard__chrono-container__has-no-chrono-description"
              >
                To help adjust your circadian rhythm, let's determine your sleep type.
              </Typography>
            )}
          </div>
        </div>
        {!hasChronotype && (
          <div>
            <LoadingButton
              disabled={isDiagnosing}
              loading={isDiagnosing}
              onClick={diagnoseChronotype}
              variant="contained"
              className="data-sleep-dashboard__chrono-container__diagnose-button"
            >
              <Typography variant="body2"> Diagnose</Typography>
            </LoadingButton>
            {!!error && <Typography>*No sleep data</Typography>}
          </div>
        )}
      </div>
      {!isWeekly && (
        <SleepBarAndInfo
          targetSleepStage={targetSleepStage}
          targetAwakeTime={targetAwakeTime}
          targetSleepTime={targetSleepTime}
          hasNoSleepStage={hasNoSleepStage}
          className="data-sleep-dashboard__box"
        />
      )}
    </div>
  );
};

export default DataSleepDashboard;
