import { CalendarEvent } from '@demind-inc/core';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(utc);
dayjs.extend(weekday);

type LifestackOriginEventProps = Omit<
  CalendarEvent,
  | 'eventId'
  | 'updatedDate'
  | 'createdDate'
  | 'start'
  | 'end'
  | 'summary'
  | 'description'
  | 'location'
  | 'status'
  | 'calendarId'
  | 'color'
  | 'organizer'
  | 'visibility'
  | 'showAs'
  | 'mtgLink'
  | 'rawJson'
  | 'eventType'
  | 'attendees'
  | 'isAllDay'
>;

/**
 * Calculates the UTC start and end time of a given date.
 * @param date - The date for which to calculate the start and end time.
 * @param options - Optional parameters.
 * @param options.utc - If true, the date will be treated as UTC.
 * @returns An object containing the UTC start and end time of the given date.
 */
export const getUTCStartEndTimeOfDay = (date: Dayjs, options?: { utc?: boolean }) => {
  let formattedDate = date;
  if (options?.utc) {
    formattedDate = date.utc();
  }
  const startTime = formattedDate
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)
    .set('milliseconds', 0);
  const endTime = formattedDate
    .add(1, 'day')
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)
    .set('milliseconds', 0);

  return { startTime, endTime };
};

/**
 * Returns the start and end dates for a week centered around the given date.
 * @param date - The date around which the week is centered.
 * @returns An object containing the start and end dates in Dayjs format.
 */
export const getWeekEdgesDateOf = (date: Dayjs) => {
  const startDate = date
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)
    .set('milliseconds', 0)
    .weekday(0);
  const endDate = date
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)
    .set('milliseconds', 0)
    .weekday(7);

  return { startDate, endDate };
};

/**
 * Returns the start and end dates for a month centered around the given date.
 * @param date - The date around which the month is centered.
 * @returns An object containing the start and end dates in Dayjs format.
 */
export const getMonthEdgesDatesOf = (date: Dayjs) => {
  const targetMonth = date.get('month');

  const startDate = date
    .set('date', 1)
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)
    .set('milliseconds', 0);

  const endDate = date
    .set('month', targetMonth + 1)
    .set('date', 1)
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)
    .set('milliseconds', 0);

  return { startDate, endDate };
};

/**
 * Creates a new CalendarEvent object with the supplied properties.
 * If any property is missing, it will be set to an empty string.
 *
 * @param event - The partial CalendarEvent object containing the properties to be set.
 * @returns A new CalendarEvent object with the supplied properties.
 */
export const supplyCreateEventOption = (
  calendarId: string,
  event: Partial<CalendarEvent>
): CalendarEvent => {
  return {
    ...event,
    calendarId,
    eventId: event.eventId || 'creating',
    summary: event.summary || '',
    start: {
      date: event.start?.date || '',
      timeZone: event.start?.timeZone || '',
    },
    end: {
      date: event.end?.date || '',
      timeZone: event.end?.timeZone || '',
    },
    updatedDate: event.updatedDate || dayjs().toISOString(),
    createdDate: event.createdDate || dayjs().toISOString(),
    status: 'confirmed',
    color: event.color || '',
  };
};

export const pickLifestackOriginEventsProps = (
  event: Partial<CalendarEvent>
): LifestackOriginEventProps => {
  const newEvent: Partial<LifestackOriginEventProps> = {};

  if (event.categories) {
    newEvent.categories = event.categories;
  }
  if (event.eventMetrics) {
    newEvent.eventMetrics = event.eventMetrics;
  }
  if (event.recommendedTaskId) {
    newEvent.recommendedTaskId = event.recommendedTaskId;
  }
  if (event.taskFrom) {
    newEvent.taskFrom = event.taskFrom;
  }
  if (event.activityFrom) {
    newEvent.activityFrom = event.activityFrom;
  }
  if (event.scheduleFrom) {
    newEvent.scheduleFrom = event.scheduleFrom;
  }

  return newEvent as LifestackOriginEventProps;
};
