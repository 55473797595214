import { useMutation } from '@tanstack/react-query';

import { accountApi } from '../api';
import { Discount } from '@demind-inc/core';
import { useState } from 'react';
import { AxiosError } from 'axios';

interface UseVerifyDiscountCodeParams {
  code: string;
}

export const useVerifyDiscountCode = () => {
  const [error, setError] = useState<string>();

  const verifyDiscountCodeMutation = useMutation({
    mutationFn: ({ code }: UseVerifyDiscountCodeParams) => {
      return accountApi.verifyDiscountCode(code).then(({ data }) => data as Discount);
    },
    onMutate: () => {
      setError('');
    },
    onError: (err) => {
      const errorData = (err as AxiosError).response?.data;
      const errorMessage = (errorData as any)?.error;

      if (errorMessage) {
        setError(errorMessage);
      }
    },
  });

  return {
    verifyDiscountCode: verifyDiscountCodeMutation.mutateAsync,
    ...verifyDiscountCodeMutation,
    error,
  };
};
