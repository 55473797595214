import { CustomTooltipContent } from '../../../common';

export const AISchedulerTooltipContent = (
  <CustomTooltipContent
    title={'🤖 AI Scheduler'}
    description="Timebox your day in one click using the AI Scheduler. Lifestack will suggest an optimal sequence of events considering your preferences and energy for the day."
    learnMoreLink="https://www.notion.so/demind-inc/How-Lifestack-Works-7fd29b5e0bc649cc859a51487ea38805?pvs=4#eae83422d2db4db2adfc434ba73b66d5"
  />
);

export const EnergyBoostTooltipContent = (
  <CustomTooltipContent
    title={'⚡️ Energy Boost'}
    description="Automatically schedules short activities that are meant to help the user gain energy when they are predicted to have lower energy."
    learnMoreLink="https://demind-inc.notion.site/How-To-Use-Energy-Boosts-82183479498a456b92110b6f7a6b1b12?pvs=74"
  />
);
