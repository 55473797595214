import { Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FC, useState } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import './AISchedulerPracticeModal.scss';
import AISchedulerPracticeSetup from '../AISchedulerPracticeSetup';
import { AISchedulerPracticeResult } from '../AISchedulerPracticeResult';
import { CalendarProvider, TodoTasksProvider } from '../../../../data-access';
import { CircadianProvider } from '../../../../data-access';

interface AISchedulerPracticeModalProps {
  visible: boolean;
  onFinish: () => void;
  onClose: () => void;
}

type PracticeStep = 'setup' | 'results';

const AISchedulerPracticeModal: FC<AISchedulerPracticeModalProps> = ({
  visible,
  onFinish,
  onClose,
}) => {
  const [currentStep, setCurrentStep] = useState<PracticeStep>('setup');

  return (
    <TodoTasksProvider>
      <CircadianProvider>
        <CalendarProvider>
          <DndProvider backend={HTML5Backend}>
            <Modal open={visible} className="ai-scheduler-practice-modal">
              <div className="ai-scheduler-practice-modal__container">
                {currentStep === 'setup' && (
                  <>
                    <CloseIcon className="ai-scheduler-practice-modal__close" onClick={onClose} />
                    <AISchedulerPracticeSetup onComplete={() => setCurrentStep('results')} />
                  </>
                )}
                {currentStep === 'results' && <AISchedulerPracticeResult onFinish={onFinish} />}
              </div>
            </Modal>
          </DndProvider>
        </CalendarProvider>
      </CircadianProvider>
    </TodoTasksProvider>
  );
};

export default AISchedulerPracticeModal;
