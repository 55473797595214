import { useMutation, useQueryClient } from '@tanstack/react-query';

import { metricsApi } from '../api';

interface UseSyncExerciseDetailsParams {
  metricId: string;
  terraUserIds: string[];
  startDate: string;
  endDate: string;
}

export function useSyncExerciseDetails() {
  const queryClient = useQueryClient();

  const syncExerciseDetailMutation = useMutation({
    mutationFn: ({ metricId, terraUserIds, startDate, endDate }: UseSyncExerciseDetailsParams) => {
      return metricsApi.syncExercise(metricId, { terraUserIds, startDate, endDate });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.exercise.details'] });
    },
  });

  return {
    syncExerciseDetail: syncExerciseDetailMutation.mutateAsync,
    ...syncExerciseDetailMutation,
  };
}
