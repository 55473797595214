import { DefaultEventCategoryId } from '@demind-inc/core';
import { getCssVariable } from '../../utils';

import { ReactComponent as MeetingIcon } from '../../assets/calendar/category/meeting.svg';
import { ReactComponent as OtherIcon } from '../../assets/calendar/category/other.svg';
import { ReactComponent as MealIcon } from '../../assets/calendar/category/meal.svg';
import { ReactComponent as ExerciseIcon } from '../../assets/calendar/category/exercise.svg';
import { ReactComponent as SocialIcon } from '../../assets/calendar/category/social.svg';
import { ReactComponent as DeepWorkIcon } from '../../assets/calendar/category/deep_work.svg';
import { ReactComponent as LightTaskIcon } from '../../assets/calendar/category/light_task.svg';
import { ReactComponent as BreakIcon } from '../../assets/calendar/category/break.svg';

const whiteColor = getCssVariable('--color-white-lightest');

export const getDefaultCategoryEventIconSet = (
  categoryId: DefaultEventCategoryId,
  size = 18,
  color = getCssVariable('--color-white-lightest')
): JSX.Element => {
  switch (categoryId) {
    case 'default_mtg': {
      return <MeetingIcon style={{ color , width: size, height: size}} />;
    }
    case 'default_break': {
      return <BreakIcon style={{ width: size, height: size, color }} />;
    }
    case 'default_deepWork': {
      return <DeepWorkIcon style={{ width: size, height: size, color }} />;
    }
    case 'default_lightTask': {
      return <LightTaskIcon style={{ width: size, height: size, color }} />;
    }
    case 'default_social': {
      return <SocialIcon style={{ width: size, height: size, color }} />;
    }
    case 'default_meal': {
      return <MealIcon style={{ width: size, height: size, color }} />;
    }
    case 'default_exercise': {
      return <ExerciseIcon style={{ width: size, height: size, color }} />;
    }
    case 'default_other': {
      return <OtherIcon style={{ width: size, height: size, color }} />;
    }
    default: {
      return <OtherIcon style={{ width: size, height: size, color }} />;
    }
  }
};
