import { atom } from 'recoil';
import { ProjectFilterItem } from '../../hooks/types';
import { defaultTaskFilterOptions } from '../constants';
import { TaskFilterOptions } from '../types';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const allTypeProjectFilter: ProjectFilterItem = {
  todoTaskId: 'all',
  name: 'All',
  appFrom: 'all',
};

export const isSyncingTodosAtom = atom<boolean>({
  key: 'isSyncingTodos',
  default: false,
});

export const projectFilterAtom = atom<ProjectFilterItem[]>({
  key: 'projectFilter',
  default: [allTypeProjectFilter],
  effects_UNSTABLE: [persistAtom],
});

export const taskFilterAtom = atom<TaskFilterOptions>({
  key: 'taskFilter',
  default: defaultTaskFilterOptions,
  effects_UNSTABLE: [persistAtom],
});
