import { SchedulerPreference } from '@demind-inc/core';
import {
  CalcTimeFrame,
  getDiffMinBetweenHHmm,
  transformHHmmToDayjs,
  transformMinToDayjs,
} from '../../../data-access';
import { initialDefaultPrefItemState, PrefItemState } from './prefItems.state';
import { SchedulerPrefTimeRange, SchedulerPrefDurationRange } from './types';
import { cloneDeep } from 'lodash';

export const getTimeRangePickedDateValues = (timeRange?: SchedulerPrefTimeRange) => ({
  defaultPickedDate: timeRange ? transformHHmmToDayjs(timeRange.time) : undefined,
  minimumPickedDate: timeRange ? transformHHmmToDayjs(timeRange.minimumTime) : undefined,
  maximumPickedDate: timeRange ? transformHHmmToDayjs(timeRange.maximumTime) : undefined,
});

export const getTimeRangePickedDurationValues = (duration?: SchedulerPrefDurationRange) => ({
  defaultPickedDate: duration ? transformMinToDayjs(duration.defaultMin) : undefined,
  minimumPickedDate: duration ? transformMinToDayjs(duration.minimumDurationMin) : undefined,
  maximumPickedDate: duration ? transformMinToDayjs(duration.maximumDurationMin) : undefined,
});

export const transformPrefEditStateToPref = (prefEditState: PrefItemState): SchedulerPreference => {
  return {
    workTime: {
      start: prefEditState.workTime.start.time,
      end: prefEditState.workTime.end.time,
    },
    deepWork: {
      durationMin: prefEditState.deepWork.duration.durationMin,
    },
    lightWork: {
      durationMin: prefEditState.lightWork.duration.durationMin,
    },
    lunch: {
      start: prefEditState.lunch.start.time,
      end: prefEditState.lunch.end.time,
      durationMin: prefEditState.lunch.duration.durationMin,
      enabled: prefEditState.lunch.enabled,
    },
    workout: {
      start: prefEditState.workout.start.time,
      end: prefEditState.workout.end.time,
      enabled: prefEditState.workout.enabled,
      durationMin: prefEditState.workout.duration.durationMin,
    },
    dinner: {
      start: prefEditState.dinner.start.time,
      end: prefEditState.dinner.end.time,
      durationMin: prefEditState.dinner.duration.durationMin,
      enabled: prefEditState.dinner.enabled,
    },
  };
};

export const transformPrefToPrefeditState = (pref: SchedulerPreference): PrefItemState => {
  return {
    workTime: {
      ...initialDefaultPrefItemState.workTime,
      ...(pref.workTime?.start
        ? {
            start: {
              ...initialDefaultPrefItemState.workTime.start,
              time: pref.workTime.start,
              defaultTime: pref.workTime.start,
            },
          }
        : {}),
      ...(pref.workTime?.end
        ? {
            end: {
              ...initialDefaultPrefItemState.workTime.end,
              time: pref.workTime.end,
              defaultTime: pref.workTime.end,
            },
          }
        : {}),
    },
    deepWork: {
      ...initialDefaultPrefItemState.deepWork,
      ...(pref.deepWork?.durationMin
        ? {
            duration: {
              ...initialDefaultPrefItemState.deepWork.duration,
              durationMin: pref.deepWork.durationMin,
              defaultMin: pref.deepWork.durationMin,
            },
          }
        : {}),
    },
    lightWork: {
      ...initialDefaultPrefItemState.lightWork,
      ...(pref.lightWork?.durationMin
        ? {
            duration: {
              ...initialDefaultPrefItemState.lightWork.duration,
              durationMin: pref.lightWork.durationMin,
              defaultMin: pref.lightWork.durationMin,
            },
          }
        : {}),
    },
    lunch: {
      ...initialDefaultPrefItemState.lunch,
      enabled: !!pref.lunch?.enabled,
      ...(pref.lunch?.start
        ? {
            start: {
              ...initialDefaultPrefItemState.lunch.start,
              time: pref.lunch.start,
              defaultTime: pref.lunch.start,
            },
          }
        : {}),
      ...(pref.lunch?.end
        ? {
            end: {
              ...initialDefaultPrefItemState.lunch.end,
              time: pref.lunch.end,
              defaultTime: pref.lunch.end,
            },
          }
        : {}),
      ...(pref.lunch?.durationMin
        ? {
            duration: {
              ...initialDefaultPrefItemState.lunch.duration,
              durationMin: pref.lunch.durationMin,
              defaultMin: pref.lunch.durationMin,
            },
          }
        : {}),
    },
    workout: {
      ...initialDefaultPrefItemState.workout,
      enabled: !!pref.workout?.enabled,
      ...(pref.workout?.durationMin
        ? {
            duration: {
              ...initialDefaultPrefItemState.workout.duration,
              durationMin: pref.workout.durationMin,
              defaultMin: pref.workout.durationMin,
            },
          }
        : {}),
      ...(pref.workout?.start
        ? {
            start: {
              ...initialDefaultPrefItemState.workout.start,
              time: pref.workout.start,
              defaultTime: pref.workout.start,
            },
          }
        : {}),
      ...(pref.workout?.end
        ? {
            end: {
              ...initialDefaultPrefItemState.workout.end,
              time: pref.workout.end,
              defaultTime: pref.workout.end,
            },
          }
        : {}),
    },
    dinner: {
      ...initialDefaultPrefItemState.dinner,
      enabled: !!pref.dinner?.enabled,
      ...(pref.dinner?.start
        ? {
            start: {
              ...initialDefaultPrefItemState.dinner.start,
              time: pref.dinner.start,
              defaultTime: pref.dinner.start,
            },
          }
        : {}),
      ...(pref.dinner?.end
        ? {
            end: {
              ...initialDefaultPrefItemState.dinner.end,
              time: pref.dinner.end,
              defaultTime: pref.dinner.end,
            },
          }
        : {}),
      ...(pref.dinner?.durationMin
        ? {
            duration: {
              ...initialDefaultPrefItemState.dinner.duration,
              durationMin: pref.dinner.durationMin,
              defaultMin: pref.dinner.durationMin,
            },
          }
        : {}),
    },
  };
};

export const combineAutoAdjustmentPref = (
  timeFrames: CalcTimeFrame,
  originalPref: PrefItemState
) => {
  const newPref = cloneDeep(originalPref);

  if (timeFrames.deepworkDurationMin) {
    newPref.deepWork.duration.durationMin = timeFrames.deepworkDurationMin;
    newPref.deepWork.autoAdjusted =
      timeFrames.deepworkDurationMin !== originalPref.deepWork.duration.durationMin;
  }
  if (timeFrames.lunchDurationMin) {
    newPref.lunch.duration.durationMin = timeFrames.lunchDurationMin;
    newPref.lunch.autoAdjusted =
      timeFrames.lunchDurationMin !== originalPref.lunch.duration.durationMin;
  }

  return newPref;
};

/**
 * Transforms the daily preferences for the run endpoint.
 *
 * @param pref - The preference item state containing the user's daily preferences.
 * @returns An object representing the scheduler preferences.
 */
export const transformDailyPrefForRunEndpoint = (pref: PrefItemState): SchedulerPreference => {
  return {
    workTime: {
      start: pref.workTime.start.time,
      end: pref.workTime.end.time,
    },
    deepWork: {
      durationMin: pref.deepWork.duration.durationMin,
    },
    lightWork: {
      durationMin: pref.lightWork.duration.durationMin,
    },
    lunch: {
      start: pref.lunch.start.time,
      end: pref.lunch.end.time,
      durationMin: pref.lunch.duration.durationMin,
      enabled: pref.lunch.enabled,
    },
    workout: {
      enabled: pref.workout.enabled,
      durationMin: pref.workout.duration.durationMin,
      start: pref.workout.start.time,
      end: pref.workout.end.time,
    },
    dinner: {
      start: pref.dinner.start.time,
      end: pref.dinner.end.time,
      durationMin: pref.dinner.duration.durationMin,
      enabled: pref.dinner.enabled,
    },
  };
};

/**
 * Validates the preference items to ensure their total duration does not exceed work hours
 * and that start times are before end times.
 *
 * @param {PrefItemState} prefItems - The preference items to validate.
 * @returns {string} - An error message if validation fails, otherwise an empty string.
 */
export const validatePreferenceItems = (prefItems: PrefItemState) => {
  const totalDurationMin = Object.entries(prefItems).reduce((acc, [prefKey, prefInfo]) => {
    if (['workTime', 'workout'].includes(prefKey)) {
      // workout should be excluded
      return acc;
    }

    if ('duration' in prefInfo) {
      return acc + prefInfo.duration.durationMin;
    } else if ('start' in prefInfo && 'end' in prefInfo) {
      const durationMin = getDiffMinBetweenHHmm(prefInfo.start.time, prefInfo.end.time);
      return acc + durationMin;
    } else {
      return acc;
    }
  }, 0);
  const workTimeDurationMin = getDiffMinBetweenHHmm(
    prefItems.workTime.start.time,
    prefItems.workTime.end.time
  );

  const isTotalDurationOver = totalDurationMin > workTimeDurationMin;

  if (isTotalDurationOver) {
    return 'It looks like the activities you set exceed your available work hours. Try adjusting the length of your tasks or extending your work hours!';
  }

  const isStartAfterEnd = Object.entries(prefItems).some(([prefKey, prefInfo]) => {
    if (!('start' in prefInfo && 'end' in prefInfo)) {
      return false;
    }
    const isStartAfterEnd = getDiffMinBetweenHHmm(prefInfo.start.time, prefInfo.end.time) <= 0;
    return isStartAfterEnd;
  });

  if (isStartAfterEnd) {
    return 'Start time should be before end time.';
  }
  return '';
};
