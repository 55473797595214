import { DefaultEventCategoryId } from '@demind-inc/core';
import { getDefaultCategoryEventIconSet } from '../../../common';
import { getCssVariable } from '../../../../utils';
import { ReactComponent as MoonSvg } from '../../../../assets/actions/moon.svg';
import { ReactComponent as EmaIcon } from '../../../../assets/calendar/category/ema.svg';

import { FC } from 'react';
import { EventType } from './types';

const whiteColor = getCssVariable('--color-white-lightest');
const secondaryColor = getCssVariable('--color-secondary');
const blueBaseColor = getCssVariable('--color-blue-base');
const redBaseColor = getCssVariable('--color-red-base');

interface CustomMarkerProps {
  cx?: number;
  cy?: number;
  eventType: EventType;
  category: string;
  score: number;
}

export const CustomMarker: FC<CustomMarkerProps> = ({ cx, cy, eventType, category, score }) => {
  let icon = <MoonSvg style={{ width: 16, height: 16, color: whiteColor }} />;
  if (eventType === 'event') {
    icon = getDefaultCategoryEventIconSet(category as DefaultEventCategoryId, 16, whiteColor);
  }
  if (eventType === 'phaseEma') {
    icon = <EmaIcon style={{ width: 16, height: 16, color: whiteColor }} />;
  }

  return (
    <g>
      <circle
        cx={cx}
        cy={cy}
        r={13}
        fill={score >= 0 ? secondaryColor : eventType === 'sleep' ? blueBaseColor : redBaseColor}
        stroke={whiteColor}
      />
      <foreignObject x={cx - 10} y={cy - 10} width={20} height={20}>
        <div className="event-marker">{icon}</div>
      </foreignObject>
    </g>
  );
};
