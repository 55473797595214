import React from 'react';
import { IconButton } from '@mui/material';
import { Sync as SyncIcon } from '@mui/icons-material';
import clsx from 'clsx';

import './ManualSyncButton.scss';

interface ManualSyncButtonProps {
  isMinimized?: boolean;
  className?: string;
  onSync: () => Promise<void>;
  isSyncing: boolean;
}

const ManualSyncButton: React.FC<ManualSyncButtonProps> = ({
  isMinimized = false,
  className,
  onSync,
  isSyncing,
}) => {
  if (isMinimized) {
    return (
      <IconButton
        onClick={onSync}
        disabled={isSyncing}
        className={clsx('manual-sync-button__minimized', className)}
      >
        <SyncIcon
          className={clsx('manual-sync-button__button__icon', {
            'is-rotating': isSyncing,
          })}
        />
      </IconButton>
    );
  }

  return (
    <div className={clsx('manual-sync-button__container', className)}>
      <button onClick={onSync} disabled={isSyncing} className={'manual-sync-button__button'}>
        <SyncIcon
          className={clsx('manual-sync-button__button__icon', { 'is-rotating': isSyncing })}
        />
        <span className="manual-sync-button__button__text">Click here to refresh data</span>
      </button>
    </div>
  );
};

export default ManualSyncButton;
