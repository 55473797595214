import { LineChart } from '@mui/x-charts';
import { FC } from 'react';
import dayjs from 'dayjs';
import { getCssVariable } from '../../../../utils';

interface PeakDipsGraphProps {
  className?: string;
  chartData: number[];
}

const WEEK_INDICES = Array.from({ length: 7 }, (_, i) => i + 1);
const primaryColor = getCssVariable('--color-primary');
const grayColor = getCssVariable('--color-gray-base');

const PeakDipsGraph: FC<PeakDipsGraphProps> = ({ className = '', chartData }) => {
  return (
    <div className={className}>
      <LineChart
        xAxis={[
          {
            data: WEEK_INDICES,
            valueFormatter: (value) =>
              dayjs()
                .day(value - 1)
                .format('dd'),
            tickInterval: WEEK_INDICES,
          },
        ]}
        series={[
          {
            data: chartData,
            color: primaryColor,
            showMark: true,
          },
        ]}
        yAxis={[
          {
            min: 0,
            max: 100,
          },
        ]}
        sx={{
          '& .MuiChartsAxis-tickLabel': {
            fill: grayColor,
          },
          '& .MuiChartsAxis-directionX .MuiChartsAxis-line': {
            stroke: grayColor,
          },
          '& .MuiChartsAxis-directionY .MuiChartsAxis-line': {
            stroke: grayColor,
          },
          '& .MuiChartsAxis-tick': {
            stroke: grayColor,
          },
        }}
        height={300}
      />
    </div>
  );
};

export default PeakDipsGraph;
