import { useMutation, useQueryClient } from '@tanstack/react-query';

import { metricsApi } from '../api';

interface UseHideMealParams {
  metricId: string;
  date: string;
  mealId: string;
}

export function useHideMeal() {
  const queryClient = useQueryClient();

  const hideMealMutation = useMutation({
    mutationFn: ({ metricId, date, mealId }: UseHideMealParams) => {
      return metricsApi.hideMeal(metricId, date, mealId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.meal.details'] });
    },
  });

  return {
    hideMeal: hideMealMutation.mutateAsync,
    ...hideMealMutation,
  };
}
