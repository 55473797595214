import { SettingsActionItem } from './types';

import { Bolt } from '@mui/icons-material';

import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar-icon.svg';
import { ReactComponent as ChromeExtensionIcon } from '../../../../assets/icons/chrome-extension-icon.svg';
import { ReactComponent as MobileIcon } from '../../../../assets/icons/mobile-icon.svg';
import { ReactComponent as NotificationsIcon } from '../../../../assets/icons/notifications-icon.svg';
import { ReactComponent as ProfileIcon } from '../../../../assets/icons/profile-icon.svg';
import { ReactComponent as SubscriptionIcon } from '../../../../assets/icons/subscription-icon.svg';
import { ReactComponent as TodoIcon } from '../../../../assets/icons/todo-icon.svg';
import { ReactComponent as WearableIcon } from '../../../../assets/icons/wearable-icon.svg';
import { ReactComponent as GoogleIcon } from '../../../../assets/icons/google-icon.svg';
import { ReactComponent as OutlookIcon } from '../../../../assets/icons/outlook-icon.svg';
import { ReactComponent as TimeFormatIcon } from '../../../../assets/icons/time-icon.svg';
import { ReactComponent as AISchedulerIcon } from '../../../../assets/scheduler/ai-scheduler-simple-icon.svg';

type MenuItem = {
  text: string;
  icon: React.FC;
};

type SettingsMenuStructure = {
  [section: string]: {
    [key in SettingsActionItem]?: MenuItem;
  };
};

export const SVG_ICONS = {
  calendar: CalendarIcon,
  chromeExtension: ChromeExtensionIcon,
  mobile: MobileIcon,
  notifications: NotificationsIcon,
  profile: ProfileIcon,
  subscription: SubscriptionIcon,
  todo: TodoIcon,
  energy: Bolt,
  wearable: WearableIcon,
  google: GoogleIcon,
  outlook: OutlookIcon,
  timeFormat: TimeFormatIcon,
  aiScheduler: AISchedulerIcon,
};

export const settingsMenuStructure: SettingsMenuStructure = {
  Account: {
    profile: { text: 'Profile', icon: SVG_ICONS.profile },
    calendars: { text: 'Calendars', icon: SVG_ICONS.calendar },
    wearables: { text: 'Wearables', icon: SVG_ICONS.wearable },
    todos: { text: 'Todos', icon: SVG_ICONS.todo },
    energyPreferences: { text: 'Energy', icon: SVG_ICONS.energy },
    subscription: { text: 'Subscription', icon: SVG_ICONS.subscription },
  },
  General: {
    timeFormat: { text: 'Time Format', icon: SVG_ICONS.timeFormat },
    notifications: { text: 'Notifications', icon: SVG_ICONS.notifications },
  },
  Feature: {
    aiScheduler: { text: 'AI Scheduler', icon: SVG_ICONS.aiScheduler },
  },
  'Product Hub': {
    mobileApp: { text: 'Mobile App', icon: SVG_ICONS.mobile },
    chromeExtension: { text: 'Chrome Extension', icon: SVG_ICONS.chromeExtension },
  },
  About: {},
};
