import { Button, DialogActions } from '@mui/material';
import { FC } from 'react';

interface TaskDueDatePickerActionsProps {
  onClearTime?: () => void;
  onClearDate?: () => void;
  onAccept?: () => void;
}

const TaskDueDatePickerActions: FC<TaskDueDatePickerActionsProps> = ({
  onClearTime,
  onClearDate,
  onAccept,
}) => {
  return (
    <DialogActions>
      {!!onClearTime && <Button onClick={onClearTime}>Remove time</Button>}
      {!!onClearDate && <Button onClick={onClearDate}>Remove date</Button>}
      <Button onClick={onAccept}>Ok</Button>
    </DialogActions>
  );
};

export default TaskDueDatePickerActions;
