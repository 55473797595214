import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { useEffect, useState, FC, useMemo } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';

import {
  CalendarProvider,
  CircadianProvider,
  TodoTasksProvider,
  useAuthContext,
  useCalendarContext,
  useCalendarEvents,
  useTodoTasksContext,
} from '../../../data-access';
import { dummyTasks } from '../constants';
import { KanbanList } from '../../KanbanView';
import { StepProgress } from './StepProgress';
import './DragAndDropPractice.scss';
import { PracticeCalendar } from '../PracticeCalendar';
import { transformEventToRBCEvent } from '../../CalendarView';

interface DragAndDropPracticeProps {
  onFinishPractice: () => void;
}

const dummyDates = [dayjs(), dayjs().add(1, 'day')];

const DragAndDropPracticeWrapper: FC<DragAndDropPracticeProps> = ({ onFinishPractice }) => {
  return (
    <TodoTasksProvider>
      <CircadianProvider>
        <CalendarProvider>
          <DndProvider backend={HTML5Backend}>
            <DragAndDropPractice onFinishPractice={onFinishPractice} />
          </DndProvider>
        </CalendarProvider>
      </CircadianProvider>
    </TodoTasksProvider>
  );
};

const DragAndDropPractice: FC<DragAndDropPracticeProps> = ({ onFinishPractice }) => {
  const { user } = useAuthContext();
  const [step, setStep] = useState(1);
  const [initialTasks, setInitialTasks] = useState(dummyTasks);
  const { todoTasksByDate, isFetchingTasks } = useTodoTasksContext();
  const { visibleCalendarIds } = useCalendarContext();
  const { events: calendarEvents } = useCalendarEvents({
    userId: user.userId,
    startDate: dayjs().startOf('day').toISOString(),
    endDate: dayjs().endOf('day').toISOString(),
    enabled: !!visibleCalendarIds?.length,
  });

  // Load tasks from todo, but use dummy tasks if not connected
  useEffect(() => {
    const todaysTasks = todoTasksByDate.find(
      (date) => date.date === dayjs().format('YYYY-MM-DD')
    )?.tasks;
    if (todaysTasks?.length) {
      setInitialTasks(todaysTasks);
      return;
    }
    setInitialTasks(dummyTasks);
  }, [todoTasksByDate]);

  useEffect(() => {
    if (step === 2) {
      setTimeout(() => {
        onFinishPractice();
      }, 1000);
    }
  }, [step]);

  const events = useMemo(() => {
    return calendarEvents
      .filter(
        (event) =>
          visibleCalendarIds?.includes(event.calendarId) &&
          !event.isAllDay &&
          event.status !== 'cancelled'
      )
      .map((event) => transformEventToRBCEvent(event));
  }, [calendarEvents, visibleCalendarIds]);

  return (
    <div className="drag-and-drop-practice">
      <div className="drag-and-drop-practice__overlay">
        <div className="drag-and-drop-practice__modal">
          <div className="drag-and-drop-practice__header">
            <p className="drag-and-drop-practice__header-text">Let's try scheduling with energy!</p>
            <StepProgress step={step} />
          </div>
          <div className="drag-and-drop-practice__content">
            {dummyDates.map((listDate) => (
              <KanbanList
                key={listDate.toISOString()}
                date={listDate}
                tasks={listDate.isSame(dayjs(), 'day') ? initialTasks : []}
                isFetchingTasks={isFetchingTasks}
                className="practice-list"
                onMoveTask={() => void 0}
                onClickTask={() => void 0}
                isDemo={true}
              />
            ))}

            <PracticeCalendar
              originalEvents={events}
              onFinishDrop={() => setStep(step + 1)}
              className="drag-and-drop-practice__content__practice-calendar"
            />
          </div>
          <div className="drag-and-drop-practice__modal__stepper-container">
            {new Array(2).fill(0).map((_, index) => (
              <div
                className={clsx('drag-and-drop-practice__modal__stepper-container__stepper', {
                  'drag-and-drop-practice__modal__stepper-container__stepper--active':
                    (index === 0 && step === 1) || (index === 1 && step >= 2),
                })}
              />
            ))}
          </div>
          <span className="drag-and-drop-practice__skip" onClick={onFinishPractice}>
            Skip
          </span>
        </div>
      </div>
    </div>
  );
};

export default DragAndDropPracticeWrapper;
