import React from 'react';

import './NoScheduleCard.scss';
import { Button } from '@mui/material';
import { NoScheduleReason } from './types';

interface NoScheduleCardProps {
  reason: NoScheduleReason;
  onChangeDate: () => void;
}

const NoScheduleCard: React.FC<NoScheduleCardProps> = ({ reason, onChangeDate }) => {
  const title = {
    afterWork: 'your work hours have ended.',
    pastday: "it's a past date.",
  }[reason];

  const ctaText = {
    afterWork: 'the following day',
    pastday: 'today',
  }[reason];

  return (
    <div className="no-schedule-card">
      <h2 className="no-schedule-card__title">
        No plans could be scheduled for the selected day because {title}
      </h2>
      <div className="no-schedule-card__bottom-group">
        <p className="no-schedule-card__desc">Would you like to schedule for {ctaText} instead?</p>
        <Button variant="contained" onClick={onChangeDate} className="no-schedule-card__cta">
          Schedule for {ctaText}
        </Button>
      </div>
    </div>
  );
};

export default NoScheduleCard;
