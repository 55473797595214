import React from 'react';
import clsx from 'clsx';
import { Calendar } from '@demind-inc/core';
import { Checkbox, FormControlLabel } from '@mui/material';

import './CalendarListItem.scss';
import { getCssVariable } from '../../../../utils';

interface CalendarListItemProps {
  calendar: Calendar;
  selected: boolean;
  onSelect: (checked: boolean) => void;
  className?: string;
}

const primaryColor = getCssVariable('--color-primary');

const CalendarListItem: React.FC<CalendarListItemProps> = ({
  calendar,
  selected,
  onSelect,
  className,
}) => {
  return (
    <FormControlLabel
      className={clsx('calendar-list-item', className)}
      control={
        <Checkbox
          sx={{
            '&.Mui-checked': {
              color: calendar.color || primaryColor,
            },
          }}
          size="small"
          checked={selected}
          onChange={(e) => onSelect(e.target.checked)}
        />
      }
      label={calendar.name}
    />
  );
};

export default CalendarListItem;
