import { FC, useMemo } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { DefaultEventCategoryId, TaskItem } from '@demind-inc/core';
import { Typography } from '@mui/material';

import './EventBlock.scss';
import { RBCEvent } from '../types';
import { getCssVariable } from '../../../../utils';
import { getDefaultCategoryEventIconSet, TaskCheckBox } from '../../../common';
import { isTemporalAutoScheduledEvent } from '../../../../helpers';

import { ReactComponent as EnergyBoostIcon } from '../../../../assets/scheduler/energy-boost-icon-purple.svg';
import { ReactComponent as AiSchedulerIcon } from '../../../../assets/scheduler/ai-scheduler-icon.svg';
import { AiSchedulerText } from '../../../common/AiSchedulerText';
import { useGeneralSettings } from '../../../../hooks';

interface EventBlockProps {
  event: Partial<RBCEvent>;
  className?: string;
  short?: boolean;
  monthView?: boolean;
  taskLinkedToEvent?: TaskItem;
  onTaskStatusChange?: (taskId: string, boardId: string, completed: boolean) => void;
}

const primaryColor = getCssVariable('--color-primary');

const EventBlock: FC<EventBlockProps> = ({
  event,
  className,
  short = false,
  monthView = false,
  taskLinkedToEvent,
  onTaskStatusChange,
}) => {
  const {
    generalSettings: { timeFormat },
  } = useGeneralSettings();

  const isShortEvent = useMemo(
    () => short || Math.abs(dayjs(event.end).diff(dayjs(event.start), 'minutes')) < 30,
    [event, short]
  );

  const contentTitleColor = useMemo(() => {
    if (['energyBoost', 'aiScheduler', 'activity'].includes(event.scheduleFrom!)) {
      return primaryColor;
    }
    return event.color || primaryColor;
  }, [event.scheduleFrom]);

  const renderCategory = () => {
    if (!event.categories[0]) {
      return;
    }
    return (
      <div className="event-block__row-wrapper">
        {event?.categories[0]?.id && (
          <div
            className="event-block__label-group__icon-wrapper"
            style={{ backgroundColor: contentTitleColor }}
          >
            {getDefaultCategoryEventIconSet(event.categories[0].id as DefaultEventCategoryId, 10)}
          </div>
        )}
        {!isShortEvent && (
          <Typography
            variant="subtitle2"
            className="event-block__label-group__text"
            style={{
              color: contentTitleColor,
            }}
          >
            {event?.categories[0]?.name}
          </Typography>
        )}
      </div>
    );
  };

  const renderTaskCheckbox = () => {
    if (monthView || !event.taskFrom || !taskLinkedToEvent) {
      return null;
    }
    const isTemporalAIEvent = isTemporalAutoScheduledEvent(event.eventId, ['aiScheduler']);

    return (
      <div className="event-block__checkbox">
        <TaskCheckBox
          isCompleted={taskLinkedToEvent.completed}
          onChange={(checked) => {
            if (!isTemporalAIEvent) {
              onTaskStatusChange?.(event.taskFrom.taskId, event.taskFrom.boardId, checked);
            }
          }}
          size={14}
          color={getCssVariable('--color-primary')}
        />
      </div>
    );
  };

  const renderTitle = () => (
    <Typography
      variant="subtitle2"
      noWrap
      className="event-block__content__title"
      style={{
        color: contentTitleColor,
        textDecorationLine: event.isDeclined ? 'line-through' : 'none',
      }}
    >
      {monthView && `${dayjs(event.start).format(timeFormat === '12h' ? 'h:mmA' : 'HH:mm')} `}
      {event.title}
    </Typography>
  );

  const renderUpperContent = () => {
    switch (event.scheduleFrom) {
      case 'energyBoost':
        return (
          <div className="event-block__row-wrapper">
            <div className="event-block__label-group">
              <div
                className="event-block__label-group__icon-wrapper"
                style={{ backgroundColor: primaryColor }}
              >
                <EnergyBoostIcon style={{ width: 10, height: 10 }} />
              </div>
              {!isShortEvent && <span style={{ color: primaryColor }}>Energy Boost</span>}
            </div>
            {!!event.categories?.length && renderCategory()}
          </div>
        );
      case 'aiScheduler':
        return (
          <div className="event-block__row-wrapper">
            <div className="event-block__label-group">
              <div className="event-block__label-group__icon-wrapper">
                <AiSchedulerIcon style={{ width: 10, height: 10 }} />
              </div>
              {!isShortEvent && <AiSchedulerText />}
            </div>
            {!!event.categories?.length && renderCategory()}
          </div>
        );
      default:
        if (!event.taskFrom && !event.categories?.length) {
          return null;
        }

        return (
          <div className="event-block__label-group">
            {!!event.categories?.length && renderCategory()}
          </div>
        );
    }
  };

  return (
    <div className={clsx('event-block', className)}>
      {event.eventId && !monthView && (
        <div
          className="event-block__calendar"
          style={{ backgroundColor: event.calendarColor || event.color || primaryColor }}
        />
      )}

      <div className={clsx('event-block__content', isShortEvent && 'event-block__content--short')}>
        {isShortEvent ? (
          <>
            {renderTaskCheckbox()}
            {renderUpperContent()}
            {renderTitle()}
          </>
        ) : (
          <>
            {renderUpperContent()}
            <div className="event-block__content__long-event-title-wrapper">
              {renderTaskCheckbox()}
              {renderTitle()}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EventBlock;
