import React, { startTransition, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, LinearProgress, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

import './InitialSyncPage.scss';
//@ts-ignore
import TutorialVideo from '../../../assets/tutorial/tutorial_demo.mov';

import { BASIC_AWAKE_TIME, BASIC_SLEEP_TIME, OnboardingContainer } from '../../../components';
import { useInitSleepData } from '../../../hooks';
import {
  energyApi,
  useAuthContext,
  useEditSleep,
  useSyncCalEventsApi,
  useUpdateOnboardingStatus,
} from '../../../data-access';
import { Path } from '../../../routing/path';
import { LoadingButton } from '@mui/lab';

const minimumDate = dayjs().subtract(1, 'd').set('h', 12).set('m', 0);
const maxiumumDate = dayjs().set('h', 18).set('m', 0);

const syncStartDate = dayjs().startOf('day').toISOString();
const syncEndDate = dayjs().add(1, 'd').endOf('day').toISOString(); // AI scheduler might need data for the next day

const InitialSyncPage: React.FC = () => {
  const { syncSleepData, hasSomeDataSynched, isSyncing } = useInitSleepData();
  const { updateOnboardingStatus } = useUpdateOnboardingStatus();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [wakeTime, setWakeTime] = useState<Dayjs>(dayjs().set('h', BASIC_AWAKE_TIME).set('m', 0));
  const [sleepTime, setSleepTime] = useState<Dayjs>(dayjs().set('h', BASIC_SLEEP_TIME).set('m', 0));
  const { handleEditSleepData, isEditingData } = useEditSleep({ date: dayjs() });
  const { syncAllCalendarEvents } = useSyncCalEventsApi();

  const prefetchCircadianData = useCallback(
    (date: string) => {
      energyApi.getCircadian(
        user.userId,
        user.calendarIds ?? [],
        user.metricId!,
        date,
        dayjs.tz.guess(),
        false
      );
    },
    [user.userId]
  );

  // Sync sleep data, calendar events and circadian data for today and tomorrow
  useEffect(() => {
    const init = async () => {
      if (!user.userId) {
        return;
      }
      syncSleepData();
      syncAllCalendarEvents(syncStartDate, syncEndDate, user.calendarIds ?? []);
      prefetchCircadianData(dayjs().format('YYYY-MM-DD'));
      prefetchCircadianData(dayjs().add(1, 'd').format('YYYY-MM-DD'));
    };

    init();
  }, [user.userId]);

  const goToNextScreen = () => {
    startTransition(() => {
      updateOnboardingStatus({
        userId: user.userId,
        newStatus: { initialSync: 'answered' },
      });
      navigate(Path.ONBOARDING.CONNECT_TODO);
    });
  };

  const canSkip = !isSyncing && !hasSomeDataSynched;

  const handleStart = async () => {
    await handleEditSleepData({
      sleepTime: sleepTime.format('YYYY-MM-DDTHH:mm:ss'),
      wakeTime: wakeTime.format('YYYY-MM-DDTHH:mm:ss'),
    });
    goToNextScreen();
  };

  const renderContent = useCallback(() => {
    if (isSyncing) {
      return (
        <div className="initial-sync-page__content">
          <Typography variant="h4" className="initial-sync-page__content__title">
            Initializing data to personalize your energy
          </Typography>
          <LinearProgress color="primary" className="initial-sync-page__progress-bar" />
        </div>
      );
    }

    if (hasSomeDataSynched) {
      return (
        <Typography variant="h4" className="initial-sync-page__content__title">
          Data synced successfully
        </Typography>
      );
    }

    return (
      <div className="initial-sync-page__content">
        <Typography variant="h4" className="initial-sync-page__content__title">
          Tell us about your sleep last night
        </Typography>
        <div className="initial-sync-page__date-picker">
          <DateTimePicker
            label="Sleep time"
            value={sleepTime}
            minDateTime={minimumDate}
            maxDateTime={maxiumumDate}
            className="initial-sync-page__date-picker__item"
            format={'MMMM DD h:mm A'}
            views={['month', 'day', 'hours', 'minutes']}
            onChange={(newValue) => {
              if (newValue) {
                setSleepTime(newValue);
              }
            }}
          />
          <DateTimePicker
            label="Wake time"
            value={wakeTime}
            minDateTime={minimumDate}
            maxDateTime={maxiumumDate}
            className="initial-sync-page__date-picker__item"
            format={'MMMM DD h:mm A'}
            views={['month', 'day', 'hours', 'minutes']}
            onChange={(newValue) => {
              if (newValue) {
                setWakeTime(newValue);
              }
            }}
          />
        </div>
      </div>
    );
  }, [isSyncing, hasSomeDataSynched]);

  return (
    <div className="initial-sync-page">
      <OnboardingContainer
        leftChildren={
          <video
            src={TutorialVideo}
            className="initial-sync-page__left-section__asset"
            autoPlay
            loop
            controls
            muted
          />
        }
        rightChildren={
          <div className="initial-sync-page__right-section">
            <Typography variant="h1" className="initial-sync-page__title">
              Initial Sync
            </Typography>
            {renderContent()}
            <div className="initial-sync-page__btn-group">
              <LoadingButton
                variant="contained"
                className="initial-sync-page__btn"
                onClick={handleStart}
                loading={isSyncing || isEditingData}
                disabled={isSyncing || isEditingData}
              >
                Start
              </LoadingButton>
              {canSkip && (
                <Button
                  variant="text"
                  className="initial-sync-page__btn--skip"
                  onClick={goToNextScreen}
                >
                  Skip
                </Button>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
};

export default InitialSyncPage;
