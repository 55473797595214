import { useQuery } from '@tanstack/react-query';
import { TaskDataTrendWithData } from '@demind-inc/core';

import { tasksApi } from '../api';

interface UseTaskDailyTrendParams {
  dataTrendId: string;
  date: string;
  enabled?: boolean;
}

interface UseTaskDailyTrendQueryParams {
  queryKey: ['lifestack.trend.task.daily', UseTaskDailyTrendParams];
}

export const useTaskDailyTrend = ({
  dataTrendId,
  date,
  enabled = true,
}: UseTaskDailyTrendParams) => {
  const taskTrendQuery = useQuery({
    queryKey: ['lifestack.trend.task.daily', { dataTrendId, date }],
    queryFn: ({ queryKey }: UseTaskDailyTrendQueryParams) => {
      const [, { dataTrendId, date }] = queryKey;
      return tasksApi
        .getDailyTasksTrend(dataTrendId, date)
        .then(({ data }) => data as TaskDataTrendWithData);
    },
    refetchOnWindowFocus: false,
    staleTime: 15000, // 15s
    enabled: !!dataTrendId && enabled,
  });

  return {
    taskTrend: taskTrendQuery.data,
    ...taskTrendQuery,
  };
};
