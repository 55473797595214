import { FC } from 'react';
import { Check } from '@mui/icons-material';
import clsx from 'clsx';

import './SubscriptionPlanContent.scss';

interface SubscriptionPlanContentProps {
  className?: string;
}

const SubscriptionPlanContent: FC<SubscriptionPlanContentProps> = ({ className }) => {
  return (
    <div className={clsx('subscription-plan-content', className)}>
      <div className="subscription-plan-content__item">
        <Check className="subscription-plan-content__item__check" />
        <div className="subscription-plan-content__item__title">
          Schedule smarter with your energy
        </div>
      </div>
      <div className="subscription-plan-content__item">
        <Check className="subscription-plan-content__item__check" />
        <div className="subscription-plan-content__item__title">
          Enjoy Lifestack on multiple platforms (mobile, web, and chrome extension)
        </div>
      </div>
      <div className="subscription-plan-content__item">
        <Check className="subscription-plan-content__item__check" />
        <div className="subscription-plan-content__item__title">Cancel anytime</div>
      </div>
    </div>
  );
};

export default SubscriptionPlanContent;
