import { useMutation, useQueryClient } from '@tanstack/react-query';
import { calendarApi } from '../api';
import { CalendarEventModifyOption } from '../types';
import { CalendarEvent } from '@demind-inc/core';
import { merge, cloneDeep, isEmpty } from 'lodash';
import { pickLifestackOriginEventsProps } from '../helpers';
import { useRecoilValue } from 'recoil';
import { syncedDatesState } from '../recoil';

export interface UseUpdateCalendarEventParams {
  calendarId: string;
  eventId: string;
  userId: string;
  newEventOption: CalendarEventModifyOption;
}

export function useUpdateCalendarEvent() {
  const queryClient = useQueryClient();
  const targetSyncedDates = useRecoilValue(syncedDatesState);
  const getQueryKey = (userId: string) => [
    'calendar.events',
    {
      userId,
      startDate: targetSyncedDates[0].startOf('day').toISOString(),
      endDate: targetSyncedDates[targetSyncedDates.length - 1].endOf('day').toISOString(),
    },
  ];

  const updateCalendarEventMutation = useMutation({
    mutationFn: ({ calendarId, eventId, userId, newEventOption }: UseUpdateCalendarEventParams) => {
      return calendarApi.updateCalendarEvent(userId, calendarId, eventId, newEventOption);
    },
    onMutate: async ({
      eventId: targetEventId,
      userId,
      newEventOption,
    }: UseUpdateCalendarEventParams) => {
      const prevEvents =
        queryClient.getQueriesData<CalendarEvent[]>({
          queryKey: getQueryKey(userId),
        })?.[0]?.[1] ?? [];

      await queryClient.cancelQueries({ queryKey: getQueryKey(userId) });
      queryClient.setQueriesData<CalendarEvent[]>(
        { queryKey: getQueryKey(userId) },
        (prevEvents) => {
          const newEvents = cloneDeep(prevEvents); // By cloning deeply, set a new reference to re-render the component.
          return newEvents?.map((event) =>
            event.eventId === targetEventId
              ? {
                  ...merge({}, event, newEventOption),
                  ...(newEventOption.categories ? { categories: newEventOption.categories } : {}),
                }
              : event
          );
        }
      );
      return { prevEvents };
    },
    onSuccess: (_, { userId, newEventOption }) => {
      queryClient.invalidateQueries({
        queryKey: ['lifestack.calendar.events', { userId }],
      });

      if (newEventOption.eventMetrics || newEventOption.categories?.length) {
        queryClient.invalidateQueries({ queryKey: ['lifestack.circadian'] });
      }
    },
    onError: (error, { userId, eventId: targetEventId, newEventOption }, context) => {
      // Revert the changes by restoring the previous data
      if (context.prevEvents) {
        queryClient.setQueriesData<CalendarEvent[]>({ queryKey: getQueryKey(userId) }, () => {
          const lifestackEventPropsChanged = pickLifestackOriginEventsProps(newEventOption);
          if (isEmpty(lifestackEventPropsChanged)) {
            return context?.prevEvents;
          }

          // Update the lifestack origin event props event when update event failed
          return context?.prevEvents.map((e) =>
            targetEventId === e.eventId
              ? {
                  ...e,
                  ...lifestackEventPropsChanged,
                }
              : e
          );
        });
      }
    },
  });

  return {
    updateCalendarEvent: updateCalendarEventMutation.mutateAsync,
    ...updateCalendarEventMutation,
  };
}
