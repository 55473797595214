import dayjs from 'dayjs';

import {
  getStartEndTimeOfMultDays,
  useAuthContext,
  useSyncExerciseDetails,
  wearableErrorSnackBarAtom,
} from '../data-access';
import { useEffect } from 'react';
import { AxiosError } from 'axios';
import { useRecoilState } from 'recoil';

interface SyncExerciseDataParams {
  terraUserIds: string[];
  startDate: string;
  endDate: string;
}

export const useSyncExerciseData = () => {
  const { syncExerciseDetail, error: syncExerciseDetailError } = useSyncExerciseDetails();
  const { user } = useAuthContext();
  const [_, setWearableErrorSnackbar] = useRecoilState(wearableErrorSnackBarAtom);

  useEffect(() => {
    if (!syncExerciseDetailError) {
      return;
    }
    const error = (syncExerciseDetailError as AxiosError).response;
    if (error?.status === 401) {
      setWearableErrorSnackbar({ message: 'Device connection issue', status: 401 });
    } else {
      setWearableErrorSnackbar({ message: 'Failed to sync exercise data', status: error?.status });
    }
  }, [syncExerciseDetailError]);

  const syncExerciseData = async ({ terraUserIds, startDate, endDate }: SyncExerciseDataParams) => {
    if (!user.metricId || !user.userId || !terraUserIds.length) {
      return;
    }

    const { startTime, endTime } = getStartEndTimeOfMultDays(dayjs(startDate), dayjs(endDate));

    if (terraUserIds.length) {
      try {
        await syncExerciseDetail({
          metricId: user.metricId,
          terraUserIds,
          startDate: startTime.toISOString(),
          endDate: endTime.toISOString(),
        });
      } catch (error) {
        console.error('Error syncing exercise data', error);
      }
    }
  };

  return { syncExerciseData };
};
