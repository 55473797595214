import React, { useState, useEffect, useMemo, memo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTimeSet } from '@demind-inc/core';
import { Button } from '@mui/material';

import './TaskDueDatePicker.scss';
import { useGeneralSettings } from '../../../hooks/useGeneralSettings';
import TaskDueDatePickerActions from './TaskDueDatePickerActions';

export type DateTimeSetOnlyTime = Omit<DateTimeSet, 'timezone'>;

interface TaskDueDatePickerProps {
  label: string;
  dateSet?: DateTimeSetOnlyTime;
  maxDateTime?: Dayjs;
  minDateTime?: Dayjs;
  onDateChange: (date: DateTimeSetOnlyTime) => void;
  onClearTime?: () => void;
  onClearDate?: () => void;
}

export const TaskDueDatePicker: React.FC<TaskDueDatePickerProps> = memo(
  ({ label, dateSet, maxDateTime, minDateTime, onDateChange, onClearTime, onClearDate }) => {
    const [open, setOpen] = useState(false);
    const [dateTimePickerVisible, setDateTimePickerVisible] = useState(false);
    const [dateTimeDetailPickerVisible, setDateTimeDetailPickerVisible] = useState(false);
    const {
      generalSettings: { timeFormat },
    } = useGeneralSettings();

    useEffect(() => {
      const hasDateSet = dateSet ? !!Object.values(dateSet).filter((i) => !!i).length : false;
      setDateTimePickerVisible(hasDateSet);
      setDateTimeDetailPickerVisible(!!dateSet?.datetime);
    }, [dateSet]);

    const datePickerTimeFormat = useMemo(() => {
      if (dateTimeDetailPickerVisible) {
        return timeFormat === '12h' ? 'MMMM DD h:mm A' : 'MMMM DD H:mm';
      }
      return 'MMMM DD';
    }, [timeFormat, dateTimeDetailPickerVisible]);

    const handleDateChange = (newValue: dayjs.Dayjs | null) => {
      if (newValue && dayjs(newValue).isValid()) {
        onDateChange({
          datetime: dateTimeDetailPickerVisible ? newValue.toISOString() : undefined,
          date: newValue.format('YYYY-MM-DD'),
        });
      }
    };

    return (
      <div className="task-due-date-picker">
        {dateTimePickerVisible ? (
          <div className="task-due-date-picker__detail-group">
            <DateTimePicker
              open={open}
              onClose={() => setOpen(false)}
              maxDateTime={maxDateTime}
              minDateTime={minDateTime}
              format={datePickerTimeFormat}
              value={
                dateSet?.datetime
                  ? dayjs(dateSet.datetime)
                  : dateSet?.date
                    ? dayjs(dateSet.date)
                    : null
              }
              views={
                dateTimeDetailPickerVisible
                  ? ['month', 'day', 'hours', 'minutes']
                  : ['month', 'day']
              }
              onAccept={handleDateChange}
              className="task-due-date-picker__date-picker"
              slotProps={{
                openPickerIcon: { style: { display: 'none' } },
                textField: { variant: 'standard', onClick: () => setOpen(true) },
                popper: { className: 'task-due-date-picker__popper' },
              }}
              slots={{
                actionBar: (props) => (
                  <TaskDueDatePickerActions
                    onAccept={props.onAccept}
                    onClearTime={dateTimeDetailPickerVisible ? onClearTime : undefined}
                    onClearDate={onClearDate}
                  />
                ),
              }}
              ampm={timeFormat === '12h'}
              ampmInClock={timeFormat === '12h'}
            />
            {!dateTimeDetailPickerVisible && (
              <Button
                variant="text"
                className="task-due-date-picker__add-time"
                onClick={() => setDateTimeDetailPickerVisible(true)}
              >
                + Add time
              </Button>
            )}
          </div>
        ) : (
          <Button
            variant="text"
            className="task-due-date-picker__add-time"
            onClick={() => {
              setDateTimePickerVisible(true);
              setDateTimeDetailPickerVisible(true);
            }}
          >
            + Add {label}
          </Button>
        )}
      </div>
    );
  }
);
