import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { EventInteractionArgs } from 'react-big-calendar/lib/addons/dragAndDrop';
import { debounce } from 'lodash';
import { CalendarTaskFrom } from '@demind-inc/core';

import {
  eventsSnackBarAtom,
  useAuthContext,
  useCalendarContext,
  useEvents,
  useUpdateCalendarEvent,
  UseUpdateCalendarEventParams,
  useUpdateTodoTask,
} from '../data-access';
import { CALENDAR_STEP_MIN, RBCEvent, transformEventToRBCEvent } from '../components';
import { getParamToUpdateTaskTimeFromCalendar, isTemporalAutoScheduledEvent } from '../helpers';
import { trackEventMixpanel } from '../utils';
import { useRecoilState } from 'recoil';

export const useEventDragAndDrop = () => {
  const [_, setEventsSnackbar] = useRecoilState(eventsSnackBarAtom);
  const { updateCalendarEvent, status: updateCalendarEventStatus } = useUpdateCalendarEvent();
  const { updateTodoTask } = useUpdateTodoTask();
  const { calendarEvents } = useEvents();
  const { findCalendarItem, checkCalendarHassAccessToModifyEvent } = useCalendarContext();
  const { user } = useAuthContext();
  const [events, setEvents] = useState<RBCEvent[]>([]);

  useEffect(() => {
    if (updateCalendarEventStatus === 'success') {
      setEventsSnackbar('Event updated');
    } else if (updateCalendarEventStatus === 'pending') {
      setEventsSnackbar('Event updating...');
    } else if (updateCalendarEventStatus === 'error') {
      setEventsSnackbar('Failed to update event');
    }
  }, [updateCalendarEventStatus]);

  useEffect(() => {
    const transformedEvents = calendarEvents.map((e) =>
      transformEventToRBCEvent(e, findCalendarItem(e.calendarId))
    );
    setEvents(transformedEvents);
  }, [calendarEvents]);

  const debouncedUpdateCalendarEventAndTask = useCallback(
    debounce(
      (updateCalendarArgs: UseUpdateCalendarEventParams, updateTaskArgs?: CalendarTaskFrom) => {
        updateCalendarEvent(updateCalendarArgs);

        if (updateTaskArgs) {
          updateTodoTask({
            userId: updateCalendarArgs.userId,
            boardId: updateTaskArgs.boardId,
            taskId: updateTaskArgs.taskId,
            newTaskInfo: {
              ...getParamToUpdateTaskTimeFromCalendar(updateCalendarArgs.newEventOption),
              appFrom: updateTaskArgs.from,
            },
          });
        }
      },
      2000
    ),
    []
  );

  const onEventResize = (data: EventInteractionArgs<object>) => {
    const { start, end, event } = data;
    const targetEvent = event as RBCEvent;
    if (
      isTemporalAutoScheduledEvent(targetEvent.eventId, ['activity', 'aiScheduler', 'energyBoost'])
    ) {
      // Do not allow to resize temporal events
      return;
    }

    const hasAccesToModify = checkCalendarHassAccessToModifyEvent(targetEvent.calendarId!);

    if (!hasAccesToModify) {
      setEventsSnackbar('You do not have access to modify this event');
      return;
    }

    if (dayjs(start).isAfter(dayjs(end))) {
      return;
    }

    // Sometimes, the end time is same as start time, in that case we need to add CALENDAR_STEP_MIN to end time
    let clampedEnd = end;
    if (dayjs(start).isSame(dayjs(end))) {
      clampedEnd = dayjs(end).add(CALENDAR_STEP_MIN, 'minute').toDate();
    }

    setEvents((prev) =>
      prev.map((e) =>
        e.eventId === targetEvent.eventId ? ({ ...e, start, end: clampedEnd } as RBCEvent) : e
      )
    );

    if (!targetEvent.calendarId) {
      return;
    }

    debouncedUpdateCalendarEventAndTask(
      {
        userId: user.userId,
        calendarId: targetEvent.calendarId!,
        eventId: targetEvent.eventId,
        newEventOption: {
          start: {
            date: dayjs(start).toISOString(),
            timeZone: dayjs.tz.guess(),
          },
          end: {
            date: dayjs(clampedEnd).toISOString(),
            timeZone: dayjs.tz.guess(),
          },
          unSyncToCalendar: targetEvent.unSyncToCalendar,
        },
      },
      targetEvent.taskFrom
    );
    trackEventMixpanel('resize_calendar_event_by_drag');
  };

  const onEventDrop = (data: EventInteractionArgs<object>) => {
    const { start, end, event } = data;
    const targetEvent = event as RBCEvent;
    if (
      isTemporalAutoScheduledEvent(targetEvent.eventId, ['activity', 'aiScheduler', 'energyBoost'])
    ) {
      // Do not allow to drop temporal events
      return;
    }

    const hasAccesToModify = checkCalendarHassAccessToModifyEvent(targetEvent.calendarId!);

    if (!hasAccesToModify) {
      setEventsSnackbar('You do not have access to modify this event');
      return;
    }

    if (dayjs(start).isSameOrAfter(dayjs(end))) {
      return;
    }

    setEvents((prev) =>
      prev.map((e) => (e.eventId === targetEvent.eventId ? ({ ...e, start, end } as RBCEvent) : e))
    );

    if (!targetEvent.calendarId) {
      return;
    }

    debouncedUpdateCalendarEventAndTask(
      {
        userId: user.userId,
        calendarId: targetEvent.calendarId!,
        eventId: targetEvent.eventId,
        newEventOption: {
          start: {
            date: dayjs(start).toISOString(),
            timeZone: dayjs.tz.guess(),
          },
          end: {
            date: dayjs(end).toISOString(),
            timeZone: dayjs.tz.guess(),
          },
        },
      },
      targetEvent.taskFrom
    );
    trackEventMixpanel('move_calendar_event_by_drag');
  };

  return {
    onEventDrop,
    onEventResize,
    events,
  };
};
