import { calendarViewSwitcherAtom, homeViewSwitcherAtom, selectedDateAtom } from './atoms';
import { unionBy } from 'lodash';
import { getMonthDatesOf, getWeekDatesOf } from '..';
import { selector } from 'recoil';

export const timelineDatesState = selector({
  key: 'timelineDatesState',
  get: ({ get }) => {
    const selectedCalendarView = get(calendarViewSwitcherAtom);
    const selectedHomeView = get(homeViewSwitcherAtom);
    const selectedDate = get(selectedDateAtom);

    if (selectedHomeView === 'Todo') {
      return [selectedDate];
    }

    switch (selectedCalendarView) {
      case 'day':
        return [selectedDate];
      case 'week':
        return getWeekDatesOf(selectedDate);
      case 'month':
        return getMonthDatesOf(selectedDate);
      default:
        return [];
    }
  },
});

export const syncedDatesState = selector({
  key: 'syncedDatesState',
  get: ({ get }) => {
    const selectedDate = get(selectedDateAtom);
    const timelineDates = get(timelineDatesState);

    const weekDates = getWeekDatesOf(selectedDate);
    const targetDates = unionBy(weekDates, timelineDates, (date) => date.format('YYYY-MM-DD')).sort(
      (a, b) => a.valueOf() - b.valueOf()
    );

    return targetDates;
  },
});

export const circadianFetchDatesState = selector({
  key: 'circadianFetchDatesState',
  get: ({ get }) => {
    const selectedDate = get(selectedDateAtom);
    const selectedCalendarView = get(calendarViewSwitcherAtom);
    const selectedHomeView = get(homeViewSwitcherAtom);

    if (selectedHomeView === 'Todo') {
      return [selectedDate];
    }

    const weekDates = getWeekDatesOf(selectedDate);

    switch (selectedCalendarView) {
      case 'day':
        return [selectedDate];
      case 'week':
        return weekDates;
      case 'month':
        return weekDates;
      default:
        return [];
    }
  },
});
