import { TaskAlt } from '@mui/icons-material';
import clsx from 'clsx';
import { FC } from 'react';
import './ContentViewSwitcher.scss';
import { IconButton } from '@mui/material';
import { ReactComponent as CalendarViewIcon } from '../../../assets/icons/calendar-icon.svg';
import { useRecoilState } from 'recoil';
import { homeViewSwitcherAtom } from '../../../data-access';
interface ContentViewSwitcherProps {
  className?: string;
}

const ContentViewSwitcher: FC<ContentViewSwitcherProps> = ({ className }) => {
  const [currentContentView, setCurrentContentView] = useRecoilState(homeViewSwitcherAtom);

  return (
    <div className={clsx('content-view-switcher', className)}>
      <IconButton
        className={clsx(
          'content-view-switcher__button',
          currentContentView === 'Todo' && 'content-view-switcher__button--active'
        )}
        onClick={() => setCurrentContentView('Todo')}
      >
        <TaskAlt className="content-view-switcher__button__icon" />
      </IconButton>
      <IconButton
        className={clsx(
          'content-view-switcher__button',
          currentContentView === 'Calendar' && 'content-view-switcher__button--active'
        )}
        onClick={() => setCurrentContentView('Calendar')}
      >
        <CalendarViewIcon className="content-view-switcher__button__icon" />
      </IconButton>
    </div>
  );
};

export default ContentViewSwitcher;
