import { useEffect, useMemo } from 'react';
import { useAllDayEvents, useCalendarEvents } from '../queries';
import { useAuthContext } from '../context';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isFetchingCalendarDataAtom, syncedDatesState, visibleCalendarIdsAtom } from '../recoil';

export const useEvents = () => {
  const { user } = useAuthContext();
  const targetSyncedDates = useRecoilValue(syncedDatesState);
  const visibleCalendarIds = useRecoilValue(visibleCalendarIdsAtom);
  const [_, setIsFetchingCalendarData] = useRecoilState(isFetchingCalendarDataAtom);

  const { startDate, endDate } = useMemo(() => {
    const startDate = targetSyncedDates[0].startOf('day').toISOString();
    const endDate = targetSyncedDates[targetSyncedDates.length - 1].endOf('day').toISOString();
    return { startDate, endDate };
  }, [targetSyncedDates]);

  const { allDayEvents } = useAllDayEvents({
    userId: user.userId,
    startDate,
    endDate,
  });
  const { events: fetchedEvents, isLoading: isFetchingEvents } = useCalendarEvents({
    userId: user.userId,
    startDate,
    endDate,
    enabled: !!user.calendarIds?.length,
  });

  useEffect(() => {
    setIsFetchingCalendarData(isFetchingEvents);
  }, [isFetchingEvents]);

  const calendarEvents = useMemo(
    () =>
      fetchedEvents.filter(
        ({ status, calendarId, isAllDay }) =>
          status !== 'cancelled' && // Remove deleted event
          visibleCalendarIds.includes(calendarId!) &&
          !isAllDay
      ),
    [fetchedEvents, visibleCalendarIds]
  );
  const visibleAllDayEvents = useMemo(
    () =>
      allDayEvents.filter(
        ({ calendarId, status }) =>
          visibleCalendarIds.includes(calendarId!) && status !== 'cancelled' // Remove deleted event
      ),
    [allDayEvents, visibleCalendarIds]
  );

  return { calendarEvents, allDayEvents: visibleAllDayEvents };
};
