import { useMutation } from '@tanstack/react-query';
import { ConvertedReferralCampaign, Discount } from '@demind-inc/core';

import { accountApi } from '../api';

interface UseRequestStripeSubscriptionParams {
  userId: string;
  priceId: string;
  email: string;
  customerToken: string;
  offeringId: string;
  freeTrialDays?: number;
  referral?: ConvertedReferralCampaign;
  discount?: Discount;
  isRestored?: boolean;
}

export function useRequestStripeSubscription() {
  const requestSubscription = useMutation({
    mutationFn: ({
      userId,
      priceId,
      email,
      customerToken,
      offeringId,
      freeTrialDays,
      referral,
      discount,
      isRestored = false,
    }: UseRequestStripeSubscriptionParams) => {
      return accountApi.requestStripeSubscription(userId, {
        priceId,
        email,
        customerToken,
        offeringId,
        freeTrialDays,
        referral,
        discount,
        isRestored,
      });
    },
  });

  return {
    requestStripeSubscription: requestSubscription.mutateAsync,
    ...requestSubscription,
  };
}
