import { FC } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import clsx from 'clsx';
import { MoreHoriz, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';

import './KanbanListFilterMenu.scss';
import { useDropdownState } from '../../../../hooks';
import { KanbanListFilterItem } from './types';

interface KanbanListFilterMenuProps {
  filterOptions: KanbanListFilterItem[];
  className?: string;
}

const KanbanListFilterMenu: FC<KanbanListFilterMenuProps> = ({ filterOptions, className }) => {
  const { anchorEl, handleOpenDropdown, handleCloseDropdown } = useDropdownState();

  return (
    <>
      <IconButton
        className={clsx('kanban-list-filter', className, anchorEl && 'kanban-list-filter--open')}
        onClick={handleOpenDropdown}
        size="small"
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseDropdown}
        className="kanban-list-filter__dropdown"
      >
        {filterOptions.map((filter) => (
          <MenuItem
            key={filter.id}
            onClick={() => {
              filter.onChangeFilter(!filter.checked);
              handleCloseDropdown();
            }}
            className={clsx('kanban-list-filter__dropdown__item')}
          >
            {filter.checked ? (
              <VisibilityOffOutlined className="kanban-list-filter__dropdown__item__icon" />
            ) : (
              <VisibilityOutlined className="kanban-list-filter__dropdown__item__icon" />
            )}
            <p className="kanban-list-filter__dropdown__item__text">
              {filter.checked ? filter.closeLabel : filter.openLabel}
            </p>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default KanbanListFilterMenu;
