import { CustomTooltipContentProps } from '../../../components';

export const chronotypeTooltipInfo: CustomTooltipContentProps = {
  title: '🕝 Chronotype',
  description:
    'Your chronotype refers to your natural wakefulness rhythm, which is regulated by factors such as sleep, temperature, light exposure (melatonin levels), and more. Your chronotype is the behavioral manifestation of your circadian rhythm - in other words, it reflects your natural peaks and dips in energy throughout the day! Lifestack determines whether you are a morning, hybrid, or night chronotype by using your sleep data to help personalize your recommended tasks.',
  referenceLinkList: [
    {
      title: 'Chronotypes in the US – Influence of age and sex',
      url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5479630/',
    },
  ],
  learnMoreLink: 'https://www.notion.so/demind-inc/Chronotype-1a17c97113e680268850cc8c21f0c563',
};
