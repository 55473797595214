import clsx from 'clsx';
import { round } from 'lodash';
import { FC, useMemo } from 'react';
import { EnergyDataTrend } from '@demind-inc/core';
import { Typography } from '@mui/material';
import './EnergyValuesCard.scss';

interface EnergyValuesCardProps {
  className?: string;
  energyDailyTrend: EnergyDataTrend;
}

const EnergyValuesCard: FC<EnergyValuesCardProps> = ({ className, energyDailyTrend }) => {
  const highEnergyDurationString = useMemo(() => {
    if (!energyDailyTrend?.highEnergyDurationMin) {
      return '--h --m';
    }
    const hours = Math.floor(energyDailyTrend.highEnergyDurationMin / 60);
    const minutes = energyDailyTrend.highEnergyDurationMin % 60;
    return `${hours}h ${minutes}m`;
  }, [energyDailyTrend?.highEnergyDurationMin]);

  const avgDiff = useMemo(() => {
    if (!energyDailyTrend?.energyDiffFromAvg) {
      return `+${0}`;
    }
    const roundedAvg = round(energyDailyTrend.energyDiffFromAvg, 1);
    if (roundedAvg >= 0) {
      return `+${roundedAvg}`;
    }
    return `${roundedAvg}`;
  }, [energyDailyTrend?.energyDiffFromAvg]);

  return (
    <div className={clsx('energy-value-card', className)}>
      <div className="">
        <Typography variant="body1" className="energy-value-card__value">
          {highEnergyDurationString}
        </Typography>
        <Typography variant="subtitle2" className="energy-value-card__value-title">
          High Energy Duration
        </Typography>
      </div>
      <div>
        <Typography variant="body1" className="energy-value-card__value">
          {avgDiff}
        </Typography>
        <Typography variant="subtitle2" className="energy-value-card__value-title">
          Avg Energy
        </Typography>
      </div>
    </div>
  );
};

export default EnergyValuesCard;
