import { FC, useState } from 'react';

import './AISchedulerModalView.scss';
import { useAIScheduler } from '../../../../../../hooks';

import AISchedulerSetupModalView from './AISchedulerSetupModalView';
import AISchedulerDefaultPrefModalView from './AISchedulerDefaultPrefModalView';
import AISchedulerDailyPrefModalView from './AISchedulerDailyPrefModalView';
import AISchedulerRunningModalView from './AISchedulerRunningModalView';

const AISchedulerModalView: FC = () => {
  const [defaultPrefStep, setDefaultPrefStep] = useState<'expla' | 'setup'>('expla');
  const {
    schedulerPreferences,
    aiSchedulerTargetVersion,
    handleTargetVersionForScheduler,
    handleRunAIScheduler,
    hasSetDefaultPrefs,
    isRunningAIScheduler,
  } = useAIScheduler();

  if (isRunningAIScheduler) {
    return <AISchedulerRunningModalView />;
  }

  // Show Daily Pref screen
  if (hasSetDefaultPrefs) {
    return (
      <AISchedulerDailyPrefModalView
        targetVersion={aiSchedulerTargetVersion}
        defaultPref={schedulerPreferences}
        onRunAIScheduler={handleRunAIScheduler}
        onToggleTargetVersion={handleTargetVersionForScheduler}
      />
    );
  }

  // Show Default Pref screen
  if (defaultPrefStep === 'expla') {
    return <AISchedulerSetupModalView onNext={() => setDefaultPrefStep('setup')} />;
  }

  return <AISchedulerDefaultPrefModalView />;
};

export default AISchedulerModalView;
