import { ScheduledAction } from '@demind-inc/core';
import { atom } from 'recoil';
import { AISchedulerVersion } from '../types';

interface AiSchedulerFeedbackApiInfoType {
  appApiRes: string;
  scienceApiReq: string;
  scienceApiRes: string;
  currentTime: string;
  preference: string;
}

export const isAISchedulerSetupOverlayVisibleAtom = atom<boolean>({
  key: 'aiScheduler.isAISchedulerSetupOverlayVisible',
  default: false,
});

export const isRunningAISchedulerAtom = atom<boolean>({
  key: 'aiScheduler.isRunningAIScheduler',
  default: false,
});

export const aiScheduledActionsAtom = atom<ScheduledAction[]>({
  key: 'aiScheduler.aiScheduledActions',
  default: [],
});

export const aiSchedulerErrorAtom = atom<string | null>({
  key: 'aiScheduler.aiSchedulerError',
  default: null,
});

export const aiFeedbackModalVisibleAtom = atom<boolean>({
  key: 'aiScheduler.aiFeedbackModalVisible',
  default: false,
});

export const aiSchedulerFeedbackApiInfoAtom = atom<AiSchedulerFeedbackApiInfoType | null>({
  key: 'aiScheduler.aiSchedulerFeedbackApiInfo',
  default: null,
});

export const aiSchedulerVersionAtom = atom<AISchedulerVersion>({
  key: 'aiScheduler.aiSchedulerVersion',
  default: 'todobox',
});
