import React, { useCallback, useMemo, memo } from 'react';
import * as d3 from 'd3';
import { HeatmapDataType } from '@demind-inc/core';
import clsx from 'clsx';

import './Heatmap.scss';
import { getCssVariable } from '../../../../utils';
import { debounce } from 'lodash';

interface HeatmapBarChartProps {
  width: string;
  height: string;
  data: HeatmapDataType[];
  opacity?: number;
  style?: React.CSSProperties;
  strokeMode?: 'thin' | 'thinner' | 'default';
  onPointClick?: (point: HeatmapDataType) => void;
}

const HeatmapBarChart: React.FC<HeatmapBarChartProps> = memo(
  ({
    data,
    width,
    height,
    style,
    opacity = 1.0,
    strokeMode = 'default',
    onPointClick = () => void 0,
  }) => {
    const colorPalette = [
      getCssVariable('--color-red-base'),
      getCssVariable('--color-yellow-base'),
      getCssVariable('--color-green-base'),
    ];
    const sleepColor = getCssVariable('--color-blue-base');
    // Memoize scales and data processing
    const { xScale, yScale, colorScale } = useMemo(() => {
      const allYGroups = data.map((d) => d.y);
      const allXGroups = data.map((d) => d.x);

      return {
        yScale: d3
          .scaleBand()
          .range([0, parseInt(height)])
          .domain(allYGroups)
          .padding(0),
        xScale: d3
          .scaleBand()
          .range([0, parseInt(width)])
          .domain(allXGroups)
          .padding(0),
        colorScale: d3
          .scaleSequential()
          .interpolator(d3.interpolateRgbBasis(colorPalette))
          .domain([0, 1]),
      };
    }, [data, width, height]);

    const getFillColor = useCallback(
      (value: number) => {
        if (value === -1) {
          return getCssVariable('--color-gray-light');
        }
        if (value === 0) {
          return sleepColor;
        }

        return colorScale(value);
      },
      [colorScale]
    );

    const handlePointClick = debounce((point: HeatmapDataType) => {
      onPointClick(point);
    }, 100);

    return (
      <div
        className={clsx('heatmap-bar-chart', {
          'heatmap-bar-chart--default': strokeMode === 'default',
          'heatmap-bar-chart--thin': strokeMode === 'thin',
          'heatmap-bar-chart--thinner': strokeMode === 'thinner',
        })}
        style={style}
      >
        <svg width={width} height={height}>
          {data.map((d, i) => (
            <rect
              key={i}
              y={yScale(d.y)}
              x={xScale(d.x)}
              onClick={() => handlePointClick(d)}
              width={xScale.bandwidth()}
              height={7}
              fill={getFillColor(d.value)}
              opacity={opacity}
              className="heatmap-bar-chart__rect"
            />
          ))}
        </svg>
      </div>
    );
  }
);

export default HeatmapBarChart;
