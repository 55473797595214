import { FC, useState } from 'react';
import { Button, Typography } from '@mui/material';
import clsx from 'clsx';

import './ConnectDevicesPage.scss';
import { useAuthContext, useTerraWidget, useUpdateOnboardingStatus } from '../../../data-access';
import { trackEventMixpanel } from '../../../utils';
import { OnboardingContainer, SkipConnectDeviceDialog } from '../../../components';
import { Path } from '../../../routing/path';
import ConnectDeviceAsset from '../../../assets/onboarding/connectDevice.gif';
import { useNavigate } from 'react-router-dom';

const ConnectDevicesPage: FC = () => {
  const { terraWidget, isPending } = useTerraWidget();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { updateOnboardingStatus } = useUpdateOnboardingStatus();

  const [openSkipDialog, setOpenSkipDialog] = useState(false);

  const handleClick = () => {
    terraWidget({ metricId: user.metricId, redirectTo: Path.ONBOARDING.ENERGY_PREFERENCE });
    trackEventMixpanel('click_connect_wearables');
  };

  const handleSkip = async () => {
    await updateOnboardingStatus({
      userId: user.userId,
      newStatus: { connectWearables: 'skipped' },
    });
    navigate(Path.ONBOARDING.ENERGY_PREFERENCE);
    trackEventMixpanel('click_skip_connect_wearables');
  };

  return (
    <div className="connect-devices-page">
      <OnboardingContainer
        leftChildren={
          <img
            src={ConnectDeviceAsset}
            alt="lifestack connect device"
            className="connect-devices-page__asset"
          />
        }
        rightChildren={
          <div className="connect-devices-page__right-section">
            <div className="connect-devices-page__content">
              <Typography variant="h1" className="connect-devices-page__content__title">
                Connect Wearables
              </Typography>
              <Typography variant="h3" className="connect-devices-page__content__description">
                You can also connect{' '}
                <Typography
                  variant="h3"
                  className="connect-devices-page__content__description--bold"
                >
                  Apple Health, Google Fit, or Samsung Health
                </Typography>{' '}
                from the mobile app settings page.
              </Typography>
            </div>
            <div className="connect-devices-page__btn-group">
              <Button
                variant="contained"
                className="connect-devices-page__btn"
                onClick={handleClick}
                disabled={isPending}
              >
                Connect Wearables
              </Button>
              <Button
                variant="text"
                className="connect-devices-page__btn--no-wearable"
                onClick={() => setOpenSkipDialog(true)}
                disabled={isPending}
              >
                I don't have wearables
              </Button>
            </div>
          </div>
        }
        additionalChildren={
          <p className="connect-devices-page__additional-children">
            *You can delete your data anytime later
          </p>
        }
      />
      <SkipConnectDeviceDialog
        visible={openSkipDialog}
        onClose={() => setOpenSkipDialog(false)}
        onSkip={handleSkip}
      />
    </div>
  );
};

export default ConnectDevicesPage;
