import { useMutation, useQueryClient } from '@tanstack/react-query';

import { tasksApi } from '../api';
import { AddTodoTaskBoardProps } from '../types';

interface UseSetTodoProjectVisibilityParams {
  boardId: string;
  closed: boolean;
}

export const useSetTodoProjectVisibility = () => {
  const queryClient = useQueryClient();

  const setProjectVisibilityMutation = useMutation({
    mutationFn: ({ boardId, closed }: UseSetTodoProjectVisibilityParams) => {
      return tasksApi.setTodoProjectVisibility(boardId, closed);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.projects'] });
    },
  });

  return {
    setTodoProjectVisibility: setProjectVisibilityMutation.mutateAsync,
    ...setProjectVisibilityMutation,
  };
};
