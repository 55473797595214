import React, { FC, useCallback, useEffect, useReducer } from 'react';
import { cloneDeep, debounce, isEmpty } from 'lodash';

import './AISchedulerSettings.scss';
import {
  DefaultPrefGrid,
  initialDefaultPrefItemState,
  prefItemReducer,
  PrefItemState,
  transformPrefEditStateToPref,
  transformPrefToPrefeditState,
} from '../../../Scheduler';
import {
  SchedulerPrefType,
  useAuthContext,
  useSchedulerPreference,
  useUpdateSchedulerPref,
} from '../../../../data-access';
import { CircularProgress } from '@mui/material';

const AISchedulerSettings: FC = () => {
  const [prefItems, dispatch] = useReducer(prefItemReducer, initialDefaultPrefItemState);
  const { updateSchedulerPref } = useUpdateSchedulerPref();
  const { user } = useAuthContext();
  const { schedulerPreferences, isLoading: isLoadingSchedulePref } = useSchedulerPreference({
    preferenceId: user.preferenceId!,
  });

  // Initialize preference items
  useEffect(() => {
    if (!schedulerPreferences || isEmpty(schedulerPreferences)) {
      return;
    }
    dispatch({ type: 'initialize', payload: transformPrefToPrefeditState(schedulerPreferences) });
  }, [schedulerPreferences]);

  const debouncedUpdateSchedulerPref = useCallback(
    debounce((newPref: PrefItemState) => {
      const updatedPrefItems = transformPrefEditStateToPref(newPref);
      updateSchedulerPref({
        preferenceId: user.preferenceId!,
        preferences: updatedPrefItems,
      });
    }, 3000),
    []
  );

  const handleChangePrefItem = ({
    type,
    payload,
  }: {
    type: keyof PrefItemState;
    payload: Partial<PrefItemState[SchedulerPrefType] | PrefItemState>;
  }) => {
    dispatch({ type, payload });
    const newPrefItems = cloneDeep(prefItems);
    //@ts-ignore
    newPrefItems[type] = {
      ...newPrefItems[type],
      ...payload,
    };
    debouncedUpdateSchedulerPref(newPrefItems);
  };

  return (
    <div className="ai-scheduler-settings">
      <div className="ai-scheduler-settings__title">AI Scheduler</div>
      <div className="ai-scheduler-settings__subtitle">Set up your preferences</div>
      {isLoadingSchedulePref ? (
        <CircularProgress />
      ) : (
        <DefaultPrefGrid
          prefItems={prefItems}
          onChangePrefItem={handleChangePrefItem}
          itemClassName="ai-scheduler-settings__grid__item"
        />
      )}
    </div>
  );
};

export default AISchedulerSettings;
