import React, { useMemo } from 'react';

import './PeakEnergy.scss';
import { ReactComponent as EnergyIcon } from '../../../assets/circadian/energy.svg';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CustomTooltip, CustomTooltipContent } from '../../common';
import { NoWearableChip } from '../NoWearableChip';
import { useCircadianContext } from '../../../data-access';
import { circadianTooltipInfo } from './constants';

interface PeakEnergyProps {
  hasNoSleepData: boolean;
}

const PeakEnergy: React.FC<PeakEnergyProps> = ({ hasNoSleepData }) => {
  const { peakEnergy } = useCircadianContext();

  const score = useMemo(
    () => (peakEnergy ? Math.round(peakEnergy * 100).toString() : '--'),
    [peakEnergy]
  );

  return (
    <div className="peak-energy">
      <CustomTooltip
        title={<CustomTooltipContent {...circadianTooltipInfo} />}
        className="peak-energy__tooltip"
      >
        <div className="peak-energy__score-group">
          <EnergyIcon className="peak-energy__icon" />
          <div className="peak-energy__score">{score}</div>
        </div>
      </CustomTooltip>
      {hasNoSleepData && (
        <CustomTooltip className="peak-energy__no-data__tooltip" title={<NoWearableChip />}>
          <ErrorOutlineIcon fontSize="small" className="peak-energy__no-data__tooltip__icon" />
        </CustomTooltip>
      )}
    </div>
  );
};

export default PeakEnergy;
