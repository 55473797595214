import React, {
  FC,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { Calendar, momentLocalizer, SlotInfo } from 'react-big-calendar';
import dayjs from 'dayjs';
import moment from 'moment';
import withDragAndDrop, {
  DragFromOutsideItemArgs,
  EventInteractionArgs,
} from 'react-big-calendar/lib/addons/dragAndDrop';

import './CalendarTimeline.scss';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useRecoilValue } from 'recoil';

import { EventBlock } from './EventBlock';
import { RBCEvent } from './types';
import { CustomTimeGutter } from './CustomTimeGutter';
import {
  CreateEventProps,
  PeakDipPhase,
  selectedDateAtom,
  useAuthContext,
  useCalendarContext,
  useCircadianContext,
  useCreateEventMenuContext,
  useEventDetailsMenuContext,
  useTodoTasksCache,
  useUpdateTaskStatus,
} from '../../../data-access';
import { CALENDAR_STEP_MIN, zoneColorForPeaksDipsPhase } from './constants';
import { getCssVariable } from '../../../utils';
import { ActivitySaveMenu, CreateEventMenu, EditEventMenu } from '../EventMenu';
import {
  getEventStyles,
  transfomrAutoScheduledEvents,
  transformCreatingEventToRBCEvent,
  transformActivityEventsToRBCEvents,
} from './helpers';
import { useGeneralSettings } from '../../../hooks/useGeneralSettings';
import {
  useAIScheduler,
  useDnDTaskToCalendar,
  useEnergyBoost,
  useMetricsActivitiesInCalendar,
  useOvernightSummary,
} from '../../../hooks';
import { AISchedulerSaveAllModal } from '../../Scheduler';

interface CalendarTimelineProps {
  events: RBCEvent[];
  hasNoSleepData?: boolean;
  onEventDrop: (data: EventInteractionArgs<object>) => void;
  onEventResize: (data: EventInteractionArgs<object>) => void;
  className?: string;
}

interface MemoizedCalendarProps extends CalendarTimelineProps {
  scrollToTime: Date;
  dragFromOutsideItem: () => Partial<RBCEvent>;
  onDropEventFromOutside: (data: DragFromOutsideItemArgs) => void;
  onSelectEvent: (event: RBCEvent) => void;
  onSelectSlot: (slot: SlotInfo) => void;
  onCreateEvent?: (event: CreateEventProps) => void;
}

const localizer = momentLocalizer(moment); // dayjsLocalizer is so slow.
const DnDCalendar = withDragAndDrop(Calendar);
const primaryColor = getCssVariable('--color-primary');
const grayBase = getCssVariable('--color-gray-base');
const calendarWidth = 400; //--calendar-width


const CustomTimeGutterWrapper = memo(({ children }: React.PropsWithChildren<{}>) => {
  const { peaksDipsBoundaries, circadianRhythms } = useCircadianContext();
  const selectedDate = useRecoilValue(selectedDateAtom);
  const { handleSelectNewEvent } = useCreateEventMenuContext();
  const { hasNoSleepData = false } = useOvernightSummary({ date: selectedDate });
  const isDefaultHeatmap = hasNoSleepData || selectedDate.isAfter(dayjs(), 'day');

  return (
    <CustomTimeGutter
      peaksDipsBoundaries={peaksDipsBoundaries}
      heatmapData={circadianRhythms}
      isDefaultHeatmap={isDefaultHeatmap}
      onCreateEvent={handleSelectNewEvent}
    >
      {children}
    </CustomTimeGutter>
  );
});

export const MemoizedCalendar = React.memo(
  forwardRef<HTMLDivElement, MemoizedCalendarProps>(
    (
      {
        events,
        scrollToTime,
        hasNoSleepData = false,
        onEventDrop,
        onEventResize,
        dragFromOutsideItem,
        onDropEventFromOutside,
        onSelectEvent,
        onSelectSlot,
        onCreateEvent = () => void 0,
        className,
      },
      ref
    ) => {
      const { peaksDipsBoundaries, circadianRhythms } = useCircadianContext();
      const selectedDate = useRecoilValue(selectedDateAtom);
      const {
        generalSettings: { timeFormat },
      } = useGeneralSettings();
      const { updateTodoTaskStatus } = useUpdateTaskStatus();
      const { user } = useAuthContext();
      const { findInCacheByDate } = useTodoTasksCache();

      const selectedTimeFormat = timeFormat === '12h' ? 'h a' : 'HH';

      const backgroundEvents = useMemo(
        () =>
          peaksDipsBoundaries
            ? Object.entries(peaksDipsBoundaries).map(([phase, { start, end }]) => ({
                start: new Date(start),
                end: new Date(end),
                color: zoneColorForPeaksDipsPhase[phase as PeakDipPhase],
              }))
            : [],
        [peaksDipsBoundaries]
      );

      const handleTaskComplete = async (taskId: string, boardId: string, completed: boolean) => {
        await updateTodoTaskStatus({
          taskId,
          boardId,
          completed,
          userId: user.userId,
        });
      };

      const getTargetTaskFromEvent = useCallback(
        (event: RBCEvent) => {
          const taskId = event.taskFrom?.taskId;
          if (!taskId) {
            return;
          }

          const tasks = findInCacheByDate(
            dayjs(event.start).format('YYYY-MM-DD'),
            event.taskFrom.boardId
          );

          return tasks?.find((task) => task.taskId === taskId);
        },
        [findInCacheByDate]
      );

      return (
        <div ref={ref} className="calendar-timeline__container">
          {/* @ts-ignore */}
          <DnDCalendar
            date={selectedDate.toDate()}
            defaultView="day"
            events={events}
            localizer={localizer}
            onEventDrop={onEventDrop}
            onSelectEvent={(event) => onSelectEvent(event as RBCEvent)}
            onEventResize={onEventResize}
            //@ts-ignore
            dragFromOutsideItem={dragFromOutsideItem}
            onDropFromOutside={onDropEventFromOutside}
            onDragOver={(e) => e.preventDefault()}
            onSelectSlot={onSelectSlot}
            resizable
            selectable
            showMultiDayTimes
            step={CALENDAR_STEP_MIN}
            dayLayoutAlgorithm="no-overlap"
            scrollToTime={scrollToTime}
            formats={{
              timeGutterFormat: (date) => {
                if (dayjs(date).minute() === 0) {
                  return dayjs(date).format(selectedTimeFormat);
                }
              },
            }}
            backgroundEvents={backgroundEvents}
            components={{
              // @ts-ignore
              timeGutterWrapper: CustomTimeGutterWrapper,
              event: (event) => (
                <EventBlock
                  event={event.event}
                  onTaskStatusChange={handleTaskComplete}
                  taskLinkedToEvent={getTargetTaskFromEvent(event.event as RBCEvent)}
                />
              ),
            }}
            toolbar={false}
            eventPropGetter={(rbcEvent: RBCEvent) => getEventStyles(rbcEvent, false)}
            className={clsx('calendar-timeline', className)}
          />
        </div>
      );
    }
  )
);

export const CalendarTimeline: FC<CalendarTimelineProps> = memo(
  ({ events, hasNoSleepData = false, onEventDrop, onEventResize, className }) => {
    const { creatingEvent, handleSelectNewEvent, clearCreatingEvent } = useCreateEventMenuContext();
    const { visibleMenuMode, selectedEvent, handleSelectEvent, clearSelectedEvent } =
      useEventDetailsMenuContext();
    const { mainCalendar } = useCalendarContext();
    const selectedDate = useRecoilValue(selectedDateAtom);
    const scrollToTime = useRef<Date>(selectedDate.toDate());

    const {
      draggedNewEvent: defaultDraggedEvent,
      dropTaskOnCalendar,
      handleDropEventFromOutside: handleDropEventFromOutsideApi,
    } = useDnDTaskToCalendar();
    const hasDraggedEvent = useRef(false);
    const [draggedEvent, setDraggedEvent] = useState<Partial<RBCEvent> | null>(null);
    const [rerenderTrigger, setRerenderTrigger] = useState(0); // New state to trigger rerenders
    const {
      scheduledEnergyboostEvents,
      selectedEnergyboostOptions,
      onAcceptScheduledEnergyboost,
      onDiscardScheduledEnergyboost,
    } = useEnergyBoost();
    const {
      aiScheduledActionsEvents,
      isSaveModalVisible,
      resetAiScheduler,
      handleAcceptAllEvents,
    } = useAIScheduler();
    const {
      activityEvents,
      selectedActivityEvent,
      clearSelectedActivityEvent,
      handleDiscardActivityEvent,
      handleSaveActivityEvent,
    } = useMetricsActivitiesInCalendar();

    const eventWithCreatingOne = useMemo(() => {
      const transformedEnergyBoostEvents = transfomrAutoScheduledEvents(
        scheduledEnergyboostEvents,
        mainCalendar?.color
      );
      const transformedAIScheduledEvents = transfomrAutoScheduledEvents(
        aiScheduledActionsEvents,
        mainCalendar?.color
      );
      const transformedActivityEvents = transformActivityEventsToRBCEvents(activityEvents);
      const groupedEvents = [
        ...events,
        ...transformedEnergyBoostEvents,
        ...transformedAIScheduledEvents.filter(
          (e) => !events.some((ce) => ce.autoScheduleFrom?.id === e.autoScheduleFrom?.id)
        ),
        ...transformedActivityEvents.filter(
          (e) => !events.some((ce) => ce.activityFrom?.activityId === e.activityFrom?.activityId)
        ), // Filter out exercises that are already in the calendar
      ];
      if (creatingEvent) {
        return [...groupedEvents, transformCreatingEventToRBCEvent(creatingEvent)];
      }
      return groupedEvents;
    }, [
      creatingEvent,
      aiScheduledActionsEvents,
      events,
      activityEvents,
      scheduledEnergyboostEvents,
      mainCalendar,
    ]);

    // Initialize dragged event
    useEffect(() => {
      if (hasDraggedEvent.current || !defaultDraggedEvent) {
        return;
      }

      setDraggedEvent({
        title: defaultDraggedEvent.summary,
        eventId: 'dragging',
        color: mainCalendar.color || primaryColor,
      });
      hasDraggedEvent.current = true;
    }, [defaultDraggedEvent, mainCalendar]);

    const handleDropEventFromOutside = (data: DragFromOutsideItemArgs) => {
      handleDropEventFromOutsideApi(data);
      setDraggedEvent(null);
      hasDraggedEvent.current = false;
      scrollToTime.current = dayjs(data.start).toDate();
      setRerenderTrigger((prev) => prev + 1); // Force re-rendering calendar after dragging & dropping, so it enables to resize right away TODO: asking it on the community (https://github.com/jquense/react-big-calendar/issues/2678)
    };

    const dragFromOutsideItem = useCallback(() => draggedEvent, [draggedEvent]);

    return (
      <>
        <MemoizedCalendar
          key={rerenderTrigger}
          ref={dropTaskOnCalendar}
          events={eventWithCreatingOne}
          hasNoSleepData={hasNoSleepData}
          onEventDrop={onEventDrop}
          onEventResize={onEventResize}
          onSelectEvent={handleSelectEvent}
          onDropEventFromOutside={handleDropEventFromOutside}
          onSelectSlot={({ start, end }) =>
            handleSelectNewEvent({ start: start.toISOString(), end: end.toISOString() })
          }
          onCreateEvent={handleSelectNewEvent}
          dragFromOutsideItem={dragFromOutsideItem}
          scrollToTime={scrollToTime.current} // Scroll to dragged event's position after re-rendering by drag&drop
          className={className}
        />
        <CreateEventMenu visible={!!creatingEvent} onClose={clearCreatingEvent} />
        <EditEventMenu
          visible={!!selectedEvent && visibleMenuMode === 'edit'}
          onClose={clearSelectedEvent}
        />
        <ActivitySaveMenu
          visible={visibleMenuMode === 'energy_boost'}
          actionOptions={selectedEnergyboostOptions?.options}
          onClose={clearSelectedEvent}
          onAccept={onAcceptScheduledEnergyboost}
          onDiscard={onDiscardScheduledEnergyboost}
          hideTimeSelector
        />
        <ActivitySaveMenu
          title="Save Activity"
          visible={visibleMenuMode === 'activity'}
          subChildren={
            selectedActivityEvent?.source ? (
              <div style={{ color: grayBase, fontSize: 12, textAlign: 'center' }}>
                Source: {selectedActivityEvent?.source}
              </div>
            ) : null
          }
          defaultStartTime={selectedActivityEvent ? dayjs(selectedActivityEvent.start.date) : null}
          defaultEndTime={selectedActivityEvent ? dayjs(selectedActivityEvent.end.date) : null}
          onClose={() => {
            clearSelectedEvent();
            clearSelectedActivityEvent();
          }}
          onAccept={(_, calendarId, startTime, endTime) =>
            handleSaveActivityEvent(calendarId, startTime, endTime)
          }
          onDiscard={handleDiscardActivityEvent}
        />
        <AISchedulerSaveAllModal
          isOpen={isSaveModalVisible}
          width={calendarWidth}
          onClose={resetAiScheduler}
          onSave={handleAcceptAllEvents}
          className="calendar-timeline__aischeduler-save-all-modal"
        />
      </>
    );
  }
);
