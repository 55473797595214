import { FC, useMemo } from 'react';
import { Button } from '@mui/material';
import dayjs from 'dayjs';

import './AISchedulerPracticeResult.scss';
import { PracticeCalendar } from '../../PracticeCalendar';
import { useAuthContext, useCalendarContext, useCalendarEvents } from '../../../../data-access';
import { useAIScheduler, useGeneralSettings } from '../../../../hooks';
import { transfomrAutoScheduledEvents, transformEventToRBCEvent } from '../../../CalendarView';
import { formatTaskTimeRange } from '../../../../helpers';

interface AISchedulerPracticeResultProps {
  onFinish: () => void;
}

const AISchedulerPracticeResult: FC<AISchedulerPracticeResultProps> = ({ onFinish }) => {
  const { user } = useAuthContext();
  const { findCalendarItem, mainCalendar, visibleCalendarIds } = useCalendarContext();
  const {
    generalSettings: { timeFormat },
  } = useGeneralSettings();

  const { targetDate, aiScheduledActionsEvents, resetAiScheduler, aiSchedulerError } =
    useAIScheduler({
      isDemo: true,
    });
  const { events: fetchedEvents } = useCalendarEvents({
    userId: user.userId,
    startDate: targetDate.startOf('day').toISOString(),
    endDate: targetDate.endOf('day').toISOString(),
    enabled: !!visibleCalendarIds?.length,
  });

  const targetDayString = useMemo(() => {
    const isToday = targetDate.isSame(dayjs(), 'date');

    return isToday ? 'today' : 'tomorrow';
  }, [targetDate]);

  const events = useMemo(() => {
    const calendarEvents = fetchedEvents.filter(
      ({ status, calendarId, isAllDay }) =>
        status !== 'cancelled' && // Remove deleted event
        visibleCalendarIds?.includes(calendarId!) &&
        !isAllDay
    );

    const transformedEvents = calendarEvents.map((e) =>
      transformEventToRBCEvent(e, findCalendarItem(e.calendarId))
    );
    const transformedAiScheduleEvents = transfomrAutoScheduledEvents(
      aiScheduledActionsEvents,
      mainCalendar?.color
    );

    return [
      ...transformedEvents,
      ...transformedAiScheduleEvents.filter(
        (e) => !transformedEvents.some((ce) => ce.autoScheduleFrom?.id === e.autoScheduleFrom?.id)
      ),
    ];
  }, [fetchedEvents, mainCalendar, findCalendarItem, visibleCalendarIds, aiScheduledActionsEvents]);

  const scheduledTodoEvents = useMemo(
    () => aiScheduledActionsEvents.filter((e) => !!e.taskFrom?.taskId),
    [aiScheduledActionsEvents]
  );

  const handleFinish = () => {
    resetAiScheduler();
    onFinish();
  };

  return (
    <div className="ai-scheduler-practice-result">
      <div className="ai-scheduler-practice-result__calendar">
        <PracticeCalendar
          originalEvents={events}
          className="ai-scheduler-practice-result__calendar__content"
        />
      </div>
      <div className="ai-scheduler-practice-result__right-group">
        <div className="ai-scheduler-practice-result__header">
          {aiSchedulerError
            ? `Sorry, we couldn't prepare your plan.. (${aiSchedulerError})`
            : `Here's your personalized plan for ${targetDayString} to boost your productivity!`}
        </div>
        <div className="ai-scheduler-practice-result__todo-list">
          {scheduledTodoEvents.length ? (
            <div className="ai-scheduler-practice-result__todo-list__content">
              <div className="ai-scheduler-practice-result__todo-list__title">Scheduled todos</div>
              <div className="ai-scheduler-practice-result__todo-list__item-group">
                {scheduledTodoEvents.map((e) => (
                  <div className="ai-scheduler-practice-result__todo-list__item" key={e.eventId}>
                    <div className="ai-scheduler-practice-result__todo-list__item__title">
                      {e.summary}
                    </div>
                    <div className="ai-scheduler-practice-result__todo-list__item__time">
                      {formatTaskTimeRange({
                        startDatetime: dayjs(e.start.date),
                        endDatetime: dayjs(e.end.date),
                        timeFormat,
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <p className="ai-scheduler-practice-result__todo-list__no-todos">No todos scheduled</p>
          )}
        </div>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleFinish}
          className="ai-scheduler-practice-result__button"
        >
          Finish
        </Button>
      </div>
    </div>
  );
};

export default AISchedulerPracticeResult;
