import { useQuery } from '@tanstack/react-query';
import { TaskLabel } from '@demind-inc/core';

import { tasksApi } from '../api';

interface UseTaskLabelsParams {
  taskLabelGroupId: string;
}

interface UseTaskLabelQueryParams {
  queryKey: ['lifestack.todo.tasks.labels', UseTaskLabelsParams];
}

export const useTaskLabels = ({ taskLabelGroupId }: UseTaskLabelsParams) => {
  const taskLabelsQuery = useQuery({
    queryKey: ['lifestack.todo.tasks.labels', { taskLabelGroupId }],
    queryFn: ({ queryKey }: UseTaskLabelQueryParams) => {
      const [, { taskLabelGroupId }] = queryKey;
      return tasksApi.getAllTaskLabels(taskLabelGroupId).then(({ data }) => data as TaskLabel[]);
    },
    refetchOnWindowFocus: false,
    staleTime: 15000, // 15s
    enabled: !!taskLabelGroupId,
  });

  return {
    taskLabels: taskLabelsQuery.data ?? [],
    ...taskLabelsQuery,
  };
};
