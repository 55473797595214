import { FC, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import Stripe from 'stripe';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import clsx from 'clsx';

import './PaywallPage.scss';
import PaywallAsset from '../../../assets/onboarding/paywall.png';
import {
  DeleteAccountDialog,
  OnboardingContainer,
  DiscountCodeDialog,
  SubscriptionPlanContent,
  SubscriptionPlanItem,
  SubscriptionTimeline,
} from '../../../components';
import {
  basicOfferingId,
  DEFAULT_FREE_TRIAL_DAYS,
  MONTH_FREE_TRIAL_DAYS,
  useAuthContext,
  useAvailableSubscriptions,
  useSubscriptionContext,
  useVerifyDiscountCode,
} from '../../../data-access';
import { translatePlanPriceDetail } from './helpers';
import { CardPayment } from './CardPayment';
import { useBreakpoints, useFeatureFlag, useQueryString } from '../../../utils';
import { Button, CircularProgress, Typography } from '@mui/material';
import { getMetaDataFromStripeSubscription } from '../../../helpers';
import { useNavigate } from 'react-router-dom';
import { Discount } from '@demind-inc/core';
import { LoadingButton } from '@mui/lab';
import { Path } from '../../../routing';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const PaywallPage: FC = () => {
  const [selectedPlan, setSelectedPlan] = useState<Stripe.Price | undefined>();
  const [deleteUserDialogVisible, setDeleteUserDialogVisible] = useState(false);
  const [appliedDiscount, setAppliedDiscount] = useState<Discount | null>(null);
  const [isDiscountCodeDialogOpen, setIsDiscountCodeDialogOpen] = useState(false);

  const { signOut } = useAuthContext();

  const { availableSubscriptions, isLoading: isFetchingPlans } = useAvailableSubscriptions({
    provider: 'stripe',
    access: 'basic',
  }); // Web supports only Stripe payment.
  const {
    rewardfulCaompaignId,
    hasExpiredSubscription,
    isRequestingSubscription,
    requestFreeSubscription,
  } = useSubscriptionContext();
  const { queryString: referralQSParam } = useQueryString({ qskey: 'via' });
  const navigate = useNavigate();
  const { isDesktop } = useBreakpoints();
  const {
    verifyDiscountCode,
    isPending: isVerifyingDiscountCode,
    error: verifyDiscountError,
  } = useVerifyDiscountCode();
  const {
    featureFlags: { FF_30_day_trial = false },
  } = useFeatureFlag();

  // NEXT: Filter subscriptions based on discount code
  const filteredAvailableSubscriptions = useMemo(
    () => availableSubscriptions.filter((plan) => plan.metadata.offeringId === basicOfferingId),
    [availableSubscriptions]
  );

  // Apply the referral code via rewardful
  useEffect(() => {
    if (!rewardfulCaompaignId) {
      return;
    }

    const init = async () => {
      const discount = await verifyDiscountCode({
        code: rewardfulCaompaignId,
      });
      setAppliedDiscount(discount);
    };
    init();
  }, [rewardfulCaompaignId]);

  useEffect(() => {
    if (!referralQSParam) {
      return;
    }
    // Update the URL with the referral code
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('via', referralQSParam);
    window.history.pushState({}, '', currentUrl.href);

    // Reload the page to ensure to apply the referral code
    const hasReloaded = JSON.parse(localStorage.getItem('hasReloaded') || 'false') as boolean;
    if (hasReloaded) {
      return;
    }
    localStorage.setItem('hasReloaded', 'true');
    setTimeout(() => window.location.reload(), 1000);
  }, [referralQSParam]);

  // Set the default plan to annual plan
  useEffect(() => {
    if (!availableSubscriptions.length) {
      return;
    }
    setSelectedPlan(availableSubscriptions.find(({ recurring }) => recurring.interval === 'year'));
  }, [availableSubscriptions]);

  const isFreePlan = useMemo(() => appliedDiscount?.discountType === 'free', [appliedDiscount]);

  const handleGoback = () => {
    navigate(-1);
  };

  const handleSignOut = () => {
    signOut();
    setTimeout(() => {
      window.location.href = window.location.origin;
    }, 1000);
  };

  const freeTrialDays = useMemo(() => {
    if (hasExpiredSubscription) {
      return 0;
    }
    if (appliedDiscount && appliedDiscount.trialDays) {
      return appliedDiscount.trialDays;
    }
    if (FF_30_day_trial) {
      return MONTH_FREE_TRIAL_DAYS;
    }
    if (!selectedPlan) {
      return DEFAULT_FREE_TRIAL_DAYS;
    }
    return getMetaDataFromStripeSubscription(selectedPlan).freeTrialDays;
  }, [selectedPlan, appliedDiscount, FF_30_day_trial, hasExpiredSubscription]);

  const offeringId = useMemo(
    () =>
      appliedDiscount?.applicableOfferingIds[0] ||
      selectedPlan?.metadata.offeringId ||
      basicOfferingId,
    [selectedPlan, appliedDiscount]
  );

  const applyDiscountCode = async (code: string) => {
    const discount = await verifyDiscountCode({
      code,
    });
    setAppliedDiscount(discount);
  };

  const renderBottomButtonGroup = () => (
    <div className="paywall-page__bottom-group">
      <Button variant="text" onClick={handleGoback} className="paywall-page__bottom-group__text">
        Go Back
      </Button>
      <Button variant="text" onClick={handleSignOut} className="paywall-page__bottom-group__text">
        Log Out
      </Button>
      <Button
        variant="text"
        onClick={() => setDeleteUserDialogVisible(true)}
        className="paywall-page__bottom-group__text"
      >
        Delete Account
      </Button>
      <DeleteAccountDialog
        visible={deleteUserDialogVisible}
        where="beforePaywall"
        onClose={() => setDeleteUserDialogVisible(false)}
      />
    </div>
  );

  return (
    <div className="paywall-page">
      <OnboardingContainer
        leftChildren={
          <img src={PaywallAsset} alt="lifestack paywall" className="paywall-page__asset" />
        }
        rightChildren={
          <div className="paywall-page__right-section">
            <div className="paywall-page__content">
              <Typography variant="h1" className="paywall-page__content__title">
                {hasExpiredSubscription ? 'Reactivate Your Subscription' : 'Start Your Free Trial'}
              </Typography>
              <div className="paywall-page__content__plans">
                {isFetchingPlans && (
                  <div className="paywall-page__content__plans--loader">
                    <CircularProgress />
                  </div>
                )}
                {!isFetchingPlans &&
                  (isFreePlan ? (
                    <SubscriptionPlanItem
                      key={'free-plan'}
                      title={'Free plan'}
                      isSelected={true}
                      isBestDeal={false}
                      description={'$0.00 / forever'}
                      onClick={() => {}}
                    />
                  ) : (
                    filteredAvailableSubscriptions.map((plan) => {
                      const recurring = plan.recurring.interval;

                      return (
                        <SubscriptionPlanItem
                          key={plan.id}
                          title={recurring === 'month' ? 'Monthly plan' : 'Annual plan'}
                          isSelected={selectedPlan?.id === plan.id}
                          isBestDeal={recurring === 'year'}
                          description={translatePlanPriceDetail(plan)}
                          onClick={() => setSelectedPlan(plan)}
                        />
                      );
                    })
                  ))}
                <SubscriptionPlanContent className="paywall-page__content__plans__explanation" />
                <div className="paywall-page__content__bottom-group">
                  {!isFreePlan && (
                    <SubscriptionTimeline
                      startTrialDate={
                        hasExpiredSubscription ? undefined : dayjs().format('YYYY-MM-DD')
                      }
                      startChargeDate={
                        hasExpiredSubscription
                          ? dayjs().format('YYYY-MM-DD')
                          : dayjs().add(freeTrialDays, 'day').format('YYYY-MM-DD')
                      }
                      freeTrialDays={freeTrialDays}
                      price={selectedPlan ? translatePlanPriceDetail(selectedPlan, true) : ''}
                      className="paywall-page__content__timeline"
                    />
                  )}
                  {isFreePlan ? (
                    <LoadingButton
                      loading={isRequestingSubscription}
                      variant="contained"
                      onClick={() => requestFreeSubscription(() => navigate(Path.DASHBOARD))}
                      className="paywall-page__content__bottom-group__button"
                    >
                      Start for Free
                    </LoadingButton>
                  ) : (
                    <Elements stripe={stripePromise}>
                      <CardPayment
                        selectedPlan={selectedPlan}
                        freeTrialDays={freeTrialDays}
                        offeringId={offeringId}
                        discount={appliedDiscount}
                      />
                    </Elements>
                  )}
                  {appliedDiscount ? (
                    <Typography
                      variant="body2"
                      className={clsx(
                        'paywall-page__content__referral',
                        'paywall-page__content__referral--applied'
                      )}
                    >
                      {/* Skip if referral affiliate code applied when resuming subscription */}
                      {appliedDiscount.discountType === 'trialExtend' && hasExpiredSubscription
                        ? 'This code is not applicable for reactivating subscription.'
                        : 'Referral code applied!'}
                    </Typography>
                  ) : (
                    <div
                      className="paywall-page__content__referral"
                      onClick={() => setIsDiscountCodeDialogOpen(true)}
                    >
                      Have a referral code?
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        }
        additionalChildren={!isDesktop && renderBottomButtonGroup()}
      />
      {isDesktop && renderBottomButtonGroup()}
      <DiscountCodeDialog
        open={isDiscountCodeDialogOpen}
        onClose={() => setIsDiscountCodeDialogOpen(false)}
        onValidate={applyDiscountCode}
        isValidating={isVerifyingDiscountCode}
        error={verifyDiscountError}
      />
    </div>
  );
};

export default PaywallPage;
