import React, { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useAuthContext, useInitTodoIntegration } from '../../../data-access';
import './TrelloAuthCallback.scss';
import { Path } from '../../../routing/path';
import { trackEventMixpanel } from '../../../utils';

const TrelloAuthCallback: React.FC = () => {
  const { initTodoIntegration } = useInitTodoIntegration();
  const { user } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.userId) {
      return;
    }

    const exchange = async () => {
      const currentUrl = window.location.href;
      const token = currentUrl.split('#token=')?.[1];

      const params = new URLSearchParams(new URL(currentUrl).search);
      const redirectTo = params.get('redirectTo');

      if (!token) {
        return;
      }

      const integrationId = await initTodoIntegration({
        token,
        todoType: 'trello',
        userId: user.userId,
        shouldSyncTasks: true,
      });

      trackEventMixpanel('connect_todo_auth_done', { todoType: 'trello' });
      navigate(redirectTo || Path.DASHBOARD);
    };

    exchange();
  }, [user.userId]);

  return (
    <div className="trello-auth-callback">
      <CircularProgress />
    </div>
  );
};

export default TrelloAuthCallback;
