import React, { useMemo, useReducer } from 'react';
import clsx from 'clsx';
import { LoadingButton } from '@mui/lab';

import './AISchedulerModalView.scss';
import {
  DefaultPrefGrid,
  initialDefaultPrefItemState,
  prefItemReducer,
  transformPrefEditStateToPref,
  validatePreferenceItems,
} from '../../../../../Scheduler';
import { useAuthContext, useUpdateSchedulerPref } from '../../../../../../data-access';

const AISchedulerDefaultPrefModalView: React.FC = () => {
  const [prefItems, dispatch] = useReducer(prefItemReducer, initialDefaultPrefItemState);
  const { updateSchedulerPref, isPending: isUpdatingScheduler } = useUpdateSchedulerPref();
  const { user } = useAuthContext();

  const validationError = useMemo(() => validatePreferenceItems(prefItems), [prefItems]);

  return (
    <div className="ai-scheduler-modal">
      <div
        className={clsx(
          'ai-scheduler-modal__left-section',

          'ai-scheduler-modal__left-section--bg'
        )}
      >
        {!!validationError && (
          <div className="ai-scheduler-modal__validation">{validationError}</div>
        )}
        <DefaultPrefGrid prefItems={prefItems} onChangePrefItem={dispatch} />
      </div>
      <div className={clsx('ai-scheduler-modal__description-container')}>
        <p className="ai-scheduler-modal__description">
          Before we get started, let’s confirm a few details to help you schedule tasks instantly
          with personalized preferences.
        </p>
        <LoadingButton
          onClick={() =>
            updateSchedulerPref({
              preferenceId: user.preferenceId!,
              preferences: transformPrefEditStateToPref(prefItems),
            })
          }
          loading={isUpdatingScheduler}
          disabled={isUpdatingScheduler || !!validationError}
          className={clsx('core-action-modal__button')}
        >
          Next
        </LoadingButton>
      </div>
    </div>
  );
};

export default AISchedulerDefaultPrefModalView;
