import { useState } from 'react';

import {
  getArrayOfXDaysBackFromToday,
  getTargetTerraUserIds,
  useAuthContext,
  useWearablesContext,
} from '../data-access';
import { useSyncSleepData } from './useSyncSleepData';

const SYNCED_DAYS = 20;
const TIMEOUT_SYNC_MS = 10000; // 10 seconds

export const useInitSleepData = () => {
  const { user } = useAuthContext();
  const { syncSleepData: syncSleepDataEndpoint, hasSomeDataSynched } = useSyncSleepData();
  const { apiDevices } = useWearablesContext();

  const [isSyncing, setIsSyncing] = useState(false);

  const syncedDates = getArrayOfXDaysBackFromToday(SYNCED_DAYS);

  const syncSleepData = async () => {
    const terraUserIds = getTargetTerraUserIds(apiDevices, 'sleep');
    if (!terraUserIds?.length || !user.metricId) {
      return;
    }

    setIsSyncing(true);
    try {
      const timeoutPromise = new Promise((_, reject) => {
        const timer = setTimeout(() => {
          clearTimeout(timer);
          reject(new Error('Sync operation timed out'));
        }, TIMEOUT_SYNC_MS);
      });
      await Promise.race([
        syncSleepDataEndpoint({
          terraUserIds,
          startDate: syncedDates[0].format('YYYY-MM-DD'),
          endDate: syncedDates[syncedDates.length - 1].format('YYYY-MM-DD'),
        }),
        timeoutPromise, // Set the time out in case syncing takes infinite time
      ]);
    } finally {
      setIsSyncing(false);
    }
  };

  return {
    isSyncing,
    hasSomeDataSynched,
    syncSleepData,
  };
};
