import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Subscription } from '@demind-inc/core';

import { useAuthContext } from './AuthProvider';

import { useSubscriptions } from '../queries';
import { getRewardfulCampaign, getRewardfulCustomerId } from '../initializer';
import { trackEventMixpanel } from '../../utils';

interface ISubscriptionContext {
  currentSubscription?: Subscription;
  hasAccessToApp: boolean;
  isValidating: boolean;
  isRequestingSubscription: boolean;
  hasExpiredSubscription: boolean;
  rewardfulCaompaignId: string;
  rewardfulCustomerId: string;
  requestFreeSubscription: (onPurchaseDone: () => void) => Promise<void>;
  refetchSubscriptions: () => Promise<void>;
}

export const SubscriptionContext = createContext({} as ISubscriptionContext);
export const useSubscriptionContext = () => useContext(SubscriptionContext);

export const SubscriptionProvider = ({ children }: { children: ReactNode }) => {
  const [rewardfulCaompaignId, setRewardfulCampaignId] = useState<string>('');
  const [rewardfulCustomerId, setRewardfulCustomerId] = useState<string>('');
  const [isRequestingSubscription, setIsRequestingSubscription] = useState(false);
  const { user, updateUser } = useAuthContext();
  const {
    subscriptions,
    isLoading: isFetchingSubscriptions,
    refetch,
  } = useSubscriptions({
    userId: user.userId,
  });

  useEffect(() => {
    window.addEventListener('Rewardful.initialized', function () {
      setRewardfulCampaignId(getRewardfulCampaign()?.id);
      setRewardfulCustomerId(getRewardfulCustomerId());
    });
  }, []);

  const refetchSubscriptions = async () => {
    await refetch();
  };

  const hasAccessToApp = useMemo(
    () => (subscriptions?.hasAccess === undefined ? true : subscriptions.hasAccess),
    [subscriptions]
  );

  const hasExpiredSubscription = useMemo(() => {
    const allSubscriptions = subscriptions?.subscriptions ?? [];
    if (!allSubscriptions.length) {
      return false;
    }
    return allSubscriptions.some((sub) => sub.status === 'expired');
  }, [subscriptions?.subscriptions]);

  // If free plan applied, update user access to free
  const requestFreeSubscription = async (onPurchaseDone: () => void) => {
    setIsRequestingSubscription(true);
    try {
      await updateUser({
        access: 'free',
      });
      await refetchSubscriptions();
      onPurchaseDone();
      trackEventMixpanel('subscribe_free_plan', { from: 'web' });
    } finally {
      setIsRequestingSubscription(false);
    }
  };

  return (
    <SubscriptionContext.Provider
      value={{
        currentSubscription: subscriptions?.activeSubscription,
        hasAccessToApp,
        isRequestingSubscription,
        isValidating: isFetchingSubscriptions,
        rewardfulCaompaignId,
        hasExpiredSubscription,
        rewardfulCustomerId,
        requestFreeSubscription,
        refetchSubscriptions,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};
