import { useQuery, useQueryClient } from '@tanstack/react-query';
import { MealDetailsByDate } from '@demind-inc/core';

import { metricsApi } from '../api';
import { useCallback } from 'react';

interface UseMealDetailsParams {
  metricId: string;
  dates: string[];
}

interface UseMealDetailsQueryParams {
  queryKey: ['lifestack.meal.details', UseMealDetailsParams];
}

export const useMealDetails = ({ metricId, dates }: UseMealDetailsParams) => {
  const queryClient = useQueryClient();

  const mealQuery = useQuery({
    queryKey: ['lifestack.meal.details', { metricId, dates }],
    queryFn: ({ queryKey }: UseMealDetailsQueryParams) => {
      const [, { metricId, dates }] = queryKey;
      return metricsApi
        .getMealDetails(metricId, dates)
        .then(({ data }) => data as MealDetailsByDate[]);
    },
    refetchOnWindowFocus: false,
    staleTime: 15000, // 15s
    enabled: !!metricId && !!dates.length,
  });

  const refetchMealDetails = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: ['lifestack.meal.details'],
    });
  }, [queryClient]);

  return {
    mealDetails: mealQuery.data,
    refetchMealDetails,
    ...mealQuery,
  };
};
