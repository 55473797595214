import { Typography } from '@mui/material';
import clsx from 'clsx';
import { FC } from 'react';
import './PeakDipsValuesCard.scss';
import { circadianPhaseInfo, PeakDipPhase, PeakDipStartEndSet } from '../../../../data-access';
import dayjs from 'dayjs';
import { useGeneralSettings } from '../../../../hooks/useGeneralSettings';

interface PeakDipsValuesCardProps {
  className?: string;
  targetPeakDipStartEndSet: PeakDipStartEndSet;
  basePeakDipStartEndSet: PeakDipStartEndSet;
  isWeekly: boolean;
}

const PeakDipsValuesCard: FC<PeakDipsValuesCardProps> = ({
  className,
  targetPeakDipStartEndSet,
  basePeakDipStartEndSet,
  isWeekly,
}) => {
  const {
    generalSettings: { timeFormat },
  } = useGeneralSettings();
  return (
    <div
      className={clsx(
        'peak-dips-values-card',
        isWeekly && 'peak-dips-values-card--weekly',
        className
      )}
    >
      <Typography variant="body1" className="peak-dips-values-card__title">
        Peaks & Dips
      </Typography>
      <div
        className={clsx(
          'peak-dips-values-card__container',
          isWeekly && 'peak-dips-values-card__container--weekly'
        )}
      >
        {Object.entries(targetPeakDipStartEndSet).map(([phase, { start, end }]) => {
          const { title, color } = circadianPhaseInfo[phase as PeakDipPhase];
          const { start: baseStart, end: baseEnd } = basePeakDipStartEndSet[phase as PeakDipPhase];

          const diffTiming = dayjs(start).diff(dayjs(baseStart), 'minute');
          const targetDateDiff = dayjs(end).diff(dayjs(start), 'minute');
          const baseDiff = dayjs(baseEnd).diff(dayjs(baseStart), 'minute');
          const diffDuration = targetDateDiff - baseDiff;
          if (!start || !end) {
            return;
          }
          const isEarlier = diffTiming <= 0;
          const isLonger = diffDuration >= 0;

          const startTime = dayjs(start).format(timeFormat === '24h' ? 'HH:mm' : 'h:mm A');
          const endTime = dayjs(end).format(timeFormat === '24h' ? 'HH:mm' : 'h:mm A');
          return (
            <div
              className="peak-dips-values-card__container__item"
              style={{ backgroundColor: `${color}1A` }}
              key={title}
            >
              <div>
                <Typography
                  style={{ color }}
                  className="peak-dips-values-card__container__item__title"
                  variant="body2"
                >
                  {title}
                </Typography>
                <Typography
                  style={{ color }}
                  className="peak-dips-values-card__container__item__subtitle"
                  variant="subtitle2"
                >{`${startTime} - ${endTime}`}</Typography>
              </div>
              <div className="peak-dips-values-card__container__item__timing">
                <Typography
                  variant="subtitle2"
                  className="peak-dips-values-card__container__item__timing__diff"
                >
                  {isNaN(Math.abs(diffTiming))
                    ? '--'
                    : `${Math.abs(diffTiming)} min ${isEarlier ? 'earlier' : 'later'}`}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className="peak-dips-values-card__container__item__timing__diff"
                >
                  {isNaN(Math.abs(diffDuration))
                    ? '--'
                    : `${Math.abs(diffDuration)} min ${isLonger ? 'longer' : 'shorter'}`}
                </Typography>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PeakDipsValuesCard;
