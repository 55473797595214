import React from 'react';
import { MobileInstallDialog } from '../../../components';
import { useDelayedVisible } from '../../../utils';
import { useLocalStorage } from '@uidotdev/usehooks';

const FirstOpenDialogGroup: React.FC = () => {
  const [shouldMobileInstallDialogOpen, setShouldMobileInstallDialogOpen] = useLocalStorage(
    'mobile_install_dialog_opened',
    false
  );
  const { isVisible: isMobileInstallDialogVisible, handleClose: handleMobileInstallDialogClose } =
    useDelayedVisible({
      skippedDependencies: [shouldMobileInstallDialogOpen],
      delayedMs: 10000, // 10 seconds
    });

  return (
    <>
      <MobileInstallDialog
        visible={isMobileInstallDialogVisible}
        onClose={() => {
          setShouldMobileInstallDialogOpen(true);
          handleMobileInstallDialogClose();
        }}
      />
    </>
  );
};

export default FirstOpenDialogGroup;
