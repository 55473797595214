import { LoadingButton } from '@mui/lab';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import clsx from 'clsx';

import Background from '../../../../../../assets/actions/edit-sleep-showcase.png';

import './EditSleepModalView.scss';
import {
  selectedCoreActionModalAtom,
  selectedDateAtom,
  useEditSleep,
} from '../../../../../../data-access';
import { BASIC_AWAKE_TIME, BASIC_SLEEP_TIME } from '../../../constants';
import { useOvernightSummary } from '../../../../../../hooks';

const EditSleepModalView: FC = () => {
  const selectedDate = useRecoilValue(selectedDateAtom);
  const [wakeTime, setWakeTime] = useState<Dayjs>(dayjs());
  const [sleepTime, setSleepTime] = useState<Dayjs>(dayjs());

  const { handleEditSleepData, isEditingData } = useEditSleep({ date: selectedDate });
  const [_, setSelectedModal] = useRecoilState(selectedCoreActionModalAtom);
  const { awakeTime: defaultWakeTime, sleepTime: defaultSleepTime } = useOvernightSummary({
    date: selectedDate,
  });

  useEffect(() => {
    if (selectedDate) {
      setSleepTime(selectedDate.set('h', BASIC_SLEEP_TIME).set('m', 0));
      setWakeTime(selectedDate.set('h', BASIC_AWAKE_TIME).set('m', 0));
    }

    if (defaultWakeTime) {
      setWakeTime(dayjs(defaultWakeTime));
    }

    if (defaultSleepTime) {
      setSleepTime(dayjs(defaultSleepTime));
    }
  }, [defaultWakeTime, defaultSleepTime, selectedDate]);

  const { minimumDate, maxiumumDate } = useMemo(() => {
    const minimumDate = selectedDate.subtract(1, 'd').set('h', 12).set('m', 0);
    const maxiumumDate = selectedDate.set('h', 18).set('m', 0);

    return { minimumDate, maxiumumDate };
  }, [selectedDate]);

  const canSave = useMemo(() => dayjs(sleepTime).isBefore(wakeTime), [sleepTime, wakeTime]);

  const onSleepDataSave = () => {
    if (!sleepTime || !wakeTime || !canSave) {
      return;
    }

    handleEditSleepData({
      sleepTime: sleepTime.format('YYYY-MM-DDTHH:mm:ss'),
      wakeTime: wakeTime.format('YYYY-MM-DDTHH:mm:ss'),
    });
    setSelectedModal(null);
  };

  return (
    <div className="edit-sleep-modal">
      <div className="edit-sleep-modal__bg--container">
        <img src={Background} alt="logo" className="edit-sleep-modal__bg" draggable="false" />
      </div>
      <div className="edit-sleep-modal__description-container">
        <p className="edit-sleep-modal__description__copy">
          Edit your sleep data if it wasn't recorded accurately
        </p>
        <div className="edit-sleep-modal__date-picker">
          <DateTimePicker
            label="Sleep time"
            value={sleepTime}
            minDateTime={minimumDate}
            maxDateTime={maxiumumDate}
            className="edit-sleep-modal__date-picker__item"
            format={'MMMM DD h:mm A'}
            views={['month', 'day', 'hours', 'minutes']}
            onChange={(newValue) => {
              if (newValue) {
                setSleepTime(newValue);
              }
            }}
          />
          <DateTimePicker
            label="Wake time"
            value={wakeTime}
            minDateTime={minimumDate}
            maxDateTime={maxiumumDate}
            className="edit-sleep-modal__date-picker__item"
            format={'MMMM DD h:mm A'}
            views={['month', 'day', 'hours', 'minutes']}
            onChange={(newValue) => {
              if (newValue) {
                setWakeTime(newValue);
              }
            }}
          />
        </div>
        <LoadingButton
          onClick={onSleepDataSave}
          className={clsx(
            'core-action-modal__button',
            isEditingData && 'edit-sleep-modal__button--loading',
            !canSave && 'edit-sleep-modal__button--disabled'
          )}
          disabled={isEditingData || !canSave}
          loading={isEditingData}
        >
          <p>Save</p>
        </LoadingButton>
      </div>
    </div>
  );
};

export default EditSleepModalView;
