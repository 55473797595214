import { FC } from 'react';
import { Button, Snackbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useRecoilState } from 'recoil';

import { settingsModalAtom, wearableErrorSnackBarAtom } from '../../../data-access';

const WearableErrorSnackBar: FC = () => {
  const [wearableErrorSnackbar, setWearableErrorSnackbar] =
    useRecoilState(wearableErrorSnackBarAtom);
  const [_, setSettingsModalVisible] = useRecoilState(settingsModalAtom);

  const handleClose = () => setWearableErrorSnackbar(null);

  return (
    <Snackbar
      open={!!wearableErrorSnackbar}
      message={'[Wearable] ' + wearableErrorSnackbar?.message}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      action={
        <>
          {wearableErrorSnackbar?.status === 401 && (
            <Button
              key={wearableErrorSnackbar?.message}
              color="error"
              size="small"
              onClick={() =>
                setSettingsModalVisible({ visible: true, defaultPosition: 'wearables' })
              }
            >
              Reconnect
            </Button>
          )}
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
};

export default WearableErrorSnackBar;
