import { getDiffMinBetweenHHmm, SchedulerPrefType } from '../../../data-access';
import { SchedulerPrefDurationRange, SchedulerPrefTimeRange } from './types';

export type PrefItemState = {
  workTime: {
    start: SchedulerPrefTimeRange;
    end: SchedulerPrefTimeRange;
  };
  deepWork: {
    duration: SchedulerPrefDurationRange;
    autoAdjusted?: boolean;
  };
  lightWork: {
    duration: SchedulerPrefDurationRange;
    autoAdjusted?: boolean;
  };
  lunch: {
    start: SchedulerPrefTimeRange;
    end: SchedulerPrefTimeRange;
    duration: SchedulerPrefDurationRange;
    enabled: boolean;
    autoAdjusted?: boolean;
  };
  workout: {
    duration: SchedulerPrefDurationRange;
    start: SchedulerPrefTimeRange;
    end: SchedulerPrefTimeRange;
    enabled: boolean;
    autoAdjusted?: boolean;
  };
  dinner: {
    start: SchedulerPrefTimeRange;
    end: SchedulerPrefTimeRange;
    duration: SchedulerPrefDurationRange;
    enabled: boolean;
    autoAdjusted?: boolean;
  };
};

//@link: https://www.notion.so/demind-inc/Feature-AI-task-Scheduler-both-Web-Mobile-8e67ec1b8bf9433889f27a564f76b1f2#daacefbea6a04e6d9f097dad4c7d1f35
export const initialDefaultPrefItemState: PrefItemState = {
  workTime: {
    start: {
      time: '09:00',
      minimumTime: '00:00',
      maximumTime: '18:00',
    },
    end: {
      time: '18:00',
      minimumTime: '09:00',
      maximumTime: '23:59',
    },
  },
  deepWork: {
    duration: {
      durationMin: 4 * 60,
      minimumDurationMin: 0,
      maximumDurationMin: 9 * 60,
    },
  },
  lightWork: {
    duration: {
      durationMin: 90,
      minimumDurationMin: 0,
      maximumDurationMin: 9 * 60,
    },
  },
  lunch: {
    enabled: true,
    start: {
      time: '12:00',
      minimumTime: '00:00',
      maximumTime: '23:59',
    },
    end: {
      time: '13:00',
      minimumTime: '00:00',
      maximumTime: '23:59',
    },
    duration: {
      durationMin: 30,
      minimumDurationMin: 0,
      maximumDurationMin: 1 * 60, // start - end
    },
  },
  workout: {
    duration: {
      durationMin: 60,
      minimumDurationMin: 0,
      maximumDurationMin: 1 * 60, // start - end
    },
    enabled: true,
    start: {
      time: '18:00',
      minimumTime: '00:00',
      maximumTime: '23:59',
    },
    end: {
      time: '19:00',
      minimumTime: '00:00',
      maximumTime: '23:59',
    },
  },
  dinner: {
    enabled: true,
    start: {
      time: '18:00',
      minimumTime: '00:00',
      maximumTime: '23:59',
    },
    end: {
      time: '20:00',
      minimumTime: '00:00',
      maximumTime: '23:59',
    },
    duration: {
      durationMin: 60,
      minimumDurationMin: 0,
      maximumDurationMin: 2 * 60, // start - end
    },
  },
};

export const prefItemReducer = (
  state: PrefItemState,
  action: {
    type: SchedulerPrefType | 'initialize';
    payload: Partial<PrefItemState[SchedulerPrefType] | PrefItemState>;
  }
): PrefItemState => {
  if (action.type === 'initialize') {
    return action.payload as PrefItemState;
  }
  switch (action.type) {
    case 'workTime':
      const workTimePayload = action.payload as PrefItemState['workTime'];
      const workTimeChangedTimeKey = Object.keys(
        workTimePayload
      ) as (keyof PrefItemState['workTime'])[];

      const newWorkTime: PrefItemState['workTime'] = {
        ...state.workTime,
        ...workTimeChangedTimeKey.reduce(
          (acc, key) => {
            acc[key] = {
              ...state.workTime[key],
              ...workTimePayload[key],
            };
            if (key === 'start') {
              acc.end = {
                ...state.workTime.end,
                minimumTime: workTimePayload[key].time, // work end time should be after work start time
              };
            } else if (key === 'end') {
              acc.start = {
                ...state.workTime.start,
                maximumTime: workTimePayload[key].time, // work start time should be before work end time
              };
            }
            return acc;
          },
          {} as PrefItemState['workTime']
        ),
      };

      const maximumDurationMin = getDiffMinBetweenHHmm(
        newWorkTime.start.time,
        newWorkTime.end.time
      );
      return {
        ...state,
        workTime: newWorkTime,
        deepWork: {
          ...state.deepWork,
          duration: {
            ...state.deepWork.duration,
            maximumDurationMin,
          },
        },
        lightWork: {
          ...state.lightWork,
          duration: {
            ...state.lightWork.duration,
            maximumDurationMin,
          },
        },
        lunch: {
          ...state.lunch,
          start: {
            ...state.lunch.start,
            minimumTime: newWorkTime.start.time,
            maximumTime: state.lunch.end.time,
          },
          end: {
            ...state.lunch.end,
            minimumTime: state.lunch.start.time,
            maximumTime: newWorkTime.end.time,
          },
          duration: {
            ...state.lunch.duration,
            maximumDurationMin,
          },
        },
        workout: state.workout, // workout could be out of work time
        dinner: {
          ...state.dinner,
          start: {
            ...state.dinner.start,
            minimumTime: newWorkTime.start.time,
            maximumTime: state.dinner.end.time,
          },
          end: {
            ...state.dinner.end,
            minimumTime: state.dinner.start.time,
            maximumTime: newWorkTime.end.time,
          },
          duration: {
            ...state.dinner.duration,
            maximumDurationMin,
          },
        },
      };

    case 'deepWork':
      return {
        ...state,
        deepWork: {
          ...state.deepWork,
          ...action.payload,
        },
      };
    case 'lightWork':
      return {
        ...state,
        lightWork: {
          ...state.lightWork,
          ...action.payload,
        },
      };
    case 'lunch':
      const lunchPayload = action.payload as PrefItemState['lunch'];
      const lunchChangedTimeKey = Object.keys(lunchPayload) as (keyof PrefItemState['lunch'])[];
      return {
        ...state,
        lunch: {
          ...state.lunch,
          ...lunchChangedTimeKey.reduce(
            (acc, key) => {
              if (key === 'enabled') {
                acc[key] = lunchPayload[key];
                return acc;
              }
              acc[key] = {
                //@ts-ignore
                ...state.lunch[key],
                //@ts-ignore
                ...lunchPayload[key],
              };
              if (key === 'start') {
                if (acc.end) {
                  acc.end.minimumTime = lunchPayload[key].time; // lunch end time should be after lunch start time
                }
                acc.duration = {
                  ...state.lunch.duration,
                  maximumDurationMin: getDiffMinBetweenHHmm(
                    lunchPayload[key].time,
                    state.lunch.end.time
                  ),
                };
              } else if (key === 'end') {
                if (acc.start) {
                  acc.start.maximumTime = lunchPayload[key].time; // lunch start time should be before lunch end time
                }
                acc.duration = {
                  ...state.lunch.duration,
                  maximumDurationMin: getDiffMinBetweenHHmm(
                    state.lunch.start.time,
                    lunchPayload[key].time
                  ),
                };
              }
              return acc;
            },
            {} as PrefItemState['lunch']
          ),
        },
      };
    case 'dinner':
      const dinnerPayload = action.payload as PrefItemState['dinner'];
      const dinnerChangedTimeKey = Object.keys(dinnerPayload) as (keyof PrefItemState['dinner'])[];
      return {
        ...state,
        dinner: {
          ...state.dinner,
          ...dinnerChangedTimeKey.reduce(
            (acc, key) => {
              if (key === 'enabled') {
                acc[key] = dinnerPayload[key];
                return acc;
              }
              acc[key] = {
                //@ts-ignore
                ...state.dinner[key],
                //@ts-ignore
                ...dinnerPayload[key],
              };
              if (key === 'start') {
                if (acc.end) {
                  acc.end.minimumTime = dinnerPayload[key].time; // dinner end time should be after dinner start time
                }
                acc.duration = {
                  ...state.dinner.duration,
                  maximumDurationMin: getDiffMinBetweenHHmm(
                    dinnerPayload[key].time,
                    state.dinner.end.time
                  ),
                };
              } else if (key === 'end') {
                if (acc.start) {
                  acc.start.maximumTime = dinnerPayload[key].time; // dinner start time should be before dinner end time
                }

                acc.duration = {
                  ...state.dinner.duration,
                  maximumDurationMin: getDiffMinBetweenHHmm(
                    state.dinner.start.time,
                    dinnerPayload[key].time
                  ),
                };
              }
              return acc;
            },
            {} as PrefItemState['dinner']
          ),
        },
      };
    case 'workout':
      const workoutPayload = action.payload as PrefItemState['workout'];
      const workoutChangedTimeKey = Object.keys(
        workoutPayload
      ) as (keyof PrefItemState['workout'])[];

      return {
        ...state,
        workout: {
          ...state.workout,
          ...workoutChangedTimeKey.reduce(
            (acc, key) => {
              if (key === 'enabled') {
                acc[key] = workoutPayload[key];
                return acc;
              }
              acc[key] = {
                //@ts-ignore
                ...state.workout[key],
                //@ts-ignore
                ...workoutPayload[key],
              };
              if (key === 'start') {
                if (acc.end) {
                  acc.end.minimumTime = workoutPayload[key].time; // workout end time should be after workout start time
                }
                acc.duration = {
                  ...state.workout.duration,
                  maximumDurationMin: getDiffMinBetweenHHmm(
                    workoutPayload[key].time,
                    state.workout.end.time
                  ),
                };
              } else if (key === 'end') {
                if (acc.start) {
                  acc.start.maximumTime = workoutPayload[key].time; // workout start time should be before workout end time
                }

                acc.duration = {
                  ...state.workout.duration,
                  maximumDurationMin: getDiffMinBetweenHHmm(
                    state.workout.start.time,
                    workoutPayload[key].time
                  ),
                };
              }
              return acc;
            },
            {} as PrefItemState['workout']
          ),
        },
      };
    default:
      return {
        ...state,
        [action.type]: {
          ...(state[action.type] as object),
          ...action.payload,
        },
      };
  }
};
