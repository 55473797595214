import { CircadianPhase } from '@demind-inc/core';
import { EnergyLevelType } from '../../../data-access';

export const translateEnergyLevelFromPhase = (
  phase?: CircadianPhase
): EnergyLevelType | null | undefined => {
  if (!phase) {
    return null;
  }
  if (phase === 'default') {
    return 'sleep';
  }

  if (phase === 'evening_low') {
    return 'wind_down';
  }

  if ((['wakeup_low', 'morning_low'] as CircadianPhase[]).includes(phase)) {
    return 'low';
  }

  if ((['morning_normal', 'evening_normal', 'wakeup_normal'] as CircadianPhase[]).includes(phase)) {
    return 'normal';
  }

  if ((['morning_high', 'evening_high'] as CircadianPhase[]).includes(phase)) {
    return 'high';
  }
};
