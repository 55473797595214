import { Modal } from '@mui/material';
import './CoreActionModal.scss';
import { CloseOutlined, HelpOutline } from '@mui/icons-material';
import { useRecoilState } from 'recoil';
import { selectedCoreActionModalAtom } from '../../../../data-access';
import { CustomTooltip } from '../../../common';
import { useMemo } from 'react';
import { ACTIONS } from '../constants';

const CoreActionModal = () => {
  const [selectedModal, setSelectedModal] = useRecoilState(selectedCoreActionModalAtom);
  const handleClose = () => {
    setSelectedModal(null);
  };

  const targetModal = useMemo(
    () => ACTIONS.find((action) => action.id === selectedModal),
    [selectedModal]
  );

  if (!selectedModal || !targetModal) return null;

  return (
    <Modal open={!!selectedModal} onClose={handleClose} className="core-action-modal">
      <div className="core-action-modal__container">
        <div className="core-action-modal__header">
          <div className="core-action-modal__header__title-group">
            <h1 className="core-action-modal__header__title">{targetModal.header}</h1>
            {!!targetModal.tooltip && (
              <CustomTooltip
                title={targetModal.tooltip}
                className="core-action-modal__header__info__tooltip"
              >
                <HelpOutline className="core-action-modal__header__info" />
              </CustomTooltip>
            )}
          </div>
          <CloseOutlined
            fontSize="medium"
            className="core-action-modal__header__close"
            onClick={handleClose}
          />
        </div>
        {targetModal.component && <targetModal.component />}
      </div>
    </Modal>
  );
};

export default CoreActionModal;
