import {
  CalendarEvent,
  CalendarScheduleFrom,
  defaultEventCategorySet,
  ScheduledAction,
} from '@demind-inc/core';
import dayjs from 'dayjs';
import {
  ACTIVITY_EVENT_PREFIX,
  AI_SCHEDULER_EVENT_PREFIX,
  ENERGY_BOOST_EVENT_PREFIX,
} from './calendar.helpers';

/**
 * Translates the AI scheduled action to a temporal calendar event.
 * @param action - The AI scheduled action.
 * @returns The translated calendar event.
 */
export const translateAIScheduledActionToTemporalCalendarEvent = (
  action: ScheduledAction
): CalendarEvent => {
  const targetCategory = defaultEventCategorySet.find(({ id }) => id === action.categoryId);
  return {
    eventId: `${AI_SCHEDULER_EVENT_PREFIX}_${action.startDate}`,
    description: 'AI-Scheduled event, created from Lifestack (https://lifestack.ai).',
    summary: action.name,
    start: {
      date: action.startDate,
      timeZone: dayjs.tz.guess(),
    },
    end: {
      date: action.endDate,
      timeZone: dayjs.tz.guess(),
    },
    updatedDate: dayjs().toISOString(),
    createdDate: dayjs().toISOString(),
    categories: targetCategory ? [targetCategory] : [],
    scheduleFrom: 'aiScheduler',
    autoScheduleFrom: {
      id: action.id!,
      from: 'aiScheduler',
    },
    taskFrom: action.todoFrom,
  };
};

export const isTemporalAutoScheduledEvent = (
  eventId: string,
  targetTypes: Array<'activity' | 'energyBoost' | 'aiScheduler'>
) => {
  if (!eventId) {
    return false;
  }
  if (targetTypes.includes('activity') && eventId.includes(ACTIVITY_EVENT_PREFIX)) {
    return true;
  }
  if (targetTypes.includes('aiScheduler') && eventId.includes(AI_SCHEDULER_EVENT_PREFIX)) {
    return true;
  }
  if (targetTypes.includes('energyBoost') && eventId.includes(ENERGY_BOOST_EVENT_PREFIX)) {
    return true;
  }
  return false;
};
