import { Calendar, CalendarEvent } from '@demind-inc/core';
import { RBCEvent } from './types';
import { getCssVariable } from '../../../utils';
import { isTemporalAutoScheduledEvent } from '../../../helpers';
import dayjs from 'dayjs';

const primaryColor = getCssVariable('--color-primary');
export const ROUNDED_UP_MINUTES_RANGE = 15;

export const transformEventToRBCEvent = (
  event: CalendarEvent,
  targetCalendar?: Calendar
): RBCEvent => {
  const meAttendee = event.attendees?.find((attendee) =>
    [targetCalendar?.internalGCalendarId, targetCalendar?.rootEmail].includes(attendee.email)
  );
  const isDeclined = meAttendee ? meAttendee.responseStatus === 'declined' : false;

  return {
    ...event,
    title: event.summary,
    start: new Date(event.start.date),
    end: new Date(event.end.date),
    allDay: event.isAllDay,
    calendarColor: targetCalendar?.color!,
    isDeclined,
  };
};

export const transformAllDayEventToRBCEvent = (
  event: CalendarEvent,
  targetCalendar?: Calendar
): RBCEvent => {
  const meAttendee = event.attendees?.find((attendee) =>
    [targetCalendar?.internalGCalendarId, targetCalendar?.rootEmail].includes(attendee.email)
  );
  const isDeclined = meAttendee ? meAttendee.responseStatus === 'declined' : false;

  return {
    ...event,
    title: event.summary,
    start: new Date(event.start.date.substring(0, 19)), // Convert 2025-04-03T00:00:00.000Z -> 2025-04-03T00:00:00 so start time is 00:00
    end: new Date(event.end.date.substring(0, 19)),
    allDay: event.isAllDay,
    calendarColor: targetCalendar?.color!,
    isDeclined,
  };
};

export const transformCreatingEventToRBCEvent = (event: Partial<CalendarEvent>): RBCEvent => {
  return {
    ...event,
    ...(event.start ? { start: new Date(event.start.date) } : {}),
    ...(event.end ? { end: new Date(event.end.date) } : {}),
  } as RBCEvent;
};

export const transformRBCEventToCalendarEvent = (event: RBCEvent): CalendarEvent => {
  return {
    ...event,
    summary: event.title,
    isAllDay: event.allDay,
    start: { date: event.start.toISOString(), timeZone: dayjs.tz.guess() },
    end: { date: event.end.toISOString(), timeZone: dayjs.tz.guess() },
  } as CalendarEvent;
};

export const transfomrAutoScheduledEvents = (
  events: CalendarEvent[],
  calendarColor: string = ''
): RBCEvent[] => {
  return events.map(
    ({
      start,
      end,
      summary,
      eventId,
      description,
      color,
      calendarId,
      updatedDate,
      createdDate,
      categories,
      scheduleFrom,
      autoScheduleFrom,
      taskFrom,
    }) => ({
      start: new Date(start.date),
      end: new Date(end.date),
      title: summary,
      eventId,
      updatedDate,
      createdDate,
      description,
      color,
      calendarId,
      calendarColor,
      categories,
      scheduleFrom,
      autoScheduleFrom,
      taskFrom,
    })
  );
};

export const transformActivityEventsToRBCEvents = (events: CalendarEvent[]): RBCEvent[] => {
  return events.map((event) => {
    return {
      ...event,
      start: new Date(event.start.date),
      end: new Date(event.end.date),
      allDay: event.isAllDay,
      title: event.summary,
      color: event.color || primaryColor,
    };
  });
};

export const getEventStyles = (rbcEvent: RBCEvent, borderHorizontal = false) => {
  const baseStyles = { style: { padding: 0 } };

  // Background event
  if (!rbcEvent.eventId) {
    return {
      style: {
        backgroundColor: `${rbcEvent.color}0F`,
        border: `1px solid ${rbcEvent.color}66`,
        borderLeft: borderHorizontal ? `1px solid ${rbcEvent.color}66` : 'none',
        borderRight: borderHorizontal ? `1px solid ${rbcEvent.color}66` : 'none',
      },
    };
  }

  const isCreatingEvent = rbcEvent.eventId === 'creating';
  const isAutoScheduledEvent = isTemporalAutoScheduledEvent(rbcEvent.eventId, [
    'activity',
    'aiScheduler',
    'energyBoost',
  ]);

  if (isAutoScheduledEvent) {
    return {
      style: {
        ...baseStyles.style,
        backgroundColor: `${primaryColor}4D`,
        border: `1px dashed ${primaryColor}`,
      },
    };
  }

  return {
    style: {
      ...baseStyles.style,
      backgroundColor: isCreatingEvent ? 'white' : `${rbcEvent.color || primaryColor}66`,
      border: isCreatingEvent ? `solid 2px ${primaryColor}` : 'none',
    },
  };
};

/**
 * Returns a rounded up date and time based on the given date.
 * @param date - The date to round up.
 * @returns The rounded up date and time.
 */
export const getRoundUpDateTime = (date: dayjs.Dayjs) => {
  return date
    .add(ROUNDED_UP_MINUTES_RANGE - (date.get('minutes') % ROUNDED_UP_MINUTES_RANGE), 'minutes')
    .startOf('minutes');
};
