import React, { useCallback, useMemo } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { HeatmapDataType } from '@demind-inc/core';
import { SlotInfo } from 'react-big-calendar';

import { getCssVariable } from '../../../../utils';
import './EnergyPopup.scss';
import { ReactComponent as EnergyIcon } from '../../../../assets/circadian/energy.svg';
import EnergyTaskSuggestions from '../EnergyTaskSuggestions';
import {
  CreateEventProps,
  selectedCoreActionModalAtom,
  useRecommendations,
} from '../../../../data-access';
import { translateEnergyLevelFromPhase } from '../helpers';
import { energyBarSet } from '../../../Energy';
import dayjs from 'dayjs';
import { DEFAULT_START_END_DATE_DURATION_MIN, getRoundUpDateTime } from '../../CalendarTimeline';
import { useRecoilState } from 'recoil';
interface EnergyPopupProps {
  data: HeatmapDataType;
  onClose?: () => void;
  onCreateEvent?: (event: CreateEventProps) => void;
}

const EnergyPopup: React.FC<EnergyPopupProps> = ({
  data,
  onClose,
  onCreateEvent = () => void 0,
}) => {
  const { actions: tasks } = useRecommendations();

  const [_, setSelectedModal] = useRecoilState(selectedCoreActionModalAtom);

  const currentEnergyLevel = useMemo(() => translateEnergyLevelFromPhase(data?.phase), [data]);

  const energyLevel = Math.round(data.value * 100);

  const { targetStartTime, targetEndTime, timeZone } = useMemo(() => {
    const targetTime = dayjs(data?.y);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const targetStartTime = getRoundUpDateTime(targetTime);
    const targetEndTime = getRoundUpDateTime(targetTime).add(
      DEFAULT_START_END_DATE_DURATION_MIN,
      'minutes'
    );
    return { targetStartTime, targetEndTime, timeZone };
  }, [data?.y]);

  const handleTaskClick = useCallback(
    (taskId: string) => {
      const taskName = tasks.find((task) => task.recommendedTaskId === taskId)?.name;
      onClose();
      onCreateEvent({
        title: taskName,
        description: 'Recommended event, created from Lifestack (https://lifestack.ai).',
        start: targetStartTime.toISOString(),
        end: targetEndTime.toISOString(),
        taskId,
      });
    },
    [tasks, targetStartTime, targetEndTime]
  );

  const handleAddEvent = useCallback(() => {
    onClose();
    onCreateEvent({
      start: targetStartTime.toISOString(),
      end: targetEndTime.toISOString(),
    });
  }, [targetStartTime, targetEndTime]);

  const handleAISchedulerClick = () => {
    onClose();
    setSelectedModal('ai_scheduler');
  };

  const handleEnergyBoostsClick = () => {
    onClose();
    setSelectedModal('energy_boost');
  };

  return (
    <Box className="energy-popup">
      <Box className="energy-popup__header">
        <Box className="energy-popup__energy-indicator">
          <EnergyIcon className="energy-popup__energy-icon" />
          <Typography variant="h4" className="energy-popup__energy-level">
            {energyLevel}
          </Typography>
        </Box>
        <IconButton onClick={onClose} className="energy-popup__close">
          <CloseIcon />
        </IconButton>
      </Box>
      <div className="energy-popup__content">
        <Typography className="energy-popup__title">
          {energyBarSet[currentEnergyLevel]?.action}
        </Typography>
        <EnergyTaskSuggestions
          tasks={tasks}
          hasEnergyBoosts={currentEnergyLevel === 'low'}
          hasAiScheduler={!!currentEnergyLevel}
          onClickTask={handleTaskClick}
          onClickAIScheduler={handleAISchedulerClick}
          onClickEnergyBoosts={handleEnergyBoostsClick}
          // onClickAddPreset={handleAddPresetClick}
        />
        <Box className="energy-popup__actions">
          {/* <button className="energy-popup__action-button">Customize Task</button> */}
          <button className="energy-popup__action-button" onClick={handleAddEvent}>
            Add Event
          </button>
        </Box>
      </div>
    </Box>
  );
};

export default EnergyPopup;
