import { atom } from 'recoil';

export const isFetchingCalendarDataAtom = atom<boolean>({
  key: 'isFetchingCalendarData',
  default: false,
});

export const isSyncingCalEventsAtom = atom<boolean>({
  key: 'isSyncingCalEvents',
  default: false,
});

export const visibleCalendarIdsAtom = atom<string[]>({
  key: 'visibleCalendarIds',
  default: [],
});

export const syncErrorRootEmailAtom = atom<string | undefined>({
  key: 'syncErrorRootEmail',
  default: undefined,
});
