import { useQuery } from '@tanstack/react-query';

import { energyApi } from '../api';

interface UseEnergyWeeklyTrendParams {
  dataTrendId: string;
  startDate: string;
  endDate: string;
  enabled?: boolean;
}

interface UseEnergyWeeklyTrendQueryParams {
  queryKey: ['lifestack.trend.energy.weekly', UseEnergyWeeklyTrendParams];
}

export const useEnergyWeeklyTrend = ({
  dataTrendId,
  startDate,
  endDate,
  enabled = true,
}: UseEnergyWeeklyTrendParams) => {
  const energyTrendQuery = useQuery({
    queryKey: ['lifestack.trend.energy.weekly', { dataTrendId, startDate, endDate }],
    queryFn: ({ queryKey }: UseEnergyWeeklyTrendQueryParams) => {
      const [, { dataTrendId, startDate, endDate }] = queryKey;
      return energyApi
        .getWeeklyEnergyTrend(dataTrendId, startDate, endDate)
        .then(({ data }) => data);
    },
    refetchOnWindowFocus: false,
    staleTime: 15000, // 15s
    enabled: !!dataTrendId && enabled,
  });

  return {
    energyTrend: energyTrendQuery.data,
    ...energyTrendQuery,
  };
};
