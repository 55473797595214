import { useMemo, useState } from 'react';
import {
  syncedDatesState,
  useAuthContext,
  useSyncCalEventsApi,
  useSyncTodosApi,
} from '../data-access';
import { RefreshingTarget } from './types';
import { useRecoilValue } from 'recoil';

export const useRefreshData = () => {
  const { reSyncCalEvents } = useSyncCalEventsApi();
  const { reSyncTasks } = useSyncTodosApi();
  const { user } = useAuthContext();
  const syncedDates = useRecoilValue(syncedDatesState);

  const [refreshingTarget, setRefreshingTarget] = useState<RefreshingTarget | undefined>(undefined);

  const { startTime, endTime } = useMemo(() => {
    const startTime = syncedDates[0].startOf('day').toISOString();
    const endTime = syncedDates[syncedDates.length - 1].endOf('day').toISOString();

    return { startTime, endTime };
  }, [syncedDates]);

  const refreshData = async () => {
    setRefreshingTarget('calendar');
    if (user.calendarIds?.length) {
      await reSyncCalEvents(user.calendarIds, startTime, endTime);
    }
    setRefreshingTarget('task');
    await reSyncTasks(user.todoTasksBoardIds ?? []);
    setRefreshingTarget(undefined);
  };

  return { refreshingTarget, refreshData };
};
