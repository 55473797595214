import { FC, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import axios from 'axios';

import { aiSchedulerFeedbackApiInfoAtom, useAuthContext } from '../../../../data-access';
import { useRecoilValue } from 'recoil';

interface AISchedulerFeedbackDialogProps {
  visible: boolean;
  onClose: () => void;
}

const formUrl =
  'https://docs.google.com/forms/d/e/1FAIpQLSdyHm6Dxg95R9WKTeZAwyOmvj-XPKW4L-gdmxMNjk-6EF2zXA/formResponse';
enum FormKeys {
  userId = 'entry.577930260',
  feedback = 'entry.1193364122',
  appApiRes = 'entry.830765065',
  preferences = 'entry.1105158981',
  currentTime = 'entry.570017597',
  scienceApiReq = 'entry.1082028300',
  scienceApiRes = 'entry.431039372',
}

const AISchedulerFeedbackDialog: FC<AISchedulerFeedbackDialogProps> = ({ visible, onClose }) => {
  const { user } = useAuthContext();
  const [feedback, setFeedback] = useState('');
  const aiSchedulerFeedbackApiInfo = useRecoilValue(aiSchedulerFeedbackApiInfoAtom);

  const canSend = useMemo(() => !!feedback, [feedback]);

  const handleSend = async () => {
    if (!canSend) {
      return;
    }

    const submitParams = new FormData();
    submitParams.append(FormKeys.userId, user.userId);
    submitParams.append(FormKeys.feedback, feedback);
    if (aiSchedulerFeedbackApiInfo) {
      submitParams.append(FormKeys.appApiRes, aiSchedulerFeedbackApiInfo.appApiRes);
      submitParams.append(FormKeys.preferences, aiSchedulerFeedbackApiInfo.preference);
      submitParams.append(FormKeys.currentTime, aiSchedulerFeedbackApiInfo.currentTime);
      submitParams.append(FormKeys.scienceApiReq, aiSchedulerFeedbackApiInfo.scienceApiReq);
      submitParams.append(FormKeys.scienceApiRes, aiSchedulerFeedbackApiInfo.scienceApiRes);
    }

    try {
      await axios.post(formUrl, submitParams);
    } catch (error) {
      console.error('Error', 'Failed to submit feedback', error);
    } finally {
      onClose();
    }
  };

  return (
    <Dialog open={visible} onClose={onClose} maxWidth={'sm'} fullWidth>
      <DialogTitle>How can we improve your schedule?</DialogTitle>
      <DialogContent style={{ flexDirection: 'column', display: 'flex', gap: 10 }}>
        <DialogContentText>
          Please share your thoughts on the schedule generated to help us make better schedules for
          you!
        </DialogContentText>
        <TextField
          id="ai-scheduler-basic"
          label="Feedback"
          placeholder="e.g. Deep work feels too long"
          variant="standard"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button onClick={handleSend} disabled={!canSend}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AISchedulerFeedbackDialog;
