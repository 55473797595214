import React from 'react';

import { DataMenuItem } from '../../data-access';
import { getCssVariable } from '../../utils';
import { DataEventDashboard } from '../../pages/DataView/DataEventDashboard';
import { DataSleepDashboard } from '../../pages/DataView/DataSleepDashboard';
import { DataTaskDashboard } from '../../pages/DataView/DataTaskDashboard';
import { DataCircadianDashboard } from '../../pages/DataView/DataCircadianDashboard';
import { EventDataTrendWithData } from '@demind-inc/core';
import { CustomTooltipContentProps } from '../common';
import { circadianTooltipInfo } from '../Energy';
import { sleepExplanationInfo } from './MenuBar';

interface DataViewMenuItem {
  id: DataMenuItem;
  title: string;
  component: React.FC<{ className?: string }>;
  explanation?: CustomTooltipContentProps;
}

interface EventItemData {
  eventKey: keyof EventDataTrendWithData;
  label: string;
  iconType: 'stress' | 'energy';
}

export const DataViewMenuItems: DataViewMenuItem[] = [
  {
    id: 'dataCircadian',
    title: 'Circadian Rhythm',
    component: DataCircadianDashboard,
    explanation: circadianTooltipInfo,
  },
  {
    id: 'dataTask',
    title: 'Task Completion',
    component: DataTaskDashboard,
  },
  {
    id: 'dataSleep',
    title: 'Sleep',
    component: DataSleepDashboard,
    explanation: sleepExplanationInfo,
  },
  {
    id: 'dataTrend',
    title: 'Most Energizing Event',
    component: DataEventDashboard,
  },
];

export const colorBlueBase = getCssVariable('--color-blue-base');
export const colorGreenBase = getCssVariable('--color-green-base');
export const colorOrangeBase = getCssVariable('--color-orange-base');
export const colorBlueLight = getCssVariable('--color-blue-light');
export const grayPurple = getCssVariable('--color-gray-purple');
export const grayBase = getCssVariable('--color-gray-base');
export const purpleTaro = getCssVariable('--color-purple-taro');
export const primaryColor = getCssVariable('--color-primary');

export const CHART_HEIGHT = 160;

export const sleepSummaryColor = {
  deep: colorBlueBase,
  light: colorBlueLight,
  rem: colorOrangeBase,
  awake: colorGreenBase,
};

export const sleepSummaryText = {
  deep: 'Deep',
  light: 'Light',
  rem: 'REM',
  awake: 'Awake',
};

export const eventTypeInfo: EventItemData[] = [
  {
    eventKey: 'energyBoostingEvent',
    label: 'Most energizing event',
    iconType: 'energy',
  },
  {
    eventKey: 'energyDrainingEvent',
    label: 'Most draining event',
    iconType: 'energy',
  },
  {
    eventKey: 'stressfulEvent',
    label: 'Most stressful event',
    iconType: 'stress',
  },
];
