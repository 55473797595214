import { FC, useState } from 'react';
import { TaskLabel } from '@demind-inc/core';
import { MoreHorizOutlined } from '@mui/icons-material';

import { getCssVariable } from '../../../../utils';
import { ColorPickerDropdown } from './ColorPickerDropdown';

import './TaskLabelSelectOption.scss';
import { IconButton } from '@mui/material';

interface TaskLabelSelectOptionProps {
  label: TaskLabel;
  allLabels: TaskLabel[];
  onUpdateLabel?: (label: TaskLabel) => void;
}

const grayLightest = getCssVariable('--color-gray-lightest');

const TaskLabelSelectOption: FC<TaskLabelSelectOptionProps> = ({
  label,
  allLabels = [],
  onUpdateLabel = () => void 0,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const labelData = allLabels.find((o) => o.id === label.id) || label;

  const handleDotClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (color: string) => {
    onUpdateLabel({
      ...labelData,
      color,
    });
  };

  return (
    <div className="task-label-select-option">
      <div className="task-label-select-option__left">
        <div
          className="task-label-select-option__color-dot"
          style={{ backgroundColor: labelData.color || label.color || grayLightest }}
        />
        <div className="task-label-select-option__name">{label.name || '(No name)'}</div>
      </div>
      <div className="task-label-select-option__right">
        <IconButton onClick={handleDotClick} data-ignore-select="true">
          <MoreHorizOutlined className="task-label-select-option__more" />
        </IconButton>
      </div>
      <ColorPickerDropdown
        label={labelData}
        anchorEl={anchorEl}
        onClose={handleClose}
        onColorChange={handleColorChange}
      />
    </div>
  );
};

export default TaskLabelSelectOption;
